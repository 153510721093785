import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33a66285"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "goods-dingwei contents" }
const _hoisted_2 = { class: "goods-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoodsInfo = _resolveComponent("GoodsInfo")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("i", {
        class: "icon iconfont icon-shanchu",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
      }),
      _createVNode(_component_swiper, {
        modules: _ctx.modules,
        "slides-per-view": 7.5,
        "space-between": 50,
        onSwiper: _ctx.onSwiper,
        onSlideChange: _ctx.onSlideChange
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodsList, (goods, index) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, null, {
              default: _withCtx(() => [
                _createVNode(_component_GoodsInfo, {
                  goods: (goods as Object)
                }, null, 8, ["goods"])
              ]),
              _: 2
            }, 1024))
          }), 256))
        ]),
        _: 1
      }, 8, ["modules", "slides-per-view", "onSwiper", "onSlideChange"])
    ])
  ]))
}