<template>
	<div class="goods-box">
		<div class="new-goods-list">
			<swiper
				:slides-per-view="5"
				:space-between="20"
				:loop="true"
				:slidesPerGroup="5"
				:modules="modules"
				:navigation="navigations"
				@swiper="onSwiper"
				@slideChange="onSlideChange">
				<!-- v3文档地址 https://swiperjs.com/vue   -->
				<swiper-slide v-for="(goods, index) in newGoodsList.data">
					<NewGoodsInfo :goods="goods"></NewGoodsInfo>
				</swiper-slide>
			</swiper>
		</div>
		<div class="swiper-button-prev"></div>
		<div class="swiper-button-next"></div>
	</div>
</template>

<script>
import { defineComponent } from "vue"
import { Swiper, SwiperSlide } from "swiper/vue"
import NewGoodsInfo from "@/components/goods/project/NewGoodsInfo.vue"
import { Navigation } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
export default defineComponent({
	name: "NewGoodsList",
	components: {
		Swiper,
		SwiperSlide,
		NewGoodsInfo,
	},
	props: {
		newGoodsList: {
			type: Object,
			default: () => ({}),
		},
	},
	setup() {
		const onSwiper = (swiper) => {
			console.log(swiper)
		}
		const onSlideChange = () => {
			console.log("slide change")
		}
		let navigations = {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		}
		return {
			onSwiper,
			onSlideChange,
			modules: [Navigation],
			navigations,
		}
	},
})
</script>

<style lang="scss" scoped>
.goods-box {
	position: relative;
	width: 100%;
	background-color: #f3f5f7;
	margin-top: 50px;
	margin-bottom: 50px;
	.swiper-button-prev {
		left: 0;
		width: 50px;
		&::after {
			content: " ";
			width: 50px;
			height: 43px;
			background-image: url(@/assets/activity/goods_left.png);
		}
	}
	.swiper-button-next {
		right: 0;
		width: 50px;
		&::after {
			content: " ";
			width: 50px;
			height: 43px;
			background-image: url(@/assets/activity/goods_right.png);
		}
	}
	.new-goods-list {
		width: 1320px;
		margin: 0 auto;
	}
}
</style>
