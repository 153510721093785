<template>
	<div class="footer">
		<div class="contents">
			<div class="fd-link">
				<dl>
					<dt><a href="#">关于本站1</a></dt>
					<dd><a href="#">使用规则</a></dd>
					<dd><a href="#">返点规则</a></dd>
					<dd><a href="#">合作协议</a></dd>
					<dd><a href="#">隐私政策</a></dd>
				</dl>
				<dl>
					<dt><a href="#">帮助中心</a></dt>
					<dd><a href="#">购物流程</a></dd>
					<dd><a href="#">发票制度</a></dd>
					<dd><a href="#">支付方式</a></dd>
					<dd><a href="#">资料下载</a></dd>
				</dl>
				<dl>
					<dt><a href="#">关于我们</a></dt>
					<dd><a href="#">网站介绍</a></dd>
					<dd><a href="#">服务理念</a></dd>
					<dd><a href="#">联系我们</a></dd>
				</dl>
				<dl>
					<dt><a href="#">关联网站</a></dt>
					<dd><a href="#">辰星（天津）自动化设备有限公司</a></dd>
				</dl>
			</div>

			<div class="fd-tel">
				<p class="title">企业服务热线:</p>
				<span class="tel">400-653-7789</span>
				<span class="tel phone">155-2241-5492</span>
				<p class="service">服务时间：8:30-17:30 (周一至周六,法定节假日除外)</p>
				<p class="info">企业服务邮箱 : shejiyuan@tjchenxing.com</p>
			</div>

			<div class="fd-r">
				<div class="qr">
					<div class="pic">
						<img src="@/assets/image/公众号.png" />
					</div>
					<p>微信公众号d</p>
				</div>
				<div class="share">
					<a href="#" class="bds_sqq" title="分享到QQ好友"></a>
					<a
						href="#"
						class="bds_weixin"
						data-cmd="weixin"
						title="分享到微信"></a>
					<a href="#" class="bds_tsina" title="分享到新浪微博"></a>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup></script>

<style scoped>
.footer {
	width: 100%;
	min-width: 1440px;
	position: relative;
	text-align: left;
	padding-top: 53px;
	padding-bottom: 53px;
	overflow: hidden;
	background: url(@/assets/icon/fd-map.png) center no-repeat;
}
.footer::after {
	content: "";
	position: absolute;
	right: 0;
	top: 0;
	height: 2px;
	width: 43%;
	background-color: #2655ae;
}
.footer::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 2px;
	width: 57%;
	background-color: #fbb113;
}
.contents {
	width: 1440px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}
.contents .fd-link {
	width: 800px;
	margin-left: 80px;
}

.contents .fd-tel {
	width: 430px;
}
.contents .fd-r {
	width: 114px;
}
.fd-link dl {
	float: left;
	margin-right: 98px;
}
.fd-link dl:last-child {
	margin-right: 0;
}
.fd-link dl dt {
	margin-bottom: 9px;
}

.fd-link dl dt a {
	font-size: 20px;
	display: block;
	line-height: 32px;
	font-weight: 700;
}

.fd-link dl dd a {
	display: block;
	font-size: 14px;
	line-height: 34px;
	color: #666;
}
.fd-tel {
	border-left: 1px solid #e5e5e5;
	padding-left: 50px;
}
.fd-tel p {
	font-size: 16px;
}
.fd-tel .tel {
	font-size: 40px;
	font-family: Impact;
	display: block;
	margin-top: 8px;
	margin-bottom: 15px;
	padding-left: 45px;
	background: url(@/assets/icon/i-07.png) left center no-repeat;
	color: #333333;
}
.fd-tel .phone {
	background: url(@/assets/icon/i-08.png) left center no-repeat;
	margin-bottom: 8px;
}
.fd-tel .service {
	margin-bottom: 10px;
}
.fd-r .qr .pic img {
	width: 100%;
}
.fd-r .qr .pic {
	margin-bottom: 12px;
	width: 110px;
	height: 110px;
}
.fd-r .qr p {
	text-align: center;
}
/* .share .bdsharebuttonbox{
        display: flex;
    } */

.share {
	width: 200px;
}
.share a {
	width: 30px;
	height: 30px;
	padding: 0;
	margin: 18px 0 0 8px;
	background-position: center;
	background-repeat: no-repeat;
	float: none;
	display: inline-block;
}

.share .bds_sqq {
	background-image: url(@/assets/icon/i-10.png);
}
.share .bds_weixin {
	background-image: url(@/assets/icon/i-11.png);
}
.share .bds_tsina {
	background-image: url(@/assets/icon/i-12.png);
}
</style>
