
import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import CurrentAddress from "@/components/common/CurrentAddress.vue";
import HtmlFooter from "../../components/HtmlFooter.vue";
import HomeHead from "../../components/HomeHead.vue";
import {
  BaiduMap,
  BmMarker,
  BmLabel,
  BmInfoWindow,
  BmControl,
} from "vue-baidu-map-3x";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

export default defineComponent({
  components: {
    HtmlFooter,
    HomeHead,
    CurrentAddress,
    BaiduMap,
    BmMarker,
    BmLabel,
    BmInfoWindow,
    BmControl,
  },
  setup() {
    useRoute();
    let id = ref(0);

    if (!useRoute().params.id) {
      id.value = 1;
    } else {
      id.value = parseInt(useRoute().params.id as string);
    }

    let header: any = reactive({});
    let route = useRoute();
    let router = useRouter();
    watch(route, () => {
      router.go(0);
    });

    let gotoId = () => {
      nextTick(() => {
        header = document.getElementById("headerTop" + id.value);
        window.scrollTo({
          top: header.offsetTop - 80,
          behavior: "smooth",
        });
      });
    };

    onMounted(() => {
      gotoId();
      useHead({
        // Can be static or computed
        title: () => "关于我们_设纪元",
        meta: [
          {
            name: `description`,
            content: () =>
              "设纪元是一个专注于工业自动化方案共享、打通工业机器人上下游产业链的综合平台，打造一个专业、高品质的工业自动化设计资料共享平台。通过上传、分享、合作签约的形式，发挥平台作用",
          },
          {
            name: `keywords`,
            content: () => "设纪元_工业自动化设计方案_工业机器人供应链",
          },
        ],
      });
    });

    return {};
  },
});
