
import { reactive, defineComponent, ref, inject } from "vue";
export default defineComponent({
  emits: ["changeState"],
  props: {
    work: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    let changeState = (val: any) => {
      context.emit("changeState", val);
    };

    //console.log(props.work.data);

    // const work = [
    //   {
    //     number: "pw202207231107561908",
    //     name: "末端执行器20220722.zip",
    //     type: "末端执行器(100-500)币",
    //     state: "审核中",
    //   },
    // ];

    return {
      // work,
      changeState,
    };
  },
});
