
import { defineComponent, inject, ref, watch } from "vue";

export default defineComponent({
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    dialogwidth:{
      type: String,
      default: '50%'
    },
    addressItems:{
      type: Object,
      default: () => ({})
    }
  },
  setup(props: any) {
    const dialogVisible = ref(false);
    watch(dialogVisible, (newvalue, oldvalue) => {
      console.log(newvalue, oldvalue);
    });
    const setVisible = (val: boolean) => {
      dialogVisible.value = val;
    };
    return {
      dialogVisible,
      setVisible,
    };
  },
});
