
import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  provide,
  inject,
} from "vue"
import type { FormInstance, FormRules } from "element-plus"
import { EluiChinaAreaDht } from "elui-china-area-dht"
import { orderApi, userApi } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
import InvoiceSubmitVue from "./InvoiceSubmit.vue"
import { invoiceType } from "@/utils/apitype/invoiceType"
const chinaData = new EluiChinaAreaDht.ChinaArea().chinaAreaflat

export default defineComponent({
  components: { EluiChinaAreaDht, InvoiceSubmitVue },
  emits: ["mclose", "changeGoShow"],
  name: "invoicefrom",
  props: {
    recordId: {
      type: String,
      default: () => ({}),
    },
    lastInfor: {
      type: Object,
      default: () => ({}),
    },
    goShow: {
      type: Boolean,
      default: true,
    },
    from: {
      type: Number,
      default: 1,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  setup(props: any, context) {
    interface result {
      data: any
    }

    let addressItem: result = reactive({
      data: {},
    })

    let address = reactive({
      data: {
        userid: 0,
        province: "", //省
        city: "", //市
        area: "", //区
        area_code: "",
        city_code: "",
        province_code: "",
        address: "", //产品详细信息
        default: "", //是否默认
        contacts: "", //联系人
        phonenumber: "", //联系手机号
        id: 0,
      },
    })
    if (props.lastInfor.data.city) {
      // address.data.city = props.lastInfor.data.city
      //todo
    }
    let isShow = ref(false)
    interface userFormType {
      addr: Array<string>
    }
    let userForm: userFormType = reactive({
      addr: [],
    })

    // 修改地址
    let updateAddress = (address: any) => {
      show.value = !show
      addressItem.data = address
      initAddress()
      // console.log(addressItem.data, "111");
    }

    //更新初始值
    let initAddress = () => {
      isShow.value = false
      if (addressItem.data.contacts) {
        address.data = Object.assign({}, address.data, addressItem.data)
        //console.log(address.data, "new-address-data");
        if (parseInt(address.data.area_code) > 0) {
          userForm.addr = [
            address.data.province_code,
            address.data.city_code,
            address.data.area_code,
          ]
        } else {
          userForm.addr = [address.data.province_code, address.data.city_code]
        }
        // console.log(userForm);
      } else {
        address.data = {
          userid: 0,
          province: "", //省
          city: "", //市
          area: "", //区
          area_code: "",
          city_code: "",
          province_code: "",
          address: "", //产品详细信息
          default: "", //是否默认
          contacts: "", //联系人
          phonenumber: "", //联系手机号
          id: 0,
        }
        userForm.addr = []
      }
      nextTick(() => {
        isShow.value = true
      })
    }

    initAddress()

    let close = () => {
      context.emit("mclose", 1)
    }

    let mclose = () => {
      context.emit("mclose")
    }

    let close1 = () => {
      show.value = true
    }

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return
      formEl.validate((valid) => {
        // console.log("开始了");
        if (valid) {
          if (address.data.id < 1) {
            // console.log(address.data.id, "id");
            //添加收货地址
            userApi.addAddress(address.data).then((res: any) => {
              // console.log(res);
              if (res.errno == 0) {
                sjyAlert("添加成功", "success", close1)
                getAddressList()
              } else {
                sjyAlert(res.errmsg, "error")
              }
            })
          } else {
            // 更改收货地址
            userApi.updateAddress(address.data).then((res: any) => {
              // console.log(res, "aa");
              if (res.errno == 0) {
                sjyAlert("修改成功", "success")
                // console.log("修改成功!");
                getAddressList()
                show.value = true
              } else {
                sjyAlert(res.errmsg, "error", close1)
              }
            })
          }
        } else {
          console.log("error submit!")
          return false
        }
      })
      //console.log("submit!");
    }

    // watch(initAddress, (newvalue, oldvalue) => {
    //   initAddress();
    // });

    let onChange = (e: any) => {
      // console.log("我执行了");
      address.data.province = chinaData[e[0]].label
      address.data.city = chinaData[e[1]].label
      if (e.length > 2) {
        address.data.area = chinaData[e[2]].label
        address.data.area_code = chinaData[e[2]].value
      } else {
        address.data.area = ""
        address.data.area_code = ""
      }

      address.data.province_code = chinaData[e[0]].value
      address.data.city_code = chinaData[e[1]].value

      //console.log(address.data);
    }

    //发票信息
    const radio1 = ref("1")

    // 普通发票
    const radio2 = ref("1")
    const validatePass = (rule: any, value: any, callback: any) => {
      // console.log(value,"哈哈哈，没有值",userForm.addr);

      if (userForm.addr.length == 0) {
        callback(new Error("请选择收货人地区"))
      } else {
        callback()
      }
    }

    const ruleFormRefs = ref<FormInstance>()
    const rules = reactive<FormRules>({
      contacts: [
        {
          required: true,
          message: "请输入收货人姓名",
          trigger: "blur",
        },
        { min: 2, max: 5, message: "姓名长度为2-5字符", trigger: "blur" },
      ],
      phonenumber: [
        {
          required: true,
          message: "请输入收货人手机号",
          trigger: "blur",
        },
        {
          pattern: /^1[3456789]\d{9}$/,
          message: "手机号格式错误",
          trigger: "blur",
        },
      ],

      area: [{ validator: validatePass, trigger: "blur" }],
      address: [
        {
          required: true,
          message: "请输入收货的详细地址",
          trigger: "blur",
        },
      ],
    })

    const ruleFormRef1 = ref<FormInstance>()
    const rules1 = reactive<FormRules>({
      name: [
        {
          required: true,
          message: "请输入单位名称",
          trigger: "blur",
        },
      ],
      address: [
        {
          required: true,
          message: "请选择收票地址账号",
          trigger: "blur",
        },
      ],
      number: [
        {
          required: true,
          message: "请输入单位税号",
          trigger: "blur",
        },
      ],
    })

    const ruleFormRef2 = ref<FormInstance>()
    const rules2 = reactive<FormRules>({
      name: [
        {
          required: true,
          message: "请输入您的姓名",
          trigger: "blur",
        },
      ],
      address: [
        {
          required: true,
          message: "请选择收票地址账号",
          trigger: "blur",
        },
      ],
    })
    // 普通发票个人表单
    const commonselfForm = reactive({
      name: "",
      address: "",
    })

    // 普通发票单位表单
    const commonForm = reactive({
      name: "",
      address: "",
      number: "",
    })
    //专用发票表单
    const specialForm = reactive({
      name: "",
      number: "",
      company_address: "",
      mobile: "",
      bank: "",
      card: "",
      address: "",
    })

    const ruleFormRef = ref<FormInstance>()
    const rule = reactive<FormRules>({
      name: [
        {
          required: true,
          message: "请输入单位名称",
          trigger: "blur",
        },
      ],
      number: [
        {
          required: true,
          message: "请输入单位税号",
          trigger: "blur",
        },
      ],
      company_address: [
        {
          required: true,
          message: "请输入单位地址",
          trigger: "blur",
        },
      ],
      mobile: [
        {
          required: true,
          message: "请输入单位电话",
          trigger: "blur",
        },
        {
          pattern: /^(\+\d{1,2}\s?)?1[3-9]\d{9}$|^0\d{2,3}-\d{7,8}$/,
          message: "手机号格式错误",
          trigger: "blur",
        },
      ],
      bank: [
        {
          required: true,
          message: "请输入开户银行",
          trigger: "blur",
        },
      ],
      card: [
        {
          required: true,
          message: "请输入银行账号",
          trigger: "blur",
        },
      ],
      address: [
        {
          required: true,
          message: "请选择收票地址账号",
          trigger: "blur",
        },
      ],
    })

    // 普通个人提交
    const submitForm = async (
      formEl: FormInstance | undefined,
      val: number
    ) => {
      chessaddress(0)
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          selfForm.people = commonselfForm.name
          selfForm.fid = props.recordId
          selfForm.address = addressList.data[0].address
          selfForm.name = addressList.data[0].contacts
          selfForm.phone = addressList.data[0].phonenumber
          selfForm.province = addressList.data[0].province
          selfForm.city = addressList.data[0].city
          selfForm.area = addressList.data[0].area
          context.emit("changeGoShow", false)
          //goShow.value = false;
          // userApi.getInvoice(selfForm).then((res: any) => {
          //   if (res.errno == 0) {
          //     sjyAlert("提交成功", "success");
          //     context.emit("mclose");
          //   } else {
          //     sjyAlert(res.errmsg, "error");
          //   }
          // });
        } else {
          console.log("error submit!", fields)
        }
      })
    }

    // 普通单位提交
    const submitFormone = async (formEl: FormInstance | undefined) => {
      chessaddress(0)
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          firmForm.company = commonForm.name
          firmForm.duty = commonForm.number
          firmForm.fid = props.recordId
          firmForm.address = addressList.data[0].address
          firmForm.name = addressList.data[0].contacts
          firmForm.phone = addressList.data[0].phonenumber
          firmForm.province = addressList.data[0].province
          firmForm.city = addressList.data[0].city
          firmForm.area = addressList.data[0].area
          context.emit("changeGoShow", false)
          //goShow.value = false;
          // userApi.getInvoice(firmForm).then((res: any) => {
          //   if (res.errno == 0) {
          //     sjyAlert("提交成功", "success");
          //     context.emit("mclose");
          //   } else {
          //     sjyAlert(res.errmsg, "error");
          //   }
          // });
        } else {
          console.log("error submit!", fields)
        }
      })
    }
    // 增值专用提交
    const submitFormtwo = async (formEl: FormInstance | undefined) => {
      chessaddress(0)
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          exclusiveForm.company = specialForm.name
          exclusiveForm.duty = specialForm.number
          exclusiveForm.com_address = specialForm.company_address
          exclusiveForm.com_mobile = specialForm.mobile
          exclusiveForm.bank = specialForm.bank
          exclusiveForm.bank_nu = specialForm.card
          exclusiveForm.fid = props.recordId
          exclusiveForm.address = addressList.data[0].address
          exclusiveForm.name = addressList.data[0].contacts
          exclusiveForm.phone = addressList.data[0].phonenumber
          exclusiveForm.province = addressList.data[0].province
          exclusiveForm.city = addressList.data[0].city
          exclusiveForm.area = addressList.data[0].area
          context.emit("changeGoShow", false)
          //goShow.value = false;
          // userApi.getInvoice(exclusiveForm).then((res: any) => {
          //   if (res.errno == 0) {
          //     sjyAlert("提交成功", "success");
          //     context.emit("mclose");
          //   } else {
          //     sjyAlert(res.errmsg, "error");
          //   }
          // });
        } else {
          // console.log("error submit!", fields);
        }
      })
    }

    let show = ref(true)
    let addlocation = () => {
      show.value = !show
    }

    let down = ref(false)

    // 要传递给后端的三种类型的表单
    //普通个人
    const selfForm = reactive({
      fid: "",
      type: "1",
      iv_type: "1",
      people: "",
      address: "",
      name: "",
      phone: "",
      province: "",
      city: "",
      area: "",
      invoice_comtent: "信息服务费",
    })
    // 普通企业
    let firmForm = reactive({
      fid: "",
      type: "2",
      iv_type: "1",
      company: "",
      address: "",
      duty: "",
      name: "",
      phone: "",
      province: "",
      city: "",
      area: "",
      invoice_comtent: "信息服务费",
    })

    // 增值税专用
    let exclusiveForm = reactive({
      fid: "",
      iv_type: "2",
      company: "",
      address: "",
      duty: "",
      name: "",
      phone: "",
      province: "",
      city: "",
      area: "",
      com_address: "",
      com_mobile: "",
      bank: "",
      bank_nu: "",
      invoice_comtent: "信息服务费",
    })

    //获取收货地址
    interface addressType {
      data: any
    }

    let addressList: addressType = reactive({
      data: [],
    })
    let getAddressList = () => {
      userApi.addressList().then((res: any) => {
        addressList.data = res.datas
        //  console.log(addressList.data);
      })
    }

    //  点击地址选择收货地址
    let chessaddress = (index: number) => {
      if (index == 1) {
        addressList.data.splice(
          0,
          2,
          addressList.data[index],
          addressList.data[0]
        )
        //console.log(addressList.data,"bbb");
        down.value = false
      }
      if (index == 2) {
        addressList.data.splice(
          0,
          3,
          addressList.data[index],
          addressList.data[0],
          addressList.data[1]
        )
        //console.log(addressList.data,"bbb");
        down.value = false
      } else {
        down.value = false
      }
      commonForm.address = addressList.data[0].address
      commonselfForm.address = addressList.data[0].address
      specialForm.address = addressList.data[0].address
      //  console.log(addressList.data[0], "地址1");
    }

    //  切换发票的内容填写和内容展示
    //let goShow: any = inject("goShow");
    // provide("goShow", goShow);
    //console.log(goShow.value == true, "goshow");
    let changeGoShow = (val: boolean) => {
      context.emit("changeGoShow", val)
    }

    const restaurants = ref<invoiceType[]>([])
    // 区分一下弹框是RMB消费还是齿轮币消费
    //console.log(props.from,"from");
    let from = props.from

    let handleSelect = (item: any) => {
      specialForm.bank = item.bank
      specialForm.number = item.duty
      specialForm.company_address = item.com_address
      specialForm.mobile = item.com_mobile
      specialForm.card = item.bank_nu
      specialForm.name = item.company
    }

    const querySearch = (queryString: string, cb: any) => {
      //console.log(restaurants, "开始执行")

      const results = queryString
        ? restaurants.value.filter(createFilter(queryString))
        : restaurants.value

      cb(results)
    }
    const createFilter = (queryString: string) => {
      return (restaurant: invoiceType) => {
        return (
          restaurant.company
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        )
      }
    }

    // const loadAll = () => {
    //   return getInvoiceList()
    // }

    let getInvoiceList = () => {
      orderApi.getInvoiceList().then((res: any) => {
        if (res.errno == 0) {
          let list = res.datas
          for (let i = 0; i < res.datas.length; i++) {
            list[i]["value"] = list[i]["company"]
          }

          restaurants.value = res.datas
        }
      })
    }

    onMounted(() => {
      getInvoiceList()
      //restaurants.value = loadAll()
      getAddressList()
      nextTick(() => {
        isShow.value = true
      })
      addressList
      chessaddress(0)
    })

    return {
      //地址信息
      close,
      address,
      onSubmit,
      rules,
      onChange,
      userForm,
      ruleFormRef,
      isShow,
      ruleFormRefs,
      close1,
      //发票信息
      radio1,
      radio2,
      specialForm,
      submitForm,
      rule,
      show,
      addlocation,
      down,
      commonForm,
      commonselfForm,
      ruleFormRef1,
      rules1,
      ruleFormRef2,
      rules2,
      querySearch,

      //   地址信息
      addressList,
      //选择收货地址
      chessaddress,
      selfForm,
      firmForm,
      exclusiveForm,
      submitFormone,
      submitFormtwo,
      updateAddress,
      addressItem,
      mclose,
      validatePass,
      changeGoShow,
      // 区分弹框打开来源
      from,
      handleSelect,
    }
  },
})
