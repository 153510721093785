
import { defineComponent, reactive, ref, watch } from "vue"
import CurrentAddress from "@/components/common/CurrentAddress.vue"
import HomeHead from "@/components/HomeHead.vue"
import HtmlFooter from "@/components/HtmlFooter.vue"
import GoodsClass from "@/components/goods/project/GoodsClass.vue"
import GoodsList from "@/components/goods/project/GoodsList.vue"
import { goodsApi } from "@/service/api"

import PageShow from "@/components/common/PageShow.vue"
import { useRoute, useRouter } from "vue-router"

export default defineComponent({
	components: {
		CurrentAddress,
		HomeHead,
		GoodsClass,
		GoodsList,
		HtmlFooter,
		PageShow,
	},
	setup() {
		//获取地址中的参数
		let goodsWhere = reactive({
			params: {
				cid: 0,
				aids: [],
				pageIndex: 1,
			},
		})
		let goodslit = reactive({
			datas: {
				data: [],
				pageSize: 0,
				total: 0,
			},
		})
		//获取商品列表
		let getGoodsList = () => {
			loading.value = true
			goodsApi.getGoodsList(goodsWhere).then((res: any) => {
				if (res.errno == 0) {
					goodslit.datas = res.datas
					loading.value = false
				}
			})
		}
		const loading = ref(true)
		let pageShowref = ref<any>()

		let setGoodsWhere = (where: any) => {
			goodsWhere.params.cid = where.params.cid
			goodsWhere.params.aids = where.params.aids
			goodsWhere.params.pageIndex = 1
			//初始化页码
			if (pageShowref.value) {
				pageShowref.value.changePageIndex(1)
			}

			getGoodsList()
			//console.log(where, "我被子组件调用了")
		}

		let handleCurrentChange = (val: any) => {
			goodsWhere.params.pageIndex = val
			//console.log("sdfasdf")
			getGoodsList()
		}
		let route = useRoute()
		let router = useRouter()
		let gotoHome = () => {
			router.push({
				name: "home",
			})
		}
		watch(route, () => {
			router.go(0)
		})

		return {
			handleCurrentChange,
			setGoodsWhere,
			goodsWhere,
			goodslit,
			loading,
			pageShowref,
			gotoHome,
		}
	},
})
