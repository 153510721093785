
import { defineComponent, inject, ref, reactive } from "vue"
import CountDown from "@/components/common/CountDown.vue"
import { getVipInfo, orderStatusText } from "@/utils/public"
import { useRouter } from "vue-router"
import { orderApi, parts } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
import { showPrice } from "@/utils/public"
import { getDeliveryStatus } from "@/utils/public"
import { ElMessageBox } from "element-plus"
import { userStore } from "@/stores/userStore"
import InvoiceDialog from "@/components/common/Dialog/InvoiceDialog.vue"
export default defineComponent({
	components: { CountDown, InvoiceDialog },
	props: {
		order: {
			type: Object,
			default: () => ({}),
		},
		index: {
			type: Number,
			default: 0,
		},
	},
	setup(props: any) {
		let userinfot = userStore()

		let router = useRouter()
		let vipinfo = getVipInfo()
		let staticUrl = inject("staticUrl")
		// let getimgUrl = (imgval: string) => {
		//   return staticUrl + imgval
		// }

		let getimgUrl = (val: any) => {
			if (val.startsWith("https")) {
				return val
			} else {
				return staticUrl + val
			}
		}

		let getStatusText = (
			index: number,
			forRrestOf: number,
			order_type: number = 0,
			send_status: number = 0
		) => {
			return orderStatusText(index, forRrestOf, order_type, send_status)
		}

		/**
		 * 查看售后
		 * @param orderId
		 */

		let postSale = (orderId: number) => {
			router.push({ name: "postsalelist", params: { orderid: orderId } })
		}

		/**
		 * 查看物流
		 * @param orderId 订单id
		 */
		interface infor {
			data: any
		}
		let ExpressInfo: infor = reactive({
			data: {},
		})
		let errorMsg = ref("")
		let deliveryStatus = ref("")
		let viewLogistics = (name: any, number: any) => {
			setDialogVisible()
			parts.getExpressInfo(name, number).then((res: any) => {
				console.log(res)
				if (res.errno == 0) {
					ExpressInfo.data = res.datas
					deliveryStatus.value = getDeliveryStatus(
						ExpressInfo.data.deliverystatus
					)
				} else {
					errorMsg.value = res.msg
				}
			})
		}
		//  查看物流弹框
		const sonRef = ref<InstanceType<typeof InvoiceDialog>>()
		let setDialogVisible = () => {
			sonRef.value?.setVisible(true)
			//console.log();
		}
		let dialogVisible = ref(false)
		// 关闭弹框
		let mclose = () => {
			dialogVisible.value = false
			sonRef.value?.setVisible(dialogVisible.value)
		}
		/**
		 * 下载
		 */
		let toDown = (id: number) => {
			//跳转到下载页面
			router.push({ name: "orderdown", params: { id: id } })
		}

		/**
		 * 待付款订单
		 */
		let toBePaid = (id: number) => {
			router.push({ name: "orderdown", params: { id: id } })
		}

		/**
		 * 订单详情
		 */
		let orderInfo = (id: number, order_type: number) => {
			if (order_type == 0) {
				router.push({ name: "orderover", params: { id: id } })
			} else {
				router.push({ name: "tobepaid", params: { id: id } })
			}
		}

		/**
		 * 产品详细页
		 */
		let goGoods = (id: number) => {
			router.push({ name: "productDetail", params: { id: id } })
		}

		/**
		 * 获取倒计时s
		 */
		let getTime = (val: number = 0) => {
			let time = new Date().getTime() / 1000
			return val - time
			//if(val>(time as number)/1000)
		}

		let deleteOrder = inject("deleteOrder", (index: number) => {}, false)
		let cancelOrder = inject("cancelOrder", (index: number) => {}, false)

		/**
		 * 更改订单状态
		 */
		let updateOrderStatus = (id: number, status: number) => {
			if (status == 4) {
				ElMessageBox.confirm("确定要删除该订单?", "", {
					type: "warning",
					confirmButtonText: "确定",
					cancelButtonText: "取消",
				})
					.then(() => {
						orderApi.updateOrderStatus(id, status).then((res: any) => {
							if (res.errno == 0) {
								sjyAlert("已删除", "success")
								deleteOrder(props.index)
							} else {
								sjyAlert(res.errmsg, "error")
							}
						})
					})
					.catch(() => {
						// catch error
					})
			} else {
				orderApi.updateOrderStatus(id, status).then((res: any) => {
					if (res.errno == 0) {
						cancelOrder(props.index)
						sjyAlert("已取消", "success")
					} else {
						sjyAlert(res.errmsg, "error")
					}
				})
			}
		}

		/**
		 * 确认收货
		 */
		let updateConfirmReceipt = (id: number) => {
			orderApi.updateConfirmReceipt(id).then((res: any) => {
				if (res.errno == 0) {
					//order.send_status = 3
					sjyAlert("已确认收货", "success")
					showSend.value = false
				}
			})
		}
		let showSend = ref(true)

		let gotoSale = (oid: number, id: number, orderno: string) => {
			router.push({
				name: "postsalestatus",
				params: {
					orderid: oid,
					goodsid: id,
					orderno: orderno,
				},
			})
		}
		return {
			getimgUrl,
			getStatusText,
			toDown,
			toBePaid,
			orderInfo,
			goGoods,
			getTime,
			updateOrderStatus,
			updateConfirmReceipt,
			showSend,
			vipinfo,
			userinfot,
			showPrice,
			viewLogistics,
			postSale,
			sonRef,
			setDialogVisible,
			dialogVisible,
			mclose,
			ExpressInfo,
			deliveryStatus,
			errorMsg,
			gotoSale,
		}
	},
})
