
import { reactive, defineComponent, ref, onMounted } from "vue";
import { company } from "@/service/api";

export default defineComponent({
  emits: ["goback"],
  props: {
    go: {
      type: String,
      default: () => ({}),
    },
  },
  setup(props: any, context) {
    interface result {
      data: any;
    }

    let getInformation: result = reactive({
      data: {},
    });
    let getcompany = () => {
      company.companyinformation().then((res: any) => {
        //console.log(res, "123");
        getInformation.data = res.datas;
      });
    };
    let goback = () => {
      context.emit("goback");
      console.log(props.go);
      props.go = "2";
    };

    onMounted(() => {
      getcompany();
    });

    return {
      getInformation,
      goback,
    };
  },
});
