
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  reactive,
  provide,
  getCurrentInstance,
} from "vue"
import HtmlFooter from "@/components/HtmlFooter.vue"
import { useRoute, useRouter } from "vue-router"

import HomeHead from "@/components/HomeHead.vue"
import CurrentAddress from "@/components/common/CurrentAddress.vue"
import { ElTable } from "element-plus"
import GoodsItem from "@/components/order/GoodsItem.vue"
import GoodsModel from "@/components/order/GoodsModel.vue"
import GoodsPrice from "@/components/order/GoodsOrderPrice.vue"
import ConsultingSelect from "@/components/order/ConsultingSelect.vue"
import Ayouhuiquan from "@/components/order/Ayouhuiquan.vue"
import DialogTitle from "@/components/common/DialogTitle.vue"
import Dialog from "@/components/common/Dialog/Dialog.vue"
import Title from "@/components/common/Dialog/item/Title.vue"
import AddressFrom from "../../components/common/Dialog/item/AddressFrom.vue"
import { userStore } from "@/stores/userStore"
import PayDialog from "@/components/common/Dialog/item/PayDialog.vue"
import { orderApi, userApi } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
import CountDown from "@/components/common/CountDown.vue"
import OrderStatusCancel from "@/components/order/OrderStatusCancel.vue"
import OrderStatusOther from "@/components/order/OrderStatusOther.vue"
import OrderCourier from "@/components/order/OrderCourier.vue"
import GoodsRealItem from "@/components/order/GoodsRealItem.vue"

// import ConfirmModel from "@/components/dialog/ConfirmModal.vue"
import GoodsPart from "@/components/order/GoodsPart.vue"
import { showPrice } from "@/utils/public"
export default defineComponent({
  name: "unpaid-or-cancel",
  components: {
    HomeHead,
    ConsultingSelect,
    HtmlFooter,
    CurrentAddress,
    GoodsPrice,
    GoodsItem,
    GoodsModel,
    Ayouhuiquan,
    DialogTitle,
    Dialog,
    Title,
    AddressFrom,
    PayDialog,
    CountDown,
    OrderStatusCancel,
    OrderStatusOther,
    OrderCourier,
    GoodsPart,
    GoodsRealItem,
  },
  setup() {
    let loading = ref(true)
    const { proxy } = getCurrentInstance() as any

    const params = useRoute()
    let orderId = parseInt(params.params.id as string)
    let router = useRouter()

    let gotoSale = (goodsId: number) => {
      router.push({
        name: "postsalestatus",
        params: {
          orderid: orderData.datas.id,
          goodsid: goodsId,
          orderno: orderData.datas.oid,
        },
      })
    }

    /**
     * 获取倒计时s
     */
    let getTime = (val: number = 0) => {
      let time = new Date().getTime() / 1000
      return val - time
      //if(val>(time as number)/1000)
    }

    interface User {
      date: string
      name: string
      address: string
    }

    let payInfo = reactive({
      data: {
        gear: 8000,
        id: 490,
        nu: "PB00000588",
        pay_password: 0,
        price: 240,
      },
    })

    const counterStore = userStore()
    let wrongtimes = ref(0)
    if (localStorage.getItem("wrongtimes") != null) {
      if (parseInt(localStorage.getItem("wrongtimes") as string) > 0) {
        wrongtimes.value = parseInt(
          localStorage.getItem("wrongtimes") as string
        )
      }
    }
    let successPay = (params: any) => {
      orderApi.payOrder(params).then((res: any) => {
        if (res.errno == 0) {
          localStorage.setItem("wrongtimes", "0")
          sjyAlert("支付成功", "success")
          router.push({ name: "paysuccess", params: { id: params.id } })

          //跳转到订单下载页面
        } else {
          if (localStorage.getItem("wrongtimes")) {
            localStorage.setItem(
              "wrongtimes",
              Number(localStorage.getItem("wrongtimes") as string) + 1 + ""
            )
          } else {
            localStorage.setItem("wrongtimes", "1")
          }
          wrongtimes.value = Number(localStorage.getItem("wrongtimes"))
          sjyAlert(res.errmsg, "error")
        }
        return false
      })
    }
    provide("successPay", successPay)
    const multipleTableRef = ref<InstanceType<typeof ElTable>>()
    const multipleSelection = ref<User[]>([])
    const toggleSelection = (rows?: User[]) => {
      if (rows) {
        rows.forEach((row) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          multipleTableRef.value!.toggleRowSelection(row, undefined)
        })
      } else {
        multipleTableRef.value!.clearSelection()
      }
    }
    const handleSelectionChange = (val: User[]) => {
      multipleSelection.value = val
    }

    /**
     * 更改订单发货状态
     * @param status 状态
     * @param tips 提示
     * @param confirmText 按钮确认文字
     * @param cancelText 按钮取消文字
     * @param alertText 弹出提示文字
     */
    let updateOrderSendStatus = (
      status: number,
      tips: string = "提示",
      confirmText: string = "确定",
      cancelText: string = "取消",
      alertText: string = "已取消"
    ) => {
      proxy.$ConfirmDialog.show({
        tips: tips,
        confirmText: confirmText,
        cancelText: cancelText,
        onConfirm: () => {
          orderApi.updateConfirmReceipt(orderData.datas.id).then((res: any) => {
            if (res.errno == 0) {
              sjyAlert(alertText, "success")
              orderData.datas.send_status = status
              setTimeout(() => {
                router.go(0)
              }, 1000)
            } else {
              sjyAlert(res.errmsg, "error")
            }
          })
        },
        onCancel: () => {
          console.log("onCancel", "取消")
        },
      })
    }

    /**
     * 更改订单状态
     * @param status 状态
     * @param tips 提示
     * @param confirmText 按钮确认文字
     * @param cancelText 按钮取消文字
     * @param alertText 弹出提示文字
     */
    let updateOrderStatus = (
      status: number,
      tips: string = "提示",
      confirmText: string = "确定",
      cancelText: string = "取消",
      alertText: string = "已取消"
    ) => {
      //弹出询问框
      proxy.$ConfirmDialog.show({
        tips: tips,
        confirmText: confirmText,
        cancelText: cancelText,
        onConfirm: () => {
          orderApi
            .updateOrderStatus(orderData.datas.id, status)
            .then((res: any) => {
              if (res.errno == 0) {
                sjyAlert(alertText, "success")
                orderData.datas.status = status
                if (status == 4) {
                  setTimeout(() => {
                    router.push({ name: "myorder" })
                  }, 2000)
                }
              } else {
                sjyAlert(res.errmsg, "error")
              }
            })
        },
        onCancel: () => {
          console.log("onCancel", "取消")
        },
      })
    }

    interface orderDataType {
      errno: number
      datas: any
      errmsg: string
    }
    const orderData: orderDataType = reactive({
      errno: 0,
      errmsg: "",
      datas: [],
    })
    let dialogVisible = ref(false)
    const sonRef = ref<InstanceType<typeof Dialog>>()
    const toPay = ref<InstanceType<typeof Dialog>>()
    let setDialogVisible = () => {
      sonRef.value?.setVisible(true)
    }

    let showPay = () => {
      if (orderData.datas.order_type == 1) {
        router.push({
          name: "payorder",
          params: {
            id: orderData.datas.id,
          },
        })
      } else {
        toPay.value?.setVisible(true)
      }
    }
    let mclose = () => {
      dialogVisible.value = false
      sonRef.value?.setVisible(dialogVisible.value)
      toPay.value?.setVisible(dialogVisible.value)
    }

    const tripleCounter = computed(() => {
      //return counterStore.counter * 3;
    })

    /**
     * 获取订单信息
     */
    let getOrderInfo = () => {
      loading.value = true

      orderApi.getOrderInfo(orderId).then((res: any) => {
        if (res.errno == 0) {
          if (res.datas.payment_error_times == 0) {
            localStorage.setItem("wrongtimes", "0")
            wrongtimes.value = 0
          }
          loading.value = false
          if (res.datas.list && res.datas.list.length < 1) {
            //该订单不存在 todo
          } else if (res.datas.status == 2 && res.datas.send_status == 0) {
            //该订单已经完成，显示订单详细页
            router.push({ name: "orderover", params: { id: orderId } })
          }

          orderData.datas = res.datas

          //整理弹出框信息
          updatePayInfo(res.datas)
        } else {
          sjyAlert(res.errmsg, "error")
        }
      })
    }

    let updatePayInfo = (orderData: any) => {
      payInfo.data.gear = orderData.gear
      payInfo.data.id = orderData.id
      payInfo.data.nu = orderData.oid
      payInfo.data.price = orderData.price
      //获取用户信息，判断用户设置了支付密码没有
      userApi.getInformation().then((res: any) => {
        if (res.errno == 0) {
          payInfo.data.pay_password = res.datas.pay_password
        }
      })
    }

    let creatOrderInfo = reactive({})

    let showMobile = (mmobile: string) => {
      var reg = /^(\d{3})\d{4}(\d{4})$/
      return mmobile.replace(reg, "$1****$2")
    }

    let stringToInt = (val: string) => {
      //showPrice(val+0)
      if (typeof val === "string") {
        // "string"
        return parseInt(val)
      }
      return val
    }

    let getOrderTitle = (status: number) => {
      //console.log(status, "订单状态")
      if (status == undefined) {
        return ""
      }
      if (status == 1) {
        return "待支付订单"
      }
      if (status == 3) {
        return "已取消订单"
      }
      return "订单信息"
    }
    // let countdownEnded = () => {
    //   orderData.datas.status = 3
    // }

    let goBuy = () => {
      console.log("再次购买")

      let ids = ""
      //整理订单数据
      for (let i = 0; i < orderData.datas.list.length; i++) {
        if (ids == "") {
          ids +=
            orderData.datas.list[i].gid +
            "-" +
            orderData.datas.list[i].is_consult +
            "-" +
            orderData.datas.list[i].numbers
        } else {
          ids +=
            "_" +
            orderData.datas.list[i].gid +
            "-" +
            orderData.datas.list[i].is_consult +
            "-" +
            orderData.datas.list[i].numbers
        }
      }
      router.push({ name: "confirmed", params: { ids: ids } })
    }

    onMounted(() => {
      getOrderInfo()
    })

    return {
      showPay,
      handleSelectionChange,
      toggleSelection,
      multipleSelection,
      multipleTableRef,
      dialogVisible,
      setDialogVisible,
      sonRef,
      toPay,
      mclose,
      counterStore,
      tripleCounter,
      getOrderInfo,
      orderData,
      creatOrderInfo,
      showMobile,
      stringToInt,
      getOrderTitle,
      payInfo,
      loading,
      getTime,
      updateOrderSendStatus,
      goBuy,
      updateOrderStatus,
      wrongtimes,
      showPrice,
      gotoSale,
    }
  },
})
