import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08388691"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "video-info" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue3VideoPlay = _resolveComponent("vue3VideoPlay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isImg)
      ? (_openBlock(), _createBlock(_component_vue3VideoPlay, {
          key: 0,
          src: _ctx.videoUrl,
          controls: false,
          width: "100%",
          height: "100%"
        }, null, 8, ["src"]))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _ctx.videoUrl
        }, null, 8, _hoisted_2))
  ]))
}