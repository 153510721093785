
import { useRouter } from "vue-router"
import { defineComponent, inject } from "vue"
import { getVipInfo } from "@/utils/public"

export default defineComponent({
	components: {},
	props: {
		goods: {
			type: Object,
			default: () => ({}),
		},
	},
	setup(props: any) {
		let router = useRouter()
		let goto = (id: number) => {
			router.push({
				name: "productDetail",
				params: {
					id,
				},
			})
		}
		let vipinfo = getVipInfo()

		let getPrice = () => {
			if (props.goods.activity_price && props.goods.activity_price > 0) {
				return Math.round(props.goods.activity_price)
			} else {
				if (vipinfo.type > 0) {
					return Math.round(props.goods.price * 0.8)
				} else {
					return props.goods.price
				}
			}
		}

		let getOldPrice = () => {
			if (props.goods.activity_price && props.goods.activity_price > 0) {
				return Math.round(props.goods.price)
			} else {
				if (vipinfo.type > 0) {
					return Math.round(props.goods.price)
				} else {
					return props.goods.original_price
				}
			}
		}

		let getPriceImg = () => {
			if (props.goods.activity_price && props.goods.activity_price > 0) {
				return "@/assets/icon/zhekoujia.png"
			} else {
				if (vipinfo.type > 0) {
					return vipinfo.vipimgurl
				}
			}
		}

		const staticUrl = inject("staticUrl")
		let imgUrl = (goodsnames: string, cover: string) => {
			if (cover) {
				return cover
			} else {
				let modleName = goodsnames.split("：")[1]
				return staticUrl + "/filespath/files/video/img/" + modleName + ".jpg"
			}
		}
		return { goto, imgUrl, vipinfo, getPrice, getPriceImg, getOldPrice }
	},
})
