
import { computed, defineComponent, onMounted, ref, watch } from "vue"
import { useElementSize, useWindowSize } from "@vueuse/core"
import FixedLeft from "@/components/common/FixedLeft.vue"
import Fixed from "@/components/common/Fixed.vue"
export default defineComponent({
	components: { FixedLeft, Fixed },
	setup() {
		const el = ref(null)
		const { width, height } = useElementSize(el)
		const WindowSize = useWindowSize()

		let beforeUnloadTime = ref(0)
		let gapTime = ref(0)
		let windowWidth = ref(0)
		windowWidth.value = WindowSize.width.value
		let scale = ref(1)
		if (WindowSize.width.value < 1600) {
			scale.value = WindowSize.width.value / 1600
		}
		watch(WindowSize.width, () => {
			if (WindowSize.width.value < 1600) {
				scale.value = WindowSize.width.value / 1600
			} else {
				scale.value = 1
			}
			windowWidth.value = WindowSize.width.value
		})

		let windowHeight = document.getElementById("app")?.offsetHeight as number

		const beforeunloadHandler = () => {
			//console.log("beforeunloadHandler", "缓存")
			//beforeUnloadTime.value = new Date().getTime()
		}
		const unloadHandler = () => {
			// console.log("unloadHandler", "缓存")
			// gapTime.value = new Date().getTime() - beforeUnloadTime.value
			// if (gapTime.value > 30000) {
			//   useCookies().cookies.remove("userLogin")
			//   localStorage.clear
			// }
		}

		let styleObject = computed(() => {
			//console.log("我是屏幕的宽度",windowWidth.value);

			if (windowWidth.value >= 1600) {
				return {}
			} else {
				return {
					transform:
						"scale(" +
						(windowWidth.value < 1600 ? windowWidth.value : 1600) / 1600 +
						")",
				}
			}
		})
		onMounted(() => {
			window.addEventListener("beforeunload", () => beforeunloadHandler())
			window.addEventListener("unload", () => unloadHandler())
		})
		return {
			windowWidth,
			windowHeight,
			scale,
			el,
			width,
			height,
			styleObject,
		}
	},
})
