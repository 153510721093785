<template>
  <div
    class="contents-bottom-info-left"
    :class="{ 'is-fixed': isFixed&&!isFixedBottom, 'is-fixed-bottom': isFixedBottom }"
  >
    <img :src="info.imgurl" /> 
  </div>
  <div class="contents-bottom-info-right">
    <div class="table-info">
      <div class="table-tr">
        <div class="table-td table-td-f">NO.</div>
        <div class="table-td table-td-s">示意图</div>
        <div class="table-td table-td-t">零件名称、产品型号及数量</div>
      </div>
      <div v-for="(item, index) in info.list" class="table-tr">
        <div class="table-td table-td-f">{{ index + 1 }}</div>
        <div class="table-td table-td-s"><img :src="item.pic" /></div>
        <div class="table-td table-td-t">
          <div class="table-td-t-info">
            <p class="title">
              {{ item.content[0] }}<span>{{ item.content[1] }}个</span>
            </p>
            <p class="tabs">{{ item.content[2] }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, props, defineComponent } from "vue";

export default defineComponent({
  setup() {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
    isFixedBottom: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Array,
      default: () => [],
    },
  },
});
</script>

<style scoped>
.contents-bottom-item .contents-bottom-info-left {
  width: 600px;
  float: left;
  top: 42px;
}
.contents-bottom-info-left img{
  max-width: 380px;
}
.contents-bottom-item .contents-bottom-info-right {
  width: 600px;
  float: right;
}
.contents-bottom-info-left img {
  margin-top: 49px;
  margin-left: 140px;
}
.contents-bottom-info-right .table-info {
  width: 545px;
  border: 1px solid #7e7e7e;
  margin: 49px auto;
  font-size: 14px;
}
.contents-bottom-info-right .table-info .table-tr {
  overflow: hidden;
  height: 118px;
  line-height: 118px;
  text-align: center;
  border-top: 1px solid #7e7e7e;
}
.contents-bottom-info-right .table-info .table-tr:first-child {
  height: 51px;
  line-height: 51px;
  border-top: none;
}

.contents-bottom-info-right .table-info .table-tr .table-td {
  float: left;
  border-left: 1px solid #7e7e7e;
}
.contents-bottom-info-right .table-info .table-tr .table-td:first-child {
  border: none;
}
.table-td-f {
  width: 55px;
  color: #333333;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.table-td-s {
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 100%;
}
.table-td-s img {
  max-width: 70%;
  max-height: 70%;
}
.table-td-t {
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.table-td-t .table-td-t-info {
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
}
.table-td-t .table-td-t-info p {
  height: auto;
  line-height: 100%;
}
.table-td-t .table-td-t-info .title {
  font-size: 14px;
  color: #4b4b4b;
}
.table-td-t .table-td-t-info .tabs {
  font-size: 12px;
  color: #666666;
}
.table-td-t .table-td-t-info .title span {
  margin-left: 10px;
}
.is-fixed {
  position: fixed;
  top: 0;
}
.is-fixed-bottom {
  position: absolute;
  left: 40px;
  bottom: 20px;
  top: auto !important;
}
</style>