
import { defineComponent, onMounted, reactive, ref } from "vue";
export default defineComponent({
  components: {},
  emits: ["tolook"],
  setup(props: any, context) {
    let tolook = () => {
      context.emit("tolook");
    };
    return {
      tolook,
    };
  },
});
