import { BasePostSaleType } from "@/utils/apitype/goodsType"
import axios from "../service"

let { get, post } = axios

const order = {
  //购物车操作
  //购物车商品列表列表
  getCartGoodsList(cartType: number = 0) {
    return post("pc/cart/list", { type: cartType })
  },
  // //删除购物车商品
  // delCartGoods(ids:Array<number>){
  // 	return post("pc/cart/del",{ids:ids})
  // },
  //更改购物车咨询服务状态
  upsetCartCounsel(cid: number, counsel: number) {
    return post("pc/cart/edit", { id: cid, counsel: counsel })
  },
  //立即购买
  getBuyGoodsInfo(params: any) {
    return post("pc/product/confirm", { params: params })
  },
  createOrder(params: any) {
    return post("pc/order/submit", params)
  },
  /**
   *
   * @param id:number pay-password
   */
  payOrder(params: any) {
    return post("pc/order/pay", params)
  },
  askAdd(params: any) {
    return post("pc/ask/add", params)
  },
  //获取咨询订单信息
  getAskById(id: number) {
    return post("pc/ask/page", { id: id })
  },
  //我的订单列表
  getMyOrder(params: any) {
    let paramss: any = {
      ...params,
    }
    paramss.start = params.start / 1000
    if (params.end > 0) {
      paramss.end = params.end / 1000 + 60 * 60 * 24
    }

    return post("pc/order/list", paramss)
  },
  //更改订单状态
  updateOrderStatus(id: number, status: number) {
    console.log(id, status, "我看下搜索条件")

    return post("pc/order/update", { id: id, status: status })
  },
  //确认收货
  updateConfirmReceipt(id: number) {
    return post("pc/order/update", { id: id, send_status: 3 })
  },
  //订单详情
  getOrderInfo(id: number) {
    return post("pc/order/info", { id: id })
  },

  //订单售后

  //订单售后退款分类
  getPostSaleClass(className:string="unshipped") {
    let classList = {
      unshipped: [//未发货 仅退款
        {
          text: "我不想要了",
          id: 1,
        },
        {
          text: "与客服协商一致退款",
          id: 2,
        },
        {
          text: "订单信息拍错（规格/数量等）",
          id: 3,
        },
        {
          text: "地址/电话信息填写错误",
          id: 4,
        },
        {
          text: "缺货",
          id: 5,
        },
      ],
      shippednotgoods: [//已发货 仅退款
        {
          text: "不想要/不喜欢",
          id: 1,
        },
        {
          text: "与客服协商一致退款",
          id: 2,
        },
        {
          text: "未按约定时间发货",
          id: 3,
        },
        {
          text: "快递/物流一直未送到",
          id: 4,
        },
        {
          text: "快递/物流无跟踪记录",
          id: 5,
        },
        {
          text: "货物破损已拒签",
          id: 6,
        },
      ],
      shipped: [ //退货退款
        {
          text: "与客服协商一致退款",
          id: 1,
        },
        {
          text: "退运费",
          id: 2,
        },
        {
          text: "商品与卖家描述不符",
          id: 3,
        },
        {
          text: "做工问题",
          id: 4,
        },
        {
          text: "商品质量问题",
          id: 5,
        },
        {
          text: "少件/漏发",
          id: 6,
        },
        {
          text: "收到商品有破损",
          id: 7,
        },
        {
          text: "未按约定时间发货",
          id: 8,
        },
        {
          text: "发票问题",
          id: 9,
        },
        {
          text: "卖家发错货",
          id: 10,
        },
      ],
    }

    let thenable = {
      then: function (resolve: any, reject: any) {
        if(className=="unshipped"){
          resolve(classList.unshipped)
        }else if(className=="shippednotgoods"){
          resolve(classList.shippednotgoods)
        }else{
          resolve(classList.shipped)
        }
        
      },
    }
    return Promise.resolve(thenable)
  },

  //订单售后
  getPostSale(oid: number) {
    return get("pc/after/sales", { oid: oid })
  },
  //添加售后
  addPostSale(postSale: BasePostSaleType) {
    let postSaleData = JSON.parse(JSON.stringify(postSale))
    let picsstring = ""
    for (let i = 0; i < postSale.pics.length; i++) {
      if (picsstring == "") {
        picsstring += postSale.pics[i].url
      } else {
        picsstring += "," + postSale.pics[i].url
      }
    }
    postSaleData.pics = picsstring
    return post("pc/after/sales", postSaleData)
  },

  //查看售后接口
  getPostSaleInfo(id: number, oid: string, pid: number) {
    return post("pc/sales/info", { id, oid, pid })
  },

  /**
   * 添加发票title
   * @param company 单位名称
   * @param duty 税号
   * @param com_address 单位地址
   * @param com_mobile 单位电话
   * @param bank 开户银行
   * @param bank_nu 银行账号
   * @returns
   */
  addInvoiceInfo(
    company: string,
    duty: string,
    com_address: string,
    com_mobile: string,
    bank: string,
    bank_nu: string,
    id: number = 0
  ) {
    return post("pc/head/add", {
      company,
      duty,
      com_address,
      com_mobile,
      bank,
      bank_nu,
      id,
    })
  },

  /**
   * 编辑发票title
   * @param company 单位名称
   * @param duty 税号
   * @param com_address 单位地址
   * @param com_mobile 单位电话
   * @param bank 开户银行
   * @param bank_nu 银行账号
   * @param id
   * @returns
   */
  editInvoiceInfo(
    company: string,
    duty: string,
    com_address: string,
    com_mobile: string,
    bank: string,
    bank_nu: string,
    id: number
  ) {
    return post("pc/head/update", {
      company,
      duty,
      com_address,
      com_mobile,
      bank,
      bank_nu,
      id,
    })
  },

  /**
   * 查询发票抬头列表
   */
  getInvoiceList() {
    return post("pc/head/list", {})
  },

  /**
   * 删除发票抬头
   */
  deleteInvoice(id: number) {
    return post("pc/head/del", { id })
  },

  /**
   * 退款单id
   * @param id
   */
  cancelSale(id: number) {
    return post("pc/sales/update", { id, status: 4 })
  },
}
export default order
