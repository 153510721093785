import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26d36d90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "goods-info" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.staticUrl+_ctx.goods.photo
    }, null, 8, _hoisted_2),
    _createElementVNode("p", null, _toDisplayString(_ctx.goods.names), 1)
  ]))
}