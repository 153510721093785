import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a110ffa8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contents" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_video_goods_item = _resolveComponent("video-goods-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodslit, (goods, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["item", { 'item-right': (parseInt(index) + 1) % 5 == 0 }]),
        key: index
      }, [
        _createVNode(_component_video_goods_item, {
          goods: (goods as Object),
          aid: _ctx.aid,
          sort: _ctx.sort
        }, null, 8, ["goods", "aid", "sort"])
      ], 2))
    }), 128))
  ]))
}