<template>
	<div class="targetswiper" ref="target">
		<div
			class="layer"
			ref="target"
			v-show="
				!isOutside &&
				getSuffix(pics[activeIndex]) != 'mp4' &&
				getSuffix(pics[activeIndex]) != 'flv'
			"
			:style="{ left: position.x + 'px', top: position.y + 'px' }"></div>
		<swiper
			:navigation="false"
			:spaceBetween="10"
			:thumbs="{ swiper: thumbsSwipers }"
			@slideChange="slideChange"
			:autoplay="{
				delay: 2500,
				disableOnInteraction: true,
			}"
			:modules="modules"
			@swiper="setSwiperRef"
			class="mySwiper2">
			<swiper-slide v-for="(pic, index) in pics">
				<div
					class="swiper-box"
					v-if="getSuffix(pic) == 'mp4' || getSuffix(pic) == 'flv'">
					<video
						v-if="index == 0"
						ref="myVideo1"
						:src="pic"
						:controls="true"
						oncontextmenu="return false"
						controlslist="nodownload"
						class="video-box"></video>
					<video
						v-if="index == 1"
						ref="myVideo2"
						:src="pic"
						:controls="true"
						oncontextmenu="return false"
						controlslist="nodownload"
						class="video-box"></video>
					<video
						v-if="index == 2"
						ref="myVideo3"
						:src="pic"
						:controls="true"
						oncontextmenu="return false"
						controlslist="nodownload"
						class="video-box"></video>
					<video
						v-if="index == 3"
						ref="myVideo4"
						:src="pic"
						:controls="true"
						oncontextmenu="return false"
						controlslist="nodownload"
						class="video-box"></video>
				</div>
				<div class="swiper-box" v-else>
					<img :class="{ 'top-img': type == 1 }" :src="pic" />
				</div>
			</swiper-slide>
		</swiper>
	</div>
	<swiper
		@swiper="setThumbsSwipers"
		:spaceBetween="0"
		:slidesPerView="4"
		:freeMode="true"
		:watchSlidesProgress="true"
		:modules="modules"
		class="mySwiper"
		:class="{ firstswiper: activeIndex == 0 }">
		<swiper-slide v-for="(pic, index) in pics">
			<div
				class="swiper-box swiper-box-img"
				v-if="getSuffix(pic) == 'mp4' || getSuffix(pic) == 'flv'">
				<img src="@/assets/common/images/video.png" />
			</div>
			<div class="swiper-box swiper-box-img" v-else>
				<img class="mySwiper-img" :src="pic" />
			</div>
		</swiper-slide>
	</swiper>
</template>
<script>
// Import Swiper Vue.js components
import { defineComponent, onMounted, ref, computed, watch, nextTick } from "vue"
import { useMouseInElement } from "@vueuse/core"

import { Swiper, SwiperSlide } from "swiper/vue"

import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"

import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper"
import VideoPlay from "./free/VideoPlay.vue"
import { getStringSuffix } from "@/utils/public"
import "vue3-video-play/dist/style.css"
//@ts-expect-error
import vue3VideoPlay from "vue3-video-play"

export default defineComponent({
	emits: ["setPosition"],
	components: {
		Swiper,
		SwiperSlide,
		VideoPlay,
		vue3VideoPlay,
	},
	props: {
		type: {
			type: Number,
			default: 0,
		},
		pics: {
			type: Array,
			default: () => [],
		},
	},
	setup(props, { emit }) {
		let isPlayIng = ref(false)
		const target = ref(null)
		const { isOutside, elementX, elementY } = useMouseInElement(target)
		const position = computed(() => {
			let x = elementX.value - 87
			let y = elementY.value - 87
			x = x < 88 ? 88 : x
			y = y < 30 ? 30 : y
			x = x > 590 ? 590 : x
			y = y > 350 ? 350 : y
			//console.log(x,y,elementX.value,elementY.value);
			//console.log(getSuffix(props.pics[activeIndex.value]), "后缀")
			if (
				getSuffix(props.pics[activeIndex.value]) != "mp4" &&
				getSuffix(props.pics[activeIndex.value]) != "flv"
			) {
				emit("setPosition", x - 88, y - 30, isOutside.value, activeIndex.value)
			} else {
				//isOutside.value =false
				emit("setPosition", x - 80, y - 60, true, activeIndex.value)
			}
			return {
				x,
				y,
			}
		})

		let getSuffix = (val) => {
			return getStringSuffix(val)
		}

		let thumbsSwipers = ref(null)
		const setThumbsSwipers = (swiper) => {
			thumbsSwipers.value = swiper
		}

		let activeIndex = ref(0)

		let slideChange = (res) => {
			activeIndex.value = res.activeIndex
		}
		let swiperRef = ref(null)
		const setSwiperRef = (swiper) => {
			swiperRef.value = swiper
		}
		watch(isOutside, (newvalue, oldvalue) => {
			if (!isPlayIng.value) {
				if (newvalue) {
					swiperRef.value.autoplay.start()
				} else {
					swiperRef.value.autoplay.stop()
				}
			}
		})

		let getOptions = (val) => {
			options.src = val
			return options
		}

		let myVideo1 = ref(null)
		let myVideo2 = ref(null)
		let myVideo3 = ref(null)
		let myVideo4 = ref(null)

		let handleVideo = () => {
			//这里写的很不好， 不想改了，  其实绑定一个变量就可以了， 他是个数组。
			if (myVideo1.value != null) {
				console.log(myVideo1.value[0])
				myVideo1.value[0].addEventListener("play", handlePlay)
				myVideo1.value[0].addEventListener("pause", handlePause)
			}
			if (myVideo2.value != null) {
				myVideo2.value[0].addEventListener("play", handlePlay)
				myVideo2.value[0].addEventListener("pause", handlePause)
			}
			if (myVideo4.value != null) {
				myVideo4.value[0].addEventListener("play", handlePlay)
				myVideo4.value[0].addEventListener("pause", handlePause)
			}
			if (myVideo3.value != null) {
				myVideo3.value[0].addEventListener("play", handlePlay)
				myVideo3.value[0].addEventListener("pause", handlePause)
			}
		}
		let handlePlay = () => {
			isPlayIng.value = true
			swiperRef.value.autoplay.stop()
		}
		let handlePause = () => {
			isPlayIng.value = false
			swiperRef.value.autoplay.start()
		}

		// let options = {
		//   width: "660px", //播放器高度
		//   height: "450px", //播放器高度
		//   color: "#409eff", //主题色
		//   title: "", //视频名称
		//   src: "https://image.sjyuan.net.cn/files/free_product/32-SP1.mp4", //视频源
		//   muted: false, //静音
		//   webFullScreen: false,
		//   speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
		//   autoPlay: false, //自动播放
		//   loop: false, //循环播放
		//   mirror: false, //镜像画面
		//   ligthOff: true, //关灯模式
		//   volume: 0.3, //默认音量大小
		//   control: false, //是否显示控制
		//   controlBtns: [
		//     "audioTrack",
		//     "quality",
		//     "speedRate",
		//     "volume",
		//     "setting",
		//     "pip",
		//     "pageFullScreen",
		//     "fullScreen",
		//   ], //显示所有按钮,
		// }
		onMounted(() => {
			nextTick(() => {
				handleVideo()
			})
		})
		return {
			modules: [FreeMode, Navigation, Thumbs, Autoplay],
			setThumbsSwipers,
			thumbsSwipers,
			target,
			isOutside,
			elementX,
			elementY,
			position,
			slideChange,
			activeIndex,
			setSwiperRef,
			getSuffix,
			getOptions,
			myVideo4,
			myVideo3,
			myVideo2,
			myVideo1,
		}
	},
	methods: {},
})
</script>
<style scoped lang="scss">
.mySwiper-img {
	image-rendering: -moz-crisp-edges;
	image-rendering: -o-crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	image-rendering: crisp-edges;
	-ms-interpolation-mode: nearest-neighbor;
}

.top-img {
	width: 660px;
	height: 450px;
}

.swiper-box {
	width: 660px;
	height: 450px;
	display: flex;
	align-items: center;
	margin: 0 auto;
	margin-top: 30px;
	margin-bottom: 24px;
}

.swiper-box img {
	width: 100%;
}

.swiper-box-img img {
	border: 1px solid #fff;
}

.swiper-box video {
	width: 100%;
}

.swiper-slide img {
	width: 100%;
	cursor: pointer;
}

.mySwiper2 {
	margin-bottom: 20px;
}

.mySwiper {
	:deep(.swiper-wrapper) {
		display: flex !important;
		justify-content: center !important;
	}

	:deep(.swiper-wrapper .swiper-slide-thumb-active img) {
		border: 1px solid #2655ae;
	}

	:deep(.swiper-box) {
		justify-content: center;
	}

	:deep(.swiper-slide) {
		width: 113px;
		height: 90px;
		border: 1px solid #fff;
		display: flex;
		align-items: center;
		background-color: #fff;
		justify-content: center;
		padding-bottom: 5px;
	}

	img {
		width: 113px;
		height: 90px;
	}
}

.firstswiper {
	:deep(.swiper-wrapper .swiper-slide:first-child img) {
		border: 1px solid #2655ae;
	}
}

.targetswiper {
	position: relative;

	.layer {
		width: 175px;
		height: 175px;
		background: rgba(0, 0, 0, 0.2);
		left: 0;
		top: 0;
		// 可以移动
		position: absolute;
		z-index: 22;
	}
}
</style>
