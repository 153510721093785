
import { defineComponent, ref } from "vue"
import { useRouter } from "vue-router"

export default defineComponent({
  name: "videoclassheader", //视频总列表页  item头部组件
  emits: ["changeClass", "changeSort"],
  props: {
    /**
     * header类型  1.全部分类   2.子分类
     * 默认1
     */
    headerType: {
      type: Number,
      default: 1,
    },
    /**
     * 是否显示更多
     */
    isShowMore: {
      type: Boolean,
      default: false,
    },

    cindex: {
      type: Number,
      default: 100,
    },
    /**
     *分类信息
     */
    classinfo: {
      type: Object,
      default: () => ({}),
    },
    /**
     * 选中的信息
     */
    checkedClassItem: {
      type: Number,
      default: 0,
    },
  },

  setup(props: any, context) {
    let itemInfoActive = ref(0)
    let activeClass = ref(0)
    let sort = ref(0)
    //点击子分类
    let changeClass = (sindex: number) => {
      context.emit("changeClass", props.cindex, sindex)
    }

    let getIdName = ()=>{
      //if(props.cindex){
        if(props.cindex<100){
          return "headerTop"+(props.cindex+1)
        }else{
          return "headerTop"
        }
        
      //}
    }

    //更改排序
    let changeSort = (sorttmp: number) => {
      context.emit("changeSort", sorttmp)
      sort.value = sorttmp
    }

    //跳转
    let router = useRouter()
    /**
     * 跳转链接
     */
    let goto = (cid: number) => {
      router.push({ name: "video-class", params: { cid: cid } })
    }

    return {
      activeClass,
      itemInfoActive,
      changeClass,
      sort,
      changeSort,
      goto,
      getIdName
    }
  },
})
