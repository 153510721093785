import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-592d7b92"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "contents"
}
const _hoisted_2 = { class: "goods-top-page" }
const _hoisted_3 = { class: "goods-list" }
const _hoisted_4 = { class: "yellow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_goods_item = _resolveComponent("goods-item")!
  const _component_page_show = _resolveComponent("page-show")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_empty = _resolveComponent("el-empty")!

  return (_ctx.goodsList.total > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, "共" + _toDisplayString(_ctx.goodsList.total) + "个 第" + _toDisplayString(_ctx.pageIndex) + "页", 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodsList.data, (goods, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              ((index + 1) % 5 != 0)
                ? (_openBlock(), _createBlock(_component_goods_item, {
                    key: 0,
                    marginTo: 10,
                    marginBottom: 10,
                    goodsinfo: goods
                  }, null, 8, ["goodsinfo"]))
                : _createCommentVNode("", true),
              ((index + 1) % 5 == 0)
                ? (_openBlock(), _createBlock(_component_goods_item, {
                    key: 1,
                    marginBottom: 10,
                    goodsinfo: goods
                  }, null, 8, ["goodsinfo"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ]),
        _createVNode(_component_page_show, {
          total: _ctx.goodsList.total,
          pageSize: _ctx.goodsList.pageSize,
          ref: "pageShow",
          onHandleCurrentChange: _ctx.handleCurrentChange
        }, null, 8, ["total", "pageSize", "onHandleCurrentChange"])
      ]))
    : (_openBlock(), _createBlock(_component_el_empty, {
        key: 1,
        "image-size": "400",
        image: "/static/free/icon/goodsEmpty.png",
        description: "当前没有搜索到您要的结果……！",
        class: "empty"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_button, {
              type: "primary",
              round: "",
              onClick: _ctx.gotoHome
            }, {
              default: _withCtx(() => [
                _createTextVNode("去首页")
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      }))
}