import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "edit-delete",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goodsselect(_ctx.mindex)))
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(["icon iconfont icon-wancheng", {'isselect':_ctx.isselect}])
    }, null, 2)
  ]))
}