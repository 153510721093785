import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3049f760"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "left" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "lable" }
const _hoisted_5 = {
  key: 0,
  class: "warning"
}
const _hoisted_6 = {
  key: 1,
  class: "tips"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "goods-item",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goto(_ctx.data.gid, _ctx.data.id)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.getImgUrl(_ctx.data.photo)
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", {
      class: "right",
      style: _normalizeStyle({ width: _ctx.itemWidth + 'px' })
    }, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.data.names), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.data.content), 1),
      false
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, "4月1日00:00:00 折扣优惠结束"))
        : _createCommentVNode("", true),
      (_ctx.data.is_send == 2)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, " 您所购买的识别软件包含加密狗需要邮寄，请注意查收！ "))
        : _createCommentVNode("", true)
    ], 4)
  ]))
}