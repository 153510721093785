
import { defineComponent, inject, onMounted, reactive, ref } from "vue"
import HtmlFooter from "../../components/HtmlFooter.vue"
import HomeHead from "../../components/HomeHead.vue"
import CurrentAddress from "@/components/common/CurrentAddress.vue"
import PartsList from "@/components/goods/project/PartsList.vue"
import { parts } from "@/service/api"
// import { shouhoukefu, shouqiankefu } from "@/utils/alert"

export default defineComponent({
  components: {
    HtmlFooter,
    HomeHead,
    CurrentAddress,
    PartsList,
  },
  setup() {
    const dialogVisible = ref(false)
		let url = ref('/static/info/bjbt.jpg')
		const shouqiankefu = ()=>{
			url.value = "/static/info/bjbt.jpg"
			dialogVisible.value = true
		}
		const shouhoukefu = ()=>{
			url.value = "/static/info/bjbt.jpg"
			dialogVisible.value = true
		}
    let partsClass = reactive({
      data: {} as any,
    })

    let getImgUrl = inject(
      "getImgUrl",
      (index: string) => {
        return ""
      },
      false
    )

    parts.partsAllClass().then((res: any) => {
      if (res.errno == 0) {
        partsClass.data = res.datas
      }
    })

    //let goodslist = [{}, {}, {}, {}, {}, {}, {}, {}]
    let goodsList = reactive({ data: {} as any })
    parts.elementSell().then((res: any) => {
      if (res.errno == 0) {
        goodsList.data = res.datas
      }
    })

    return { goodsList, partsClass, getImgUrl, shouhoukefu, shouqiankefu ,url,dialogVisible}
  },
})
