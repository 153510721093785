
import { reactive, ref, defineComponent } from "vue";
import PageShow from "@/components/common/PageShow.vue";
//缺省页
import lackRecordVue from "./lackRecord.vue";
export default defineComponent({
  components: { PageShow, lackRecordVue },
  props: {
    AccountToSon: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, context) {
    const total = ref(100);

    let handleCurrentChange = (val: number) => {
      // console.log(val);
    };
    return {
      total,
      handleCurrentChange,
    };
  },
});
