import { userStore } from "@/stores/userStore"
import axios from "../service"
import { ResponseDataType } from "@/lib/hooks/useList/types"

let { get, post, updateOss } = axios

const user = {
  /**
   * 注册
   */
  userRegister(data: any) {
    return post("/pc/user/register", data)
  },
  /**
   * 登录
   */
  userLogin(data: any) {
    return post("/pc/user/login", data)
  },
  // 获取用户信息
  getInformation(uid: any = {}) {
    return get("pc/user/general", uid)
  },
  //获取用户的基本信息（保存到缓存里面的信息）
  getUserLoginInfo() {
    return this.getInformation()
  },

  //提交用户信息
  sunmitInformation(data: any) {
    return post("pc/user/general", data)
  },

  //修改支付密码
  changePay(data: any) {
    return this.sunmitInformation(data)
  },
  // 修改登录密码
  changePwd(data: any) {
    return this.sunmitInformation(data)
  },
  /**
   * @param portrait 图片地址
   */
  changePortrait(portrait: string) {
    return this.sunmitInformation({ portrait })
  },
  // 获取收货地址
  addressList() {
    return post("/pc/address/list", {})
  },
  // 删除收货地址
  delAddress(id: number, defaultvalue: number) {
    return post("pc/address/del", { id: id, default: defaultvalue })
  },
  //添加收货地址
  addAddress(addressInfo: any) {
    let userinfo = userStore()
    console.log(userinfo.id, "获取用户id")

    addressInfo.userid = userinfo.id
    return post("pc/address/add", addressInfo)
  },
  //更新收货地址
  updateAddress(addressInfo: any) {
    return post("pc/address/update", addressInfo)
  },
  //获取优惠券列表
  getCouponsList() {
    let userinfo = userStore()
    return post("pc/coupons/mylist", { uid: userinfo.id })
  },
  //获取上传的token
  getOSSToken() {
    return post("pc/osssign", {})
  },
  //获取上传的token
  updateOss(url: string, new_multipart_params: any) {
    return updateOss(url, new_multipart_params)
  },
  // 个人认证
  userVerify(data: any) {
    return post("pc/user/cert", data)
  },
  // 个人主页
  userHome(author: any) {
    return post("pc/user/data", { author: author })
  },
  // 个人主页作品列表
  userWorklist(data: any) {
    return post("pc/user/worklist", data)
  },
  // 个人主页粉丝列表和关注列表
  userFans(data: any) {
    return post("/pc/user/followfans", data)
  },
  // 个人主页 关注和取消关注
  userFollow(data: any) {
    return post("/pc/user/dofollow", data)
  },
  //子账号列表
  sonlist() {
    return post("/pc/subacc/list", {})
  },
  //子账号列表
  companyUserList() {
    return post("/pc/subaccall/list", {})
  },
  //添加子账号
  addSon(data: any) {
    return post("/pc/subacc/add", data)
  },
  //删除子账号
  splicSon(data: any) {
    return post("pc/subacc/del", data)
  },
  //修改子账号
  changeSon(data: any) {
    return post("pc/subacc/update", data)
  },

  // 获取用户的交易记录
  getAccount(data: any) {
    if (data.end > 0) {
      data.end = Number(data.end) + 60 * 60 * 24
    }
    console.log("这是交易记录的条件", data)

    return post("pc/flow/list", data)
  },

  // 获取用户的交易记录
  getAccounts(size: any, page: any, data: any) {
    if (data.end > 0) {
      data.end = Number(data.end) + 60 * 60 * 24
    }

    return post("pc/flow/list", { pageSize: size, pageIndex: page, ...data })
  },
  // 开具发票
  getInvoice(data: any) {
    return post("pc/bill/create", data)
  },

  //发票详情
  invoiceInfo(fid: any) {
    return post("pc/bill/info", { fid: fid })
  },

  // 开票显示接口
  invoiceShow() {
    return post("pc/bill/show", {})
  },
  //提现

  withdrawMoney(data: any) {
    return post("/pc/cash/out", data)
  },

  // 微信绑定
  wxbind() {
    return get("/pc/wx/bind", {})
  },
  // 微信解绑
  wxUnbind() {
    return post("/pc/wx/unbind", {})
  },

  // 获取用户的足迹列表
  getFoot(data: any) {
    return post("/pc/track/list", data)
  },

  // 获取用户在哪一天有足迹
  dayFoot(data: any) {
    return post("/pc/track/days", data)
  },

  // 我的消息
  userMessage() {
    return post("/pc/news/list", {})
  },
  //  改变用户消息的已读未读消息状态
  unRead() {
    return post("/pc/news/update", {})
  },
  // 用户会员开通记录
  userVipRecord() {
    return post("/pc/vip/records", {})
  },

  // 支付
  userPay(data: any) {
    return post("/pc/pay/native", data)
  },

  // 用户建议
  userSuggest(data: any) {
    return post("/pc/supplier/add", data)
  },

  /**
   *
   * @param product_name 搜索关键字
   * @param type 1 设纪元商品  2 免费专区  3 视频专区
   * @param cid 分类id
   * @param ctime 1 7天内  2 30天内  3 90天内  4 半年前  5 一年前
   * @param status 0 全部  1 有优惠  2 已买过  3 失效
   * @param params
   * @returns
   */
  getStoreList(
    product_name: String,
    type: Number,
    cid: Number,
    ctime: Number,
    status: Number,
    params: Object = {}
  ) {
    return post("pc/store/list", {
      product_name,
      type,
      cid,
      ctime,
      status,
      ...params,
    })
  },
}
export default user
