
import { defineComponent,PropType } from "vue";
export default defineComponent({
  props: {
    info: {
      type: Array,
      default: () => [
        {
          title:"",
          content:[]
        }
      ],
    },
  },
  
  setup(){
    
    return {
    }
  }
});
