
import { sjyAlert } from "@/utils/alert"
import { defineComponent, ref } from "vue"
import { useRoute, useRouter } from "vue-router"

export default defineComponent({
  components: {},
  setup() {
    let type = parseInt(useRoute().params.type as string)
    let router = useRouter()
    let msg = ref("")
    if (type == 1) {
      msg.value = "24小时内只能下载一次"
    } else if (type == 2) {
      msg.value = "您还没有购买"
    } else if (type == 3) {
      msg.value = "抱歉，文件已丢失或已删除，请联系客服"
    } else {
      msg.value = "页面错误"
    }
    sjyAlert(msg, "error")
    setTimeout(() => {
      //   window.opener = null
      //   window.open("", "_self")
      //   window.close()
      router.go(-1)
    }, 2000)

    return {}
  },
})
