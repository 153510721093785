
import { reactive, defineComponent, onMounted } from "vue";
import { userApi } from "@/service/api";
import { userStore } from "@/stores/userStore";

export default defineComponent({
  emits: ["changeInformation"],
  setup(props: any, context) {
    interface result {
      data: any;
    }

    let getInformation: result = reactive({
      data: {},
    });

    let getuserInformation = () => {
      userApi.getInformation().then((res: any) => {
        getInformation.data = res.datas;
      });
    };
    let changeInformation = () => {
      context.emit("changeInformation");
    };

    onMounted(() => {
      getuserInformation();
    });

    let userinfot = userStore();

    return {
      getuserInformation,
      getInformation,
      changeInformation,
      userinfot,
    };
  },
});
