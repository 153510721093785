
import { defineComponent } from "vue"
import "vue3-video-play/dist/style.css"
//@ts-expect-error
import vue3VideoPlay from "vue3-video-play"

export default defineComponent({
  components: { vue3VideoPlay },
  emits: ["onPlay", "onPause"],
  props: {
    src: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  setup(props: any, { emit }) {
    let onPlay = () => {
      emit("onPlay")
    }
    let onPause = () => {
      emit("onPause")
    }
    let options = {
      width: props.width + "px", //播放器高度
      height: props.height + "px", //播放器高度
      color: "#409eff", //主题色
      title: "", //视频名称
      src: props.src, //视频源
      muted: false, //静音
      webFullScreen: false,
      speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
      autoPlay: false, //自动播放
      loop: false, //循环播放
      mirror: false, //镜像画面
      ligthOff: true, //关灯模式
      volume: 0.3, //默认音量大小
      control: false, //是否显示控制
      controlBtns: [
        "audioTrack",
        "quality",
        "speedRate",
        "setting",
        "pip",
        "pageFullScreen",
        "fullScreen",
      ], //显示所有按钮,
    }

    return { onPlay, onPause, options }
  },
})
