import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d3fae1fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["contents", { html: _ctx.isFullScreen }])
  }, [
    _createElementVNode("img", {
      src: _ctx.imgUrl,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goto()))
    }, null, 8, _hoisted_1)
  ], 2))
}