
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import HtmlFooter from "@/components/HtmlFooter.vue";
import HomeHead from "@/components/HomeHead.vue";
import CurrentAddress from "@/components/common/CurrentAddress.vue";
import DialogPay from "@/components/dialog/DialogPay.vue";
import vueQr from "vue-qr/src/packages/vue-qr.vue";
import { useRoute, useRouter } from "vue-router";
import { orderApi, parts } from "@/service/api";

export default defineComponent({
  components: {
    HomeHead,
    HtmlFooter,
    CurrentAddress,
    DialogPay,
    vueQr,
  },
  setup(props: any) {
    let route = useRoute();
    let orderId = ref(0);

    let bankPayInfo = reactive({
      pas: {
        MerId: "",
        MerOrderNo: "",
        OrderAmt: "",
        TranDate: "",
        TranTime: "",
        TranType: "",
        BusiType: "",
        Version: "",
        CurryNo: "",
        AccessType: "",
        AcqCode: "",
        MerPageUrl: "",
        MerBgUrl: "",
        MerResv: "",
        Signature: "",
      },
      url: "",
    });

    if (route.params.id != "") {
      orderId.value = parseInt(route.params.id as string);
    }
    let qrCodeValue = ref("");
    /**
     * 微信支付
     */
    let getWxPay = () => {
      parts.wxPay(orderId.value).then((res: any) => {
        console.log(res.data.code_url);

        if (res.errno == 0) {
          qrCodeValue.value = res.data.code_url;
        }
      });
    };
    /**
     * 支付宝支付
     */
    let getAliPay = () => {
      parts.aliPay(orderId.value).then((res: any) => {
        //console.log(res);

        if (res.errno == 0) {
          window.open(res.data, "_blank");
          //qrCodeValue.value = res.data.code_url
        }
      });
    };
    const fromRef: any = ref(null);
    /**
     * 对公支付
     */
    let GetBankPay = () => {
      // parts.bankPay(orderId.value).then((res: any) => {
      //   //console.log(res)

      //   if (res.errno == 0) {
      //     qrCodeValue.value = res.data.code_url
      //   }
      // })
      // window.open(
      //   "https://api.sjyuan.net.cn/api/pc/pay/ment?orderId=" + orderId.value,
      //   "_blank"
      // )
      parts.bankPay(orderId.value).then((res: any) => {
        if (res.errno == 0) {
          //console.log(res)
          bankPayInfo.pas = res.datas.pas;
          bankPayInfo.url = res.datas.url;
          //fromRef.value.submit()
          nextTick(() => {
            fromRef.value.submit();
          });
        }
      });
    };

    let nowActive = ref(0);
    let isToPayIng = ref(0);
    let changeActive = (val: number = 0) => {
      nowActive.value = val;
      if (val == 1) {
        //todo
        setInterval(() => {
          isToPayIng.value = 1;
        }, 1500);
        getAliPay();
      } else if (val == 2) {
        isToPayIng.value = 1;
        GetBankPay();
      }
    };
    let router = useRouter();
    let cancel = () => {
      isToPayIng.value = 0;
      router.push({ name: "tobepaid", params: { id: orderId.value } });
    };
    let timer = ref(0);
    let getOrderInfo = () => {
      orderApi.getOrderInfo(orderId.value).then((res: any) => {
        if (res.datas.status == 2) {
          clearInterval(timer.value);
          router.push({ name: "tobepaid", params: { id: orderId.value } });
        }
      });
    };
    timer.value = setInterval(() => {
      getOrderInfo();
    }, 5000);
    onMounted(() => {
      getWxPay();
    });
    onUnmounted(() => {
      clearInterval(timer.value);
    });
    return {
      nowActive,
      changeActive,
      isToPayIng,
      cancel,
      qrCodeValue,
      fromRef,
      bankPayInfo,
    };
  },
});
