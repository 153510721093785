import axios from "../service"

let { get, post } = axios
interface createIds {
  id: number
}
const parts = {
  partsAllClass() {
    return post("pc/element/class", {})
  },
  /**
   * 获取b备件区商品列表
   * @param cid1 一级分类id
   * @param cid2 二级分类id
   * @param type 0 综合  1 销量 2 最新 3 价格
   * @param sort 0 正序排列  1 降序
   * @param pageIndex 页数
   * @returns
   */
  partsGetList(
    cid1: number,
    cid2: number,
    type: number,
    sort: number,
    pageIndex: number
  ) {
    return post("pc/class/product", { cid1, cid2, type, sort, pageIndex })
  },

  /**
   * 创建配件区订单
   * @returns
   */
  orderCreate() {
    return post("pc/order/create", {})
  },
  /**
   * 获取配件区的推荐商品列表
   * @returns
   */
  elementSell() {
    return post("pc/element/sell", {})
  },
  /**
   * 微信支付
   * @param orderid 订单id
   */
  wxPay(orderid: number) {
    return this.orderPay(orderid, 1)
  },
  /**
   * 支付宝支付
   * @param orderid 订单id
   */
  aliPay(orderid: number) {
    return this.orderPay(orderid, 2)
  },
  /**
   * 对公支付
   * @param orderid 订单id
   */
  bankPay(orderid: number) {
    return get("pc/pay/ment", { orderId: orderid })
  },
  orderPay(orderid: number, type: number = 1) {
    return post("pc/pay/ment", { type, orderId: orderid, channel: 2 })
  },
  /**
   * 创建配件订单
   * @param addId 收货地址id
   * @param price 实付金额
   * @param params 多个商品id json
   * @returns
   */
  createOrder(addId: number, price: number, params: any, remarks: string = "") {
    return post("pc/order/create", { addId, price, params, remarks })
  },
  getExpressInfo(expressname: string, expressnumber: string) {
    let data = {
      express_sn: expressnumber,
      pinyin: "",
    }
    if (expressname == "中通快递") {
      data.pinyin = "ZTO"
    }
    return post("pc/express/info", data)
  },
}
export default parts
