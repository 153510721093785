
import { defineComponent, ref, inject } from "vue"

export default defineComponent({
	name: "detailinfo",
	components: {},
	props: {
		goodsDetail: {
			type: Object,
			default: () => ({}),
		},
		isRealGoods: {
			type: Number,
			default: 0,
		},
	},
	//props:['goodsDetail'],
	setup() {
		let getImgUrl = inject(
			"getImgUrl",
			(index: string) => {
				return ""
			},
			false
		)
		let staticUrl = inject("staticUrl")
		return { staticUrl, getImgUrl }
	},
})
