
import { defineComponent, ref, inject, computed } from "vue"
import Mask from "@/components/collection/mask.vue"
import Delete from "../../collection/delete.vue"
import ProjectGoods from "./ProjectGoods.vue"
import PartsGoods from "./PartsGoods.vue"
import FreeGoods from "./FreeGoods.vue"
import VideoGoods from "./VideoGoods.vue"
export default defineComponent({
	name: "project goodsinfo",
	components: { Mask, Delete, ProjectGoods, FreeGoods, VideoGoods, PartsGoods },
	props: {
		goods: {
			type: Object,
			default: () => ({}),
		},
		mindex: {
			type: Number,
			default: 0,
		},
	},
	setup(props: any, { emit }) {
		const staticUrl = inject("staticUrl")
		let goodsImg = ref("/static/temp/goods.jpg")
		let changeUrl = (val: any) => {
			if (val.startsWith("https")) {
				return val
			} else {
				return staticUrl + val
			}
		}
		/**
		 * 是否显示删除按钮
		 */
		let showDelete = ref(false)
		/**
		 * 鼠标离开触发的事件
		 */
		let step = inject("step")
		let leaving = (index: any) => {
			showDelete.value = false
			if (index != 2) {
				toedit(0)
			}
		}
		/**
		 * 点击删除按钮显示出删除框
		 */
		let toedit = inject(
			"toedit",
			(index: number) => {
				console.log("我被点击了")
			},
			false
		)

		let clickDelete = (val: number) => {
			showDelete.value = true
			toedit(val)
		}

		let collectionType = inject("collectionType")
		let oneClickDelete = (val: boolean) => {
			showDelete.value = false
			if (val) {
				//调用父级方法，从列表删除
			}
		}
		return {
			goodsImg,
			staticUrl,
			changeUrl,
			leaving,
			showDelete,
			clickDelete,
			step,
			collectionType,
			oneClickDelete,
			toedit,
		}
	},
})
