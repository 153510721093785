
import {
	computed,
	defineComponent,
	onMounted,
	ref,
	reactive,
	inject,
	watch,
} from "vue"
import HtmlFooter from "../../components/HtmlFooter.vue"
import HomeHead from "../../components/HomeHead.vue"
import { goodsApi, publicApi, userApi } from "../../service/api"
import { getSonVip, getVipInfo } from "@/utils/public"
import { userStore } from "@/stores/userStore"
import { useRoute, useRouter } from "vue-router"
import { sjyAlert } from "@/utils/alert"
import InvoiceDialog from "@/components/common/Dialog/InvoiceDialog.vue"
export default defineComponent({
	components: {
		HtmlFooter,
		HomeHead,
		InvoiceDialog,
	},
	setup() {
		interface result {
			datas: any
		}
		// let trys = (val: number) => {

		//   if (val == 1) {
		//     console.log('123')
		//   } else if (val == 2) {
		//     console.log('456')
		//   } else {
		//     console.log('789')
		//   }
		// }
		let userInfo = userStore()
		let tabPosition = ref("left")
		let activeName = ref("second")
		let state = ref(0)
		let userInfor: result = reactive({
			datas: {},
		})

		let arr1: result = reactive({
			datas: {},
		})
		//let vipInfor:any = reactive({})
		let fansInfor: result = reactive({
			datas: {},
		})
		let a = ref(0)
		let author = ref(0)
		let mobile = ref("")
		let userName = ref("")

		userName.value = userStore().username
		mobile.value = userStore().mobile
		let router = useRouter()
		let vipInfor: any = reactive({ data: {} })
		vipInfor.data = getVipInfo()
		//console.log(vipInfor, "会员信息");

		// let vipInfors = sonVipInfo();
		let carouselshare: any = ref()
		let handleClick = (tab: any, event: any) => {
			switch (tab.props.name) {
				case "first":
					carouselshare.value.setActiveItem(0)
					break
				case "second":
					carouselshare.value.setActiveItem(1)
					break
				case "third":
					carouselshare.value.setActiveItem(2)
					break
				default:
					carouselshare.value.setActiveItem(0)
			}
		}
		let changelist = (a: number) => {
			state.value = a
		}
		// 处理头像
		let getImgUrl = inject(
			"getImgUrl",
			(index: string) => {
				return ""
			},
			false
		)

		// 退出登录

		let loginOut = () => {
			userStore().deleteUserInfo()
			sjyAlert("已退出", "success", () => {
				// router.push({ name: "login" }); // userRouter
				location.reload()
			})
		}
		//  总齿轮币
		let money = () => {
			if (userInfor.datas.sgear == null) {
				a.value = 0
			} else {
				a = userInfor.datas.sgear
			}
		}
		//   判断是否登录
		const showName = computed(() => {
			return userName.value ? userName.value : mobile.value
		})
		// console.log(userInfo, userInfo.id, "用户信息");
		// if (userInfo.id > 0) {
		//   userApi.getInformation().then((res: any) => {
		//     //console.log(res);
		//     userInfor.datas = res.datas
		//     author.value = res.datas.id
		//     userApi.userHome(author.value).then((res: any) => {
		//       //console.log(res);
		//       fansInfor.datas = res.data
		//     })
		//   })
		// } else {
		// }

		let movibotClass: any = publicApi.getMulu()
		let gotoMovibot = (url: any) => {
			window.open("https://www.movibot-art.com" + url)
		}

		let gotoMovibots = (url: any) => {
			window.open(url)
		}

		let goSkip = () => {
			if (state.value == 0) {
				let goskip = router.resolve({ name: "goods-class" })
				window.open(goskip.href, "_blank")
			}
			if (state.value == 1) {
				let goskip = router.resolve({ name: "video" })
				window.open(goskip.href, "_blank")
			}
			if (state.value == 2) {
				let goskip = router.resolve({ name: "free" })
				window.open(goskip.href, "_blank")
			}
			if (state.value == 3) {
				window.open(
					"https://www.movibot-art.com/goods/list/1/11/RV%E5%87%8F%E9%80%9F%E6%9C%BA"
				)
			}
		}
		// 共享平台部分点击切换
		let tabsShare = () => {
			if (activeName.value == "first") {
				activeName.value = "third"
				return
			}
			if (activeName.value == "second") {
				activeName.value = "first"
				return
			}
			if (activeName.value == "third") {
				activeName.value = "second"
				return
			}
		}

		let tabsShares = () => {
			if (activeName.value == "first") {
				activeName.value = "second"
				return
			}
			if (activeName.value == "second") {
				activeName.value = "third"
				return
			}
			if (activeName.value == "third") {
				activeName.value = "first"
				return
			}
		}

		// 认证弹窗
		let dialogVisibles = ref(false)

		let setDialogVisibles = () => {
			sonRefs.value?.setVisible(true)
		}
		const sonRefs = ref<InstanceType<typeof InvoiceDialog>>()
		// 关闭弹框
		let mcloses = () => {
			dialogVisibles.value = false
			sonRefs.value?.setVisible(dialogVisibles.value)
		}

		let isVip = userInfo.isVip

		let shareChange = (val: number) => {
			//console.log(val, "开始了吗")
			switch (val) {
				case 0:
					activeName.value = "first"
					break
				case 1:
					activeName.value = "second"
					break
				case 2:
					activeName.value = "third"
					break
				default:
					activeName.value = "first"
			}
		}

		let fatherInformation: result = reactive({
			datas: {},
		})

		let getuserInformation = () => {
			if (userInfo.id > 0) {
				userApi.getInformation().then((res: any) => {
					// getInformation.data = res.datas;
					userInfor.datas = res.datas
					author.value = res.datas.id
					let uid = userInfor.datas.pid
					userApi.userHome(author.value).then((res: any) => {
						//console.log(res);
						fansInfor.datas = res.data
					})
					userApi.getInformation({ uid: uid }).then((res: any) => {
						fatherInformation.datas = res.datas
					})

					if (res.datas.pid > 0) {
						getSonVip(res.datas.pid)
					} else {
						userInfo.updateVip(
							res.datas.vip_type,
							res.datas.vip_title,
							res.datas.vip_etime,
							res.datas.vip_id
						)
					}
				})
			}
		}

		watch(userInfo, () => {
			vipInfor.data = getVipInfo()
		})
		let goUser = () => {
			if (userInfor.datas.pid == 0) {
				router.push({
					name: "personalcenter",
					params: { userid: userInfor.datas.id },
				})
			}
			if (userInfor.datas.pid != 0) {
				router.push({
					name: "personalcenter",
					params: { userid: userInfor.datas.pid },
				})
			}
		}

		onMounted(() => {
			goodsApi.getGoodsClass().then((res: any) => {
				//  console.log(res);

				if (res.datas) {
					arr1.datas = res.datas
				}
				// getSonVip();
				getuserInformation()
			})

			//console.log(this.mobile, this.userName);
		})
		return {
			tabPosition,
			activeName,
			state,
			arr1,
			userInfor,
			vipInfor,
			a,
			router,
			author,
			fansInfor,
			userName,
			mobile,
			handleClick,
			loginOut,
			getImgUrl,
			money,
			changelist,
			showName,
			userInfo,
			movibotClass,
			gotoMovibot,
			gotoMovibots,
			goSkip,
			isVip,
			tabsShare,
			tabsShares,
			dialogVisibles,
			setDialogVisibles,
			mcloses,
			sonRefs,
			shareChange,
			carouselshare,
			getuserInformation,
			goUser,
			fatherInformation,
			// vipInfors,
			// sonInfor,
			// getSonVip,
			// trys
		}
	},
})
