
import { showPrice } from "@/utils/public"
import { defineComponent } from "vue"

export default defineComponent({
  components: {},
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    orderType: {
      type: Number,
      default: 0,
    },
  },
  setup(props: any) {
    let getGoodsPrice = (orderGoods: any) => {
      if (orderGoods.activity_yh_price > 0) {
        return orderGoods.activity_yh_price
      } else if (orderGoods.vip_yh_price > 0) {
        return orderGoods.vip_yh_price
      } else {
        return orderGoods.goods_price
      }
    }
    return { getGoodsPrice, showPrice }
  },
})
