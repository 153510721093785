
import { reactive, defineComponent, ref, inject, computed } from "vue";
import type { UploadProps, UploadUserFile } from "element-plus";
import "vue-cropper/dist/index.css";
import { VueCropper } from "vue-cropper";
import { userApi } from "@/service/api";
import { userStore } from "@/stores/userStore";
import { sjyAlert } from "@/utils/alert";

export default defineComponent({
  name: "avatarEdit",
  emits: ["changeDialogVisible", "setHeaderImg"],
  components: {
    VueCropper,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },

  setup(props: any, { emit }) {
    const dialogVisibled = computed(() => {
      //注意此时需要单独定义，不可封装在data中
      return props.dialogVisible;
    });

    let options = reactive({
      img: "", //原图文件
      autoCrop: true, //默认生成截图框
      fixedBox: true, //固定截图框大小
      canMoveBox: false, //截图框不能拖动
      autoCropWidth: 200, //截图框宽度
      autoCropHeight: 200, //截图框高度
      centerBox: false, //截图框被限制在图片里面
      enlarge: 0.5,
    });
    let previews = reactive({
      data: {
        div: "",
        url: "",
        img: "",
      },
    });
    let previewsDiv = reactive([
      {
        style: {
          width: "200px",
          height: "200px",
          margin: "0 auto",
        },
        zoomStyle: {
          zoom: 1,
        },
      },
      // //68px 预览样式
      // {
      //   style: {
      //     width: "68px",
      //     height: "68px",
      //     margin: "27px auto",
      //   },
      //   zoomStyle: {
      //     zoom: 0.34,
      //   },
      // },
      // //48px 预览样式
      // {
      //   style: {
      //     width: "48px",
      //     height: "48px",
      //     margin: "0 auto",
      //   },
      //   zoomStyle: {
      //     zoom: 0.24,
      //   },
      // },
    ]);
    let active = ref(0);
    const uploads: UploadProps["onChange"] = (uploadFile: any, uploadFiles) => {
      console.log("正在上传");
      const isIMAGE =
        uploadFile.raw.type === "image/jpeg" ||
        uploadFile.raw.type === "image/png";
      const isLt3M = uploadFile.raw.size / 1024 / 1024 < 3;
      console.log(uploadFile.raw.size);

      if (!isIMAGE) {
        console.log("isIMAGE，错误了");
        return false;
      }
      if (!isLt3M) {
        sjyAlert("上传的图片大小最多为3M", "error");
        return false;
      }
      let reader = new FileReader();
      reader.readAsDataURL(uploadFile.raw);
      console.log(uploadFile.raw);
      reader.onload = (e: any) => {
        console.log(e.target.result, "图片数据");
        options.img = e.target.result; //base64
        //cropper.value.changeScale(0.5)
      };
    };
    let realTime = (data: any) => {
      previews.data = data;
    };
    let uploadBtn = ref<any>();
    let uploadPreviews = () => {
      active.value = 1;
      uploadBtn.value.$el.click();
    };
    const userCommonStore = userStore();
    let imgUrl = inject("imgUrl");
    let cropper = ref<any>();
    let getCrop = () => {
      // 获取截图的 base64 数据
      cropper.value.getCropData((data: any) => {
        console.log(data);
      });
      // 获取截图的 blob 数据
      cropper.value.getCropBlob((data: any) => {
        updateOss(data);
        console.log(data);
      });
    };
    let closeDialog = () => {
      emit("changeDialogVisible");
    };
    let updateOss = (rawFile: any) => {
      userApi.getOSSToken().then((res: any) => {
        let url = res.data.host;
        let new_multipart_params = {
          // key表示上传到Bucket内的Object的完整路径，例如exampledir/exampleobject.txtObject，完整路径中不能包含Bucket名称。
          // filename表示待上传的本地文件名称。
          key: res.data.dir + userCommonStore.id + "/" + "header.jpg",
          policy: res.data.policy,
          OSSAccessKeyId: res.data.accessid,
          // 设置服务端返回状态码为200，不设置则默认返回状态码204。
          success_action_status: "200",
          signature: res.data.signature,
          file: rawFile,
        };
        userApi.updateOss(url, new_multipart_params).then((res: any) => {
          //console.log("返回信息", new_multipart_params, "返回信息结束")
          //调用
          let updateImgUrl = imgUrl + "/" + new_multipart_params.key;
          //调用父级方法，关闭弹出框并传过去头像的地址
          //changeDialogVisible
          emit("setHeaderImg", updateImgUrl);
        });
      });
    };

    let imageUrlToBase64 = (url: string, mactive: number = 1) => {
      active.value = mactive;
      let homeImage = new Image();
      // 解决跨域问题
      homeImage.setAttribute("crossOrigin", "anonymous");
      homeImage.src = url;
      //homeImage.onload为异步加载
      homeImage.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = homeImage.width;
        canvas.height = homeImage.height;
        let context = canvas.getContext("2d") as CanvasRenderingContext2D;
        context.drawImage(homeImage, 0, 0, homeImage.width, homeImage.height);
        let quality = 0.8;
        // 这里的dataurl就是base64类型
        let dataURL = canvas.toDataURL("image/jpeg", quality);
        options.img = dataURL;
        console.log(dataURL);
        // base64ToFile(dataURL, "moren"); // 这里是将base64转为file格式的方法
      };
    };

    return {
      options,
      previews,
      previewsDiv,
      uploads,
      realTime,
      uploadPreviews,
      getCrop,
      closeDialog,
      uploadBtn,
      cropper,
      dialogVisibled,
      imageUrlToBase64,
      active,
    };
  },
});
