let dateFormat = (time: number, type: string) => {
  let formatTime
  let date
  if (time === 0) {
    date = new Date()
  } else {
    date = new Date(time)
  }
  let Year = date.getFullYear()
  let month = date.getMonth() + 1
  let Month = month >= 10 ? month : '0' + month
  let day = date.getDate()
  let Day = day >= 10 ? day : '0' + day
  let Hour = date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()
  let Minute = date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()
  let Second = date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds()
  if (type === 'yyyy-MM-dd') {
    formatTime = Year + '-' + month + '-' + day
    return formatTime
  } if (type === '年-月-日') {
    formatTime = Year + '年' + month + '月' + day + "日"
    return formatTime
  }if (type === '月-日') {
    formatTime = month + '月' + day + "日"
    return formatTime
  } else if (type === 'MM-dd') {
    formatTime = month + '-' + day
    return formatTime
  } else if (type === 'yyyy-MM-dd hh:mm:ss') {
    formatTime = Year + '-' + month + '-' + day + ' ' + Hour + ':' + Minute + ':' + Second
    return formatTime
  } else if (type === 'hh:mm:ss') {
    formatTime = Hour + ':' + Minute + ':' + Second
    return formatTime
  } else if(type === 'YYYY-MM-dd'){
    formatTime = Year + '-' + Month + '-' + Day
    return formatTime
  }else {
    return "error type!"
  }
}
let videoDateFormat = (time: number) => {
  let nowdate
  let date
  nowdate = new Date()
  date = new Date(time)
  //console.log(nowdate.getFullYear(),date.getFullYear());

  if (nowdate.getFullYear() == date.getFullYear()) {

    return dateFormat(time, "月-日");
  } else {
    return dateFormat(time, "年-月-日");
  }
}
let durationFormat = (time: number) => {
  if (time < 2) {
    time = 30 * 1000
  }
  let date
  date = new Date(time)
  let Minute = date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()
  let Second = date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds()
  return Minute + ":" + Second
}
/**
 * 
 * @param time 单位s
 * @returns 10：20
 */
let arrangeDate = (time: number) => {
  time = time * 1000
  let date
  date = new Date(time)
  let Minute = date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()
  let Second = date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds()
  return Minute + ":" + Second
}

/**字符串转换成时间戳 */
function dateStrChangeTimeTamp(dateStr:string){
  //例如var date = '2015-03-05 17:59:00.0';
  dateStr = dateStr.substring(0,19);
  dateStr = dateStr.replace(/-/g,'/');
  return new Date(dateStr).getTime();
}
export default {
  dateFormat,
  videoDateFormat,
  durationFormat,
  arrangeDate,
  dateStrChangeTimeTamp
}