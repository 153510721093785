
import { defineComponent, inject } from "vue"
import GoodsInfoCollection from "./GoodsInfoCollection.vue"

export default defineComponent({
	components: { GoodsInfoCollection },
	props: {
		goodslit: {
			type: Object,
			default: () => ({}),
		},
		editstatus: {
			type: Boolean,
			default: false,
		},
	},
	setup(props: any) {
		let step = inject("step")

		let getmindex = (val: string) => {
			return parseInt(val)
		}
		return { step, getmindex }
	},
})
