
import { reactive, defineComponent, ref, watch, onMounted } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import { EluiChinaAreaDht } from "elui-china-area-dht";
import type { UploadProps, UploadUserFile } from "element-plus";
import { ElMessage, ElMessageBox } from "element-plus";
const chinaData = new EluiChinaAreaDht.ChinaArea().chinaAreaflat;
import { company } from "@/service/api";
import type { Action } from "element-plus";
import { userStore } from "@/stores/userStore";
import { sjyAlert } from "@/utils/alert";
import { userApi } from "@/service/api";
import { useRoute } from "vue-router";

export default defineComponent({
  emits: ["change", "gonext"],

  components: { EluiChinaAreaDht },
  setup(props: any, context) {
    interface CardType {
      name: string;
      url: string;
    }
    interface userinfoType {
      license: Array<CardType>;

      name: string;
      mobile: string;
      office_num: string;
    }
    let firmForm = reactive({
      comopany: "",
      address: "",
      products: "",
      company_type: "",
      province: "",
      city: "",
      area: "",
      // 省 市 区 对应的code
      area_code: "",
      city_code: "",
      province_code: "",

      uid: 0,
    });
    const validatePass = (rule: any, value: any, callback: any) => {
      console.log("我执行了");

      if (companyForm.addr.length == 0) {
        callback(new Error("请选择公司所在地区"));
      } else {
        callback();
      }
    };
    const ruleFormRef = ref<FormInstance>();
    // {}

    const rules = reactive<FormRules>({
      comopany: [{ required: true, message: "请输入公司名", trigger: "blur" }],
      address: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
      products: [
        { required: true, message: "请输入主营产品", trigger: "blur" },
      ],
      company_type: [
        { required: true, message: "请选择公司类型", trigger: "blur" },
      ],
      area: [{ validator: validatePass, trigger: "blur" }],
    });

    const ruleFormRef1 = ref<FormInstance>();
    const rules1 = reactive<FormRules>({
      mobile: [
        { required: true, message: "请输入电话号", trigger: "blur" },
        {
          pattern: "^1[35678]\\d{9}$",
          message: "请输入正确的手机号",
          trigger: "blur",
        },
      ],
      name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
      office_num: [
        { required: true, message: "请输入公司电话号", trigger: "blur" },
      ],
      license: [{ required: true, message: "请上传营业执照", trigger: "blur" }],
    });

    interface userFormType {
      addr: Array<string>;
    }
    let companyForm: userFormType = reactive({
      addr: [],
    });

    let onChange = (e: any) => {
      firmForm.province = chinaData[e[0]].label;
      firmForm.city = chinaData[e[1]].label;
      //firmForm.area = chinaData[e[2]].label;

      if (e.length > 2) {
        firmForm.area = chinaData[e[2]].label;
        firmForm.area_code = chinaData[e[2]].value;
      } else {
        firmForm.area = "";
        firmForm.area_code = "";
      }
      firmForm.province_code = chinaData[e[0]].value;
      firmForm.city_code = chinaData[e[1]].value;
      //firmForm.area_code = chinaData[e[2]].value;
    };

    let show = ref(true);

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          show.value = false;
        } else {
          //console.log("error submit!", fields);
        }
      });
    };

    // 提交界面

    const imageUrl = ref("");
    let firmForms: userinfoType = reactive({
      name: "",
      mobile: "",
      office_num: "",
      // 营业执照
      license: [],
    });

    // 照片上传
    const dialogImageUrls = ref("");
    const dialogVisibles = ref(false);
    const handlePictureCardPreviews: UploadProps["onPreview"] = (
      uploadFile
    ) => {
      dialogImageUrls.value = uploadFile.url!;
      dialogVisibles.value = true;
    };

    const beforeUpload: UploadProps["beforeUpload"] = (rawFile: any) => {
      if (rawFile.type !== "image/jpeg" && rawFile.type !== "image/png") {
        sjyAlert("请上传正确的图片格式", "error");
        return false;
      } else if (rawFile.size / 1024 / 1024 > 5) {
        sjyAlert("图片单张大小不能超过5M", "error");
        return false;
      }
      userApi.getOSSToken().then((res: any) => {
        let url = res.data.host;
        let new_multipart_params = {
          // key表示上传到Bucket内的Object的完整路径，例如exampledir/exampleobject.txtObject，完整路径中不能包含Bucket名称。
          // filename表示待上传的本地文件名称。
          key: res.data.dir + rawFile.uid + rawFile.name,
          policy: res.data.policy,
          OSSAccessKeyId: res.data.accessid,
          // 设置服务端返回状态码为200，不设置则默认返回状态码204。
          success_action_status: "200",
          signature: res.data.signature,
          file: rawFile,
        };
        userApi.updateOss(url, new_multipart_params).then((res: any) => {
          firmForms.license = [
            {
              name: rawFile.uid + rawFile.name,
              url:
                "https://image.sjyuan.net.cn/img/sjy/" +
                rawFile.uid +
                rawFile.name,
            },
          ];
        });
      });
      return false;
    };

    let userinfot = userStore();
    const open = () => {
      firmForm.uid = userinfot.id;
      company.companyVerify(firmForm).then((res: any) => {
        console.log(res);
      });
      ElMessageBox.alert(
        "审核结果将在1-3个工作日内公布,请耐心等待",
        "提交成功",
        {
          confirmButtonText: "OK",
          callback: (action: Action) => {
            ElMessage({
              type: "info",
              message: `action: ${action}`,
            });
          },
        }
      );
    };

    let getRouter = useRoute().params.type;

    const submitForm1 = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      await formEl.validate((valid, fields) => {
        if (valid) {
          let toSubmitFrom = {
            source: 0,
            comopany: firmForm.comopany,
            address: firmForm.address,
            products: firmForm.products,
            company_type: firmForm.company_type,
            province: firmForm.province,
            city: firmForm.city,
            area: firmForm.area,
            // 省 市 区 对应的code
            area_code: firmForm.area_code,
            city_code: firmForm.city_code,
            province_code: firmForm.province_code,
            // 营业执照
            license: firmForms.license[0].url,
            uid: userinfot.id,
            name: firmForms.name,
            mobile: firmForms.mobile,
            office_num: firmForms.office_num,
          };

          if (getRouter && getRouter == "1") {
            toSubmitFrom.source = 1;
          } else {
            toSubmitFrom.source = 2;
          }
          console.log(toSubmitFrom);

          company.addcompany(toSubmitFrom).then((res: any) => {
            //console.log(firmForm);
            //console.log(res);
            if (res.errno == 0) {
              context.emit("gonext");
            } else {
              sjyAlert(res.errmsg, "error");
            }
          });
        } else {
          //console.log("error submit!", fields);
        }
      });
    };

    let goback = () => {
      show.value = true;
    };

    interface result {
      data: any;
    }

    let getInformation: result = reactive({
      data: {},
    });

    let getInformations: result = reactive({
      data: {},
    });
    // 个人信息接口
    let getuserInformation = () => {
      userApi.getInformation().then((res: any) => {
        getInformations.data = res.datas;
      });
    };

    let getcompany = () => {
      company.companyinformation().then((res: any) => {
        getInformation.data = res.datas;
        firmForm.comopany = getInformation.data.company;
        firmForm.province = getInformation.data.province;
        firmForm.area = getInformation.data.area;
        firmForm.city = getInformation.data.city;
        firmForm.address = getInformation.data.address;
        firmForm.products = getInformation.data.products;
        // firmForms.license =getInformation.data.license
        firmForms.name = getInformation.data.name;
        firmForms.mobile = getInformation.data.mobile;
        firmForms.office_num = getInformation.data.office_num;
      });
    };

    onMounted(() => {
      getcompany();
      getuserInformation();
    });
    return {
      firmForm,
      onChange,
      rules,
      rules1,
      companyForm,
      show,
      // initAddress,

      beforeUpload,
      imageUrl,
      open,
      goback,
      firmForms,
      userinfot,
      submitForm,
      ruleFormRef,
      ruleFormRef1,
      submitForm1,
      getInformation,
      getRouter,
      handlePictureCardPreviews,
      dialogVisibles,
      dialogImageUrls,
    };
  },
});
