
// 引入element ui 样式
import { reactive, ref, defineComponent, onMounted, onUnmounted } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import SlideVerify, { SlideVerifyInstance } from "vue3-slide-verify";
import HtmlFooter from "../../components/HtmlFooter.vue";
import HomeHead from "../../components/HomeHead.vue";
import { userApi, publicApi } from "@/service/api";
import { sjyAlert } from "@/utils/alert";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { HtmlFooter, HomeHead, SlideVerify },
  setup() {
    const ruleFormRef = ref<FormInstance>();
    const registerForm = reactive({
      mobile: "",
      code: "",
      agreement: [],
      password: "",
    });

    const rules = reactive<FormRules>({
      agreement: [
        {
          type: "array",
          // required 是否为必选项
          required: true,
          message: "请勾选",
          //验证逻辑的触发方式
          trigger: "change",
        },
      ],

      mobile: [
        { required: true, message: "请输入手机号", trigger: "blur" },
        {
          pattern: "^1[35678]\\d{9}$",
          message: "请输入正确的手机号",
          trigger: "blur",
        },
      ],
      password: [
        {
          required: true,
          message: "请输入8-16位大小写字母、数字组合的登录密码",
          trigger: "blur",
        },
        {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{8,16}$/,
          message: "密码需为8-16位大小写字母、数字组合",
          trigger: "blur",
        },
      ],
    });
    let router = useRouter();

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          //console.log(registerForm);
          userApi.userRegister(registerForm).then((res: any) => {
            console.log(res);
            if (res.errno == 0) {
              sjyAlert("注册成功", "success", () => {
                router.push({ name: "login" });
              });
            } else {
              sjyAlert(res.errmsg, "error");
            }
          });
        }
      });
    };

    let count = ref(60);

    let isShow = ref(true);
    //let 秒后再获取
    let countShowText = ref("获取验证码");
    var times: any;

    const showVerifyCode = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          verifyCode.value = true;
        } else {
        }
      });
    };
    let verifyImg = ["/static/verify/1.jpg", "/static/verify/2.jpg"];
    let countdown = () => {
      count.value = 60; //赋值60秒
      isShow.value = false;

      getPhongCode();
      times = setInterval(() => {
        count.value = count.value - 1; //递减
        if (count.value <= 0) {
          // <=0 变成获取按钮
          isShow.value = true;
          clearInterval(times);
        }
        countShowText.value = count.value + "秒后再获取";
      }, 1000); //1000毫秒后执行
    };
    let verifyCode = ref(false);
    let getPhongCode = () => {
      //这里应该验证一下手机号的正确性  TODO
      publicApi.getPhoneCode(registerForm.mobile).then((res: any) => {
        if (res.errno == 0) {
          sjyAlert("发送成功", "success");
          verifyCode.value = false;
        } else {
          isShow.value = true;
          clearInterval(times);
          sjyAlert(res.errmsg, "error");
        }
      });
    };

    const msg = ref("");
    const block = ref<SlideVerifyInstance>();

    const onAgain = () => {
      msg.value = "检测到非人为操作的哦！ try again";
      // 刷新
      block.value?.refresh();
    };
    const onSuccess = (times: number) => {
      //msg.value = `login success, 耗时${(times / 1000).toFixed(1)}s`
      ///console.log(msg.value)
      countdown();
    };
    const onFail = () => {
      msg.value = "验证不通过";
    };

    const onRefresh = () => {
      msg.value = "点击了刷新小图标";
    };

    const handleClick = () => {
      // 刷新
      block.value?.refresh();
      msg.value = "";
    };
    // 点击验证图片以外的部分关闭验证图片
    let offVerifyCode = () => {
      verifyCode.value = false;
    };
    let open = () => {
      const { href } = router.resolve({
        name: "privacyPolicy",
      });
      window.open(href, "_blank");
    };

    onMounted(() => {
      window.addEventListener("click", offVerifyCode);
    });

    onUnmounted(() => {
      window.removeEventListener("click", offVerifyCode);
    });

    return {
      registerForm,
      rules,
      ruleFormRef,
      submitForm,
      countdown,
      countShowText,
      isShow,
      open,
      text: "向右滑动->",
      accuracy: 1,
      onSuccess,
      onFail,
      onRefresh,
      handleClick,
      showVerifyCode,
      onAgain,
      verifyCode,
      verifyImg,
      block,
    };
  },
});
