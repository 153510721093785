
import { reactive, ref, defineComponent, provide } from "vue";
import PageShow from "@/components/common/PageShow.vue";

import { userApi } from "@/service/api";
import InvoiceDialog from "@/components/common/Dialog/InvoiceDialog.vue";
import InvoiceFrom from "@/components/common/Dialog/item/InvoiceFrom.vue";
import type { FormInstance, FormRules } from "element-plus";
import lackRecordVue from "./lackRecord.vue";
export default defineComponent({
  components: { PageShow, InvoiceDialog, InvoiceFrom, lackRecordVue },
  name: "topuptable",
  props: {
    AccountToSon: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["getAccounts"],
  setup(props: any, context) {
    let addressItem = reactive({
      data: {},
    });

    const dialogFormVisible = ref(false);

    const total = ref(100);
    // let handleCurrentChange = (val: number) => {
    //   console.log(val);
    // };

    const ruleFormRef = ref<FormInstance>();
    const invoiceForm = reactive({
      name: "",
    });
    const rules = reactive<FormRules>({
      name: [
        {
          required: true,
          message: "Please input Activity name",
          trigger: "blur",
        },
      ],
    });

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          dialogFormVisible.value = false;
          console.log("submit!");
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    // 发票弹框

    interface addressType {
      data: any;
    }

    let addressList: addressType = reactive({
      data: [],
    });
    let lastInfor: addressType = reactive({
      data: {},
    });

    let dialogVisible = ref(false);
    const sonRef = ref<InstanceType<typeof InvoiceDialog>>();
    let recordId = ref("");
    let setDialogVisible = (index: number) => {
      sonRef.value?.setVisible(true);
      //console.log();
      recordId.value = props.AccountToSon.data.list[index].id;
      //todo  通过recrdid获取已经填写的信息
      //  调用一下发票显示接口
      userApi.invoiceShow().then((res: any) => {
        //console.log(res.datas.length, "对象的长度");
        //console.log(res.datas,"是否为空");

        if (res.datas.length != 0) {
          goShow.value = false;
          lastInfor.data = res.datas;
        }
      });
    };

    let mclose = (type: number = 0) => {
      dialogVisible.value = false;
      sonRef.value?.setVisible(dialogVisible.value);
      //if (type == 1) {
      getAddressList();
      context.emit("getAccounts");
      //}
      addressItem.data = {};
    };

    // 发票信息弹框
    let dialogVisibles = ref(false);
    const sonRefs = ref<InstanceType<typeof InvoiceDialog>>();
    let fid = ref("");
    let information: addressType = reactive({
      data: {},
    });
    let setDialogVisibles = (index: number) => {
      sonRefs.value?.setVisible(true);
      //console.log();
      fid.value = props.AccountToSon.data.list[index].id;
      userApi.invoiceInfo(fid.value).then((res: any) => {
        //console.log(res, "111");
        information.data = res.datas;
      });
    };

    let mcloses = (type: number = 0) => {
      dialogVisible.value = false;
      sonRefs.value?.setVisible(dialogVisible.value);
    };

    // 获取地址
    let getAddressList = () => {
      userApi.addressList().then((res: any) => {
        addressList.data = res.datas;
      });
    };

    let goShow = ref(true);
    let changeGoShow = (val: boolean) => {
      goShow.value = val;
    };

    return {
      total,
      // handleCurrentChange,
      dialogFormVisible,
      invoiceForm,
      rules,
      submitForm,
      ruleFormRef,
      sonRef,
      setDialogVisible,
      addressList,
      addressItem,
      mclose,
      getAddressList,
      recordId,
      sonRefs,
      setDialogVisibles,
      dialogVisibles,
      mcloses,
      fid,
      information,
      goShow,
      lastInfor,
      changeGoShow,
    };
  },
});
