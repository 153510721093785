
import { reactive, defineComponent, ref, onMounted } from "vue";
import InvoiceDialog from "@/components/common/Dialog/InvoiceDialog.vue";
import { userApi } from "@/service/api";
import { userStore } from "@/stores/userStore";
import { getVipInfo } from "@/utils/public";
import dateStrChangeTimeTamp from "@/utils/dateFormat";
import dateFormat from "@/utils/dateFormat";

export default defineComponent({
  components: { InvoiceDialog },
  setup() {
    // 开通弹框
    let dialogVisible = ref(false);

    let setDialogVisible = () => {
      sonRef.value?.setVisible(true);
      //console.log();
    };
    const sonRef = ref<InstanceType<typeof InvoiceDialog>>();
    // 关闭弹框
    let mclose = () => {
      dialogVisible.value = false;
      sonRef.value?.setVisible(dialogVisible.value);
    };

    // 个人信息
    interface result {
      data: any;
    }
    let getInformation: result = reactive({
      data: {},
    });
    let fatherInformation: result = reactive({
      data: {},
    });

    // 会员开通记录
    let viprecord: result = reactive({
      data: {},
    });
    let getuserInformation = () => {
      userApi.getInformation().then((res: any) => {
        getInformation.data = res.datas;
        let uid = getInformation.data.pid;
        userApi.getInformation({ uid: uid }).then((res: any) => {
          //console.log(res, "1234");
          fatherInformation.data = res.datas;
        });
        //console.log(res);
      });
      let data = {
        id: userinfot.id,
      };
    };
    let userinfot = userStore();

    let getFatherInfor = () => {};

    let a = ref(0);
    let money = () => {
      if (
        fatherInformation.data.sgear == null ||
        getInformation.data.sgear == null
      ) {
        a.value = 0;
      }
      if (getInformation.data.pid != 0) {
        a = fatherInformation.data.sgear;
      }
      if (getInformation.data.pid == 0) {
        a = getInformation.data.sgear;
      }
    };

    let getTime = (index: number) => {
      let newTime = dateStrChangeTimeTamp.dateStrChangeTimeTamp(
        viprecord.data.list[index].ctime
      );
      let a = ref("");
      if (viprecord.data.list[index].vip_id == 16) {
        a.value = dateFormat.dateFormat(newTime + 2592000000, "YYYY-MM-dd");
        return a.value;
      }
      if (viprecord.data.list[index].vip_id == 17) {
        a.value = dateFormat.dateFormat(newTime + 2592000000 * 3, "YYYY-MM-dd");
        return a.value;
      }
      if (viprecord.data.list[index].vip_id == 18) {
        a.value = dateFormat.dateFormat(
          newTime + 2592000000 * 12,
          "YYYY-MM-dd"
        );
        return a.value;
      }
    };

    let getVipList = () => {
      userApi.userVipRecord().then((res: any) => {
        console.log("aaa", res);
        viprecord.data = res.datas;
      });
    };

    //区分 vip 类型
    let vipinfo = getVipInfo();

    onMounted(() => {
      getuserInformation();
      getVipList();
      getFatherInfor();
    });
    return {
      dialogVisible,
      mclose,
      sonRef,
      setDialogVisible,
      getInformation,
      getuserInformation,
      a,
      money,
      userinfot,
      getVipList,
      vipinfo,
      fatherInformation,
      viprecord,
      getTime,
    };
  },
});
