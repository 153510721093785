
import { defineComponent,ref,provide, inject} from "vue";
import PayCommon from "./PayItem/PayCommon.vue";
import PaySetPwd from "./PayItem/PaySetPwd.vue";
import PayInputPwd from "./PayItem/PayInputPwd.vue";

export default defineComponent({
  components: { PayCommon, PaySetPwd, PayInputPwd },
  props:{
    creatOrderInfo:{
      type: Object,
      default: () => ({})
    },
    wrongtimes:{
      type: Number,
      default: 0
    }
  },
  setup(props:any) {
    let setPwd = ref(true)
    console.log(props.creatOrderInfo);    
    setPwd.value = (props.creatOrderInfo.data.pay_password==0||props.creatOrderInfo.data.pay_password==null)&&(props.creatOrderInfo.data.gear-props.creatOrderInfo.data.price>=0)?true:false

    console.log(props.creatOrderInfo.data.pay_password,props.creatOrderInfo.data.gear-props.creatOrderInfo.data.price,"我的打印",props.creatOrderInfo.data);
    

    let setPayPassword = ()=>{
      setPwd.value = false;
    }
    provide("setPayPassword", setPayPassword);

    return {setPwd};
  },
});
