
import { defineComponent, ref,inject } from "vue";

export default defineComponent({
  name:"imginfo",
  components: {},
  props:['goodsDetail'],
  setup(props:any) {
    let imgUrl = ref([]);
    let staticUrl = inject("staticUrl");
    imgUrl.value = props.goodsDetail.bodys2;
    let getimgUrl = (val: any) => {
			if (val.startsWith("https")) {
				return val
			} else {
				return staticUrl + val
			}
		}
    return { imgUrl,getimgUrl };
  },
});
