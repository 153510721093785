
import { defineComponent, reactive, ref, onMounted } from "vue"
import VideoGoodsItem from "./VideoGoodsItem.vue"
import Loading from "../common/Loading.vue"

export default defineComponent({
	name: "VideoSearchLine",
	components: { VideoGoodsItem, Loading },
	props: {
		goodslit: {
			type: Object,
			default: () => ({}),
		},
	},
	setup() {
		let loadingType = ref(0)

		let aid = 0
		let sort = 0
		onMounted(() => {})
		return {
			loadingType,
			aid,
			sort,
		}
	},
})
