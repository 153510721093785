
import { reactive, defineComponent, ref, onMounted } from "vue";
import { uploadHistory } from "@/service/api";

export default defineComponent({
  emits: ["returnSubmit"],
  setup(props, context) {
    let returnSubmit = () => {
      context.emit("returnSubmit");
    };
    interface result {
      data: any;
    }

    let workInfor: result = reactive({
      data: [],
    });
    let worksHistory = () => {
      uploadHistory.uploadHistory().then((res: any) => {
        //  console.log(res.datas.data[0], "历史");
        workInfor.data[0] = res.datas.data[0];
      });
    };
    onMounted(() => {
      worksHistory();
    });
    return {
      returnSubmit,
      workInfor,
    };
  },
});
