
import {
	computed,
	defineComponent,
	inject,
	onMounted,
	provide,
	reactive,
	ref,
	watch,
} from "vue"
import HtmlFooter from "../../components/common/HtmlFooter.vue"
import HomeHead from "@/components/HomeHead.vue"
import AddQuestion from "../../components/order/AddQuestion.vue"
import ShowQuestion from "../../components/order/ShowQuestion.vue"
import { orderApi } from "@/service/api"
import Dialog from "@/components/common/Dialog/Dialog.vue"
import Title from "@/components/common/Dialog/item/Title.vue"
import PayDialog from "@/components/common/Dialog/item/PayDialog.vue"
import DialogTitle from "@/components/common/DialogTitle.vue"

import { useRoute, useRouter } from "vue-router"
import { getVipInfo } from "@/utils/public"
import { sjyAlert } from "@/utils/alert"
import { userStore } from "@/stores/userStore"
// import {plu} from "element-plus/icons";

export default defineComponent({
	components: {
		HtmlFooter,
		HomeHead,
		AddQuestion,
		ShowQuestion,
		PayDialog,
		Title,
		Dialog,
		DialogTitle,
	},
	setup() {
		interface questionType {
			pics: Array<string>
			content: string
		}
		let userStoreInfo = userStore()
		interface unSubmitType {
			id: number
			list: Array<questionType>
		}
		let id = useRoute().params.id
		let orderid = useRoute().params.orderid

		//待提交的问题列表
		let uncommitted = reactive({ params: { id: id, list: [] } as any })
		let addToUnCommitted = (val: unSubmitType, index: number = 100) => {
			if (index == 100) {
				uncommitted.params.list.push(val)
			} else {
				uncommitted.params.list[index] = val
			}
		}

		const staticUrl = inject("staticUrl")
		let changeUrl = (val: any) => {
			if (val.startsWith("https")) {
				return val
			} else {
				return staticUrl + val
			}
		}

		let submitq = () => {
			if (payPrice.value > 0) {
				toPayOrder()
			} else {
				orderApi.askAdd(uncommitted).then((res: any) => {
					if (res.errno == 0) {
						sjyAlert("提交成功", "success")
						setTimeout(() => {
							console.log("我执行了")
							router.push({ name: "orderover", params: { id: orderid } })
						}, 1000)
					}
				})
			}
		}

		let todelete = (index: number) => {
			uncommitted.params.list.splice(index, 1)
		}
		let toshow = (index: number) => {
			//关闭所有的展开查看
			for (let i = 0; i < uncommitted.params.list; i++) {
				uncommitted.params.list[i].isShow = false
			}
			uncommitted.params.list[index].isShow =
				!uncommitted.params.list[index].isShow
		}

		let toPayOrder = () => {
			toPay.value?.setVisible(true)
		}
		let router = useRouter()

		let successPay = (params: any) => {
			uncommitted.params.pay_password = params.pay_password
			uncommitted.params.price = payPrice.value
			orderApi.askAdd(uncommitted).then((res: any) => {
				if (res.errno == 0) {
					sjyAlert("支付成功", "success")
					router.push({ name: "orderover", params: { id: orderid } })
					//跳转到订单下载页面
				} else {
					sjyAlert(res.errmsg, "error")
				}
				return false
			})
		}
		provide("successPay", successPay)

		let creatOrderInfo = reactive({
			data: {
				gear: 0,
				id: 0,
				nu: "",
				pay_password: 1,
				price: 2696,
			},
		})
		let committed = reactive({ data: {} as any })
		let hideAdd = ref(false)
		let getAskById = () => {
			orderApi.getAskById(parseInt(id as string)).then((res: any) => {
				if (res.errno == 0) {
					committed.data = res.datas
					creatOrderInfo.data.gear = res.datas.gear
					for (let i = 0; i < committed.data.list.length; i++) {
						if (committed.data.list[i].status == 0) {
							hideAdd.value = true
							break
						}
					}
				}
			})
		}
		const toPay = ref<InstanceType<typeof Dialog>>()

		let dialogVisible = ref(false)

		let mclose = (type: number = 0) => {
			dialogVisible.value = false
			toPay.value?.setVisible(dialogVisible.value)
			if (type == 1) {
				//getAddressList();
			}
			//addressItem.data = {};
		}
		const payPrice = ref(0)

		let vipInfo = getVipInfo()

		let isBuyed = () => {
			let isTrue = true
			if (committed.data.list && committed.data.list.length > 0) {
				for (let i = 0; i < committed.data.list.length; i++) {
					if (committed.data.list[i].status == "0") {
						isTrue = false
						break
					}
				}
			} else {
				isTrue = false
			}
			return isTrue
		}

		watch(uncommitted, (newvalue, oldvalue) => {
			if (uncommitted.params.list.length > 0) {
				if (committed.data.is_consult == 1) {
					if (
						Math.round(
							parseInt(uncommitted.params.list.length) +
								parseInt(committed.data.list.length)
						) > 3
					) {
						payPrice.value =
							Math.round(
								parseInt(uncommitted.params.list.length) +
									parseInt(committed.data.list.length) -
									3
							) * 10
					} else {
						payPrice.value = 0
					}
				} else {
					if (parseInt(uncommitted.params.list.length) > 3) {
						payPrice.value =
							Math.round(parseInt(uncommitted.params.list.length) - 3) * 10 + 25
					} else {
						payPrice.value = 25
					}
				}
			}
			//if (vipInfo.type > 0) {
			payPrice.value = Math.round(payPrice.value * userStoreInfo.discount)
			//}
			creatOrderInfo.data.price = payPrice.value
		})

		let toSee = (index: number) => {
			for (let i = 0; i < committed.data.list; i++) {
				committed.data.list[i].isShow = false
			}
			committed.data.list[index].isShow = !committed.data.list[index].isShow
		}
		onMounted(() => {
			getAskById()
		})

		return {
			addToUnCommitted,
			uncommitted,
			submitq,
			todelete,
			toshow,
			committed,
			mclose,
			creatOrderInfo,
			toPayOrder,
			toPay,
			payPrice,
			toSee,
			hideAdd,
			changeUrl,
			isBuyed,
		}
	},
})
