
import { defineComponent, onMounted, reactive, inject } from "vue";
export default defineComponent({
  components: {},
  setup() {
    let self = inject("self");
    console.log(self);
    
    return {
      self,
    };
  },
});
