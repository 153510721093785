
import HeaderNav from "@/components/HeaderNav.vue"
import DemoSwiper from "@/components/DemoSwiper.vue"
import HeaderNavMid from "@/components/HeaderNavMid.vue"
import NavList from "@/components/NavList.vue"
import GoodsAttr from "@/components/goods/GoodsAttr.vue"
import GoodsItem from "@/components/goods/GoodsItem.vue"
import GoodsList from "@/components/goods/GoodsList.vue"
import HtmlFooter from "@/components/HtmlFooter.vue"
import { defineComponent, reactive, onMounted, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import HomeHead from "@/components/HomeHead.vue"
import CurrentAddress from "@/components/common/CurrentAddress.vue"

export default defineComponent({
  name: "Index",
  components: {
    HeaderNav,
    DemoSwiper,
    HeaderNavMid,
    NavList,
    GoodsAttr,
    GoodsItem,
    GoodsList,
    HtmlFooter,
    HomeHead,
    CurrentAddress,
  },

  setup(props: any, { emit }) {
    //参数
    let route = useRoute()
    let router = useRouter()
    // watch(route, () => {
    //   router.go(0)
    // })
    interface whereType {
      params: {
        cid: number
        aids: Array<number>
      }
    }
    let cid = route.params.cid ? parseInt(route.params.cid as string) : 0
    let aid = route.params.aid
    let freeWhere: whereType = reactive({
      params: {
        cid: 0,
        aids: [],
      },
    })

    watch(route,()=>{
      router.go(0)
    })

    let setGoodsWhere = (where: any) => {
      freeWhere.params = where.params
    }
    return {
      setGoodsWhere,
      freeWhere,
      cid,
      aid,
    }
  },
})
