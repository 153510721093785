import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input_number = _resolveComponent("el-input-number")!

  return (_openBlock(), _createBlock(_component_el_input_number, {
    onChange: _ctx.handleChange,
    modelValue: _ctx.num,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.num) = $event)),
    min: _ctx.goodsInfo.data.minimum,
    max: _ctx.goodsInfo.data.stock
  }, null, 8, ["onChange", "modelValue", "min", "max"]))
}