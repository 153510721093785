
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name:"banner",//banner组件
  props: {
    imgUrl: {
      type: String,
      default: "",
    },
    /**
     *是否全屏，默认false，显示内容宽度
     */
    isFullScreen: {
      type: Boolean,
      default: false,
    },
    /**
     * toUrl 跳转的url
     * object {name:,params}
     */
    toUrl: {
      type: Object,
      default: () => ({}),
    },
    /**
     * 是否有跳转
     */
    isJump:{
        type: Boolean,
        default: false
    }
  },
  setup(props: any, context) {

    let router = useRouter();

    /**
     * 跳转链接
     */
    let goto = () => {
      if(props.isJump){
        router.push(props.toUrl);
      }else{
        return false;
      }
      
    };
    return {
      goto,
    };
  },
});
