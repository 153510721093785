
  import { defineComponent } from "vue";
  import CustomerTitle from "./common/CustomerTitle.vue";
  
  export default defineComponent({
    components: { CustomerTitle },
    setup() {
      let titleList = ["账户管理", "优惠券使用说明"];
      return { titleList };
    },
  });
  