
import {
  defineComponent,
  ref,
  computed,
  reactive,
  onMounted,
  inject,
} from "vue"
import HtmlFooter from "@/components/HtmlFooter.vue"
import HomeHead from "@/components/HomeHead.vue"
import CurrentAddress from "@/components/common/CurrentAddress.vue"
import { ElTable } from "element-plus"
import GoodsItem from "@/components/order/GoodsItem.vue"
import { goodsApi, orderApi } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
import DialogTitle from "@/components/common/DialogTitle.vue"
import Dialog from "@/components/common/Dialog/Dialog.vue"
import Title from "@/components/common/Dialog/item/Title.vue"
import DownDoc from "../../components/order/DownDoc.vue"
import { useRoute, useRouter } from "vue-router"
import dateFormat from "@/utils/dateFormat"

export default defineComponent({
  components: {
    HomeHead,
    HtmlFooter,
    CurrentAddress,
    GoodsItem,
    DialogTitle,
    Dialog,
    Title,
    DownDoc,
  },
  setup() {
    const params = useRoute()
    let orderId = parseInt(params.params.id as string)
    let router = useRouter()
    let loading = ref(true)

    interface orderDataType {
      errno: number
      datas: any
      errmsg: string
    }
    const orderData: orderDataType = reactive({
      errno: 0,
      errmsg: "",
      datas: [],
    })
    interface formInlineType {
      type: Array<Number | String>
    }
    let formInline: formInlineType = reactive({
      type: [],
    })

    const multipleTableRef = ref<InstanceType<typeof ElTable>>()

    let toggleAllSelection = () => {
      // console.log(multipleTableRef.value)
      multipleTableRef.value!.toggleAllSelection()
    }

    interface User {
      date: string
      name: string
      address: string
      id: string
      names: string
    }
    let APIURL = inject("APIURL")
    const multipleSelection = ref<User[]>([])
    const toggleSelection = (rows?: User[]) => {
      if (rows) {
        rows.forEach((row) => {
          // TODO: improvement typing when refactor table
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          multipleTableRef.value!.toggleRowSelection(row, undefined)
        })
      } else {
        multipleTableRef.value!.clearSelection()
      }
    }

    const handleSelectionChange = (val: User[]) => {
      multipleSelection.value = val
      console.log(multipleSelection.value)
    }

    let downAll = () => {
      if (orderIsColse()) {
        // sjyAlert("订单过期，无法下载")
        showPays()
        return false
      }


      // if (formInline.type.length > 0) {
      let elink1 = document.createElement("a")
      elink1.download =
        multipleSelection.value[multipleSelection.value.length - 1].names
      elink1.style.display = "none"
      elink1.href =
        APIURL +
        "/api/pc/product/download?oid=" +
        orderData.datas.id +
        "&sid=" +
        multipleSelection.value[multipleSelection.value.length - 1].id
      document.body.appendChild(elink1)
      elink1.click()
      document.body.removeChild(elink1)

      let count = ref(0)
      count.value = multipleSelection.value.length

      let times = setInterval(() => {
        count.value = count.value - 1 //递减
        if (count.value <= 0) {
          // <=0 变成获取按钮

          clearInterval(times)
        }

        let elink1 = document.createElement("a")
        elink1.download = multipleSelection.value[count.value - 1].names
        elink1.style.display = "none"
        elink1.href =
          APIURL +
          "/api/pc/product/download?oid=" +
          orderData.datas.id +
          "&sid=" +
          multipleSelection.value[count.value - 1].id
        document.body.appendChild(elink1)
        elink1.click()
        document.body.removeChild(elink1)
        //countShowText.value = count.value + "秒后再获取";
      }, 2000) //1000毫秒后执行
      //跳转下载
      //for (let i = 0; i < multipleSelection.value.length; i++) {
      // console.log("开始了凯斯了")
      // let elink1 = document.createElement("a")
      // elink1.download = multipleSelection.value[0].names
      // elink1.style.display = "none"
      // elink1.href =
      //   "https://api.sjyuan.net.cn/api/pc/product/download?oid=" +
      //   orderData.datas.id +
      //   "&sid=" +
      //   multipleSelection.value[0].id
      // document.body.appendChild(elink1)
      // elink1.click()
      // document.body.removeChild(elink1)

      // let elink = document.createElement("a")
      // elink.download = multipleSelection.value[1].names
      // elink.style.display = "none"
      // elink.href =
      //   "https://api.sjyuan.net.cn/api/pc/product/download?oid=" +
      //   orderData.datas.id +
      //   "&sid=" +
      //   multipleSelection.value[1].id
      // document.body.appendChild(elink)
      // elink.click()
      // document.body.removeChild(elink)

      // elink = document.createElement("a")
      // elink.download = multipleSelection.value[2].names
      // elink.style.display = "none"
      // elink.href =
      //   "https://api.sjyuan.net.cn/api/pc/product/download?oid=" +
      //   orderData.datas.id +
      //   "&sid=" +
      //   multipleSelection.value[2].id
      // document.body.appendChild(elink)
      // elink.click()
      // document.body.removeChild(elink)

      // goodsApi
      //   .downGoods(orderData.datas.id, multipleSelection.value[i].id)
      //   .then((res: any) => {
      //     //console.log(res)
      //     // if (res.errno != 0) {
      //     //   sjyAlert(res.errmsg, "error")
      //     //   return false
      //     // }
      //     const blob = new Blob([res.data], {
      //       type: "application/zip",
      //     })
      //     const fileName = decodeURI(res.headers["content-disposition"])
      //     //console.log(fileName,res.headers,res)
      //     let fileDwonName = fileName.split("=")[1]
      //     if (fileDwonName == undefined) {
      //       fileDwonName = multipleSelection.value[i].names
      //     }
      //     if ("download" in document.createElement("a")) {
      //       // 非IE下载let modleName = goodsInfo.datas.names.split("：")[1]
      //       const elink = document.createElement("a")
      //       elink.download = fileDwonName
      //       elink.style.display = "none"
      //       elink.href = URL.createObjectURL(blob)
      //       document.body.appendChild(elink)
      //       elink.click()
      //       URL.revokeObjectURL(elink.href) // 释放URL 对象
      //       document.body.removeChild(elink)
      //     } else {
      //       // IE10+下载
      //       //navigator.msSaveBlob(blob, fileName)
      //     }
      //   })
      //}
      // } else {
      //   sjyAlert("请先同意协议", "warning")
      // }
    }

    let handleClick = (index: number) => {
      if (orderIsColse()) {
        // sjyAlert("订单过期，无法下载", "warning")
        showPays()
        return false
      }
      // if (formInline.type.length > 0) {
      //blob:http://localhost:8080/908a6fd3-116e-47f0-ba00-906e30399992
      const elink = document.createElement("a")
      elink.download = orderData.datas.list[index].names
      elink.style.display = "none"
      elink.href =
        APIURL + "/api/pc/product/download?oid=" +
        orderData.datas.id +
        "&sid=" +
        orderData.datas.list[index].id
      document.body.appendChild(elink)
      elink.click()
      document.body.removeChild(elink)
      //return false

      // goodsApi
      //   .downGoods(orderData.datas.id, orderData.datas.list[index].id)
      //   .then((res: any) => {
      //     // if (res.errno != 0) {
      //     //   sjyAlert(res.errmsg, "error")
      //     //   return false
      //     // }
      //     const blob = new Blob([res.data], {
      //       type: "application/zip",
      //     })
      //     const fileName = decodeURI(res.headers["content-disposition"])
      //     let fileDwonName = fileName.split("=")[1]
      //     if (fileDwonName == undefined) {
      //       fileDwonName = orderData.datas.list[index].names
      //     }
      //     if ("download" in document.createElement("a")) {
      //       // 非IE下载let modleName = goodsInfo.datas.names.split("：")[1]
      //       const elink = document.createElement("a")
      //       elink.download = fileName.split("=")[1]
      //       elink.style.display = "none"
      //       elink.href = URL.createObjectURL(blob)
      //       document.body.appendChild(elink)
      //       elink.click()
      //       URL.revokeObjectURL(elink.href) // 释放URL 对象
      //       document.body.removeChild(elink)
      //     } else {
      //       // IE10+下载
      //       //navigator.msSaveBlob(blob, fileName)
      //     }
      //   })
      // } else {
      //   sjyAlert("请先同意协议", "warning")
      // }
    }

    /**
     * 获取订单信息
     */
    let getOrderInfo = () => {
      loading.value = true
      orderApi.getOrderInfo(orderId).then((res: any) => {
        loading.value = false
        if (res.errno == 0) {
          if (res.datas.list && res.datas.id < 1) {
            return false
          } else if (res.datas.status != 2) {
            //该订单已经完成，显示订单详细页
            router.push({ name: "tobepaid", params: { id: orderId } })
          } else {
            orderData.datas = res.datas
          }
        } else {
          sjyAlert(res.errmsg, "error")
        }
      })
    }

    let getEndTime = (datestring: string = "2015-03-05 17:59:00.0") => {
      return dateFormat.dateFormat(
        dateFormat.dateStrChangeTimeTamp(datestring) + 30 * 24 * 3600 * 1000,
        "年-月-日"
      )
    }

    //判断订单是否已经过期
    let orderIsColse = () => {
      let notTime = new Date().getTime()
      if (
        notTime - dateFormat.dateStrChangeTimeTamp(orderData.datas.pay_time) >
        30 * 24 * 3600 * 1000
      ) {
        return true
      } else {
        return false
      }
    }

    let gotoDown = () => {
      router.push({ name: "orderover", params: { id: orderId } })
    }
    let dialogVisible = ref(false)

    const toPay = ref<InstanceType<typeof Dialog>>()
    let showPay = () => {
      toPay.value?.setVisible(true)
    }
    let mclose = () => {
      dialogVisible.value = false
      // sonRef.value?.setVisible(dialogVisible.value);
      toPay.value?.setVisible(dialogVisible.value)
    }

    const toPays = ref<InstanceType<typeof Dialog>>()
    let showPays = () => {
      toPays.value?.setVisible(true)
    }
    let mcloses = () => {
      dialogVisible.value = false
      // sonRef.value?.setVisible(dialogVisible.value);
      toPays.value?.setVisible(dialogVisible.value)
    }
    let readDoc = () => {
      mclose()
      formInline.type[0] = ""
      console.log("我接收到了信息")
    }
    onMounted(() => {
      getOrderInfo()
    })
    return {
      orderData,
      handleClick,
      formInline,
      toggleAllSelection,
      multipleTableRef,
      handleSelectionChange,
      multipleSelection,
      mclose,
      toPay,
      showPay,
      readDoc,
      gotoDown,
      loading,
      downAll,
      getEndTime,
      mcloses,
      toPays,
      showPays,
    }
  },
})
