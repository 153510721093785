
import { defineComponent, reactive, onMounted, ref } from "vue"
import { goodsApi } from "@/service/api"
import CurrentAddress from "../common/CurrentAddress.vue"
import { useRouter } from "vue-router"

export default defineComponent({
  components: { CurrentAddress },
  emits: ["setGoodsWhere"],
  props: {
    id: {
      type: Number,
      default: 0,
    },
    cid: {
      type: Number,
      default: 0,
    },
    aid: {
      type: String,
      default: "",
    },
  },
  setup(props: any, context) {
    //console.log(props.freeWhere);  useRoute
    // const router = useRouter()

    interface goodsAttrType {
      datas: any
      errno: number
      errmsg: string
    }

    interface whereType {
      params: {
        cid: number
        aids: Array<number>
      }
    }

    // let aid = props.freeWhere.aid
    let adis: Array<number> = []

    let where: whereType = reactive({
      params: {
        cid: 0,
        aids: [],
      },
    })

    //当前选择的分类下标
    let nowIndex = ref(0)

    // let index=this.conditions.findIndex((item)=>
    //             {
    //                 if(item.id == id){
    //                     return true;
    //                 }
    //             }
    //         )

    let goodsAttr: goodsAttrType
    //获取aids
    let aids: Array<number> = reactive([])

    goodsAttr = reactive({
      datas: [],
      errno: 0,
      errmsg: "",
    })

    let getGoodsAttr = () => {
      goodsApi.attrList().then((res) => {
        goodsAttr.datas = (res as goodsAttrType).datas
        goodsAttr.errno = (res as goodsAttrType).errno
        goodsAttr.errmsg = (res as goodsAttrType).errmsg
        initWhere()
        getGoodsListWhere()
      })
    }

    //切换分类
    let selectClass = (val: number) => {
      nowIndex.value = val
      //清空所有选择的属性
      deleteAttr()
      getGoodsListWhere()
    }
    //取消所有选择的属性
    let deleteAttr = () => {
      for (let i = 0; i < goodsAttr.datas.length; i++) {
        for (let j = 0; j < goodsAttr.datas[i].attr.length; j++) {
          for (let m = 0; m < goodsAttr.datas[i].attr[j].options.length; m++) {
            goodsAttr.datas[i].attr[j].options[m].status = 0
          }
        }
      }
    }

    //单选 ， 同类型只能选择一个
    let radioAttr = (indexs: number) => {
      for (
        let m = 0;
        m < goodsAttr.datas[nowIndex.value].attr[indexs].options.length;
        m++
      ) {
        goodsAttr.datas[nowIndex.value].attr[indexs].options[m].status = 0
      }
    }

    //选择属性
    let selectAttr = (indexs: number, indexo: number) => {
      if (
        goodsAttr.datas[nowIndex.value].attr[indexs].options[indexo].status == 1
      ) {
        goodsAttr.datas[nowIndex.value].attr[indexs].options[indexo].status = 0
      } else {
        radioAttr(indexs)
        goodsAttr.datas[nowIndex.value].attr[indexs].options[indexo].status = 1
      }
      getGoodsListWhere()
    }

    let router = useRouter()

    //整理查询条件,传递出去
    let getGoodsListWhere = () => {
      aids = []
      for (let i = 0; i < goodsAttr.datas.length; i++) {
        for (let j = 0; j < goodsAttr.datas[i].attr.length; j++) {
          for (let m = 0; m < goodsAttr.datas[i].attr[j].options.length; m++) {
            if (goodsAttr.datas[i].attr[j].options[m].status == 1) {
              aids.push(goodsAttr.datas[i].attr[j].options[m].id)
            }
          }
        }
      }

      where.params.cid = parseInt(goodsAttr.datas[nowIndex.value].id)
      where.params.aids = []
      where.params.aids.push(...aids)

      // router.push({
      //   path: "/free/" + where.params.cid + "/" + where.params.aids.join("_"),
      // })

      // router.push({
      //   name:'free',
      //   params:{
      //     cid:where.params.cid,
      //     aid:where.params.aids.join("_")
      //   }
      // })

      context.emit("setGoodsWhere", where)
    }

    //初始化
    let initWhere = () => {
      //console.log("kaisi e a ", props.aid)
      let maids = props.aid.split("_")

      if (props.cid && props.cid > 0) {
        for (let i = 0; i < goodsAttr.datas.length; i++) {
          if (props.aid && props.aid != "") {
            if (maids.length > 0) {
              for (let j = 0; j < goodsAttr.datas[i].attr.length; j++) {
                for (
                  let m = 0;
                  m < goodsAttr.datas[i].attr[j].options.length;
                  m++
                ) {
                  //console.log(goodsAttr.datas[i].attr[j].options)
                  for (let n = 0; n < maids.length; n++) {
                    if (goodsAttr.datas[i].attr[j].options[m].id == maids[n]) {
                      goodsAttr.datas[i].attr[j].options[m].status = 1
                      break
                    }
                  }
                }
              }
            }
          }
          if (goodsAttr.datas[i].id == props.cid) {
            nowIndex.value = i
            break
          }
        }
      }
    }

    onMounted(() => {
      getGoodsAttr()
    })
    return {
      getGoodsAttr,
      goodsAttr,
      nowIndex,
      selectClass,
      selectAttr,
    }
  },
})
