
import { reactive, defineComponent, ref } from "vue";
export default defineComponent({
  emits: ["showsss", "changeState"],
  props: {
    work: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    let changeShow = () => {
      context.emit("showsss");
    };
    let changeState = (val: any) => {
      context.emit("changeState", val);
    };
    console.log(props.work, "333");

    return {
      changeState,
      changeShow,
    };
  },
});
