
// 引入element ui 样式
import { reactive, ref, defineComponent, onMounted } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import HtmlFooter from "../../components/HtmlFooter.vue";
import HomeHead from "../../components/HomeHead.vue";
import { goBack } from "@/utils/public";
import { publicApi, userApi } from "@/service/api";
import { sjyAlert } from "@/utils/alert";
import { userStore } from "@/stores/userStore";
import { updateCartTotal } from "@/utils/public";
import { useRoute, useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";
export default defineComponent({
  components: { HtmlFooter, HomeHead },
  setup() {
    const ruleFormRef = ref<FormInstance>();
    const loginForm = reactive({
      mobile: "",
      agreement: [],
      password: "",
      verify: "",
      type: 1,
    });
    let router = useRouter();
    const userCommonStore = userStore();
    const rules = reactive<FormRules>({
      agreement: [
        {
          type: "array",
          // required 是否为必选项
          required: true,
          message: "请勾选",
          //验证逻辑的触发方式
          trigger: "change",
        },
      ],

      mobile: [
        { required: true, message: "请输入手机号", trigger: "blur" },
        {
          pattern: "^1[35678]\\d{9}$",
          message: "请输入正确的手机号",
          trigger: "blur",
        },
      ],
      password: [
        { required: true, message: "请输入密码", trigger: "blur" },
        {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{8,16}$/,
          message: "密码需为8-16位大小写字母、数字组合",
          trigger: "blur",
        },
      ],
    });

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          // console.log("submit!");
          userApi.userLogin(loginForm).then((res: any) => {
            if (res.errno == 0) {
              //保存
              userCommonStore.setUserInfo(res.datas);
              sjyAlert("登录成功", "success");
              const MCookies = useCookies();
              MCookies.cookies.set("userLogin", "true", 60 * 60);
              updateCartTotal();
              if (localStorage.getItem("gorouter") != null) {
                //console.log(localStorage.getItem("gorouter"))
                let mroute = JSON.parse(
                  localStorage.getItem("gorouter") as string
                );

                router.push({ name: mroute.name, params: mroute.params });
              } else {
                router.push({ name: "user" });
              }
            } else {
              sjyAlert(res.errmsg, "error");
              //更改验证码
              changeImgCode();
            }
          });
        } else {
        }
      });
    };

    let imgCode = ref();
    let changeImgCode = () => {
      publicApi.getImgcode().then((res: any) => {
        const img = btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        imgCode.value = "data:image/png;base64," + img;
      });
    };
    let open = () => {
      const { href } = router.resolve({
        name: "privacyPolicy",
      });
      window.open(href, "_blank");
    };
    onMounted(() => {
      changeImgCode();
    });

    return {
      loginForm,
      rules,
      ruleFormRef,
      submitForm,
      imgCode,
      changeImgCode,
      open,
    };
  },
});
