import { vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-115fd7c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "img-box" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      class: "layer",
      ref: "target",
      style: _normalizeStyle({ left: _ctx.position.x + 'px', top: _ctx.position.y + 'px' })
    }, null, 4), [
      [_vShow, !_ctx.isOutside]
    ]),
    _createElementVNode("img", {
      ref: "target",
      src: _ctx.goodsImg
    }, null, 8, _hoisted_2)
  ]))
}