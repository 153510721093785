
import { defineComponent, onMounted, ref } from "vue";
import formdata from "@/utils/dateFormat";

export default defineComponent({
  components: {},
  props:{
    totaltime:{
        type: Number,
        default: 60
    }
  },
  emits:['countdownEnded'],
  setup(props:any,{emit}) {

    const timer = ref(props.totaltime); //倒计时
    const benginTimer = () => {
      const times = setInterval(() => {
        if (timer.value <= 0) {
          clearInterval(times);
          emit('countdownEnded')
          //timer.value = 60;
          return;
        } else {
          timer.value--;
        }
      }, 1000);
    };

    let arrangeDate = (val:number) => {
        //console.log(formdata.arrangeDate(val))
        return formdata.arrangeDate(val);
    }

    onMounted(() => {
      benginTimer();
    });

    return { timer, arrangeDate};
  },
});
