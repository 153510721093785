
import { showPrice } from "@/utils/public"
import { defineComponent, inject, ref } from "vue"
import { useRouter } from "vue-router"

export default defineComponent({
	name: "project goodsinfo",
	components: {},
	props: {
		bodyWidth: {
			type: Number,
			default: 270,
		},
		contentsWidth: {
			type: Number,
			default: 244,
		},
		goods: {
			type: Object,
			default: () => ({}),
		},
		isRealGoods: {
			type: Number,
			default: 0,
		},
	},
	setup() {
		//productDetail
		let goodsImg = ref("/static/temp/goods.jpg")
		const staticUrl = inject("staticUrl")

		let changeUrl = (val: any) => {
			if (val.startsWith("https")) {
				return val
			} else {
				return staticUrl + val
			}
		}

		let router = useRouter()
		let goto = (uid: number) => {
			router.push({
				name: "personalcenter",
				params: {
					userid: uid,
				},
			})
			return false
		}

		let gotoGoods = (goodsid: number) => {
			const { href } = router.resolve({
				name: "productDetail",
				params: {
					id: goodsid,
				},
			})
			window.open(href, "_blank")
			return false
		}
		return {
			goodsImg,
			changeUrl,
			gotoGoods,
			goto,
			showPrice,
		}
	},
})
