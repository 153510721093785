import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ec3f51d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "goods-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_goods_info = _resolveComponent("goods-info")!

  return (_ctx.loading || _ctx.goodslit.length > 0)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodslit, (item, index) => {
          return (_openBlock(), _createElementBlock("li", null, [
            _createVNode(_component_goods_info, { goods: item }, null, 8, ["goods"])
          ]))
        }), 256))
      ]))
    : _createCommentVNode("", true)
}