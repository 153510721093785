
import { reactive, defineComponent, ref } from "vue";
// import type { TabsPaneContext } from "element-plus";
import { userStore } from "@/stores/userStore";
import { publicApi } from "@/service/api";
import { sjyAlert } from "@/utils/alert";
export default defineComponent({
  emits: ["getStatus"],
  setup(props: any, context) {
    const informationForm = reactive({
      //修改登录密码 短信验证码框
      code: "",
      // 绑定的手机号
      mobile: "",
    });

    let count = ref(60);
    let isShow = ref(true);
    //let 秒后再获取
    let countShowText = ref("获取短信验证码");
    var times: any;

    let countdown = () => {
      count.value = 60; //赋值60秒
      isShow.value = false;
      getPhongCode();
      times = setInterval(() => {
        count.value = count.value - 1; //递减
        if (count.value <= 0) {
          // <=0 变成获取按钮
          isShow.value = true;
          clearInterval(times);
        }
        countShowText.value = count.value + "秒后再获取";
      }, 1000); //1000毫秒后执行
    };

    let getPhongCode = () => {
      //这里应该验证一下手机号的正确性  TODO
      publicApi.getPhoneCode(userinfot.mobile).then((res: any) => {
        if (res.errno == 0) {
          sjyAlert("发送成功", "success");
        } else {
          isShow.value = true;
          clearInterval(times);
          sjyAlert(res.errmsg, "error");
        }
      });
    };

    let verifyCode = () => {
      informationForm.mobile = userinfot.mobile;
      publicApi.verifyCode(informationForm).then((res: any) => {
        //console.log(res);
        if (informationForm.code == "") {
          sjyAlert("验证码不能为空!", "error");
        } else {
          if (res.errno == 0) {
            context.emit("getStatus");
          } else {
            sjyAlert(res.errmsg, "error");
          }
        }
      });
    };

    let next = () => {
      verifyCode();
    };

    let userinfot = userStore();
    return {
      informationForm,
      next,
      userinfot,
      isShow,
      countdown,
      countShowText,
      verifyCode,
    };
  },
});
