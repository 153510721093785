
import { publicApi, userApi } from "@/service/api";
import { sjyAlert } from "@/utils/alert";
import { defineComponent, reactive, ref ,inject} from "vue";
import { userStore } from "@/stores/userStore";

export default defineComponent({
  components: {},
  props: {
    creatOrderInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any) {
    const userinfo = userStore();
    let input = ref();
    let showPwd = ref(false);
    let updateSetPayPassword = inject("setPayPassword", () => {}, false)

    //提交支付订单
    let payFrom = reactive({
      id: props.creatOrderInfo.data.id,
      pay_password: "",
    });
    //设置支付密码
    let setPayPassword = reactive({
      pay_password: "",
      code: "",
    });

    let changeShowPwd = () => {
      showPwd.value = !showPwd.value;
    };

    let showMobile = (mmobile: string) => {
      var reg = /^(\d{3})\d{4}(\d{4})$/;
      return mmobile.replace(reg, "$1****$2");

      //return mobile.replaceAll("(\\d{3})\\d{4}(\\d{4})","$1****$2");
    };

    let title = ref("设置支付密码");
    let step = ref(1);
    let buttonText = ref("确定");
    let submit = () => {
      
      if (step.value == 1) {
        userApi.changePay(setPayPassword).then((res:any) => {
          if(res.errno==0){
            step.value = 2;
            title.value = "请输入支付密码";
            buttonText.value = "立即支付";
            updateSetPayPassword()
            sjyAlert("设置成功","success")
          }else{
            sjyAlert(res.errmsg,"error")
          }
        });
        
      }
    };

    let count = ref(60);
    let isShow = ref(true);
    //let 秒后再获取
    let countShowText = ref("获取短信验证码");
    var times: any;
    let countdown = () => {
      count.value = 60; //赋值60秒
      isShow.value = false;

      getPhongCode();
      times = setInterval(() => {
        count.value = count.value - 1; //递减
        if (count.value <= 0) {
          // <=0 变成获取按钮
          isShow.value = true;
          clearInterval(times);
        }
        countShowText.value = count.value + "秒后再获取";
      }, 1000); //1000毫秒后执行
    };

    let getPhongCode = () => {
      //这里应该验证一下手机号的正确性  TODO
      console.log(userinfo.mobile,"验证手机号的正确性")
      publicApi.getPhoneCode(userinfo.mobile).then((res: any) => {
      //publicApi.getPhoneCode("15822036509").then((res: any) => {
        if (res.errno == 0) {
          sjyAlert("发送成功", "success");
        } else {
          isShow.value = true;
          clearInterval(times);
          sjyAlert(res.errmsg, "error");
        }
      });
    };

    return {
      showMobile,
      userinfo,
      input,
      showPwd,
      changeShowPwd,
      title,
      step,
      submit,
      buttonText,
      countdown,
      payFrom,
      setPayPassword,
      isShow,
      countShowText,
    };
  },
});
