import axios from "../service"
import { userStore } from "@/stores/userStore"

let { get, post, getZip } = axios
interface storeType {
  product_id: number
  type: number
  status: number
  product_name?: string
  cid?: number
  params?: Object
}
const goods = {
  attrList() {
    return post("pc/attribute/option", {})
  },

  //获取方案分类以及属性
  getGoodsClass() {
    return post("pc/index/class", {}, false, "v1")
  },

  getGoodsClassBylocal() {
    this.getGoodsClass().then()
  },

  //获取商品列表
  getGoodsList(where: any) {
    return post("pc/product/attrs", { ...where })
  },

  //获取热销
  getHotGoodsList(where: any) {
    return post("pc/product/hot", { ...where })
  },

  //获取免费专区的商品详情
  getFreeGoodsInfo(where: any) {
    let userinfo = userStore()
    return post("pc/product/details", { ...where, uid: userinfo.id })
  },

  //商品详细页，获取商品的详细信息
  getGoodsInfo(id: number) {
    let userinfo = userStore()
    return post("pc/product/info", { id: id, uid: userinfo.id })
  },

  /**
   *
   * @param id 产品id
   * @param counsel true 确定  false 取消
   * @returns
   */
  addCart(id: number, counsel: boolean, type: number = 0, numbers: number = 1) {
    return post("pc/cart/add", { id: id, counsel: counsel ? 1 : 0, type: type, numbers: numbers })
  },
  //删除购物车
  deleteCart(ids: Array<number>) {
    return post("pc/cart/del", { ids: ids })
  },

  /**
   * 添加收藏
   * @param product_id 商品id
   * @param type 1 设纪元商品  2 免费专区  3 视频专区
   * @param status 收藏状态 1 收藏  0 取消收藏
   * @param product_name 商品名称
   * @param cid 商品名称
   * @param params
   * @returns
   */
  addStore(data: Array<storeType>) {
    let product_id
    //console.log("addstroe", product_ids)

    return post("pc/store/add", { params: data })
  },
  /**
   *
   * @param keyword 关键字
   * @param type 1 设纪元商品  2 免费专区  3 视频专区
   * @param sort 1 综合排序  2 人气  3 最新排序  4 下载
   * @param price 1 倒序 2 正序
   * @param pageIndex 页码
   * @param params
   * @returns
   */
  searchGoods(
    keyword: string = "",
    type: number = 1,
    sort: number = 1,
    price: number = 1,
    pageIndex: number = 1,
    params: object = {}
  ) {
    return post("pc/product/keyword", {
      keyword,
      type,
      sort,
      price,
      pageIndex,
      ...params,
    })
  },

  //商品详细页，获取商品的详细信息
  //https://api.sjyuan.net.cn/api/pc/cart/add
  // getGoodsInfo(id:number){
  // 	return post("pc/product/info", { id:id })
  // }
  //删除购物车
  // getFreeGoodsList(
  //   cid: number,
  //   aids: Array<number> = [],
  //   pageIndex: number = 1
  // ) {
  //   let params = {
  //     cid: cid,
  //     options: aids,
  //     pageIndex: pageIndex,
  //   }
  //   return post("pc/product/search", { params })
  // },

  getFreeGoodsList(
    cid: number,
    aids: Array<number> = [],
    pageIndex: number = 1,
    pageSize?: number | null,
    sort?: number | null
  ) {
    let params: {
      cid: number;
      options: Array<number>;
      pageIndex: number;
      pageSize?: number | null;
      sort?: number | null
    } = {
      cid: cid,
      options: aids,
      pageIndex: pageIndex,
      sort: sort
    };

    if (pageSize !== undefined && pageSize !== null) {
      params.pageSize = pageSize;
    }

    return post("pc/product/search", { params });
  },


  // 免费专区点击下载量+1
  downNumber(id: number) {
    return post("pc/product/downs", { id: id })
  },

  //设备供应链
  getEquipmentList(
    cid: number,
    pageSize: number = 5,
    pageIndex: number = 1,
    sort: number = 3

  ) {
    return post("pc/movibot/product", { cid: cid, pageSize: pageSize, pageIndex: pageIndex, sort: sort })
  },


  /**
   *
   * @param oid 大订单编号
   * @param sid 小订单编号
   * @returns
   */
  downloadGoods(oid: string, sid: string) {
    return post("pc/product/download", { oid, sid })
  },

  addGoodsTrack(product_id: number, type: number, status: number = 0) {
    return post("pc/track/add", { product_id, type, status })
  },


  addGoodsDuration(product_id: number, type: number, duration: number = 1, source: number = 1) {
    return post("pc/view/add", { product_id, type, duration: Math.round(duration / 1000), source }, false, "", false)
  },

  downGoods(oid: string, sid: string) {
    return getZip("pc/product/download", { oid, sid })
  },

  /**
   * 折扣专区
   */
  discountGoods(pageIndex: number, pageSize: number) {
    return post("pc/product/discount", { pageIndex, pageSize })
  },
  /**
   * 每月新品接口
   */
  monthProduct() {
    return post("pc/product/month", {})
  },
  /**
   * 判断用户是否买过该商品
   * @param uid 用户id
   * @param id  商品id
   * @returns
   */
  goodsBought(uid: number, id: number) {
    return post("pc/product/buys", { uid, id })
  },
  //pc/product/month
}
export default goods
