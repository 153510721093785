import { createApp } from "vue";
import ConfirmComponent from "./ConfirmModal.vue";

const ConfirmPlugins = {};
let $vm;

const defaultsOptions = {
  tips: "提示",
  confirmText: "确定",
  cancelText: "取消",
  visible:false
};

const initInstance = () => {
  const app = createApp(ConfirmComponent);//创建一个应用实例。
  const container = document.createElement("div");
  $vm = app.mount(container);//将应用实例挂载在一个容器元素中。
  document.body.appendChild(container);
};

ConfirmPlugins.install = function (app) {
  const ConfirmDialog = {
    show(options) {
      if (!$vm) initInstance();
      options = Object.assign({}, defaultsOptions, options);
      for (const i in options) {
        $vm[i] = options[i];
      }
      let handleCancel = $vm.handleCancel;
      let handleConfirm = $vm.handleConfirm;
      $vm.handleCancel = () => {
        handleCancel();
        options && options.onCancel && options.onCancel();
      };

      $vm.handleConfirm = () => {
        handleConfirm();
        options && options.onConfirm && options.onConfirm();
      };

      $vm.visible = true;
      return $vm;
    },
    hide() {
      if ($vm) $vm.visible = false;
    },
  };
  app.config.globalProperties.$ConfirmDialog = ConfirmDialog;
};

export default ConfirmPlugins;
