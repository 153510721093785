
import { defineComponent, reactive } from "vue";
export default defineComponent({
  name: "SearchInfo",
  methods: {
    setSearch() {},
    toshow(val:number){
      console.log(val)
    }
  },
  data() {
    return {
      message: "",
    };
  },
});
