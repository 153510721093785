
import { reactive, defineComponent, ref, onMounted, inject } from "vue";
import type { TabsPaneContext } from "element-plus";
import { ElMessage } from "element-plus";
import type { UploadProps } from "element-plus";
import { sjyAlert } from "@/utils/alert";
import { userStore } from "@/stores/userStore";
import Userinformation from "./Userinformation.vue";
import UserForm from "./UserForm.vue";
import ChangePwd from "./ChangePwd.vue";
import SurePwd from "./SurePwd.vue";
import ChangePay from "./ChangePay.vue";
import SurePay from "./SurePay.vue";
import AddSonNumberVue from "./AddSonNumber.vue";
import UserInformation1 from "../../PersonalCenter/UserInformation.vue";
import AvatarEdit from "@/components/AvatarEdit.vue";
import { userApi } from "@/service/api";
import { useRoute } from "vue-router";
import { getVipInfo } from "@/utils/public";

export default defineComponent({
  components: {
    Userinformation,
    ChangePwd,
    SurePwd,
    ChangePay,
    SurePay,
    UserForm,
    UserInformation1,
    AddSonNumberVue,
    AvatarEdit,
  },

  setup() {
    interface result {
      data: any;
    }
    let vipinfo = getVipInfo();
    //头像上传部分
    const imageUrl = ref("");

    const handleAvatarSuccess: UploadProps["onSuccess"] = (
      response,
      uploadFile
    ) => {
      imageUrl.value = URL.createObjectURL(uploadFile.raw!);
    };

    const beforeAvatarUpload: UploadProps["beforeUpload"] = (rawFile) => {
      if (rawFile.type !== "image/jpeg") {
        ElMessage.error("Avatar picture must be JPG format!");
        return false;
      } else if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error("Avatar picture size can not exceed 2MB!");
        return false;
      }
      return true;
    };

    let getImgUrl = inject(
      "getImgUrl",
      (index: string) => {
        return "";
      },
      false
    );
    let route = useRoute();
    // tabs 切换

    let activeName = ref("first");
    if (route.params.type) {
      activeName.value = route.params.type as string;
    }
    const informationForm = reactive({
      //修改登录密码 短信验证码框
      codeinput: "",
      // 支付密码短信验证码
      moneycodeinput: "",
      // 动态渲染密码修改页面
      currentView: "ChangePwd",
      status: "",

      changePays: "ChangePay",
      payStatus: "",
      // 用户信息界面
      user: "Userinformation",
    });

    //tabs 切换
    let handleClick = (tab: TabsPaneContext, event: Event) => {};

    let status = () => {
      informationForm.status = "";
    };
    // 动态渲染修改密码第一步第二步
    let getStatus = () => {
      if ((informationForm.status = "")) {
        //console.log(informationForm.status);
        informationForm.currentView = "ChangePwd";
      } else if ((informationForm.status = "1")) {
        //console.log(informationForm.status);
        informationForm.currentView = "SurePwd";
      }
    };

    // 动态渲染修改支付密码第一步第二步
    let payStatus = () => {
      if ((informationForm.payStatus = "")) {
        informationForm.changePays = "ChangePay";
      } else if ((informationForm.payStatus = "1")) {
        // console.log(informationForm.payStatus);
        informationForm.changePays = "SurePay";
      }
    };

    let goUserForm = () => {
      informationForm.user = "Userinformation";
    };

    let changeInformation = () => {
      informationForm.user = "UserForm";
    };
    let dialogVisible = ref(false);
    let changeDialogVisible = () => {
      dialogVisible.value = !dialogVisible.value;
    };
    let userinfot = userStore();

    /**
     *
     * @param imgUrl
     */

    let setHeaderImg = (imgUrl: string) => {
      dialogVisible.value = false;
      //console.log("我有头像了");
      let code = new Date().getTime();
      userinfot.updateHeaderImg(imgUrl + "?" + code);
      userApi.changePortrait(imgUrl).then((res: any) => {
        console.log(res);
      });
    };

    // 获取用户信息
    let getInformation: result = reactive({
      data: {},
    });

    let getuserInformation = () => {
      userApi.getInformation().then((res: any) => {
        getInformation.data = res.datas;
        console.log(res);
      });
    };

    //  绑定微信
    let bind = () => {
      userApi.wxbind().then((res: any) => {
        //console.log(res);
        window.open(res.url);
      });
    };
    //解绑
    let onbind = () => {
      userApi.wxUnbind().then((res: any) => {
        //console.log(res);
        if (res.errno == 0) {
          sjyAlert("解绑成功", "success");
          //window.open(res.url);
          location.reload();
        }
      });
    };

    onMounted(() => {
      getuserInformation();
    });

    return {
      activeName,
      handleClick,
      informationForm,

      getStatus,
      payStatus,
      status,

      imageUrl,
      handleAvatarSuccess,
      beforeAvatarUpload,
      goUserForm,
      changeInformation,

      userinfot,
      dialogVisible,
      changeDialogVisible,
      setHeaderImg,

      getInformation,
      bind,
      onbind,
      getImgUrl,
      vipinfo,
    };
  },
});
