
import { defineComponent, reactive, ref,inject } from "vue";
import { userStore } from "@/stores/userStore";


export default defineComponent({
  components: {},
  emits:['changeCounsel'],
  props:{
    counsel:{
      type: Number,
      default: 0
    },
    cartId:{
      type: Number,
      default: 0
    }
  },
  setup(props:any,{emit}) {
    let region = ref(0)
    let userInfo = userStore();
    let servicePice = inject("servicePice", 25);

    region.value = props.counsel;
    let regionList = reactive([
      { label: Math.ceil(servicePice*userInfo.discount)+"/1-3个问题", rvalue: 1 },
      { label: "暂不购买", rvalue: 0 },
    ]);

    

    let changeCounsel = ()=>{
      emit('changeCounsel',region.value,parseInt(props.cartId));
    }
    
    return { region,regionList,changeCounsel};
  },
});
