import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4730e5c5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detail-info" }
const _hoisted_2 = {
  key: 0,
  class: "info-item"
}
const _hoisted_3 = { class: "title" }
const _hoisted_4 = ["title"]
const _hoisted_5 = {
  key: 1,
  class: "info-item"
}
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "info-conents" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 2,
  class: "info-item"
}
const _hoisted_10 = { class: "title" }
const _hoisted_11 = { class: "info-conents" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 3,
  class: "info-item"
}
const _hoisted_14 = {
  key: 0,
  class: "title"
}
const _hoisted_15 = { class: "info-conents" }
const _hoisted_16 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.goodsDetail.format_title != '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.isRealGoods == 1 ? "产品属性" : _ctx.goodsDetail.format_title), 1),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodsDetail.format, (format, index) => {
              return (_openBlock(), _createElementBlock("li", {
                title: index + ':' + format
              }, _toDisplayString(index + ":" + format), 9, _hoisted_4))
            }), 256))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.goodsDetail.bodys)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.isRealGoods == 1 ? "产品功能" : "方案简介"), 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              innerHTML: _ctx.goodsDetail.bodys,
              class: "html-box"
            }, null, 8, _hoisted_8)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.goodsDetail.bodys1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.isRealGoods == 1 ? "产品特点" : "方案特点"), 1),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", {
              innerHTML: _ctx.goodsDetail.bodys1,
              class: "html-box"
            }, null, 8, _hoisted_12)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.goodsDetail.bodys2.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          (_ctx.isRealGoods == 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, "产品展示"))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_15, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodsDetail.bodys2, (item, index) => {
              return (_openBlock(), _createElementBlock("img", {
                src: _ctx.getImgUrl(item)
              }, null, 8, _hoisted_16))
            }), 256))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}