
import { defineComponent, ref } from "vue"

export default defineComponent({
  components: {},
  emits: ["changStatus"],
  props: {
    price: {
      type: Number,
      default: 25,
    },
  },
  setup(props: any, { emit }) {
    let status = ref(false)
    let changStatus = () => {
      status.value = !status.value
      emit("changStatus", status.value)
    }
    return { changStatus, status }
  },
})
