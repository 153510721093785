
import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue"
import GoodsMoreSelect from "@/components/order/postsale/GoodsMoreSelect.vue"
import { OrderGoodsType } from "@/utils/apitype/goodsType"

const goodsTypeHelper = function (): Array<OrderGoodsType> {
  return [] as Array<OrderGoodsType>
}
export default defineComponent({
  components: { GoodsMoreSelect },
  name: "ConfirmModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    goodsList: {
      type: goodsTypeHelper,
    },
  },
  emits: {
    onConfirm: null,
    onCancel: null,
  },
  setup(props: any, { emit }) {
    let tempData = Object.assign({}, props)
    let propsData = reactive(tempData)

    //标识是否全部选中
    let isAll = ref(false)

    /**
     * 选择
     */
    let handleConfirm = () => {
      propsData.visible = false
    }

    /**
     * 提交选择
     */
    let handleCancel = () => {
      propsData.visible = false
      let goodsList = propsData.goodsList
      console.log(goodsList, "goodslist1")

      return goodsList
    }

    /**
     * 点击选择单个商品
     */
    let selectGoods = (index: number) => {
      propsData.goodsList[index].isSelect = !propsData.goodsList[index].isSelect
      console.log(
        propsData.goodsList,
        propsData.goodsList[index].isSelect,
        !propsData.goodsList[index].isSelect,
        "选择的数据"
      )

      handleIsAll()
    }

    /**
     * 选择全部商品
     */
    let selectAllGoods = () => {
      var index = propsData.goodsList.findIndex((item: any) => {
        if (item.isSelect == false) {
          return true
        }
      })
      if (index < 0) {
        for (let i = 0; i < propsData.goodsList.length; i++) {
          propsData.goodsList[i].isSelect = false
        }
        handleIsAll()
      } else {
        for (let i = 0; i < propsData.goodsList.length; i++) {
          propsData.goodsList[i].isSelect = true
        }
        handleIsAll()
      }
      console.log(propsData.goodsList, "选择的数据")
    }

    /**
     * 判断是否全部选中
     */
    let handleIsAll = () => {
      var index = propsData.goodsList.findIndex((item: any) => {
        if (item.isSelect == false) {
          return true
        }
      })
      if (index < 0) {
        isAll.value = true
      } else {
        isAll.value = false
      }
    }
    onMounted(() => {
      nextTick(() => {
        handleIsAll()
        //propsData.goodsList
      })
    })

    return {
      handleConfirm,
      handleCancel,
      selectGoods,
      ...toRefs(propsData),
      selectAllGoods,
      isAll,
    }
  },
})
