import { ElMessage } from "element-plus"
import * as _ from "lodash"
/**
 * @param msg 提示信息
 * @param type 类型  success error warning info
 */
let sjyAlert3 = _.throttle(
  (msg: string, types = "success", goTo: any = () => { }) => {
    if (
      types == "success" ||
      types == "error" ||
      types == "warning" ||
      types == "info"
    ) {
      ElMessage(
        {
          message: msg,
          grouping: true,
          type: types,
          duration: 2000,
        },
        goTo()
      )
    }
  },
  4000
) //防抖

let sjyAlert2 = (msg: string, types = "success", goTo: any = () => { }) => {
  if (
    types == "success" ||
    types == "error" ||
    types == "warning" ||
    types == "info"
  ) {
    ElMessage(
      {
        message: msg,
        grouping: true,
        type: types,
        duration: 2000,
      },
      goTo()
    )
  }
}

let sjyAlert = _.debounce(sjyAlert2, 500) //节流

let fangankefu = () => {
  window.open(
    "https://affim.baidu.com/unique_33831074/chat?siteId=21035732&userId=33831074&siteToken=94117ca1b983d2c1ff0806e226337d83",
    "",
    "modal=yes,width=800,height=650,resizable=no,scrollbars=no"
  )
}
let shouqiankefu = () => {
  window.open(
    "https://affim.baidu.com/unique_33831074/chat?siteId=21035732&userId=33831074&siteToken=94117ca1b983d2c1ff0806e226337d83",
    "",
    "modal=yes,width=800,height=650,resizable=no,scrollbars=no"
  )
}

let shouhoukefu = () => {
  window.open(
    "https://affim.baidu.com/unique_33831074/chat?siteId=21035732&userId=33831074&siteToken=94117ca1b983d2c1ff0806e226337d83",
    "",
    "modal=yes,width=800,height=650,resizable=no,scrollbars=no"
  )
}

export { sjyAlert, fangankefu, shouqiankefu, shouhoukefu }
