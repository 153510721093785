
import { userApi } from "@/service/api"
import { reactive, defineComponent, ref, onMounted } from "vue"
import Dialog from "@/components/common/Dialog/Dialog.vue"
import AddressFrom from "@/components/common/Dialog/item/AddressFrom.vue"
import Title from "@/components/common/Dialog/item/Title.vue"
import DialogTitle from "@/components/common/DialogTitle.vue"
// 缺省页
import Lack from "./addressLack.vue"
import { sjyAlert } from "@/utils/alert"
import { ElMessageBox } from "element-plus"
export default defineComponent({
  components: {
    Dialog,
    AddressFrom,
    Title,
    DialogTitle,
    Lack,
  },
  setup() {
    let addressItem = reactive({
      data: {},
    })
    let loading = ref(false)
    let dialogVisible = ref(false)
    const sonRef = ref<InstanceType<typeof Dialog>>()
    let setDialogVisible = () => {
      if (addressList.data.length >= 3) {
        sjyAlert("最多存在3个收货地址", "warning")
      } else {
        sonRef.value?.setVisible(true)
      }
    }

    let mclose = (type: number = 0) => {
      dialogVisible.value = false
      sonRef.value?.setVisible(dialogVisible.value)
      //if (type == 1) {
      getAddressList()
      //}
      addressItem.data = {}
    }

    interface addressType {
      data: any
    }

    let addressList: addressType = reactive({
      data: [],
    })
    //获取收货地址
    let getAddressList = () => {
      loading.value = true
      userApi.addressList().then((res: any) => {
        loading.value = false
        addressList.data = res.datas
        //console.log(addressList.data)
      })
    }
    /**
     * 修改收货地址
     */
    let updateAddress = (address: any) => {
      sonRef.value?.setVisible(true)
      addressItem.data = address
    }

    //设置默认地址
    let setDefaultAddress = (index: number) => {
      userApi.delAddress(addressList.data[index].id, 1).then((res: any) => {
        if (res.errno == 0) {
          sjyAlert("设置成功", "success")
          for (let i = 0; i < addressList.data.length; i++) {
            addressList.data[i].default = false
          }
          addressList.data[index].default = true
        }
      })
    }

    //删除地址
    let delAddress = (index: number) => {
      ElMessageBox.confirm("确定要删除收货地址?", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          userApi.delAddress(addressList.data[index].id, 2).then((res: any) => {
            if (res.errno == 0) {
              sjyAlert("删除成功", "success")
              addressList.data.splice(index, 1)
            }
          })
        })
        .catch(() => {
          // catch error
        })
    }

    onMounted(() => {
      getAddressList()
    })

    return {
      addressList,
      mclose,
      addressItem,
      setDialogVisible,
      sonRef,
      updateAddress,
      setDefaultAddress,
      delAddress,
      loading
    }
  },
})
