
import { reactive, defineComponent, ref, onMounted, inject } from "vue"
import dateFormat from "@/utils/dateFormat"
import { useRoute, useRouter } from "vue-router"
export default defineComponent({
	components: {},
	props: {
		goodslist: {
			type: Object,
			default: () => ({}),
		},
	},
	setup(props: any, context) {
		let step: any = inject("step")
		let leaving = (e: MouseEvent) => {
			;(e.target as HTMLElement).classList.add("show")
		}
		let leaving1 = (e: MouseEvent) => {
			;(e.target as HTMLElement).classList.remove("show")
		}

		// 点击展示删除框
		let del = (index: any, indexs: number) => {
			//console.log(props.goodslist[index][indexs]);
			props.goodslist[index][indexs].isselect = true
		}
		// 点击取消隐藏删除框
		let cancel = (index: any, indexs: number) => {
			//console.log(props.goodslist[index][indexs]);
			props.goodslist[index][indexs].isselect = false
		}
		// 点击确定删除 子传父传过去id 删除
		let delfoot = (index: any, indexs: number) => {
			// console.log(props.goodslist[index][indexs]);
			let ids = props.goodslist[index][indexs].id
			context.emit("delfoot", ids)
		}
		const staticUrl = inject("staticUrl")

		// 处理返回的图片地址
		let imgUrl = (goodsnames: string, cover: string) => {
			if (cover) {
				return cover
			} else {
				let modleName = goodsnames.split("：")[1]
				return staticUrl + "/filespath/files/video/img/" + modleName + ".jpg"
			}
		}

		// 时间判断显示的是昨天还是今天 还是日期
		let now = dateFormat.dateFormat(new Date().getTime(), "YYYY-MM-dd")
		let timeTamp = dateFormat.dateStrChangeTimeTamp(now)
		let yesterday = dateFormat.dateFormat(
			timeTamp - 60 * 60 * 24 * 1000,
			"YYYY-MM-dd"
		)

		//单个收藏
		// product_id: any,
		//   product_name: string,
		//   cid: number,
		//   val: number
		let addstore = (index: any, indexs: number, val: number) => {
			// console.log(props.goodslist[index][indexs]);
			let product_id = props.goodslist[index][indexs].id
			let product_name = props.goodslist[index][indexs].names
			let cid = props.goodslist[index][indexs].cid
			//console.log(props.goodslist);

			context.emit("addstore", product_id, product_name, cid, val)
		}

		let chessCheck = (index: string, indexs: number) => {
			props.goodslist[index][indexs].isselect =
				!props.goodslist[index][indexs].isselect
		}
		let router = useRouter()
		let goGoods = (uid: any) => {
			if (step.value != 2) {
				router.push({
					name: "productDetail",
					params: {
						id: uid,
					},
				})
			}
		}
		onMounted(() => {})

		return {
			leaving,
			leaving1,
			del,
			imgUrl,
			now,
			timeTamp,
			yesterday,
			step,
			cancel,
			delfoot,
			addstore,
			goGoods,
			chessCheck,
		}
	},
})
