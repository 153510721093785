<template>
  <div class="contents-bottom-info-contents">
    <div class="contents-line">
      <span class="contents-line-left">【设计方案】<span class="title">{{ goodsname }}</span></span>
      <!-- <a :href="info.list">免费下载</a> -->
      <a @click="down">免费下载</a>
    </div>
    <div class="contents-info">
      <p class="contents-info-title">
        <i class="icon iconfont icon-shuoming"></i>

        下载说明：该文件包含{{ showType == 1 ? "三维图、二维图、BOM" : "三维图" }}，可免费直接下载压缩文件
      </p>
      <img v-if="showType == 1" src="/static/free/msm.jpg" />
      <img v-else src="/static/free/waigou.jpg" />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, props } from "vue";
import { useRouter } from "vue-router"
import { goodsApi } from "@/service/api"
export default {
  setup(props) {
    const state = reactive({
      count: 0,
    });
    const router = useRouter()
    let id = router.currentRoute.value.params.id
    const info = toRefs(props.info)
    let down = () => {
      goodsApi.downNumber(id).then((res) => {
        console.log(res.errno)
        if(res.errno == 0){
          //console.log(props.info.list)
          window.open(props.info.list)
        }
      })
    }

    return {
      ...toRefs(state),
      down,
    };
  },
  props: {
    info: {
      type: Array,
      default: () => [],
    },
    goodsname: {
      type: String,
      default: "",
    },
    showType: {
      type: Number,
      default: 1,
    }
  },
};
</script>

<style scoped>
.contents-bottom-info-contents {
  width: 100%;
}

.contents-line {
  overflow: hidden;
  height: 78px;
  line-height: 78px;
  font-size: 16px;
  border-bottom: 1px dashed #d2d2d2;
}

.contents-line .contents-line-left {
  float: left;
}

.contents-line a {
  text-decoration-line: underline;
  color: #2655ae;
  font-size: 14px;
  float: right;
}

.contents-info {
  text-align: center;
}

.contents-info .contents-info-title {
  text-align: left;
  color: #2655AE;
  line-height: 60px;
  height: 60px;
}

.contents-info img {
  width: 80%;
}
</style>