
import { defineComponent, onMounted, reactive, watch } from "vue"
import GoodsInfo from "./GoodsInfo.vue"

export default defineComponent({
	components: { GoodsInfo },
	props: {
		goodslit: {
			type: Object,
			default: () => ({}),
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	setup(props: any) {
		// watch(props.goodsWhere, (newvalue, oldvalue) => {
		//   //console.log(newvalue, oldvalue);
		//   getGoodsList()
		// });

		//let getGoodsList = () => {
		// goodsApi.getGoodsList(props.goodsWhere).then((res:any) => {
		//   if(res.errno==0){
		//     goodslit.datas = res.datas
		//   }

		// });
		//};

		// onMounted(()=>{
		//   getGoodsList()
		// })

		return {}
	},
})
