
import {
  defineComponent,
  onMounted,
  reactive,
  computed,
  ref,
  inject,
  nextTick,
onUnmounted,
} from "vue"
import CurrentAddress from "@/components/common/CurrentAddress.vue"
import HeaderNav from "@/components/HeaderNav.vue"
import NavList from "@/components/NavList.vue"
import HtmlFooter from "@/components/HtmlFooter.vue"
import VideoInfoGoodList from "../../components/video/FreeVideoInfoGoodList.vue"
import { useRouter, useRoute } from "vue-router"
import { goodsApi, videoApi } from "@/service/api"
import HomeHead from "@/components/HomeHead.vue"
import { sjyAlert } from "@/utils/alert"
import { userStore } from "@/stores/userStore"


export default defineComponent({
  name: "videoinfo", //视频详细页
  components: {
    CurrentAddress,
    HeaderNav,
    NavList,
    HtmlFooter,
    VideoInfoGoodList,
    HomeHead,
  },
  // computed: {
  //   videoUrl() {
  //     return this.goodsInfo.data;
  //   },
  // },
  setup() {
    let userinfot = userStore()

    interface resType {
      datas: any
      errno: number
      errmsg: string
      curIndex: 0
    }

    let route = useRoute()
    let baseurl = inject("BASEURL", "")

    let routerPath = ref("")
    routerPath.value = baseurl + route.path

    let curIndex = 0
    interface goodsType {
      data: any
      errno: number
      curIndex: string | number
      errmsg: string
    }
    let goodsInfo: goodsType = reactive({
      data: "",
      errno: 0,
      curIndex: 1,
      errmsg: "",
    })
    const staticUrl = inject("staticUrl")

    const videoUrl = computed(() => {
      let modleName = goodsInfo.data.names.split("：")[1]
      //options.src = staticUrl + "/filespath/files/video/" + modleName + ".mp4"
      return staticUrl + "/filespath/files/video/" + modleName + ".mp4"
    })
    let sttrsCut = (sttrs: string, division: string = " ") => {
      sttrs = sttrs.trim()
      return (sttrs || "").split(division)
    }
    //let isStore = ref(0)
    const addStart = () => {
      if (goodsInfo.data.store == 1) {
        let mdata = [
          {
            product_id: goodsInfo.data.id,
            type: 3,
            status: 0,
            product_name: goodsInfo.data.names,
            cid: goodsInfo.data.cid,
          },
        ]
        //取消删除
        goodsApi.addStore(mdata).then((res: any) => {
          if (res.errno == 0) {
            goodsInfo.data.store = 0
            sjyAlert("已取消收藏", "success")
          } else {
            sjyAlert(res.errmsg, "warning")
          }
        })
      } else {
        let mdata = [
          {
            product_id: goodsInfo.data.id,
            type: 3,
            status: 1,
            product_name: goodsInfo.data.names,
            cid: goodsInfo.data.cid,
          },
        ]
        goodsApi.addStore(mdata).then((res: any) => {
          if (res.errno == 0) {
            goodsInfo.data.store = 1
            sjyAlert("收藏成功", "success")
          }
        })
      }
    }

    let attach = sttrsCut(route.params.attach as string, "-")

    let scid = ref<number>(Number(attach[0]))
    let aid: number = Number(attach[1])
    let sort: number = Number(attach[2])
    let uid = userinfot.id

    //查询商品详细信息

    let getGoodsInfo = (id: string, cid: number, aid: number, sort: number) => {
      let goodswhere = {
        id,
        cid,
        aid,
        sort,
        uid,
      }
      let userinfo = userStore()

      videoApi.videoInfo(goodswhere).then((res: any) => {
        goodsInfo.data = (res as resType).datas.data
        goodsInfo.errno = (res as resType).errno
        goodsInfo.curIndex = (res as resType).datas.curIndex
        goodsInfo.errmsg = (res as resType).errmsg
        scid.value =  goodsInfo.data.cid
        if (goodsInfo.data['vodeo_url']) {
          options.src = goodsInfo.data['vodeo_url']
        } else {
          let modleName = goodsInfo.data.names.split("：")[1]
          options.src = staticUrl + "/filespath/files/video/" + modleName + ".mp4"
        }

        if (userinfo.id > 0) {
          goodsApi.addGoodsTrack(goodsInfo.data.id, 3, 1).then((res: any) => {
            //console.log(res);
          })
        }
      })
    }
    let sedGoodsInfo = (info: any) => {
      goodsInfo.data = info
      if (goodsInfo.data['vodeo_url']) {
        options.src = goodsInfo.data['vodeo_url']
      } else {
        let modleName = goodsInfo.data.names.split("：")[1]
        options.src = staticUrl + "/filespath/files/video/" + modleName + ".mp4"
      }

    }
    let entryTime = Date.now()
		const addDuration = () => {
			const leaveTime = Date.now()
			const duration = leaveTime - entryTime
			localStorage.setItem(
				"duration",
				goodsInfo.data.id + "_" + 3 + "_" + duration
			)

			goodsApi
				.addGoodsDuration(goodsInfo.data.id, 3, duration)
				.then((res: any) => {
					localStorage.removeItem("duration")
				})
		}
		onUnmounted(() => {
			addDuration()		
		})
		addEventListener("beforeunload", addDuration)
    onMounted(() => {
      getGoodsInfo(route.params.id as string, scid.value, aid, sort)
      nextTick(() => {
        window.scrollTo(0, 0)
      })
    })
    let options: any = reactive({})
    options = {
      width: "1006px", //播放器高度
      height: "566px", //播放器高度
      color: "#409eff", //主题色
      title: "", //视频名称
      //src: "https://www.sjyuan.net.cn/filespath/files/video/ZS0600222.mp4", //视频源
      muted: false, //静音
      webFullScreen: false,
      speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
      autoPlay: false, //自动播放
      loop: false, //循环播放
      mirror: false, //镜像画面
      ligthOff: true, //关灯模式
      volume: 0.3, //默认音量大小
      control: true, //是否显示控制
      controlBtns: [
        "audioTrack",
        "quality",
        "speedRate",
        "setting",
        "pip",
        "pageFullScreen",
        "fullScreen",
      ], //显示所有按钮,
    }

    return {
      goodsInfo,
      getGoodsInfo,
      sttrsCut,
      attach,
      scid,
      aid,
      sort,
      sedGoodsInfo,
      videoUrl,
      addStart,
      routerPath,
      options,
    }
  },
})
