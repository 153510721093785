
import { defineComponent, inject, ref } from "vue"

export default defineComponent({
  emits: ["oneClickDelete"],
  props: {
    showDelete: {
      type: Boolean,
      default: false,
    },
    mindex: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  setup(props: any, { emit }) {
    let clickDelete = (val: boolean) => {
      emit("oneClickDelete", val)
      if(val){
        deleteGoodsListByIndex(props.mindex)
      }
      
    }
    let isSelect = ref(true)
    const deleteGoodsListByIndex = inject(
      "deleteGoodsListByIndex",
      (index: number) => {
        console.log("我被点击了")
      },
      false
    )

    return { clickDelete, isSelect, deleteGoodsListByIndex }
  },
})
