<template>
    <div class="cart-icon">
        <div class="icon-cart">
            <img src="@/assets/icon/i-04.png" />
            <div class="icon-cart-number">12</div>
        </div>
        <div class="cart-lable">
            <span>购物车</span>
        </div>        
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped>
    .cart-icon{
        width: 80px;
        text-align: center;
    }
    .icon-cart{
        position: relative;
    }
    .icon-cart .icon-cart-number{
        position:absolute;
        top:0;
        left: 44px;
        font-size: 10px;
        color: #fff;
        background-color: #3765BD;        
        border: 2px solid #fff; 
        padding: 2px 8px; 
        border-radius: 11px;      
    }
    .cart-icon span{
        font-size: 14px;
        font-weight: 500;
        color: #FBB113;
    }
    .cart-lable{
        margin-top: 3px;
    }
</style>