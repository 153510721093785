
  import { reactive, defineComponent, ref } from "vue";
  
  export default defineComponent({
    components: {},
    props:{
      CouponList:{
        type: Array,
        default: () => []
      }
    },
    setup() {
      return {};
    },
  });
  