
import {
  reactive,
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  inject,
} from "vue"
import type { TabsPaneContext } from "element-plus"
import PersonageVip from "./PersonageVip.vue"
import FirmVip from "./FirmVip.vue"
import FirmVipTop from "./FirmVipTop.vue"
import PersonageTOP from "./selfVipTop.vue"
import InvoiceDialog from "@/components/common/Dialog/InvoiceDialog.vue"
import { userStore } from "@/stores/userStore"
import { getVipInfo } from "@/utils/public"
import { useRoute } from "vue-router"
import { userApi } from "@/service/api"
import router from "@/router"

export default defineComponent({
  components: {
    PersonageVip,
    FirmVip,
    FirmVipTop,
    PersonageTOP,
    InvoiceDialog,
  },
  setup() {
    let userInfo = userStore()
    let isVip = userInfo.isVip
    let viptype = useRoute().params.type
    //console.log(viptype);

    const activeName = ref("first")
    if (viptype && viptype == "second") {
      activeName.value = "second"
    }
    const handleClick = (tab: TabsPaneContext, event: Event) => {
      //   console.log(tab, event)
    }

    // 区分会员
    let vipinfo = getVipInfo()

    //  弹框
    let dialogVisible = ref(false)
    interface addressType {
      data: any
    }
    let setDialogVisible = () => {
      sonRef.value?.setVisible(true)
      //console.log();
    }
    const sonRef = ref<InstanceType<typeof InvoiceDialog>>()
    // 关闭弹框
    let mclose = () => {
      dialogVisible.value = false
      sonRef.value?.setVisible(dialogVisible.value)
    }
    let WSURL = inject("WSURL", "")
    var ws: any
    try {
      ws = new WebSocket(WSURL)
    } catch {}

    let submitCode = JSON.stringify({
      userId: userInfo.id,
    })
    ws.onopen = function (evt: any) {
      ws.send(submitCode)
    }
    let userinfot = userStore()
    let wozhixingle = () => {
      userApi.getInformation().then((res: any) => {
        userinfot.updateVip(
          res.datas.vip_type,
          res.datas.vip_title,
          res.datas.vip_etime,
          res.datas.vip_id
        )
      })
      router.go(0)
    }
    ws.onmessage = function (e: any) {
      console.log("服务器返回的信息: " + e.data)
      wozhixingle()
    }
    ws.onclose = (e: any) => {
      console.log(e.data)

      console.log("WebSocket onclose")
    }

    // times = setInterval(() => {
    //     count.value = count.value - 1; //递减
    //     if (count.value <= 0) {
    //       // <=0 变成获取按钮
    //       isShow.value = true;
    //       clearInterval(times);
    //     }
    //     countShowText.value = count.value + "秒后再获取";
    //   }, 1000);
    let tt = setInterval(function () {
      //console.log("开始检测一下...")
      try {
        ws.send(submitCode)
        //console.log("我发送成功了...")
      } catch (error) {
        //console.log("执行断线重连...")
      }
      
      // websocket.Init(sname)
      // lockReconnect = false
    },20000)

    onUnmounted(() => {
      ws.close()
      clearTimeout(tt);
    })

    return {
      handleClick,
      activeName,
      dialogVisible,
      mclose,
      sonRef,
      setDialogVisible,
      isVip,
      vipinfo,
    }
  },
})
