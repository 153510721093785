import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"

import Free from "../views/free/Index.vue"
import FreeGoods from "../views/free/Goods.vue"
import Video from "../views/video/Video.vue"
import VideoClass from "@/views/video/VideoClass.vue"
import VideoInfo from "@/views/video/VideoInfo.vue"
import FreeVideoInfo from "@/views/video/FreeVideoInfo.vue"
import FreeVideoClass from "@/views/video/FreeVideoClass.vue"
import Home from "@/views/home/Home.vue"
import Homecode from "@/views/home/HomeCode.vue"
// 四个登录方式
import Login from "@/views/login/Login.vue"
import NumberLogin from "@/views/login/NumberLogin.vue"
import SonLogin from "@/views/login/SonLogin.vue"
import SonNumber from "@/views/login/SonNumber.vue"
//找回密码
import FindPwd from "@/views/login/findPwd.vue"
import SurePwd from "@/views/login/surePwd.vue"

// 注册
import Register from "@/views/login/Register.vue"

//关于商品路由
import goodsClass from "@/views/goods/goodsClass.vue"
import Search from "@/views/goods/search.vue"
import partsSearch from "@/views/goods/partsSearch.vue"
import goodsDetail from "@/views/goods/detail.vue"

//订单路由 cart
import Cart from "@/views/order/Cart.vue"
import Confirmed from "@/views/order/ConfirmedOrder.vue"
import PaySuccess from "@/views/order/PaySuccess.vue"
import ToBePaid from "@/views/order/ToBePaid.vue"
import OrderDown from "@/views/order/OrderDown.vue"
import OrderOver from "@/views/order/OrderOver.vue"
import ServiceOrder from "@/views/order/ServiceOrder.vue"
import PayOrder from "@/views/order/PayOrder.vue"
import PostSale from "@/views/order/PostSale.vue"
import PostSaleStatus from "@/views/order/PostSaleStatus.vue"
import PostSaleList from "@/views/order/PostSaleList.vue"

// 个人中心
import User from "@/views/user/index.vue"
import Aside from "@/views/user/layout/information/Aside.vue"
import SelfVerify from "@/views/user/layout/selfverify/SelfVerifys.vue"
import FirmVerify from "@/views/user/layout/firmVerify/index.vue"
import MyLocation from "@/views/user/layout/mylocation/index.vue"
import MyMessage from "@/views/user/layout/mymessage/index.vue"
import DiscountCoupon from "@/views/user/layout/DiscountCoupon/DiscountCoupon.vue"
import Works from "@/views/user/layout/Works/index.vue"
import MyOrder from "@/views/user/layout/order/OrderList.vue"
import MyVip from "@/views/user/layout/myVip/index.vue"
import Project from "@/views/user/layout/collection/Project.vue"
import PersonalCenter from "@/views/user/PersonalCenter/index.vue"
import MyFoormark from "@/views/user/layout/myFootmark/index.vue"
import MyAccount from "@/views/user/layout/myAccount/index.vue"
import ActivityGoods from "@/views/goods/activityGoods.vue"
import Invoice from "@/views/user/layout/userInvoice/index.vue"

// 右侧悬浮栏
import Suggest from "@/views/broadSide/suggest/index.vue"
//生态合作
import FirmCooperation from "@/views/firmCooperation/index.vue"
import SchemeCooperation from "@/views/firmCooperation/schemeCooperation.vue"
import ChainCooperation from "@/views/firmCooperation/chainCooperation.vue"
import Verify from "@/views/firmCooperation/verify.vue"

// 关于我们
import AboutOur from "@/views/aboutOur/aboutOur.vue"

//静态新闻页面
import userGuide from "@/views/info/userGuide.vue"
import aboutSite from "@/views/info/aboutSite.vue" //使用规则
import cooperation from "@/views/info/cooperation.vue" //合作协议
import privacyPolicy from "@/views/info/privacyPolicy.vue" //隐私政策
import renzheng from "@/views/info/renzheng.vue" //隐私政策

import emptyInfo from "@/views/info/empty.vue" //隐私政策
import emptyText from "@/views/info/emptyText.vue"
//客户服务
import Customer from "@/views/customer/index.vue"
//新手指南
import Guidance from "@/views/guidance/index.vue"

//错误页面
import errorPage from "@/views/error/index.vue"
// 备件区主页
import spareHome from "@/views/spareHome/spareHome.vue"
//发票服务

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Homecode,
    meta: {
      title: "设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },
  {
    path: "/homecode",
    name: "homecode",
    component: Home,
    meta: {
      title: "设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },
  // 四个登录方式
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "登录-设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },

  {
    path: "/sonlogin",
    name: "sonlogin",
    component: SonLogin,
    meta: {
      title: "子账号登录-设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },

  {
    path: "/numberlogin",
    name: "numberlogin",
    component: NumberLogin,
    meta: {
      title: "登录-设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },

  {
    path: "/sonnumber",
    name: "sonnumber",
    component: SonNumber,
    meta: {
      title: "子账号-设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },

  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      title: "注册-设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },

  {
    path: "/findpwd",
    name: "findpwd",
    component: FindPwd,
    meta: {
      title: "找回密码-设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },
  {
    path: "/surepwd",
    name: "surepwd",
    component: SurePwd,
    meta: {
      title: "重置密码-设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },

  //关于商品路由
  {
    path: "/goods-class/:classid?/:attrid?",
    name: "goods-class",
    component: goodsClass,
    meta: {
      title: "设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },
  {
    path: "/search/:type?/:sv?/:page?",
    name: "search",
    component: Search,
    meta: {
      title: "设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },
  {
    path: "/partssearch/:pid?/:sid?",
    name: "partssearch",
    component: partsSearch,
    meta: {
      title: "设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },
  {
    path: "/product/detail/:id",
    name: "productDetail",
    component: goodsDetail,
    meta: {
      title: "设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },
  {
    path: "/activity",
    name: "activity",
    component: ActivityGoods,
    meta: {
      title: "设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },
  {
    path: "/payorder/:id?",
    name: "payorder",
    component: PayOrder,
    meta: {
      title: "设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },

  //关于商品路由end

  //客服中心
  {
    path: "/customer/:comName?",
    name: "customer",
    component: Customer,
    meta: {
      title: "设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },
  //客服中心end

  //售后服务
  {
    path: "/postsale/:orderid/:goodsid/:orderno?",
    name: "postsale",
    component: PostSale,
    meta: {
      title: "设纪元-售后",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },

  //售后服务状态
  {
    path: "/postsalestatus/:orderid/:goodsid/:orderno",
    name: "postsalestatus",
    component: PostSaleStatus,
    meta: {
      title: "设纪元-售后",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },
  //订单申请售后列表
  {
    path: "/postsalelist/:orderid",
    name: "postsalelist",
    component: PostSaleList,
    meta: {
      title: "设纪元-售后",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },

  //新手指南
  {
    path: "/guidance/:comName?",
    name: "guidance",
    component: Guidance,
    meta: {
      title: "设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },

  //关于订单路由
  {
    path: "/cart/:type?",
    name: "cart",
    component: Cart,
    meta: {
      title: "设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },
  {
    path: "/confirmed-order/:ids/:counsel?",
    name: "confirmed",
    component: Confirmed,
    meta: {
      title: "设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },
  {
    path: "/paysuccess/:id",
    name: "paysuccess",
    component: PaySuccess,
    meta: {
      title: "支付成功-设纪元",
      description:
        "设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
      keywords:
        "设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
    },
  },
  {
    path: "/tobepaid/:id",
    name: "tobepaid",
    component: ToBePaid,
  },
  {
    path: "/orderdown/:id",
    name: "orderdown",
    component: OrderDown,
  },
  {
    path: "/orderover/:id",
    name: "orderover",
    component: OrderOver,
  },
  {
    path: "/service/:id/:orderid",
    name: "service",
    component: ServiceOrder,
  },

  //关于订单路由end
  {
    path: "/free/:cid?/:aid?",
    name: "free",
    component: Free,
  },
  {
    path: "/free-goods/:id",
    name: "free-goods",
    component: FreeGoods,
  },
  {
    path: "/video/:id?",
    name: "video",
    component: Video,
  },
  {
    path: "/video-class/:cid/:aid?",
    name: "video-class",
    component: VideoClass,
  },
  {
    path: "/video-info/:id/:attach?", //cid-aid-sort
    name: "video-info",
    component: VideoInfo,
  },
  {
    path: "/free-video-info/:id/:attach?",
    name: "free-video-info",
    component: FreeVideoInfo,
  },
  {
    path: "/free-video-class/:id/:sid?",
    name: "free-video-class",
    component: FreeVideoClass,
  },


  //个人中心
  {
    path: "/user",
    name: "user",
    component: User,
    redirect: "/user/aside",
    children: [
      {
        //基本信息
        name: "aside",
        path: "aside/:type?",
        component: Aside,
        meta: {
          title: "基本信息",
        },
      },
      {
        //个人认证
        name: "selfverify",
        path: "selfverify",
        component: SelfVerify,
        meta: {
          title: "作品中心",
        },
      },
      {
        //企业认证
        name: "firmverify",
        path: "firmverify",
        component: FirmVerify,
        meta: {
          title: "作品中心",
        },
      },
      {
        //我的地址
        name: "mylocation",
        path: "mylocation",
        component: MyLocation,
      },
      {
        //我的消息
        name: "mymessage",
        path: "mymessage",
        component: MyMessage,
      },
      {
        //我的优惠券
        name: "discountcoupon",
        path: "discountcoupon",
        component: DiscountCoupon,
      },
      {
        //作品中心
        name: "works",
        path: "works",
        component: Works,
      },
      {
        //我的订单
        name: "myorder",
        path: "myorder/:status?",
        component: MyOrder,
      },
      {
        //会员中心
        name: "myvip",
        path: "myvip/:type?",
        component: MyVip,
      },
      {
        //我的收藏
        name: "project",
        path: "project",
        component: Project,
      },
      {
        //我的足迹
        name: "myfootmark",
        path: "myfootmark",
        component: MyFoormark,
      },
      {
        //我的账户
        name: "myaccount",
        path: "myaccount/:type?/:types?",
        component: MyAccount,
      },
      {
        //发票服务
        name: "invoice",
        path: "invoice",
        component: Invoice,
      },
    ],
  },

  // 个人主页
  {
    path: "/personalcenter/:userid",
    name: "personalcenter",
    component: PersonalCenter,
  },

  // 右侧悬浮框 建议反馈
  {
    path: "/suggest",
    name: "suggest",
    component: Suggest,
  },

  // 企业合作
  {
    path: "/firmCooperation",
    name: "firmCooperation",
    component: FirmCooperation,
  },
  // 设计方案合作伙伴
  {
    path: "/schemeCooperation",
    name: "schemeCooperation",
    component: SchemeCooperation,
  },
  {
    path: "/chainCooperation",
    name: "chainCooperation",
    component: ChainCooperation,
  },

  {
    path: "/verify/:type?",
    name: "verify",
    component: Verify,
  },

  // 关于我们
  {
    path: "/aboutour/:id?",
    name: "aboutour",
    component: AboutOur,
  },
  //新闻详细页 userGuide
  {
    path: "/userGuide", //用户中心
    name: "userguide",
    component: userGuide,
  },
  {
    path: "/aboutSite", //使用规则
    name: "aboutsite",
    component: aboutSite,
  },
  {
    path: "/cooperation", //合作协议
    name: "cooperation",
    component: cooperation,
  },
  {
    path: "/privacyPolicy", //隐私政策
    name: "privacyPolicy",
    component: privacyPolicy,
  },
  // {
  //   path: "/emptyInfo", //隐私政策
  //   name: "emptyInfo",
  //   component: emptyInfo,
  // },
  {
    path: "/renzheng", //隐私政策
    name: "renzheng",
    component: renzheng,
  },
  {
    path: "/emptyText",
    name: "emptyText",
    component: emptyText,
  },
  {
    path: "/errorPage/:type?",
    name: "errorPage",
    component: errorPage,
  },
  //备件区首页
  {
    path: "/spareHome",
    name: "spareHome",
    component: spareHome,
  },
  // 匹配不存在的路由重定向到首页
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
