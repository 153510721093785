import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a1f136b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-list" }
const _hoisted_2 = { class: "order-contents" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MyOrderList = _resolveComponent("MyOrderList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MyOrderList, {
        orderList: _ctx.orderList,
        goodstotal: _ctx.total,
        loading: _ctx.loading,
        ostatus: _ctx.goodsWhere.data.status,
        onSetWhere: _ctx.setWhere,
        pageSize: _ctx.goodsWhere.data.pageSize,
        onHandleCurrentChange: _ctx.handleCurrentChange,
        onSetOrderStatus: _ctx.setOrderStatus
      }, null, 8, ["orderList", "goodstotal", "loading", "ostatus", "onSetWhere", "pageSize", "onHandleCurrentChange", "onSetOrderStatus"])
    ])
  ]))
}