
import { reactive, defineComponent, ref, onMounted } from "vue";
import { company } from "@/service/api";

export default defineComponent({
  setup() {
    interface result {
      data: any;
    }

    let getInformation: result = reactive({
      data: {},
    });
    let getcompany = () => {
      company.companyinformation().then((res: any) => {
        console.log(res);
        getInformation.data = res.datas;
        console.log(getInformation.data);
      });
    };

    onMounted(() => {
      getcompany();
    });
    return {
      getInformation,
    };
  },
});
