
import { reactive, defineComponent, onMounted, inject } from "vue"
import { userStore } from "@/stores/userStore"
import { userApi } from "@/service/api"
import { getSonVip, getVipInfo } from "@/utils/public"
import { useRouter, useRoute } from "vue-router"

export default defineComponent({
	components: {},
	setup() {
		let userinfot = userStore()
		interface result {
			data: any
		}
		let userInfo = userStore()
		let isVip = userInfo.isVip
		let getInformation: result = reactive({
			data: {},
		})

		let userFansInfor: result = reactive({
			data: {},
		})

		let getImgUrl = inject(
			"getImgUrl",
			(index: string) => {
				return ""
			},
			false
		)

		let getuserInformation = () => {
			userApi.getInformation().then((res: any) => {
				if (res.errno == 0) {
					getInformation.data = res.datas

					if (res.datas.pid > 0) {
						getSonVip(res.datas.pid)
					} else {
						userinfot.updateVip(
							res.datas.vip_type,
							res.datas.vip_title,
							res.datas.vip_etime,
							res.datas.vip_id
						)
					}
				}
			})
		}
		let router = useRouter()
		let goUser = () => {
			if (getInformation.data.pid == 0) {
				router.push({
					name: "personalcenter",
					params: { userid: getInformation.data.id },
				})
			}
			if (getInformation.data.pid != 0) {
				router.push({
					name: "personalcenter",
					params: { userid: getInformation.data.pid },
				})
			}
		}

		let getUserFansInfor = () => {
			let data = {
				id: userinfot.id,
			}

			userApi.userHome(userinfot.id).then((res: any) => {
				userFansInfor.data = res.data
			})
		}
		let vipinfo = getVipInfo()

		onMounted(() => {
			getuserInformation()
			getUserFansInfor()
		})

		return {
			userinfot,
			getuserInformation,
			getInformation,
			getImgUrl,
			vipinfo,
			userInfo,
			isVip,
			userFansInfor,
			goUser,
		}
	},
})
