
import {
	computed,
	defineComponent,
	onMounted,
	reactive,
	ref,
	watchEffect,
} from "vue"
import { userStore } from "@/stores/userStore"
import { sjyAlert } from "@/utils/alert"
import { useRouter, useRoute } from "vue-router"
import HomeClass from "@/components/HomeClass.vue"
import { useHead } from "@vueuse/head"
import { notInRouter } from "@/utils/public"
import { userApi } from "@/service/api"
import { parts } from "@/service/api"

export default defineComponent({
	components: { HomeClass },
	emits: ["clickSearch"],
	setup(props: any, { emit }) {
		let userName = ref("")
		userName.value = userStore().username
		let mobile = ref("")
		mobile.value = userStore().mobile
		let sv = ref("")
		const route = useRoute()
		sv.value = route.params.sv as string

		let partsClass = reactive({
			data: {} as any,
		})

		parts.partsAllClass().then((res: any) => {
			if (res.errno == 0) {
				partsClass.data = res.datas
			}
		})

		const showName = computed(() => {
			return userName.value ? userName.value : mobile.value
		})
		let router = useRouter()
		//console.log(router, "router")

		let showClass = route.name

		let loginOut = () => {
			userStore().deleteUserInfo()
			sjyAlert("已退出", "success", () => {
				if (route.name == "home") {
					router.go(0)
				} else {
					router.push({ name: "home" }) // userRouter
				}
			})
		}
		let gotoCart = () => {
			router.push({
				name: "cart",
				params: {
					type: userStore().cartType,
				},
			})
		}
		let cartTotal = ref(0)
		cartTotal.value = userStore().cartTotal
		watchEffect(() => {
			cartTotal.value = userStore().cartTotal
		})
		let typeValueText = ref("设计方案")
		let typeValue = ref(1)
		let changeType = (type: number) => {
			switch (type) {
				case 1:
					typeValueText.value = "设计方案"
					typeValue.value = 1
					break
				case 2:
					typeValueText.value = "原理动画"
					typeValue.value = 2
					break
				case 3:
					typeValueText.value = "免费专区"
					typeValue.value = 3
					break
				case 4:
					typeValueText.value = "供应链"
					typeValue.value = 4
					break
				default:
					typeValueText.value = "设计方案"
					typeValue.value = 1
					break
			}
		}

		let toSearch = (val: string = "") => {
			if (val != "") {
				sv.value = val
			}
			if (sv.value == "" || sv.value == undefined) {
				sv.value = "机械手"
			}
			if (typeValue.value != 4) {
				router.push({
					name: "search",
					params: {
						type: typeValue.value,
						sv: sv.value,
						page: 1,
					},
				})
			} else {
				//todo 跳转到海外网站
			}
			emit("clickSearch", sv.value, typeValue.value)
		}

		let isShowClass = ref(true)

		let onMouseleave = () => {
			isShowClass.value = true
		}

		let onMouseenter = () => {
			isShowClass.value = true
		}
		let gotoMovibot = (url: any) => {
			window.open(url)
		}

		let saveGoRouter = () => {
			if (notInRouter(route.name)) {
				localStorage.setItem(
					"gorouter",
					JSON.stringify({ name: route.name, params: route.params })
				)
			}
		}
		interface result {
			data: any
		}
		let userMessage: result = reactive({
			data: {},
		})

		// 判断是否有新消息
		let messageNumber = ref(0)
		let getUserMessage = () => {
			if (showName.value != "") {
				// console.log("登录了")
				let messageArr: any = []
				userApi.userMessage().then((res: any) => {
					userMessage.data = res.datas
					// console.log(res,"xx");

					for (let k in res.datas) {
						if (res.datas[k].status == 0) {
							// console.log(res.datas[k].id);
							messageArr.push(res.datas[k].id)
						}
					}
					//console.log(messageArr.length)
					messageNumber.value = messageArr.length
				})
			}
		}

		onMounted(() => {
			changeType(parseInt(route.params.type as string))
			saveGoRouter()
			getUserMessage()
		})
		if (route.meta && route.meta.keywords) {
			const siteData = reactive({
				title: route.meta.title as string,
				description: route.meta.description as string,
				keywords: route.meta.keywords as string,
			})
			useHead({
				// Can be static or computed
				title: () => siteData.title,
				meta: [
					{
						name: `description`,
						content: () => siteData.description,
					},
					{
						name: `keywords`,
						content: () => siteData.keywords,
					},
				],
			})
		} else {
			useHead({
				// Can be static or computed
				title: () => "设纪元",
				meta: [
					{
						name: `description`,
						content: () =>
							"设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料，包含SolidWorks模型、cad图纸、bom清单、设计说明书。即下即用，无需处理。",
					},
					{
						name: `keywords`,
						content: () =>
							"设纪元网，机械三维模型，CAD图纸，3D模型，机械模型 ，自动化设备",
					},
				],
			})
		}

		return {
			showName,
			loginOut,
			gotoCart,
			cartTotal,
			changeType,
			typeValueText,
			toSearch,
			sv,
			onMouseleave,
			onMouseenter,
			isShowClass,
			showClass,
			gotoMovibot,
			userMessage,
			messageNumber,
			partsClass,
		}
	},
})
