import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a7e3aac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pic" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "price" }
const _hoisted_4 = { class: "title nowrap" }
const _hoisted_5 = {
  key: 0,
  class: "attr nowrap"
}
const _hoisted_6 = { class: "click-num" }
const _hoisted_7 = { class: "left" }
const _hoisted_8 = { class: "username" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "goods-info",
    style: _normalizeStyle({ width: _ctx.bodyWidth + 'px' }),
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.gotoGoods(_ctx.goods.id)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        style: _normalizeStyle({ width: _ctx.contentsWidth + 'px' }),
        src: _ctx.changeUrl(_ctx.goods.photo)
      }, null, 12, _hoisted_2)
    ]),
    _createElementVNode("div", {
      style: _normalizeStyle({ width: _ctx.contentsWidth + 'px' }),
      class: "txt"
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("i", {
          class: _normalizeClass(["icon iconfont", {
						'icon-a-Group14379': _ctx.isRealGoods == 0,
						'icon-fl-renminbi': _ctx.isRealGoods == 1,
					}])
        }, null, 2),
        _createTextVNode(_toDisplayString(_ctx.showPrice(_ctx.goods.price, _ctx.isRealGoods)), 1)
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.goods.names), 1),
      (_ctx.goods.attr)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goods.attr.split(' '), (attr, index) => {
              return (_openBlock(), _createElementBlock("span", null, _toDisplayString(attr) + "  ", 1))
            }), 256))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.goods.click) + "次浏览", 1),
        (_ctx.goods.portrait)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "right",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.goto(_ctx.goods.uid)), ["stop"]))
            }, [
              _createElementVNode("div", {
                class: "header",
                style: _normalizeStyle({
							background: 'url(' + _ctx.goods.portrait + ') no-repeat',
						})
              }, null, 4),
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.goods.username), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ], 4)
  ], 4))
}