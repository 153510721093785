
import { reactive, toRefs, defineComponent} from "vue";

export default defineComponent({
  setup(props:any, context) {
    const state = reactive({
      count: 0,
    });
    const selectLable = (nowIndex: number) => {
      context.emit("selectLable", nowIndex);
    };
    return {
      ...toRefs(state),
      selectLable,
    };
  },
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
    nowIndex: {
      type: Number,
      default: 1,
    },
    pic_cs: {
      type: Array,
      default: () => [],
    },
  },
});
