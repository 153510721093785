
import { defineComponent, inject } from "vue";

export default defineComponent({
  name: "downinfo",
  components: {},
  props: ['goodsModel', 'DownUrl'],
  setup(props: any) {
    const staticUrl = inject("staticUrl");
    let baseUrl = "https://img2.sjyuan.net.cn"
    let down = () => {
      console.log(props.DownUrl)
      //window.open(props.DownUrl)
      let rx = /^http?/i
      if (rx.test(props.DownUrl)) {
        window.open(props.DownUrl)
      } else {
        window.open(baseUrl + props.DownUrl)
      }
    }
    return { down };
  },
});
