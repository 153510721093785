
import { defineComponent } from "vue"
import { useRoute, useRouter } from "vue-router"
export default defineComponent({
	name: "free-goods-item",
	props: {
		marginTo: {
			type: Number,
			default: 0,
		},
		marginBottom: {
			type: Number,
			default: 0,
		},
		goodsinfo: {
			type: Object,
			default: () => ({}),
		},
	},
	setup() {
		let router = useRouter()
		let goto = (id: number) => {
			const { href } = router.resolve({
				name: "free-goods",
				params: {
					id: id,
				},
			})
			window.open(href, "_blank")
		}
		return {
			goto,
		}
	},
})
