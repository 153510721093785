
import { defineComponent } from "vue"
import CurrentAddress from "@/components/common/CurrentAddress.vue"
import HomeHead from "@/components/HomeHead.vue"
import HtmlFooter from "@/components/HtmlFooter.vue"
export default defineComponent({
  components: { HomeHead, HtmlFooter, CurrentAddress },
  setup() {
    return {}
  },
})
