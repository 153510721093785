import { OrderGoodsType } from "@/utils/apitype/goodsType"

interface historyType {
  ctime: number
  explain: string //退款说明
  id: number
  title: string
}

interface postSaleType {
  id: number
  ctime: number
  explain: string //退款说明
  sales_type: number
  service_type: number
  money: number
  reason: string
  pics: string
  status: number
  utime: number
  ftime: number
  rtime: number
  signed: number
  sales_status: number
  pay_type?:number
}

class PostSale {
  historyList: Array<historyType> = []
  goodsList: Array<OrderGoodsType> = []
  info: postSaleType = {
    id: 0,
    ctime: 0,
    explain: "",
    sales_type: 0,
    service_type: 0,
    money: 0,
    reason: "",
    pics: "",
    status: 0,
    utime: 0,
    ftime: 0,
    rtime: 0,
    signed: 0,
    sales_status: 0,
    pay_type:1
  }
}

export { PostSale, postSaleType }
