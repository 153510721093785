import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f0a75a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "all" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.getInformation.data.id)
      ? (_openBlock(), _createBlock(_component_el_tabs, {
          key: 0,
          modelValue: _ctx.activeName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event)),
          class: "demo-tabs",
          onTabClick: _ctx.handleClick
        }, {
          default: _withCtx(() => [
            (_ctx.getInformation.data.pid == 0)
              ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                  key: 0,
                  label: "齿轮币充值",
                  name: "first"
                }))
              : _createCommentVNode("", true),
            (_ctx.getInformation.data.pid == 0)
              ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                  key: 1,
                  label: "提现",
                  name: "second"
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_el_tab_pane, {
              label: "交易记录",
              name: "third"
            })
          ]),
          _: 1
        }, 8, ["modelValue", "onTabClick"]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tabs.show), {
      onChecked: _ctx.checked,
      ref: "Son",
      onGorecord: _ctx.gorecord,
      onClick: _ctx.gorecord,
      getInformation: _ctx.getInformation
    }, null, 40, ["onChecked", "onGorecord", "onClick", "getInformation"]))
  ]))
}