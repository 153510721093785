
import { ref, defineComponent, onMounted, reactive, inject, watch } from "vue";
import PageShow from "@/components/common/PageShow.vue";
import { useRoute, useRouter } from "vue-router";
import { userApi } from "@/service/api";
import lack from "./component/fansLack.vue";
import { userStore } from "@/stores/userStore";
export default defineComponent({
  components: {
    PageShow,
    lack,
  },
  setup() {
    let firm = ref(true);
    let attention = ref(true);
    let handleCurrentChange = (val: number) => {
      fanslist.pageIndex = val;
    };
    // 一共多少条
    const total = ref(0);
    // 一页多少
    let pageSize = ref(20);

    interface result {
      data: any;
    }
    let getFansList: result = reactive({
      data: {},
    });
    // 获取粉丝列表
    let userId = useRoute().params.userid;
    let fanslist = reactive({
      follow: userId,
      pageIndex: 1,
      pageSize,
    });
    let getFanslists = () => {
      userApi.userFans(fanslist).then((res: any) => {
        getFansList.data = res.datas;
        total.value = res.datas.total;
        pageSize.value = res.datas.pageSize;
        console.log(getFansList.data.list);
      });
    };

    //关注,取消关注
    let userinfot = userStore();
    let followId = reactive({
      follow: "",
      fan: "",
    });

    let dofollow = (index: number, val: number) => {
      //被关注的id
      followId.follow = getFansList.data.list[index].id;
      // 关注的id
      followId.fan = userId.toString();
      console.log(followId.follow, followId.fan);

      //console.log(followId.fan);
      userApi.userFollow(followId).then((res: any) => {
        getFanslists();
      });
    };
    // 处理返回的图片地址
    let getImgUrl = inject(
      "getImgUrl",
      (index: string) => {
        return "";
      },
      false
    );

    interface result {
      data: any;
    }
    let getInformation: result = reactive({
      data: {},
    });

    let route = useRoute();
    let router = useRouter();
    watch(route, (newvalue, oldvalue) => {
      //console.log(newvalue, oldvalue);
      router.go(0);
    });

    // let getuserInformation = () => {
    //   userApi.getInformation().then((res: any) => {
    //     if (res.errno == 0) {
    //       getInformation.data = res.datas;
    //     }
    //   });
    // };

    let goUser = (index: number) => {
      //console.log(getFansList.data.list[index].id);

      userApi
        .getInformation({ uid: getFansList.data.list[index].id })
        .then((res: any) => {
          if (res.errno == 0) {
            //console.log(res);
            if (res.datas.pid == 0) {
              router.push({
                name: "personalcenter",
                params: { userid: res.datas.id },
              });
            }
            if (res.datas.pid != 0) {
              router.push({
                name: "personalcenter",
                params: { userid: res.datas.pid },
              });
            }
          }
        });
    };
    onMounted(() => {
      getFanslists();
    });
    return {
      firm,
      attention,
      handleCurrentChange,
      total,
      getFanslists,
      fanslist,
      getFansList,
      getImgUrl,
      dofollow,
      pageSize,
      goUser,
    };
  },
});
