
import { parts } from "@/service/api"
import { defineComponent, reactive, ref } from "vue"
import { getDeliveryStatus } from "@/utils/public"

export default defineComponent({
  components: {},
  name: "orderCouier",
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any) {
    //快递进度列表
    let deliveryList = reactive({
      data: {} as any,
    })
    //错误信息
    let errorMsg = ref("")
    let deliveryStatus = ref("")
    parts
      .getExpressInfo(
        props.orderData.datas.expressname,
        props.orderData.datas.expressnumber
      )
      .then((res: any) => {
        
        if (res.errno == 0) {
          deliveryList.data = res.datas
          console.log(deliveryList.data,"kuaidi")
          deliveryStatus.value = getDeliveryStatus(
            deliveryList.data.deliverystatus
          )
        } else {
          errorMsg.value = res.msg
          // switch (res.status) {
          //   case 201:
          //     errorMsg.value = "快递单号错误"
          //     break
          //   case 203:
          //     errorMsg.value = "快递公司不存在"
          //     break
          //   case 204:
          //     errorMsg.value = "快递公司识别失败"
          //     break
          //   case 205:
          //     errorMsg.value = "没有信息"
          //     break
          //   case 207:
          //     errorMsg.value = "该单号被限制，错误单号"
          //     break
          //   default:
          //     errorMsg.value = res.msg
          // }
        }
      })
    return { deliveryList, errorMsg, deliveryStatus }
  },
})
