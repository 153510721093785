
import { defineComponent, reactive, onMounted, ref } from "vue";
import { userApi } from "@/service/api";

export default defineComponent({
  components: {},
  setup() {
    let blue = false;
    // 个人信息接口  判断是否是子账号
    interface result {
      data: any;
    }
    let getInformation: result = reactive({
      data: {},
    });
    let getuserInformation = () => {
      userApi.getInformation().then((res: any) => {
        getInformation.data = res.datas;
      });
    };

    // 判断是否有新消息
    let messageNumber = ref(0);
    let getUserMessage = () => {
      let messageArr: any = [];
      userApi.userMessage().then((res: any) => {
        for (let k in res.datas) {
          if (res.datas[k].status == 0) {
            messageArr.push(res.datas[k].id);
          }
        }
        messageNumber.value = messageArr.length;
      });
    };
    onMounted(() => {
      getuserInformation();
      getUserMessage();
    });

    return { blue, getInformation, getuserInformation, messageNumber };
  },
});
