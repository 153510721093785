
import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  watch,
  watchEffect,
} from "vue"
import type { FormInstance, FormRules } from "element-plus"
import { EluiChinaAreaDht } from "elui-china-area-dht"
import { userApi } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
const chinaData = new EluiChinaAreaDht.ChinaArea().chinaAreaflat

export default defineComponent({
  components: { EluiChinaAreaDht },
  emits: ["mclose"],

  props: {
    addressItem: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, context) {
    let address = reactive({
      data: {
        userid: 0,
        id: 0,
        province: "", //省
        city: "", //市
        area: "", //区
        area_code: "",
        city_code: "",
        province_code: "",
        address: "", //产品详细信息
        default: "", //是否默认
        contacts: "", //联系人
        phonenumber: "", //联系手机号
      },
    })
    let isShow = ref(false)
    interface userFormType {
      addr: Array<string>
    }
    let userForm: userFormType = reactive({
      addr: [],
    })

    //更新初始值
    let initAddress = () => {
      isShow.value = false
      if (props.addressItem.data.contacts) {
        address.data = Object.assign({}, address.data, props.addressItem.data)

        if (parseInt(address.data.area_code) > 0) {
          userForm.addr = [
            address.data.province_code,
            address.data.city_code,
            address.data.area_code,
          ]
        } else {
          userForm.addr = [address.data.province_code, address.data.city_code]
        }
      } else {
        address.data = {
          userid: 0,
          province: "", //省
          city: "", //市
          area: "", //区
          area_code: "",
          city_code: "",
          province_code: "",
          address: "", //产品详细信息
          default: "", //是否默认
          contacts: "", //联系人
          phonenumber: "", //联系手机号
          id: 0,
        }
        userForm.addr = []
      }
      nextTick(() => {
        isShow.value = true
      })
    }

    initAddress()

    watch(props, (newvalue, oldvalue) => {
      initAddress()
    })

    let close = () => {
      context.emit("mclose", 1)
    }

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return
      formEl.validate((valid) => {
        console.log("开始了")
        if (valid) {
          if (address.data.id < 1) {
            userApi.addAddress(address.data).then((res: any) => {
              if (res.errno == 0) {
                sjyAlert("添加成功", "success", close)
              } else {
                sjyAlert(res.errmsg, "error")
              }
            })
          } else {
            console.log(address.data.id, "开始了")
            userApi.updateAddress(address.data).then((res: any) => {
              if (res.errno == 0) {
                sjyAlert("修改成功", "success", close)
              } else {
                sjyAlert(res.errmsg, "error")
              }
            })
          }
        } else {
          console.log("error submit!")
          return false
        }
      })
      //console.log("submit!");
    }
    const validatePass = (rule: any, value: any, callback: any) => {
      if (userForm.addr.length == 0) {
        callback(new Error("请选择收货人地区"))
      } else {
        callback()
      }
    }
    const ruleFormRef = ref<FormInstance>()
    const rules = reactive<FormRules>({
      contacts: [
        {
          required: true,
          message: "请输入收货人姓名",
          trigger: "blur",
        },
        { min: 2, max: 5, message: "姓名长度为2-5字符", trigger: "blur" },
      ],
      phonenumber: [
        {
          required: true,
          message: "请输入收货人手机号",
          trigger: "blur",
        },
        {
          pattern: /^1[3456789]\d{9}$/,
          message: "手机号格式错误",
          trigger: "blur",
        },
      ],
      area: [{ validator: validatePass, trigger: "blur" }],
      address: [
        {
          required: true,
          message: "请输入收货的详细地址",
          trigger: "blur",
        },
      ],
    })

    let onChange = (e: any) => {
      console.log("我执行了")
      address.data.province = chinaData[e[0]].label
      address.data.city = chinaData[e[1]].label
      if (e.length > 2) {
        address.data.area = chinaData[e[2]].label
        address.data.area_code = chinaData[e[2]].value
      } else {
        address.data.area = ""
        address.data.area_code = ""
      }

      address.data.province_code = chinaData[e[0]].value
      address.data.city_code = chinaData[e[1]].value

      // userForm.addr = [
      //   address.data.province_code,
      //   address.data.city_code,
      //   address.data.area_code,
      // ]
    }
    onMounted(() => {
      nextTick(() => {
        isShow.value = true
        console.log(props.addressItem.data.contacts, "aa")
      })
    })

    return {
      close,
      address,
      onSubmit,
      rules,
      onChange,
      userForm,
      ruleFormRef,
      isShow,
    }
  },
})
