<template>
  <div class="box">
    <div class="demo">
      <swiper
        :slides-per-view="5"
        :space-between="50"
        :loop=true
        :slidesPerGroup="5"
        :modules="modules"
        :navigation="navigations"
        @swiper="onSwiper"
        @slideChange="onSlideChange">
        <!-- v3文档地址 https://swiperjs.com/vue   -->
        <swiper-slide><img src="/static/temp/1.jpg" /></swiper-slide>
        <swiper-slide><img src="/static/temp/1.jpg" /></swiper-slide>
        <swiper-slide><img src="/static/temp/1.jpg" /></swiper-slide>
        <swiper-slide><img src="/static/temp/1.jpg" /></swiper-slide>
        <swiper-slide><img src="/static/temp/1.jpg" /></swiper-slide>
        <swiper-slide><img src="/static/temp/1.jpg" /></swiper-slide>
        <swiper-slide><img src="/static/temp/1.jpg" /></swiper-slide>
        <swiper-slide><img src="/static/temp/1.jpg" /></swiper-slide>
        <swiper-slide><img src="/static/temp/1.jpg" /></swiper-slide>
      </swiper>
    </div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import { Swiper, SwiperSlide } from "swiper/vue"
import { Navigation } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
export default defineComponent({
  name: "DemoSwiper",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper)
    }
    const onSlideChange = () => {
      console.log("slide change")
    }
    let navigations = {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation],
      navigations,
    }
  },
})
</script>

<style lang="scss" scoped>
.box {
  position: relative;
  width: 100%;
  .swiper-button-prev{
    &::after{
      content: " ";
    }
  }
  .demo {
    width: 80%;
    margin: 0 auto;
  }
}
</style>
