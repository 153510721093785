import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05d98696"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "contents class-list"
}
const _hoisted_2 = { class: "contents-left" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "contents attr-list" }
const _hoisted_5 = { class: "attr-list-item-left" }
const _hoisted_6 = { class: "attr-list-item-right" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.goodsAttr.datas.length>0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodsAttr.datas, (classInfo, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: _normalizeClass({ active: index == _ctx.nowIndex }),
                  onClick: ($event: any) => (_ctx.selectClass(index))
                }, _toDisplayString(classInfo.name), 11, _hoisted_3))
              }), 128))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodsAttr.datas[_ctx.nowIndex].attr, (attr, indexs) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "attr-list-item",
          key: indexs
        }, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(attr.name) + ":", 1),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attr.options, (option, indexo) => {
              return (_openBlock(), _createElementBlock("span", {
                key: indexo,
                onClick: ($event: any) => (_ctx.selectAttr(indexs, indexo)),
                class: _normalizeClass({ 'active-span': option.status == 1 })
              }, _toDisplayString(option.name), 11, _hoisted_7))
            }), 128))
          ])
        ]))
      }), 128))
    ])
  ], 64))
}