
import { reactive, ref, defineComponent, onMounted } from "vue"
import type { FormInstance, FormRules } from "element-plus"
import { userApi } from "@/service/api"
import InvoiceDialog from "@/components/common/Dialog/InvoiceDialog.vue"
import { sjyAlert } from "@/utils/alert"
import { useRouter } from "vue-router"
export default defineComponent({
	components: { InvoiceDialog },
	emits: ["checked"],
	setup(props, context) {
		interface result {
			data: any
		}
		let radio2 = ref("1")

		let router = useRouter()
		let torecord = (type: string = "") => {
			context.emit("checked")
			// router.push({
			//   name: "myaccount",
			//   params: {
			//     type: type,
			//   },
			// });
		}

		// 弹窗
		let dialogVisible = ref(false)

		let setDialogVisible = () => {
			sonRef.value?.setVisible(true)
			//console.log();
		}
		const sonRef = ref<InstanceType<typeof InvoiceDialog>>()
		// 关闭弹框
		let mclose = () => {
			dialogVisible.value = false
			sonRef.value?.setVisible(dialogVisible.value)
		}

		// 提现通知弹窗
		let dialogVisibles = ref(false)

		let setDialogVisibles = () => {
			sonRefs.value?.setVisible(true)
			console.log("aaa")
		}
		const sonRefs = ref<InstanceType<typeof InvoiceDialog>>()
		// 关闭弹框
		let mcloses = () => {
			dialogVisibles.value = false
			sonRefs.value?.setVisible(dialogVisibles.value)
		}

		const ruleFormRef = ref<FormInstance>()

		let checkAge = (rule: any, value: any, callback: any) => {
			const boolean = new RegExp("^[1-9][0-9]*$").test(value)
			if (!value) {
				return callback(new Error("提现数额不能为空"))
			}
			setTimeout(() => {
				if (!boolean) {
					callback(new Error("提现数额应为100-2000之间的整数"))
				} else {
					if (value < 100 || value > 2000) {
						callback(new Error("提现数额应为100-2000之间的整数"))
					} else {
						callback()
					}
				}
			}, 200)
		}

		let checkAges = (val: number) => {
			if (val < 100) {
				sjyAlert("提现金额必须大于100", "error")
			} else if (val > 2000) {
				sjyAlert("提现金额最大2000", "error")
				moneyinput.money = 2000
			}
		}

		const rules = reactive<FormRules>({
			money: [{ validator: checkAge, trigger: "blur" }],
		})

		let moneyinput = reactive({
			money: 0,
		})
		const submitForm = async (formEl: FormInstance | undefined) => {
			if (!formEl) return
			await formEl.validate((valid, fields) => {
				if (valid) {
					userApi.withdrawMoney(moneyinput).then((res: any) => {
						console.log(res)
						if (res.errno == 0) {
							sjyAlert("已发起提现请求", "success")
						} else if (res.errno == 91111) {
							sonRefs.value?.setVisible(true)
						} else {
							sjyAlert(res.errmsg, "error")
						}
					})
				} else {
					console.log("error submit!", fields)
				}
			})
		}

		//获取用户信息 拿 可提现余额
		let getInformation: result = reactive({
			data: {},
		})

		let getuserInformation = () => {
			userApi.getInformation().then((res: any) => {
				getInformation.data = res.datas
				//console.log(res);
			})
		}

		onMounted(() => {
			getuserInformation()
		})

		return {
			radio2,
			torecord,
			moneyinput,
			dialogVisible,
			mclose,
			sonRef,
			setDialogVisible,
			setDialogVisibles,
			sonRefs,
			mcloses,
			ruleFormRef,
			rules,
			submitForm,
			checkAge,
			getuserInformation,
			getInformation,
			dialogVisibles,
			checkAges,
		}
	},
})
