
import { defineComponent } from "vue";
import Youhuiquan from "@/components/order/Youhuiquan.vue";

export default defineComponent({
  components: { Youhuiquan },
  props: {
    couponList: {
      type: Object,
      default: () => ({}),
    },
    couponsIndex:{
      type: Number,
      default: 0
    }
  },
  setup() {

    return {};
  },
});
