
import { reactive, defineComponent, ref, onMounted, watch } from "vue";
import { userApi } from "@/service/api";
import type { FormInstance, FormRules } from "element-plus";
import { sjyAlert } from "@/utils/alert";
import { userStore } from "@/stores/userStore";

export default defineComponent({
  components: {},
  emits: ["goUserForm", "changeUser"],
  setup(props: any, context) {
    const informationForm = reactive({
      // 用户名input框
      username: "",
      // 姓名input框
      name: "",
      // 公司名
      company: "",
      //职业
      vocation: "",
      // 常用软件选择框
      type: [],
      software: "",
    });

    const ruleFormRef = ref<FormInstance>();
    const rules = reactive<FormRules>({
      company: [
        {
          min: 0,
          max: 50,
          message: "公司名的长度不能超过50",
          trigger: "blur",
        },
      ],
      name: [
        { min: 2, max: 20, message: "姓名的长度应为1-20位", trigger: "blur" },
      ],
    });

    let userinfot = userStore();

    /**
     * 更新vuex
     */
    let updateUserInfo = () => {
      userinfot.updateUserInfo(informationForm);
    };
    interface result {
      data: any;
    }
    let getInformation: result = reactive({
      data: {},
    });

    let submitInformation = () => {
      informationForm.software = informationForm.type.join(",");
      userApi.sunmitInformation(informationForm).then((res: any) => {
        //console.log(res,"用户信息");
        //console.log(informationForm);
        if (res.errno == 0) {
          // setUserInfo(userInfo: any){}
          updateUserInfo();

          sjyAlert("提交成功", "success");
          // getInformation.data = res.datas;
          context.emit("goUserForm", "changeUser");
        } else {
          sjyAlert(res.errmsg, "error");
        }
      });
    };

    // 个人信息接口
    let getuserInformation = () => {
      userApi.getInformation().then((res: any) => {
        getInformation.data = res.datas;
      });
    };

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          submitInformation();
        } else {
          // console.log("error submit!", fields);
        }
      });
    };

    let checkboxshow = (soft: string) => {
      //获取选择的软件
      //console.log(userinfot.software);
      if (!userinfot.software) {
        return false;
      } else {
        let fanhuiarray = userinfot.software.split(",");
        var a = fanhuiarray.indexOf(soft);
        if (a > -1) {
          return true;
        } else {
          return false;
        }
      }
    };

    // watch(userinfot,()=>{
    //   console.log("变化了呢");

    //   informationForm.username = userinfot.username;
    //   informationForm.name = userinfot.name;
    //   informationForm.company = userinfot.company;
    //   informationForm.vocation = userinfot.vocation;
    // })

    onMounted(() => {
      getuserInformation();
      informationForm.username = userinfot.username;
      informationForm.name = userinfot.name;
      informationForm.company = userinfot.company;
      informationForm.vocation = userinfot.vocation;
    });

    return {
      informationForm,
      submitInformation,
      rules,
      ruleFormRef,
      submitForm,
      userinfot,
      checkboxshow,
      getInformation,
      getuserInformation,
    };
  },
});
