
import { showPrice } from "@/utils/public"

import { useRouter } from "vue-router"
import { defineComponent, ref, inject, computed } from "vue"
import { getVipInfo } from "@/utils/public"
export default defineComponent({
	name: "project goodsinfo",
	components: {},
	props: {
		goods: {
			type: Object,
			default: () => ({}),
		},
	},
	setup(props: any) {
		let vipinfo = getVipInfo()

		const staticUrl = inject("staticUrl")
		let router = useRouter()
		let goodsImg = ref("/static/temp/goods.jpg")
		let changeUrl = (val: any) => {
			if (val == undefined) {
				console.log(props.goods)
			} else {
				if (val.startsWith("https")) {
					return val
				} else {
					return staticUrl + val
				}
			}
		}
		let goto = (uid: number) => {
			router.push({
				name: "personalcenter",
				params: {
					userid: uid,
				},
			})
			return false
		}
		let gotoGoods = (goodsid: number) => {
			const { href } = router.resolve({
				name: "productDetail",
				params: {
					id: goodsid,
				},
			})
			window.open(href, "_blank")
			return false
		}

		let getPrice = () => {
			if (props.goods.activity_price && props.goods.activity_price > 0) {
				return Math.round(props.goods.activity_price)
			} else {
				if (vipinfo.type > 0) {
					return Math.round(props.goods.price * 0.8)
				} else {
					return Math.round(props.goods.price)
				}
			}
		}
		let getPriceType = () => {
			if (props.goods.activity_price && props.goods.activity_price > 0) {
				return 2
			} else {
				if (vipinfo.type > 0) {
					return 1
				} else {
					return 0
				}
			}
		}
		return {
			goodsImg,
			staticUrl,
			changeUrl,
			goto,
			gotoGoods,
			vipinfo,
			getPrice,
			getPriceType,
			showPrice,
		}
	},
})
