
import { defineComponent, ref, inject, computed } from "vue"
export default defineComponent({
	name: "project goodsinfo",
	components: {},
	props: {
		goods: {
			type: Object,
			default: () => ({}),
		},
		showBorder: {
			type: Boolean,
			default: false,
		},
	},
	setup(props: any) {
		const staticUrl = inject("staticUrl")
		let goodsImg = ref("/static/temp/goods.jpg")
		let changeUrl = (val: any) => {
			if (val.startsWith("https")) {
				return val
			} else {
				return staticUrl + val
			}
		}
		//判断是否是折扣
		let isActivity = () => {
			if (props.goods.activity_price && props.goods.activity_price > 0) {
				return true
			} else {
				return false
			}
		}
		let getZheKou = () => {
			if (isActivity()) {
				return (
					Math.round((props.goods.activity_price / props.goods.price) * 100) /
					10
				)
			}
		}
		return {
			goodsImg,
			staticUrl,
			changeUrl,
			isActivity,
			getZheKou,
		}
	},
})
