
import { reactive, defineComponent, ref, onMounted } from "vue";
import { userApi } from "@/service/api";

export default defineComponent({
  emits: ["changeInformation"],
  setup(props: any, context) {
    const selfverifyForm = reactive({
      name: "",
      id: "",
      bool: true,
      idCard: "",
    });

    interface result {
      data: any;
    }

    let getInformation: result = reactive({
      data: {},
    });

    let getuserInformation = () => {
      userApi.getInformation().then((res: any) => {
        getInformation.data = res.datas;
        //console.log(getInformation.data);
        // console.log(res);
      });
    };
    let show = ref(true);
    const hideNumber = () => {
      show.value = !show.value;
    };

    let changeInformation = () => {
      context.emit("changeInformation");
    };
    onMounted(() => {
      getuserInformation();
    });
    return {
      selfverifyForm,
      hideNumber,
      getuserInformation,
      getInformation,
      show,
      changeInformation,
    };
  },
});
