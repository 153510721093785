
import { defineComponent, onMounted, reactive, ref } from "vue";
export default defineComponent({
  components: {},
  emits: ["setDialogVisible()"],
  setup(props, context) {
    let address = () => {
      context.emit("setDialogVisible()");
    };
    return {
      address,
    };
  },
});
