
import { defineComponent } from "vue"

export default defineComponent({
  components: {},
  props: {
    orderInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {}
  },
})
