
import { userStore } from "@/stores/userStore"

import { orderApi, userApi } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
import { computed, defineComponent, inject, reactive, ref, watch } from "vue"

export default defineComponent({
  components: {},
  props: {
    creatOrderInfo: {
      type: Object,
      default: () => ({}),
    },
    wrongtimes:{
      type: Number,
      default: 0
    }
  },
  setup(props: any) {

    const formInline = reactive({
      password: "",
      region: "",
    })

    let userInfot = userStore()

    let successPay = inject("successPay", (params: any) => {}, false)

    let buttonText = ref("立即付款")
    let showPwd = ref(false)
    const onSubmit = () => {
      if (
        Number(props.creatOrderInfo.data.gear) -
          Number(props.creatOrderInfo.data.price) <
        0
      ) {
        //去充值
        //console.log("去充值");
        window.open("/user/myaccount")
      } else {
        //支付订单
        let params = {
          id: props.creatOrderInfo.data.id,
          pay_password: formInline.password,
        }
        
        successPay(params)
      }
    }
    // let wrongTimesShow = computed(() => {
    //     return props.wrongtimes==6
    // })
    let changeShowPwd = () => {
      showPwd.value = !showPwd.value
    }

    let errormsg = ref("")
    //子账户不显示去充值
    let disabledButtom = ref(false)

    if (
      Number(props.creatOrderInfo.data.gear) -
        Number(props.creatOrderInfo.data.price) <
      0
    ) {
      errormsg.value = "您的账户余额不足!"
      buttonText.value = "去充值"
      if(userInfot.pid>0){
        disabledButtom.value=true
      }
    }


    return {
      formInline,
      onSubmit,
      buttonText,
      changeShowPwd,
      showPwd,
      errormsg,
      userInfot,
      disabledButtom
      // wrongTimesShow
    }
  },
})
