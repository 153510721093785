
import { useRouter, useRoute, RouteRecordName } from "vue-router"
import { computed, defineComponent, ref } from "vue"

export default defineComponent({
  components: {},
  emits: ["handleCurrentChange"],
  props: {
    total: {
      type: Number,
      default: 10,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    pageIndex: {
      type: Number,
      default: 1,
    },
  },
  setup(props: any, context) {
    let handleCurrentChange = (val: number) => {
      context.emit("handleCurrentChange", val)
    }

    return {
      handleCurrentChange,
    }
  },
})
