
import { ref, defineComponent, onMounted, reactive, inject, watch } from "vue";
import PageShow from "@/components/common/PageShow.vue";
import { useRoute, useRouter } from "vue-router";
import { userApi } from "@/service/api";
import { userStore } from "@/stores/userStore";
import Lack from "./component/attenLack.vue";
export default defineComponent({
  emits: ["transmitData"],
  components: { PageShow, Lack },
  setup(props, context) {
    let attention = ref(true);
    const total = ref(0);
    // 一页多少
    let pageSize = ref(10);
    let handleCurrentChange = (val: number) => {
      fanslist.pageIndex = val;
    };

    //  存放关注的用户的信息
    interface result {
      data: any;
    }
    let getInformation: result = reactive({
      data: {},
    });

    // 获取关注列表
    let userId = useRoute().params.userid;
    let fanslist = reactive({
      fan: userId,
      pageIndex: 1,
      pageSize,
    });
    let getFanslist = () => {
      userApi.userFans(fanslist).then((res: any) => {
        console.log(res);

        if (res.errno == 0) {
          for (let i = 0; i < res.datas.list.length; i++) {
            res.datas.list[i].isselect = true;
          }
          getInformation.data = res.datas;
          total.value = res.datas.total;
          pageSize.value = res.datas.pageSize;
        }
      });
    };
    let following = ref(false);
    // 关注,取消关注
    let userinfot = userStore();
    let followId = reactive({
      follow: "",
      fan: "",
    });

    let show = ref(false);
    let dofollow = (index: number, val: number) => {
      if (val == 0) {
        getInformation.data.list[index].isselect = true;
      } else {
        getInformation.data.list[index].isselect = false;
      }
      // 被关注的人
      followId.follow = getInformation.data.list[index].id;
      //关注的人 自己的
      followId.fan = userinfot.id.toString();
      //console.log("自己的", followId.fan, "被关注的", followId.follow);
      userApi.userFollow(followId).then((res: any) => {});
    };

    // 处理返回的图片地址
    let getImgUrl = inject(
      "getImgUrl",
      (index: string) => {
        return "";
      },
      false
    );
    let route = useRoute();
    let router = useRouter();
    watch(route, (newvalue, oldvalue) => {
      //console.log(newvalue, oldvalue);
      router.go(0);
    });

    // 把头像信息传递给父组件
    let transmitData = (photos: any) => {
      context.emit("transmitData", photos);
    };

    onMounted(() => {
      getFanslist();
    });
    return {
      attention,
      total,
      handleCurrentChange,
      dofollow,
      followId,
      userinfot,
      getInformation,
      following,
      getImgUrl,
      show,
      transmitData,
      fanslist,
    };
  },
});
