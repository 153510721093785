import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04924e42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.activeName,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeName) = $event)),
      class: "demo-tabs",
      onTabClick: _ctx.handleClick
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "上传作品",
          name: "first"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tabs.currentView), {
              onPayStatus: _ctx.payStatus,
              onReturnSubmit: _ctx.returnSubmit,
              onChangeNumber: _ctx.changeNumber
            }, null, 40, ["onPayStatus", "onReturnSubmit", "onChangeNumber"]))
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "上传历史",
          name: "second"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tabs.UploadRecord), {
              onChangeState: _ctx.changeState,
              onUpWork: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goUpWork())),
              onShowsss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showsss())),
              work: _ctx.work.data,
              changeEd: _ctx.changeEd
            }, null, 40, ["onChangeState", "work", "changeEd"]))
          ]),
          _: 1
        }),
        (_ctx.altershow)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 0,
              label: "修改作品",
              name: "thirdly"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tabs.currentView), {
                  onPayStatus: _ctx.payStatus,
                  onReturnSubmit: _ctx.returnSubmit
                }, null, 40, ["onPayStatus", "onReturnSubmit"]))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "onTabClick"])
  ]))
}