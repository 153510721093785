
import { ref, reactive, defineComponent, onMounted, watch, computed } from "vue"
import { getVipInfo } from "@/utils/public"
import { userStore } from "@/stores/userStore"
import { userApi } from "@/service/api"

export default defineComponent({
	components: {},
	name: "userinformation",
	props: {
		getInformation: {
			type: Object,
			default: () => ({}),
		},
	},
	setup(props: any) {
		//console.log(props);
		//console.log("我是子页面");
		let userInfo = userStore()
		let isVip = userInfo.isVip
		let user: any = reactive({ data: {} })

		user.data = props.getInformation

		//console.log(user.data.vip_id, "123");

		watch(props, () => {
			user.data = props.getInformation
		})

		const vipinfo = computed(() => {
			return getVipInfo({
				vipInfo: { vipid: user.data.vip_id, vipType: user.data.vip_type },
			})
		})
		// let vipinfo = getVipInfo({
		//   vipInfo: { vipid: user.data.vip_id, vipType: user.data.vip_type },
		// });
		let userinfot = userStore()
		interface result {
			data: any
		}

		let getInformations: result = reactive({
			data: {},
		})

		// let getuserInformation = () => {
		//   userApi.getInformation().then((res: any) => {
		//     getInformations.data = res.datas;
		//     //console.log(res);
		//   });
		// };

		interface result {
			data: any
		}

		onMounted(() => {
			// getuserInformation();
		})
		return {
			user,
			vipinfo,
			userinfot,
			getInformations,
			isVip,
		}
	},
})
