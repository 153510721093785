import { ref, unref, watch } from "vue"
import { OptionsType } from "./types"
import { sjyAlert } from "@/utils/alert"

let preRequestFn = (res: any) => {
  return res
}

export default function useList<ItemType extends Object, X = any>(
  listRequestFn: Function,
  options: OptionsType = {},
  resetOption: Function
) {
  const {
    preRequest = preRequestFn,
    filterOption = ref(),
    transformFn = undefined,
  } = options
  // 加载态
  const loading = ref(false)
  // 当前页
  const curPage = ref(1)
  // 总
  const total = ref(0)
  // 分页大小
  const pageSize = ref(10)
  // 数据
  const list = ref<ItemType[]>([])

  const reset = () => {
    filterOption.value = resetOption()
    loadData()
  }

  const loadData = (page = curPage.value, size = pageSize.value) => {
    // 兼容page可能是event
    if (typeof page === "object") {
      page = unref(curPage)
    }
    loading.value = true
    let where = preRequest?.(filterOption.value)
    listRequestFn(size, page, where).then((res: any) => {
      if (res.errno == 0) {
        const transformResult = transformFn ? transformFn(res) : res
        total.value = transformResult.datas.total
        list.value = transformResult.datas.list
        options?.exportSuccess?.(res)
      } else {
        sjyAlert(res.errmsg, "error", options?.exportSuccess?.(res))
      }
      loading.value = false
    })
  }

  // 监听分页数据改变
  watch([curPage, pageSize], () => {
    loadData(curPage.value)
  })

  return {
    loading,
    curPage,
    total,
    filterOption,
    reset,
    pageSize,
    loadData,
    list,
  }
}
