
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props:{
    creatOrderInfo:{
      type: Object,
      default: () => ({})
    }
  },
  setup() {

    return {};
  },
});
