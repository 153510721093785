
import { reactive, defineComponent, ref, onMounted, inject } from "vue"
import dateFormat from "@/utils/dateFormat"
import { goodsApi } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
import { updateCartTotal } from "@/utils/public"
import { useRoute, useRouter } from "vue-router"
export default defineComponent({
	components: {},
	emits: ["delfoot", "addstore"],
	props: {
		goodslist: {
			type: Object,
			default: () => ({}),
		},
	},

	setup(props: any, context) {
		let step: any = inject("step")

		let leaving = (e: MouseEvent) => {
			if (step != 2 && step) {
				;(e.target as HTMLElement).classList.add("show")
			}
		}
		let leaving1 = (e: MouseEvent) => {
			if (step != 2 && step) {
				;(e.target as HTMLElement).classList.remove("show")
			}
		}

		// 点击展示删除框
		let del = (index: any, indexs: number) => {
			//console.log(props.goodslist[index][indexs]);
			props.goodslist[index][indexs].isselect = true
		}
		// 点击取消隐藏删除框
		let cancel = (index: any, indexs: number) => {
			//console.log(props.goodslist[index][indexs]);
			props.goodslist[index][indexs].isselect = false
		}
		// 点击确定删除 子传父传过去id 删除
		let delfoot = (index: any, indexs: number) => {
			// console.log(props.goodslist[index][indexs]);
			let ids = props.goodslist[index][indexs].id
			context.emit("delfoot", ids)
		}

		// 处理返回的图片地址
		let getImgUrl = inject(
			"getImgUrl",
			(index: string) => {
				return ""
			},
			false
		)
		// 时间判断显示的是昨天还是今天 还是日期
		let now = dateFormat.dateFormat(new Date().getTime(), "YYYY-MM-dd")
		let timeTamp = dateFormat.dateStrChangeTimeTamp(now)
		let yesterday = dateFormat.dateFormat(
			timeTamp - 60 * 60 * 24 * 1000,
			"YYYY-MM-dd"
		)
		//console.log(yesterday);

		// 添加购物车
		let addcard = (index: string, indexs: number) => {
			console.log(props.goodslist[index][indexs].id)
			goodsApi
				.addCart(props.goodslist[index][indexs].id, true)
				.then((res: any) => {
					if (res.errno == 0) {
						updateCartTotal()
						sjyAlert("添加成功", "success")
					} else {
						sjyAlert(res.errmsg, "error")
					}
				})
		}

		//获取收藏列表

		//单个收藏
		let addstore = (index: any, indexs: number, val: number) => {
			// console.log(props.goodslist[index][indexs]);
			let product_id = props.goodslist[index][indexs].id
			let product_name = props.goodslist[index][indexs].names
			let cid = props.goodslist[index][indexs].cid
			context.emit("addstore", product_id, product_name, cid, val)
		}

		// 选择checkbox
		let chessCheck = (index: string, indexs: number) => {
			props.goodslist[index][indexs].isselect =
				!props.goodslist[index][indexs].isselect
		}
		let router = useRouter()
		let goGoods = (uid: any) => {
			if (step.value != 2) {
				router.push({
					name: "productDetail",
					params: {
						id: uid,
					},
				})
			}
		}

		onMounted(() => {})

		return {
			step,
			leaving,
			leaving1,
			del,
			delfoot,
			cancel,

			getImgUrl,
			now,
			timeTamp,
			yesterday,
			addstore,
			addcard,
			goGoods,
			chessCheck,
		}
	},
})
