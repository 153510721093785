
import { defineComponent, onMounted } from "vue"
import HtmlFooter from "../../components/HtmlFooter.vue"
import HomeHead from "../../components/HomeHead.vue"
import Aside from "./layout/information/Aside.vue"
import SelfVerify from "./layout/selfverify/SelfVerifys.vue"
import UserMenu from "@/components/user/UserMenu.vue"
import Information from "@/components/user/Information.vue"
import CurrentAddress from "@/components/common/CurrentAddress.vue"
import { template } from "lodash"

export default defineComponent({
  components: {
    HtmlFooter,
    HomeHead,
    Aside,
    SelfVerify,
    UserMenu,
    Information,
    CurrentAddress,
  },
  setup() {
    let blue = false
    onMounted(() => {
      //boluo里是不是有这些信息
      //获取用户信息
    })
    return {
      blue,
    }
  },
})
