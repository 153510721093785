
import { reactive, defineComponent, ref, onMounted } from "vue";
import Submit from "./Submit.vue";
import InReview from "./InReview.vue";
import Verified from "./Verified.vue";
import Fail from "./Fail.vue";
import { userApi } from "@/service/api";

export default defineComponent({
  components: { Submit, InReview, Verified, Fail },
  setup() {
    interface result {
      data: any;
    }
    let page = ref("");
    const selfverifyForm = reactive({
      currentView: page,
    });

    const goback = () => {
      //console.log('aa');
      selfverifyForm.currentView = "Submit";
    };

    let changeInformation = () => {
      selfverifyForm.currentView = "Submit";
    };

    let getInformation: result = reactive({
      data: {},
    });

    let getuserInformation = () => {
      userApi.getInformation().then((res: any) => {
        getInformation.data = res.datas;
        console.log(getInformation.data);
        //   根据个人信息中的认证状态区分页面
        // console.log(getInformation.data.identification, "ab");
        if (
          getInformation.data.identification == "1" ||
          getInformation.data.identification == null
        ) {
          page.value = "Submit";
        }
        if (getInformation.data.identification == "2") {
          page.value = "InReview";
        }
        if (getInformation.data.identification == "3") {
          page.value = "Verified";
        }
        if (getInformation.data.identification == "4") {
          page.value = "Fail";
        }
      });
    };

    onMounted(() => {
      getuserInformation();
    });
    return {
      selfverifyForm,
      goback,
      changeInformation,
      page,
    };
  },
});
