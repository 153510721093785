
import { reactive, defineComponent, ref, onMounted } from "vue"
import { userStore } from "@/stores/userStore"
import { userApi, publicApi } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
import vueQr from "vue-qr/src/packages/vue-qr.vue"
import InvoiceDialog from "@/components/common/Dialog/InvoiceDialog.vue"
import { getVipInfo } from "@/utils/public"

export default defineComponent({
	components: { vueQr, InvoiceDialog },
	setup() {
		// 控制线下汇款提示出现与隐藏
		let show = ref(false)
		let hintShow = () => {
			show.value = !show.value
		}

		// 打开弹框
		let dialogVisible = ref(false)
		let setDialogVisible = () => {
			sonRef.value?.setVisible(true)
			//console.log();
		}
		const sonRef = ref<InstanceType<typeof InvoiceDialog>>()
		// 关闭弹框
		let mclose = () => {
			dialogVisible.value = false
			sonRef.value?.setVisible(dialogVisible.value)
		}

		// 去认证弹框
		// 打开弹框
		let dialogVisibles = ref(false)
		let setDialogVisibles = () => {
			sonRefs.value?.setVisible(true)
			//console.log();
		}
		const sonRefs = ref<InstanceType<typeof InvoiceDialog>>()
		// 关闭弹框
		let mcloses = () => {
			dialogVisibles.value = false
			sonRefs.value?.setVisible(dialogVisible.value)
		}
		// 处理二维码
		const qrCodeValue = ref("") //二维码内容
		//  点击付款方式进行付款
		let hintHide = (val: number) => {
			if (getInformation.data.company_id == null) {
				sonRefs.value?.setVisible(true)
			} else {
				show.value = false
				vipData.type = val
				//console.log(vipData);
				userApi.userPay(vipData).then((res: any) => {
					//console.log(res);
					if (res.errno == 0) {
						if (vipData.type == 1) {
							setDialogVisible()
							qrCodeValue.value = res.data.code_url
							// console.log(qrCodeValue);
						} else if (vipData.type == 2) {
							window.open(res.data)
						}
					} else {
						sjyAlert(res.errmsg, "error")
					}
				})
			}
		}
		//点击会员类型赋予边框
		let vip = ref("0")
		let vipShow = () => {
			vip.value = "0"
			vipData.vipId = 16
			if (vipinfo.type != 2) {
				vipData.total = 5000
			}
			if (vipinfo.type == 2) {
				vipData.total = 5000 * 0.98
			}
		}

		let vipShow1 = () => {
			vip.value = "1"
			vipData.vipId = 17
			if (vipinfo.type != 2) {
				vipData.total = 15000
			}
			if (vipinfo.type == 2) {
				vipData.total = 15000 * 0.98
			}
		}

		let vipShow2 = () => {
			vip.value = "2"
			vipData.vipId = 18
			if (vipinfo.type != 2) {
				vipData.total = 30000
			}
			if (vipinfo.type == 2) {
				vipData.total = 30000 * 0.98
			}
		}

		// 判断是否是会员
		let userInfo = userStore()
		let isVip = userInfo.isVip
		let vipinfo = getVipInfo()
		// 是否是子账号

		//console.log(vipinfo);

		let changeMoney = (vips: any) => {
			if (vipinfo.type == 2) {
				if (vips == "0") {
					return 5000 * 0.98
				}
				if (vips == "1") {
					return 15000 * 0.98
				}
				if (vips == "2") {
					return 30000 * 0.98
				}
			} else {
				if (vips == "0") {
					return 5000
				}
				if (vips == "1") {
					return 15000
				}
				if (vips == "2") {
					return 30000
				}
			}
		}

		const tableData = [
			{
				one: "购买设计方案",
				two: "可购买商城内所有设计方案并享",
			},
			{
				one: "下载设计方案",
				two: "主账号与子账号可下载所有已购设计方案",
			},
			{
				one: "充值",
				two: "由主账号充值,子账号不可充值,主账号与子账号共用一个齿轮币账户消费",
			},
			{
				one: "管理子账号",
				two: "主账号拥有子账号的管理权限,主账号可修改子账号的使用状态、创建和删除子账号",
			},
			{
				one: "优惠券",
				two: "子账号可以使用主账号的优惠券",
			},
			{
				one: "企业名片",
				two: "企业认证后,主账号与子账号均展示企业认证标识与企业名片",
			},
			{
				one: "上传作品",
				two: "子账号上传作品属于企业作品",
			},
			{
				one: "登录方式",
				two: "子账号登录时需选择【子账号登录】方可登录。子账号与用户其个人账号不能同时登录",
			},
		]

		//  发送到后端的购买数据
		let vipData = reactive({
			type: 0,
			orderType: 3,
			vipId: 0,
			total: 0,
			userid: userInfo.id,
			channel: 2,
		})
		// 对公支付手机号正则中间不展示

		let newTel =
			userInfo.mobile.substr(0, 3) + "****" + userInfo.mobile.substr(7)

		// 获取用户信息 是否企业认证 企业认证才能购买企业会员
		interface result {
			data: any
		}

		let getInformation: result = reactive({
			data: {},
		})

		let getuserInformation = () => {
			userApi.getInformation().then((res: any) => {
				getInformation.data = res.datas
			})
		}

		let count = ref(60)
		let isShow = ref(true)
		//let 秒后再获取
		let countShowText = ref("获取验证码")
		var times: any

		let countdown = () => {
			count.value = 60 //赋值60秒
			isShow.value = false

			getPhongCode()
			times = setInterval(() => {
				count.value = count.value - 1 //递减
				if (count.value <= 0) {
					// <=0 变成获取按钮
					isShow.value = true
					clearInterval(times)
				}
				countShowText.value = count.value + "秒后再获取"
			}, 1000) //1000毫秒后执行
		}

		let getPhongCode = () => {
			//这里应该验证一下手机号的正确性  TODO
			publicApi.bankCode().then((res: any) => {
				if (res.errno == 0) {
					sjyAlert("发送成功", "success")
				} else {
					isShow.value = true
					clearInterval(times)
					sjyAlert(res.errmsg, "error")
				}
			})
		}

		onMounted(() => {
			vipShow()
			getuserInformation()
		})
		return {
			tableData,
			show,
			hintShow,
			hintHide,
			vip,
			vipShow,
			vipShow1,
			vipShow2,
			changeMoney,
			isVip,
			vipData,
			userInfo,
			newTel,
			dialogVisible,
			sonRef,
			mclose,
			setDialogVisible,
			qrCodeValue,
			vipinfo,
			dialogVisibles,
			setDialogVisibles,
			sonRefs,
			mcloses,
			countdown,
			isShow,
			getPhongCode,
			countShowText,
			getInformation,
		}
	},
})
