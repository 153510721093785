
import { reactive, defineComponent, ref, onMounted } from "vue";
import WrittenInformation from "./WrittenInformation.vue";
import InReview from "./InReview.vue";
import PassVerify from "./PassVerify.vue";
import FailVerify from "./FailVerify.vue";
import { company, userApi } from "@/service/api";

export default defineComponent({
  components: {
    WrittenInformation,
    InReview,
    PassVerify,
    FailVerify,
  },
  setup() {
    const firmverifyForm = reactive({
      name: "",
      id: "",
      currentView: "WrittenInformation",
      status: "",
    });
    let page = ref("WrittenInformation");
    let verify = reactive({
      show: page,
    });

    let gonext = () => {
      verify.show = "InReview";
    };

    let goback = () => {
      verify.show = "WrittenInformation";
    };

    interface result {
      data: any;
    }

    let getInformation: result = reactive({
      data: {},
    });
    let getInformations: result = reactive({
      data: {},
    });
    // 个人信息接口
    let getuserInformation = () => {
      userApi.getInformation().then((res: any) => {
        getInformations.data = res.datas;
        if (getInformations.data.pid != 0) {
          page.value = "PassVerify";
        }
      });
    };
    // 企业认证详情
    let getcompany = () => {
      company.companyinformation().then((res: any) => {
        getInformation.data = res.datas;
        //   根据企业认证中的认证状态区分页面
        if (getInformation.data.status == "1") {
          page.value = "InReview";
        }
        if (getInformation.data.status == "2") {
          page.value = "PassVerify";
        }
        if (getInformation.data.status == "3") {
          page.value = "FailVerify";
        }
      });
    };

    let go = ref("1");

    onMounted(() => {
      getcompany();
      getuserInformation();
    });
    return {
      firmverifyForm,
      verify,
      goback,
      gonext,
      page,
      getInformations,
      go,
    };
  },
});
