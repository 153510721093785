<template>
  <div class="imgUpload">
    aaa
    <br />
    <div id="ossfile">你的浏览器不支持flash,Silverlight或者HTML5！</div>

    <div id="container">
      <a id="selectfiles" href="javascript:void(0);" class="btn">选择文件</a>
      <a id="postfiles" href="javascript:void(0);" class="btn">开始上传</a>
    </div>

    <pre id="console"></pre>
  </div>
</template>

<script>
import plupload from "plupload"
import { userApi } from "@/service/api"

var accessid = ""
var accesskey = ""
var host = ""
var policyBase64 = ""
var signature = ""
var callbackbody = ""
var filename = ""
var key = ""
var expire = 0
var g_object_name = ""
var g_object_name_type = "random_name"
var now = Date.parse(new Date()) / 1000
var timestamp = Date.parse(new Date()) / 1000

// function send_request() {
//   var xmlhttp = null
//   if (window.XMLHttpRequest) {
//     xmlhttp = new XMLHttpRequest()
//   } else if (window.ActiveXObject) {
//     xmlhttp = new ActiveXObject("Microsoft.XMLHTTP")
//   }

//   if (xmlhttp != null) {
//     // serverUrl是 用户获取 '签名和Policy' 等信息的应用服务器的URL，请将下面的IP和Port配置为您自己的真实信息。
//     // serverUrl = 'http://88.88.88.88:8888/aliyun-oss-appserver-php/php/get.php'
//     let serverUrl = "http://xxx/oss/up/"

//     xmlhttp.open("GET", serverUrl, false)
//     xmlhttp.send(null)
//     return xmlhttp.responseText
//   } else {
//     alert("Your browser does not support XMLHTTP.")
//   }
// }

function check_object_radio() {
  var tt = document.getElementsByName("myradio")
  for (var i = 0; i < tt.length; i++) {
    if (tt[i].checked) {
      g_object_name_type = tt[i].value
      break
    }
  }
}

function get_signature() {
  // 可以判断当前expire是否超过了当前时间， 如果超过了当前时间， 就重新取一下，3s 作为缓冲。
  now = timestamp = Date.parse(new Date()) / 1000
  if (expire < now + 3) {
    let body = send_request()
    var obj = eval("(" + body + ")")
    host = obj["host"]
    policyBase64 = obj["policy"]
    accessid = obj["accessid"]
    signature = obj["signature"]
    expire = parseInt(obj["expire"])
    callbackbody = obj["callback"]
    key = obj["dir"]
    return true
  }
  return false
}

function random_string(len) {
  len = len || 32
  var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"
  var maxPos = chars.length
  var pwd = ""
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

function get_suffix(filename) {
  let pos = filename.lastIndexOf(".")
  let suffix = ""
  if (pos != -1) {
    suffix = filename.substring(pos)
  }
  return suffix
}

function calculate_object_name(filename) {
  if (g_object_name_type == "local_name") {
    g_object_name += "${filename}"
  } else if (g_object_name_type == "random_name") {
    let suffix = get_suffix(filename)
    g_object_name = key + random_string(10) + suffix
  }
  return ""
}

function get_uploaded_object_name(filename) {
  if (g_object_name_type == "local_name") {
    tmp_name = g_object_name
    tmp_name = tmp_name.replace("${filename}", filename)
    return tmp_name
  } else if (g_object_name_type == "random_name") {
    return g_object_name
  }
}

function set_upload_param(up, filename, ret,file="") {
  // if (ret == false) {
  //   ret = get_signature()
  // }
  g_object_name = key
  if (filename != "") {
    let suffix = get_suffix(filename)
    calculate_object_name(filename)
  }
  //
  let date = new Date();

  
  let new_multipart_params = {
    key: g_object_name,
    policy: policyBase64,
    OSSAccessKeyId: accessid,
    success_action_status: "200", //让服务端返回200,不然，默认会返回204
    //callback: callbackbody,
    signature: signature,
    //file:file
  }
console.log(new_multipart_params);
  up.setOption({
    url: host,
    multipart_params: new_multipart_params,
  })

  up.start()
}

export default {
  model: {
    prop: "msg",
    event: "ee",
  },
  props: {
    msg: "",
  },
  data() {
    return {
      url: this.msg || "",
    }
  },
  created() {},
  mounted() {
    this.getOssSet()
  },
  methods: {
    getOssSet() {
      userApi.getOSSToken().then((res) => {
        host = res.data.host
        policyBase64 = res.data.policy
        accessid = res.data.accessid
        signature = res.data.signature
        expire = parseInt(res.data.expire)
        callbackbody = res.data.callback
        key = res.data.dir
        this.initPlUploader()
      })
    },



    /**
     * 初始化上传插件
     */
    initPlUploader() {
      var uploader = new plupload.Uploader({
        runtimes: "html5,flash,silverlight,html4",
        browse_button: "selectfiles",
        //multi_selection: false,
        // container: document.getElementById('container'),
        // flash_swf_url : 'lib/plupload-2.1.2/js/Moxie.swf',
        // silverlight_xap_url : 'lib/plupload-2.1.2/js/Moxie.xap',
        //url: "http://oss.aliyuncs.com",

        filters: {
          mime_types: [
            //只允许上传图片和zip文件
            { title: "Image files", extensions: "jpg,gif,png,bmp" },
            { title: "Zip files", extensions: "zip,rar,ipa" },
          ],
          max_file_size: "1000mb", //最大只能上传10mb的文件
          prevent_duplicates: true, //不允许选取重复文件
        },

        init: {
          PostInit: function () {
            document.getElementById("ossfile").innerHTML = ""
            document.getElementById("postfiles").onclick = function () {
              set_upload_param(uploader, "", false)
              return false
            }
          },

          FilesAdded: function (up, files) {
            plupload.each(files, function (file) {
              document.getElementById("ossfile").innerHTML +=
                '<div id="' +
                file.id +
                '">' +
                file.name +
                " (" +
                plupload.formatSize(file.size) +
                ")<b></b>" +
                '<div class="progress"><div class="progress-bar" style=" 0%"></div></div>' +
                "</div>"
            })
          },
          QueueChanged:function(up, files){
            //console.log("选择了文件")
            set_upload_param(uploader, "", false)
          },

          BeforeUpload: function (up, file) {
            check_object_radio()
            set_upload_param(up, file.name, true,file)
          },

          UploadProgress: function (up, file) {
            var d = document.getElementById(file.id)
            d.getElementsByTagName("b")[0].innerHTML =
              "<span>" + file.percent + "%</span>"
            var prog = d.getElementsByTagName("div")[0]
            var progBar = prog.getElementsByTagName("div")[0]
            progBar.style.width = 2 * file.percent + "px"
            progBar.setAttribute("aria-valuenow", file.percent)
          },

          FileUploaded: function (up, file, info) {
            if (info.status == 200) {
              console.log(info)
              document
                .getElementById(file.id)
                .getElementsByTagName("b")[0].innerHTML =
                "upload to oss success, object name:" +
                get_uploaded_object_name(file.name) +
                " 回调服务器返回的内容是:" +
                info.response
            } else if (info.status == 203) {
              document
                .getElementById(file.id)
                .getElementsByTagName("b")[0].innerHTML =
                "上传到OSS成功，但是oss访问用户设置的上传回调服务器失败，失败原因是:" +
                info.response
            } else {
              document
                .getElementById(file.id)
                .getElementsByTagName("b")[0].innerHTML = info.response
            }
          },

          Error: function (up, err) {
            if (err.code == -600) {
              document
                .getElementById("console")
                .appendChild(
                  document.createTextNode(
                    "选择的文件太大了,可以根据应用情况，在upload.js 设置一下上传的最大大小"
                  )
                )
            } else if (err.code == -601) {
              document
                .getElementById("console")
                .appendChild(
                  document.createTextNode(
                    "选择的文件后缀不对,可以根据应用情况，在upload.js进行设置可允许的上传文件类型"
                  )
                )
            } else if (err.code == -602) {
              document
                .getElementById("console")
                .appendChild(
                  document.createTextNode("这个文件已经上传过一遍了")
                )
            } else {
              document
                .getElementById("console")
                .appendChild(
                  document.createTextNode("Error xml:" + err.response)
                )
            }
          },
        },
      })
      uploader.init()
    },
  },
  watch: {},
}
</script>
