import axios from "../service";
import { userStore } from "@/stores/userStore"

let { get, post } = axios

const video = {
	attrList(where: any = {}) {
		return post("pc/class/attrs", where)
	},
	videoList(where: any = {}) {
		return post("/pc/video/list", where)

	},
	videoInfo(where: any = {}) {
		let userinfo = userStore()
		where.uid = userinfo.id
		return post("/pc/video/info", where)
	},
	homeVideoList() {
		return post("/pc/video/talks", {})

	},

}
export default video