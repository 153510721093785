
import { defineComponent, reactive, ref, onMounted, watch } from "vue"
import HomeHead from "@/components/HomeHead.vue"
import HtmlFooter from "@/components/HtmlFooter.vue"
import CurrentAddress from "@/components/common/CurrentAddress.vue"

import PartsList from "@/components/goods/project/PartsList.vue"

import PageShow from "@/components/common/PageShow.vue"
import VideoLine from "@/components/video/VideoSearchLine.vue"
import { goodsApi } from "@/service/api"
import { RouteRecordName, useRoute, useRouter } from "vue-router"
import FreeSearchLine from "@/components/goods/FreeSearchLine.vue"
import { parts } from "@/service/api"

export default defineComponent({
  components: {
    HomeHead,
    HtmlFooter,
    CurrentAddress,
    PageShow,
    VideoLine,
    FreeSearchLine,
    PartsList,
  },
  setup() {
    let total = ref(100)
    let loading = ref(true)
    let handleCurrentChange = (val: number) => {
      pageIndex.value = val
      pageShow.value.changePageIndex(pageIndex.value)
      updateUrl(pageIndex.value)
      getGoodsList()
    }

    const route = useRoute()

    let partsClass = reactive({
      data: {} as any,
    })

    const routeData = reactive({
      pid: parseInt(route.params.pid as string),
      sid: parseInt(route.params.sid as string),
    })

    let sClass = reactive({
      data: {} as any,
    })
    parts.partsAllClass().then((res: any) => {
      if (res.errno == 0) {
        partsClass.data = res.datas
        if (routeData.pid > 0) {
          //获取默认二级分类
          let index = partsClass.data.findIndex((item: any) => {
            if (item.id == routeData.pid) {
              return true
            }
          })
          sClass.data = partsClass.data[index].son
        } else {
          sClass.data = partsClass.data[0].son
          routeData.pid = partsClass.data[0].id
          routeData.sid = 0
        }
      }
    })
    let changClass = (index: number) => {
      routeData.pid = partsClass.data[index].id
      routeData.sid = 0
      sClass.data = partsClass.data[index].son
      change()
    }
    let changSClass = (iindex: number) => {
      if (iindex == 100) {
        routeData.sid = 0
      } else {
        let index = partsClass.data.findIndex((item: any) => {
          if (item.id == routeData.pid) {
            return true
          }
        })
        routeData.sid = partsClass.data[index].son[iindex].id
      }
      change()
    }

    let currentTab = ref("PartsList")
    let sortType = ref(1)
    let pageIndex = ref(1)

    const pageShow = ref<any>()

    if (route.params.page) {
      pageIndex.value = parseInt(route.params.page as string)
    }

    watch(route, () => {
      router.go(0)
    })
    // if (route.params.type) {
    //   //let curre = parseInt(route.params.type as string)
    //   currentTab.value = tabs[curre - 1]
    // }
    //console.log(pageIndex.value, "sdfasdfsdfsdfasdfasdfs")
    //获取参数
    interface goodsType {
      data: any
      errno: number
      errmsg: string
      total: number
      pageSize: number
    }
    let goodslit: goodsType = reactive({
      data: [],
      errno: 0,
      errmsg: "",
      total: 0,
      pageSize: 20,
    })

    let type = ref(1)
    let sv = ref(route.params.sv)

    let isShowPage = ref(false)

    let change = () => {
      if (!loading.value) {
        loading.value = true
        pageIndex.value = 1
        isShowPage.value = false
        handleCurrentChange(pageIndex.value)
      }
    }

    let changeSoftType = (val: number) => {
      if (val == 4) {
        if (sortType.value != 4) {
          sortType.value = 4
        } else {
          sortType.value = 5
        }
      } else {
        sortType.value = val
      }

      //console.log(sortType.value)
      getGoodsList()
      // type.value = val
    }
    //获取商品列表
    let getGoodsList = () => {
      let sort
      let price
      loading.value = true
      if (sortType.value < 4) {
        sort = sortType.value - 1
        price = 0
      } else {
        sort = 3
        if (sortType.value == 4) {
          price = 1
        } else {
          price = 2
        }
      }
      parts
        .partsGetList(
          routeData.pid,
          routeData.sid,
          sort,
          price,
          pageIndex.value
        )
        .then((res: any) => {
          if (res.errno == 0) {
            goodslit.data = res.datas.data
            goodslit.total = res.datas.total
            goodslit.pageSize = res.datas.pageSize
            isShowPage.value = true
          }
          loading.value = false
        })
    }

    let changePageIndex = (type: number) => {
      if (type == 1) {
        //加
        Math.ceil(goodslit.total / goodslit.pageSize)
        if (pageIndex.value < Math.ceil(goodslit.total / goodslit.pageSize)) {
          pageIndex.value = pageIndex.value + 1
          pageShow.value.changePageIndex(pageIndex.value)
          getGoodsList()
        }
      } else {
        //减
        if (pageIndex.value > 1) {
          pageIndex.value = pageIndex.value - 1
          pageShow.value.changePageIndex(pageIndex.value)
          getGoodsList()
        }
        //pageShow.value.changePageIndex(1)
      }
      updateUrl(pageIndex.value)
    }
    let router = useRouter()

    let updateUrl = (val: number) => {
      let params = {
        pid: routeData.pid,
        sid: routeData.sid,
        page: val + "",
      }

      router.push({
        name: route.name as RouteRecordName,
        params,
      })
    }
    let gotoHome = () => {
      router.push({
        name: "home",
      })
    }
    onMounted(() => {
      getGoodsList()
    })

    return {
      total,
      handleCurrentChange,
      currentTab,
      changeSoftType,
      type,
      goodslit,
      loading,
      sortType,
      pageIndex,
      isShowPage,
      pageShow,
      changePageIndex,
      gotoHome,
      partsClass,
      routeData,
      sClass,
      changClass,
      changSClass,
    }
  },
})
