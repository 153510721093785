
import { useRouter, useRoute, RouteRecordName } from "vue-router"
import { sjyAlert } from "@/utils/alert"
import { defineComponent } from "vue"
import useClipboard from "vue-clipboard3"

export default defineComponent({
  components: {},
  name: "dialogshare",
  emits:['cancel'],
  setup(props: any, { emit }) {
    const { toClipboard } = useClipboard()
    let router = useRouter()
    let route = useRoute()
    const { href } = router.resolve({
      name: route.name as RouteRecordName,
      params: {
        ...route.params,
      },
    })
    let copyurl = location.origin + href
    const copyInfo = async () => {
      try {
        await toClipboard(copyurl)
        sjyAlert("复制成功", "success")
      } catch (e) {
        sjyAlert("复制失败", "error")
      }
    }

    let cancel = ()=>{
      emit("cancel")
      //console.log("cancel");
      
    }


    return { copyurl, copyInfo,cancel }
  },
})
