
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props:{
    modleTitle:{
        type: String,
        default: ''
    }
  },
  setup() {
    return {};
  },
});
