
import {
	defineComponent,
	onMounted,
	reactive,
	ref,
	provide,
	watch,
	watchEffect,
} from "vue"
import { Search } from "@element-plus/icons-vue"
import GoodsCollectionList from "@/components/user/goods/GoodsCollectionList.vue"
import { goodsApi, orderApi, parts, userApi } from "@/service/api"
import PageShow from "@/components/common/PageShow.vue"
import { ElMessageBox } from "element-plus"
import { updateCartTotal } from "@/utils/public"
import { sjyAlert } from "@/utils/alert"
import { useRouter } from "vue-router"

export default defineComponent({
	components: { GoodsCollectionList, PageShow },
	setup() {
		let total = ref(0)
		let pageSize = ref(20)
		let nowPage = ref(1)
		//筛选条件
		const formInline = reactive({
			status: 0,
			ctime: 0,
			cid: 0,
			product_name: "",
			isall: [],
			type: 1,
			pageIndex: 1,
		})

		const productName = ref("")
		const isall = ref([])

		//0，不显示遮挡层   1.显示单个删除遮挡层  2.多选遮挡层
		let step = ref(0)
		//更改step
		const toedit = (val: number) => {
			step.value = val
		}

		//多选，点击更改选中状态
		let goodsselect = (index: number) => {
			goodslit.datas.data[index].isselect = !goodslit.datas.data[index].isselect
		}
		//多选，点击更改选中状态
		let deleteGoodsListByIndex = (index: number) => {
			//取消收藏
			let product_ids = []
			product_ids.push(goodslit.datas.data[index].id)
			deleteStore(product_ids)
			goodslit.datas.data.splice(index, 1)
		}

		let deleteGoodsListByids = () => {
			for (let i = 0; i < selectGoodsList.value.length; i++) {
				for (let j = 0; j < goodslit.datas.data.length; j++) {
					// console.log(goodslit.datas.data.length,"111");
					if (selectGoodsList.value[i] == goodslit.datas.data[j].id) {
						goodslit.datas.data.splice(j, 1)
						break
					}
				}
			}
		}

		let deleteStore = (product_ids: Array<Number>) => {
			let mdatas = []
			for (let i = 0; i < product_ids.length; i++) {
				mdatas.push({
					product_id: Number(product_ids[i]),
					type: collectionType.value,
					status: 0,
				})
			}

			goodsApi.addStore(mdatas).then((res: any) => {
				console.log(res)
			})
		}

		//全选
		let selectAllGoods = (val: boolean) => {
			console.log(goodslit.datas)

			for (let i = 0; i < goodslit.datas.data.length; i++) {
				// console.log(goodslit.datas.data[i]);
				goodslit.datas.data[i].isselect = val
			}
		}

		//删除单个
		let deleteone = () => {
			ElMessageBox.confirm("确定要删除吗?", "", {
				type: "warning",
				confirmButtonText: "确定",
				cancelButtonText: "取消",
			})
				.then(() => {
					getSelectGoods()
					deleteGoodsListByids()
					deleteStore(selectGoodsList.value)
				})
				.catch(() => {
					// catch error
				})
		}
		//批量删除
		let deleteall = () => {
			ElMessageBox.confirm("确定要删除吗?", "", {
				type: "warning",
				confirmButtonText: "确定",
				cancelButtonText: "取消",
			})
				.then(() => {
					getSelectGoods()
					deleteGoodsListByids()

					//获取选中的商品
					deleteStore(selectGoodsList.value)
				})
				.catch(() => {
					// catch error
				})
		}

		//案例列表
		let goodslit = reactive({
			datas: {
				data: [{ isselect: false, id: 0 }],
				pageSize: 0,
				total: 0,
			},
		})
		//获取商品列表
		let getGoodsList = () => {
			loading.value = true
			userApi
				.getStoreList(
					productName.value,
					formInline.type,
					formInline.cid,
					formInline.ctime,
					formInline.status,
					{ pageIndex: formInline.pageIndex }
				)
				.then((res: any) => {
					if (res.errno == 0) {
						for (let i = 0; i < res.datas.data.length; i++) {
							res.datas.data[i].isselect = false
							// console.log(res.datas.data[i],"列表");
						}
						goodslit.datas = res.datas
						total.value = res.datas.total
						pageSize.value = res.datas.pageSize
						loading.value = false
					}
				})
		}
		//切换分页
		let handleCurrentChange = (val: number) => {
			nowPage.value = val
			formInline.pageIndex = val
		}

		const loading = ref(true)

		//监听筛选条件是否变化
		watch(formInline, (newvalue, oldvalue) => {
			// console.log("条件改变了")
			// if (formInline.isall.length > 0) {
			//   selectAllGoods(true)
			// } else {
			//   selectAllGoods(false)
			// }
			getGoodsList()
		})

		watchEffect(() => {
			if (isall.value.length > 0) {
				selectAllGoods(true)
			} else {
				selectAllGoods(false)
			}
		})

		watch(goodslit, (newvalue, oldvalue) => {
			var selecttotal = 0
			moreselect.value = false
			for (let i = 0; i < goodslit.datas.data.length; i++) {
				if (goodslit.datas.data[i].isselect) {
					selecttotal++
				}
				if (selecttotal > 1) {
					moreselect.value = true
					break
				}
			}
		})

		let moreselect = ref(false)

		let selectGoodsList = ref([1])
		//获取选择商品的id
		let getSelectGoods = () => {
			selectGoodsList.value = []
			for (let i = 0; i < goodslit.datas.data.length; i++) {
				//goodslit.datas.data[i].isselect = val
				if (goodslit.datas.data[i].isselect) {
					selectGoodsList.value.push(goodslit.datas.data[i].id)
				}
			}
			//return selectGoodsList;
			//console.log(selectGoodsList.value)
		}

		let changeTypeVal = ref(1)

		let collectionType = ref(1)
		let tempItem: classTypeItem = {
			value: 0,
			title: "全部分类",
		}
		let changeType = (val: number) => {
			toedit(0)
			switch (val) {
				case 1:
					changeTypeVal.value = 1
					selectWhere.classname.list = sjyuanclass.data
					break
				case 2:
					changeTypeVal.value = 2
					selectWhere.classname.list = freeclass.data
					break
				case 3:
					//loading.value = true
					//goodslit.datas.data = []  //先注释掉，
					changeTypeVal.value = 3
					selectWhere.classname.list = videoclass.data
					break
				case 4:
					//loading.value = true
					//goodslit.datas.data = []  //先注释掉，
					changeTypeVal.value = 4
					selectWhere.classname.list = partsClass.data
					break
				default:
					changeTypeVal.value = 1
					break
			}
			// console.log(selectWhere.classname.list,sjyuanclass.data)
			// selectWhere.classname.list.unshift(tempItem)
			collectionType.value = changeTypeVal.value
			initWhere(val)
		}
		let initWhere = (val: number) => {
			formInline.status = 0
			formInline.ctime = 0
			formInline.cid = 0
			formInline.type = val
			productName.value = ""
		}
		interface classTypeItem {
			value: Number
			title: String
		}
		interface classType {
			data: Array<classTypeItem>
		}

		let sjyuanclass: classType = reactive({ data: [] })
		let freeclass: classType = reactive({ data: [] })
		let videoclass: classType = reactive({ data: [] })
		let getGoodsAttr = () => {
			goodsApi.getGoodsClass().then((res: any) => {
				let sclass = res.datas.自动化设计方案
				for (let i = 0; i < sclass.length; i++) {
					let msclass = {
						title: sclass[i].names,
						value: sclass[i].id,
					}
					sjyuanclass.data.push(msclass)
				}
				sjyuanclass.data.unshift(tempItem)
				changeType(1)

				let fclass = res.datas.免费专区
				//console.log(fclass)
				for (let i = 0; i < fclass.length; i++) {
					let msclass = {
						title: fclass[i].name,
						value: fclass[i].id,
					}
					freeclass.data.push(msclass)
				}
				freeclass.data.unshift(tempItem)
				let vclass = res.datas.机械原理动画
				for (let i = 0; i < vclass.length; i++) {
					let msclass = {
						title: sclass[i].names,
						value: sclass[i].id,
					}
					videoclass.data.push(msclass)
				}
				videoclass.data.unshift(tempItem)
			})
		}

		interface classnameType {
			title: String
			list: Array<classTypeItem>
		}
		//设计方案搜索条件
		let selectWhere = reactive({
			status: {
				title: "状态",
				list: [
					{
						title: "全部",
						value: 0,
					},
					{
						title: "有优惠",
						value: 1,
					},
					{
						title: "已购买",
						value: 2,
					},
					{
						title: "失效",
						value: 3,
					},
				],
			},
			collectiontime: {
				title: "状态",
				list: [
					{
						title: "全部",
						value: 0,
					},
					{
						title: "近一周",
						value: 1,
					},
					{
						title: "一个月",
						value: 2,
					},
					{
						title: "三个月",
						value: 3,
					},
					{
						title: "一年",
						value: 4,
					},
					{
						title: "更早",
						value: 5,
					},
				],
			},
			classname: {
				title: "全部分类",
				list: [],
			} as classnameType,
		})
		let router = useRouter()
		let goto = () => {
			let routerName = "goods-class"
			let params = {}
			if (collectionType.value == 3) {
				routerName = "video-class"
				params = {
					cid: 3,
				}
			}
			if (collectionType.value == 2) {
				routerName = "free"
			}
			if (collectionType.value == 1) {
				routerName = "goods-class"
				params = {
					cid: 3,
				}
			}
			router.push({
				name: routerName,
				params: params,
			})
		}

		provide("step", step)
		provide("collectionType", collectionType)
		provide("goodsselect", goodsselect)
		provide("deleteGoodsListByIndex", deleteGoodsListByIndex)

		provide("toedit", toedit)
		let partsClass: classType = reactive({ data: [] })
		partsClass.data.push(tempItem)
		/**
		 * 获取备件区的商品列表
		 */
		let getPartsClass = () => {
			parts.partsAllClass().then((res: any) => {
				if (res.errno == 0) {
					for (let i in res.datas) {
						let classItem = {
							value: res.datas[i].id,
							title: res.datas[i].names,
						}
						partsClass.data.push(classItem)
					}
					//partsClass.data = res.datas
				}
			})
		}
		onMounted(() => {
			getGoodsAttr()
			getGoodsList()
			getPartsClass()
		})
		return {
			formInline,
			toedit,
			Search,
			getGoodsList,
			loading,
			goodslit,
			handleCurrentChange,
			step,
			deleteone,
			deleteall,
			moreselect,
			changeType,
			collectionType,
			selectWhere,
			total,
			pageSize,
			nowPage,
			productName,
			isall,
			tempItem,
			goto,
			changeTypeVal,
		}
	},
})
