
import { defineComponent } from "vue";
import CustomerTitle from "./common/CustomerTitle.vue";

export default defineComponent({
  components: { CustomerTitle },
  setup() {
    let titleList = ["企业认证"];
    return { titleList };
  },
});
