
import { reactive, defineComponent, ref, onMounted } from "vue";
import type { TabsPaneContext } from "element-plus";

import All from "./All.vue";
import CanUse from "./CanUse.vue";
import Used from "./Used.vue";
import Past from "./Past.vue";
import Null from "./Null.vue";
import { userApi } from "@/service/api";
import { sjyAlert } from "@/utils/alert";

export default defineComponent({
  components: { All, CanUse, Used, Past, Null },
  setup() {
    const activeName = ref("first");

    const handleClick = (tab: TabsPaneContext, event: Event) => {
      console.log(tab, event);
    };

    interface CouponType {
      data: any;
    }

    //全部
    let allCouponList = reactive({ data: [] });
    //正常
    let activeCouponList: CouponType = reactive({ data: [] });
    //已使用
    let usedCouponList: CouponType = reactive({ data: [] });
    //已过期
    let overdueCouponList: CouponType = reactive({ data: [] });

    /**
     * 获取所有的优惠券
     */
    let getCouponsList = () => {
      userApi.getCouponsList().then((res: any) => {
        if (res.errno == 0) {
          if (res.data.list.length > 0) {
            allCouponList.data = res.data.list;
            res.data.list.findIndex((item: any) => {
              if (item.status == "已领取") {
                activeCouponList.data.push(item);
              }
              if (item.status == "已使用") {
                usedCouponList.data.push(item);
              }
              if (item.status == "已过期") {
                overdueCouponList.data.push(item);
              }
            });
          }
        } else {
          sjyAlert(res.errmsg, "error");
        }
      });
    };
    onMounted(() => {
      getCouponsList();
    });
    return {
      activeName,
      handleClick,
      allCouponList,
      activeCouponList,
      usedCouponList,
      overdueCouponList,
    };
  },
});
