import axios from "../service";

let { get, post } = axios;

const company = {
  //企业认证
  companyVerify(data: any) {
    return post("pc/company/cert", data);
  },
  // 企业认证详情
  companyinformation() {
    return post("pc/company/details", {});
  },
  // 添加供应商
  addcompany(data: any) {
    return post("/pc/supplier/add", data);
  },
};
export default company;
