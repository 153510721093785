
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  name: "dialogpay",
  emits: ["cancel"],
  setup(props: any, { emit }) {
    let cancel = () => {
      emit("cancel");
    };

    return { cancel };
  },
});
