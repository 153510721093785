
import { defineComponent, ref, onMounted, onUnmounted } from "vue"
import { userStore } from "@/stores/userStore"
import { useRouter } from "vue-router"
// import { fangankefu, shouhoukefu, shouqiankefu } from "@/utils/alert"
export default defineComponent({
	name: "FixedLeft",
	components: {},
	setup() {
		const dialogVisible = ref(false)
		let url = ref('/static/info/sjfa.jpg')
		const fangankefu = ()=>{			
			url.value = "/static/info/sjfa.jpg"
			dialogVisible.value = true
		}
		const shouhoukefu = ()=>{
			url.value = "/static/info/bjbt.jpg"
			dialogVisible.value = true
		}
		const handleClose = (done: () => void) => {	
		}
		let router = useRouter()
		let id = userStore().id
		let goToUserCenter = () => {
			router.push({ name: "suggest" })
		}
		let goToTop = () => {
			window.scrollTo(0, 0)
		}
		const showBackToTop = ref(true) // 控制回到顶部按钮显示与隐藏的变量
		const handleScroll = () => {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop
			const firstScreenHeight = window.innerHeight
			// 判断滚动位置是否超过首屏高度，更新showBackToTop的值
			showBackToTop.value = true  //scrollTop > firstScreenHeight
		}
		onMounted(() => {
			window.addEventListener("scroll", handleScroll) // 添加滚动事件监听器
		})
		onUnmounted(() => {
			window.removeEventListener("scroll", handleScroll) // 移除滚动事件监听器
		})
		return {
			goToTop,
			goToUserCenter,
			id,
			fangankefu,
			shouhoukefu,

			showBackToTop,
			dialogVisible,
			handleClose,
			url
		}
	},
})
