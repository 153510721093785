
import { goodsApi, publicApi } from "@/service/api"
import { useWindowScroll } from "@vueuse/core"
import { defineComponent, onMounted, reactive, ref } from "vue"

export default defineComponent({
	components: {},

	setup() {
		let tabPosition = ref("left")
		let state = ref(0)
		let classList = reactive<any>({})

		const changeClass = (val: number) => {
			state.value = val
		}
		let movibotClass: any = publicApi.getMulu()
		let gotoMovibot = (url: any) => {
			window.open("https://www.movibot-art.com" + url)
		}
		onMounted(() => {
			goodsApi.getGoodsClass().then((res: any) => {
				if (res.datas) {
					classList.自动化设计方案 = res.datas.自动化设计方案
					classList.机械原理动画 = res.datas.机械原理动画
					classList.免费专区 = res.datas.免费专区
					classList.工业自动化供应链 = res.datas.工业自动化供应链
				}
				//console.log(classList.机械原理动画);
			})
		})

		return {
			classList,
			tabPosition,
			state,
			changeClass,
			movibotClass,
			gotoMovibot,
		}
	},
})
