
import { parts } from "@/service/api"
import { defineComponent, reactive, ref } from "vue"
import { useRouter } from "vue-router"

export default defineComponent({
  components: {},
  name: "goods-detail-class",
  setup() {
    let activeClass = ref(0)
    let isShow = ref(false)
    let router = useRouter()
    let handleChage = (id: number, classLength: number = 0) => {
      if (classLength == 0) {
        //跳转到分类页面 name: 'partssearch',
        //params: { pid: mclass.id, sid: sclass.id },
        let routeUrl = router.resolve({
          name: "partssearch",
          params: { pid: id, sid: 0 },
        })
        window.open(routeUrl.href, "_blank")
      } else {
        if (activeClass.value == id) {
          activeClass.value = 0
        } else {
          activeClass.value = id
        }
      }
    }
    let partsClass = reactive({
      data: {} as any,
    })

    parts.partsAllClass().then((res: any) => {
      if (res.errno == 0) {
        partsClass.data = res.datas
      }
    })
    return { isShow, handleChage, partsClass, activeClass }
  },
})
