
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  name:"showDownDoc",
  emits:['readDoc'],
  setup(props:any,{emit}) {
    let readDoc = ()=>{
        emit('readDoc')
    }
    return {readDoc};
  },
});
