
import { reactive, defineComponent, ref, onMounted } from "vue";
import { userApi } from "@/service/api";
export default defineComponent({
  emits: ["goback"],
  setup(props, context) {
    const selfverifyForm = reactive({
      name: "",
      id: "",
      bool: true,
      idCard: "",
    });
    interface result {
      data: any;
    }
    let getInformation: result = reactive({
      data: {},
    });
    let getuserInformation = () => {
      userApi.getInformation().then((res: any) => {
        getInformation.data = res.datas;
        //console.log(getInformation.data);
        // console.log(res);
      });
    };
    let show = ref(true);
    const hideNumber = () => {
      show.value = !show.value;
    };

    onMounted(() => {
      getuserInformation();
    });

    let goback = () => {
      context.emit("goback");
    };
    return {
      selfverifyForm,
      hideNumber,
      getuserInformation,
      getInformation,
      show,
      goback,
    };
  },
});
