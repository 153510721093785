
import { reactive, ref, defineComponent } from "vue";
import PageShow from "@/components/common/PageShow.vue";
import lackRecordVue from "../lackRecord.vue";
export default defineComponent({
  components: { PageShow, lackRecordVue },
  props: {
    AccountToSon: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, context) {
    return {};
  },
});
