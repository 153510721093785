
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import HomeHead from "@/components/HomeHead.vue";
import CurrentAddress from "@/components/common/CurrentAddress.vue";
import HtmlFooter from "@/components/HtmlFooter.vue";
import Fapiao from "@/components/customer/Fapiao.vue";
import HotProblem from "@/components/customer/HotProblem.vue";
import GeRenVip from "@/components/customer/GeRenVip.vue";
import GongSiVip from "@/components/customer/GongSiVip.vue";
import ZuopinShangchuan from "@/components/customer/ZuopinShangchuan.vue";
import QiyeRenzheng from "@/components/customer/QiyeRenzheng.vue";
import QiyeHezuo from "@/components/customer/QiyeHezuo.vue";
import ShejishiHezuo from "@/components/customer/ShejishiHezuo.vue";
import GerenRenzheng from "@/components/customer/GerenRenzheng.vue";
import TuikuanShuoming from "@/components/customer/TuikuanShuoming.vue";
import ChilunbiZhifu from "@/components/customer/ChilunbiZhifu.vue";
import DuigongZhifu from "@/components/customer/DuigongZhifu.vue";
import ZaixianZhifu from "@/components/customer/ZaixianZhifu.vue";
import ZhifuWenti from "@/components/customer/ZhifuWenti.vue";
import Chilunbi from "@/components/customer/Chilunbi.vue";
import Youhuiquan from "@/components/customer/Youhuiquan.vue";
import { classList } from "@/utils/public";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  components: {
    HomeHead,
    CurrentAddress,
    HtmlFooter,
    Fapiao,
    HotProblem,
    GeRenVip,
    GongSiVip,
    ZuopinShangchuan,
    QiyeRenzheng,
    QiyeHezuo,
    ShejishiHezuo,
    GerenRenzheng,
    TuikuanShuoming,
    DuigongZhifu,
    ChilunbiZhifu,
    ZaixianZhifu,
    Chilunbi,
    Youhuiquan,
    ZhifuWenti,
  },
  setup() {
    let route = useRoute();

    let comName = ref("HotProblem");
    let router = useRouter();
    watch(comName, () => {
      router.push({
        name: "customer",
        params: {
          comName: comName.value,
        },
      });
    });

    watch(route, () => {
      router.go(0);
    });

    let nowClassList = reactive({ data: classList });

    let clickClass = (index: number, indexson: number) => {
      for (let i = 0; i < nowClassList.data.length; i++) {
        nowClassList.data[i].isChecked = false;
        for (let j = 0; j < nowClassList.data[i].list.length; j++) {
          nowClassList.data[i].list[j].isChecked = false;
        }
      }

      nowClassList.data[0].isChecked = true;
      nowClassList.data[8].isChecked = true;
      nowClassList.data[index].isChecked = true;
      if (nowClassList.data[index].list.length > 0) {
        nowClassList.data[index].list[indexson].isChecked = true;
        comName.value = nowClassList.data[index].list[indexson].path;
      } else {
        comName.value = nowClassList.data[index].path;
      }
      window.scrollTo(0, 0);
    };

    let clickOpenClass = (index: number) => {
      nowClassList.data[index].open = !nowClassList.data[index].open;
    };

    let initClass = () => {
      if (route.params.comName) {
        for (let i = 0; i < nowClassList.data.length; i++) {
          if (nowClassList.data[i].path == route.params.comName) {
            clickClass(i, 0);
            if (route.params.comName) {
              comName.value = route.params.comName as string;
            }
            break;
          }
          for (let j = 0; j < nowClassList.data[i].list.length; j++) {
            if (nowClassList.data[i].list[j].path == route.params.comName) {
              clickClass(i, j);
              clickOpenClass(i);
              if (route.params.comName) {
                comName.value = route.params.comName as string;
              }
              break;
            }
          }
        }
      }
    };
    onMounted(() => {
      initClass();
    });

    return { clickClass, nowClassList, comName, clickOpenClass };
  },
});
