
import { defineComponent, onMounted, reactive, ref, watch } from "vue"
import type { FormInstance } from "element-plus"
import { Search } from "@element-plus/icons-vue"
import dateFormat from "@/utils/dateFormat"
import { userApi } from "@/service/api"
export default defineComponent({
  components: {},
  emits: ["setWhere"],
  setup(props: any, { emit }) {
    const orderSearch = reactive({
      status: "0",
      uid: "",
      start: [0, 0],
      pageIndex: 1,
      pageSize: 20,
    })

    // const onSubmit = () => {
    //   console.log("submit!");
    // };
    const ruleFormRef = ref<FormInstance>()

    const resetForm = () => {
      //if (!formEl) return;
      orderSearch.start = [0, 0]
      orderSearch.uid = ""
      //formEl.resetFields();
    }

    watch(orderSearch,()=>{
      emit("setWhere", orderSearch)
      
    })

    // const submitForm = async (formEl: FormInstance | undefined) => {
    //   if (!formEl) return
    //   await formEl.validate((valid, fields) => {
    //     if (valid) {
    //       emit("setWhere", orderSearch)
    //     } else {
    //       console.log("error submit!", fields)
    //     }
    //   })
    // }
    let isShowMoreWhere = ref(true)
    const showMoreWhere = () => {
      isShowMoreWhere.value = !isShowMoreWhere.value
    }
    let moreType = ref(0)
    const changeDate = () => {
      //console.log("我改变了吗");
      moreType.value = 0
    }
    let sonList: any = reactive({ data: [] })

    //获取子账号列表
    let getSonList = () => {
      userApi.companyUserList().then((res: any) => {
        sonList.data = res.datas
      })
    }

    const setDate = (type: number) => {
      let dateString = dateFormat.dateFormat(new Date().getTime(), "yyyy-MM-dd")
      let timeTamp = dateFormat.dateStrChangeTimeTamp(dateString)
      orderSearch.start[1] = timeTamp
      if (type == 1) {
        //当前时间
        orderSearch.start[0] = timeTamp
        moreType.value = 1
      } else if (type == 2) {
        orderSearch.start[0] = timeTamp - 60 * 60 * 24 * 7 * 1000
        moreType.value = 2
        //近一周
      } else if (type == 3) {
        //近一月
        orderSearch.start[0] = timeTamp - 60 * 60 * 24 * 30 * 1000
        moreType.value = 3
      } else {
        //近三月
        orderSearch.start[0] = timeTamp - 60 * 60 * 24 * 90 * 1000
        moreType.value = 4
      }
    }
    onMounted(() => {
      getSonList()
    })
    return {
      orderSearch,
      resetForm,
      ruleFormRef,
      // submitForm,
      Search,
      showMoreWhere,
      isShowMoreWhere,
      changeDate,
      setDate,
      moreType,
      sonList,
    }
  },
})
