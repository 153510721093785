
import { defineComponent } from "vue";
import CustomerTitle from "./common/CustomerTitle.vue";

export default defineComponent({
  components: { CustomerTitle },
  setup() {
    let titleList = ["支付方式", "齿轮币支付"];
    return { titleList };
  },
});
