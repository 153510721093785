
import { defineComponent,inject } from "vue";

export default defineComponent({
  components: {},
  props: {
    couponList: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    let setCouponsIndex = inject("setCouponsIndex", (id:number) => {}, false)
    return {setCouponsIndex};
  },
});
