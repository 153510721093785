import axios from "../service";

let {get, post } = axios

const brand = {
	sendCaptcha(data: Object) {
		// return new Promise((resolve, reject) => {
        //     axios({
        //             method: 'post',
        //             url,
        //             data: qs.stringify(data),
        //         })
        //         .then(res => {
        //             resolve(res.data)
        //         })
        //         .catch(err => {
        //             reject(err)
        //         });
        // })
		return get("/captcha/sent",data)
	},
	verifyCaptcha(data: Object){
		return post("/captcha/verify",data)
	},
	register(data: Object){
		return post("/register/cellphone",data)
	}

}
export default brand


