
import { useRouter, useRoute, RouteRecordName } from "vue-router"
import { computed, defineComponent, ref } from "vue"

export default defineComponent({
	components: {},
	emits: ["handleCurrentChange"],
	props: {
		total: {
			type: Number,
			default: 10,
		},
		pageSize: {
			type: Number,
			default: 10,
		},
	},
	setup(props: any, context) {
		let handleCurrentChange = (val: number) => {
			pageIndex.value = val
			context.emit("handleCurrentChange", val)
		}
		let pageIndex = ref(1)
		let route = useRoute()

		let isShow = computed(() => {
			return Math.ceil(props.total / props.pageSize) > 1
		})

		if (route.params.page) {
			pageIndex.value = parseInt(route.params.page as string)
		}
		let router = useRouter()

		let changePageIndex = (val: number) => {
			pageIndex.value = val
			let params = {
				...route.params,
			}
			params.page = val + ""
			// console.log("我也执行了")
			// router.push({
			//   name: route.name as RouteRecordName,
			//   params,
			// })
		}
		return {
			handleCurrentChange,
			pageIndex,
			changePageIndex,
			isShow,
		}
	},
})
