
import { reactive, ref, defineComponent, onMounted } from "vue"
import type { TabsPaneContext } from "element-plus"
import dateFormat from "@/utils/dateFormat"
import { userApi } from "@/service/api"
import { userStore } from "@/stores/userStore"
// 个人表单
import allTable from "./component/allTable.vue"
import earningTable from "./component/earningTable.vue"
import shoppingTable from "./component/shoppingTable.vue"
import topupTable from "./component/topupTable.vue"
import withdrawTable from "./component/withdrawTable.vue"
//企业表单
import allTableFirm from "./component/firm/allTableFirm.vue"
import earningTableFirm from "./component/firm/earningTableFirm.vue"
import topupTableFirm from "./component/firm/topupTableFirm.vue"
import shoppingTableFirm from "./component/firm/shoppingTableFirm.vue"
import withdrawTableFirm from "./component/firm/withdrawTableFirm.vue"
//子账号表单
import allTableSon from "./component/son/allTableSon.vue"
import shoppingTableSon from "./component/son/shoppingTableSon.vue"
import earningTableSon from "./component/son/earningTableSon.vue"
import PageShow from "@/components/common/PageShow.vue"
import RmbVue from "./component/Rmb.vue"
import { useRoute } from "vue-router"

export default defineComponent({
  components: {
    allTable,
    earningTable,
    shoppingTable,
    topupTable,
    withdrawTable,
    RmbVue,
    //企业表单
    allTableFirm,
    earningTableFirm,
    topupTableFirm,
    shoppingTableFirm,
    withdrawTableFirm,
    //子账号
    allTableSon,
    shoppingTableSon,
    earningTableSon,
    PageShow,
  },
  emits: ["gorecord"],

  props: {
    getInformation: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    let information = reactive({
      start: 0,
      end: 0,
      id: "",
    })
    let userInfo = userStore()
    let types = useRoute().params.types

    let total = ref(0)

    let isloading = ref(false)

    // 交易记录的信息
    let recordinformation = reactive({
      uid: "",
      pageIndex: 1,
      start: "",
      end: "",
      type: "0",
    })

    const size = ref<"" | "large" | "small">("")
    let style = ref("one")
    let color = ref("one")
    let table = reactive({
      show: "allTable",
    })
    const activeName = ref("first")
    const handleClick = (tab: TabsPaneContext, event: Event) => {
      changeClick(tab.index)
    }
    const changeClick = (tabindex: any) => {
      recordinformation.pageIndex = 1
      total.value = 0
      isloading.value = true
      AccountToSon.data.total = 0
      AccountToSon.data.list = []
      getuserInformation()
      if (tabindex == "0") {
        // console.log(getInformations.data, "判断一下");
        if (getInformations.data.pid != 0) {
          table.show = "allTableSon"
        }
        if (
          getInformations.data.pid == 0 &&
          getInformations.data.company_id != null
        ) {
          table.show = "allTableFirm"
          //console.log("qiye");
        }
        if (
          getInformations.data.pid == 0 &&
          getInformations.data.company_id == null
        ) {
          table.show = "allTable"
        }

        recordinformation.type = "0"
        userApi.getAccount(recordinformation).then((res: any) => {
          AccountToSon.data = res.datas
          isloading.value = false
          total.value = res.datas.total
        })
      }
      if (tabindex == "1") {
        recordinformation.type = "2"
        userApi.getAccount(recordinformation).then((res: any) => {
          AccountToSon.data = res.datas
          isloading.value = false
          total.value = res.datas.total
          if (getInformations.data.pid != 0) {
            table.show = "shoppingTableSon"
          }
          if (
            getInformations.data.pid == 0 &&
            getInformations.data.company_id != null
          ) {
            table.show = "shoppingTableFirm"
            //console.log("qiye");
          }
          if (
            getInformations.data.pid == 0 &&
            getInformations.data.company_id == null
          ) {
            table.show = "shoppingTable"
          }
        })
      }
      if (tabindex == "3") {
        if (getInformations.data.pid != 0) {
          table.show = "earningTableSon"
        }
        if (
          getInformations.data.pid == 0 &&
          getInformations.data.company_id != null
        ) {
          table.show = "earningTableFirm"
          //console.log("qiye");
        }
        if (
          getInformations.data.pid == 0 &&
          getInformations.data.company_id == null
        ) {
          table.show = "earningTable"
        }

        recordinformation.type = "5"
        userApi.getAccount(recordinformation).then((res: any) => {
          AccountToSon.data = res.datas
          isloading.value = false
          total.value = res.datas.total
        })
      }
      if (tabindex == "4") {
        if (getInformations.data.pid != 0) {
          table.show = "topupTableSon"
        }
        if (
          getInformations.data.pid == 0 &&
          getInformations.data.company_id != null
        ) {
          table.show = "topupTableFirm"
        }
        if (
          getInformations.data.pid == 0 &&
          getInformations.data.company_id == null
        ) {
          table.show = "topupTable"
        }

        recordinformation.type = "1"
        userApi.getAccount(recordinformation).then((res: any) => {
          AccountToSon.data = res.datas
          isloading.value = false
          total.value = res.datas.total
        })
      }
      if (tabindex == "5") {
        if (getInformations.data.pid != 0) {
          table.show = "withdrawTableSon"
        }
        if (
          getInformations.data.pid == 0 &&
          getInformations.data.company_id != null
        ) {
          table.show = "withdrawTableFirm"
        }
        if (
          getInformations.data.pid == 0 &&
          getInformations.data.company_id == null
        ) {
          table.show = "withdrawTable"
        }
        table.show = "withdrawTable"
        recordinformation.type = "3"
        //console.log("提现");

        userApi.getAccount(recordinformation).then((res: any) => {
          //console.log(res);
          AccountToSon.data = res.datas
          isloading.value = false
          total.value = res.datas.total
        })
      }
      if (tabindex == "2") {
        // console.log("我执行到了");
        table.show = "RmbVue"
        activeName.value = "sixth"
        //console.log("rmb");
        recordinformation.type = "6"
        userApi.getAccount(recordinformation).then((res: any) => {
          // console.log(res);
          AccountToSon.data = res.datas
          isloading.value = false
          total.value = res.datas.total
        })
      }
    }

    let self = ref(false)
    let moreType = ref(0)
    const changeDate = () => {
      //console.log("我改变了吗");
      moreType.value = 0
    }
    // 点击获取天数
    const setDate = (type: number) => {
      let dateString = dateFormat.dateFormat(new Date().getTime(), "yyyy-MM-dd")
      let timeTamp = dateFormat.dateStrChangeTimeTamp(dateString)

      information.end = timeTamp

      if (type == 1) {
        //当前时间
        information.start = timeTamp
        moreType.value = 1
        style.value = "one1"
      } else if (type == 2) {
        information.start = timeTamp - 60 * 60 * 24 * 7 * 1000
        moreType.value = 2
        style.value = "two"
        //近一周
      } else if (type == 3) {
        //近一月
        information.start = timeTamp - 60 * 60 * 24 * 30 * 1000
        moreType.value = 3
        style.value = "three"
      } else {
        //近三月
        information.start = timeTamp - 60 * 60 * 24 * 90 * 1000
        moreType.value = 4
        style.value = "four"
      }
    }

    // 获取用户的交易记录

    let AccountToSon: result = reactive({
      data: {},
    })
    // 点击查询按钮加载的表单    结束的时间应该是今天 往前推时间
    let getAccount = () => {
      if (information.start) {
        recordinformation.start = (information.start / 1000).toString()
        //console.log(recordinformation.start);
      }
      if (information.end) {
        recordinformation.end = (information.end / 1000).toString()
      }
      if (information.start == null) {
        recordinformation.start = "0"
      }
      if (information.end == null) {
        recordinformation.end = "0"
      }
      userApi.getAccount(recordinformation).then((res: any) => {
        AccountToSon.data = res.datas
        total.value = res.datas.total
        //console.log( AccountToSon.data);
      })
    }

    // 进入页面加载的记录表单
    let getAccounts = () => {
      userApi.getAccount(recordinformation).then((res: any) => {
        AccountToSon.data = res.datas
        total.value = res.datas.total
      })
    }

    // 子账号列表
    interface result {
      data: any
    }
    let getInformation: result = reactive({
      data: {},
    })
    let getson = () => {
      userApi.sonlist().then((res: any) => {
        getInformation.data = res.datas
        // console.log(res.datas, "111");
      })
    }
    let handleCurrentChange = (val: number) => {
      recordinformation.pageIndex = val
      getAccounts()
    }
    let son = ref(true)

    // 获取用户信息
    let getInformations: result = reactive({
      data: {},
    })

    let getuserInformation = (first: number = 0) => {
      userApi.getInformation().then((res: any) => {
        getInformations.data = res.datas
        if (first == 1 && types != "2") {
          if (getInformations.data.pid != 0) {
            table.show = "allTableSon"
          }
          if (
            getInformations.data.pid == 0 &&
            getInformations.data.company_id != null
          ) {
            table.show = "allTableFirm"
          }
          if (
            getInformations.data.pid == 0 &&
            getInformations.data.company_id == null
          ) {
            table.show = "allTable"
          }
        }
      })
    }

    onMounted(() => {
      getson()
      getuserInformation(1)
      if (types == "2") {
        changeClick(types)
        console.log("执行了吗?")
      } else {
        getAccounts()
      }
    })

    return {
      information,
      size,
      style,
      color,
      table,
      handleClick,
      activeName,
      self,
      son,
      moreType,
      changeDate,
      setDate,
      getAccount,
      recordinformation,
      getInformation,
      AccountToSon,
      isloading,
      handleCurrentChange,
      total,
      userInfo,
      getAccounts,
      getInformations,
    }
  },
})
