import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Fixed = _resolveComponent("Fixed")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "wodeneirongbox",
      style: _normalizeStyle({ height: _ctx.height * _ctx.scale + 'px' })
    }, [
      _createElementVNode("div", {
        class: _normalizeClass({ wodeneirong: _ctx.windowWidth < 1600 }),
        style: _normalizeStyle(_ctx.styleObject),
        ref: "el"
      }, [
        _createVNode(_component_router_view)
      ], 6)
    ], 4),
    _createVNode(_component_Fixed)
  ], 64))
}