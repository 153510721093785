
import { defineComponent, inject } from "vue"
import { useRouter } from "vue-router"
import formdata from "@/utils/dateFormat"
export default defineComponent({
	props: {
		goods: {
			type: Object,
			default: () => ({}),
		},
		aid: {
			type: Number,
			default: 0,
		},
		sort: {
			type: Number,
			default: 0,
		},
	},

	setup(props: any) {
		let router = useRouter()
		let goto = (cid: number, id: number) => {
			const { href } = router.resolve({
				name: "video-info",
				params: { id: id, attach: cid + "-" + props.aid + "-" + props.sort },
			})
			window.open(href, "_blank")
		}
		let sttrsCut = (sttrs: string, division: string = " ") => {
			sttrs = sttrs.trim()
			return (sttrs || "").split(division)
		}
		const staticUrl = inject("staticUrl")
		let imgUrl = (goodsnames: string, cover: string) => {
			if (cover) {
				return cover
			} else {
				let modleName = goodsnames.split("：")[1]
				return staticUrl + "/filespath/files/video/img/" + modleName + ".jpg"
			}
		}
		let dateFormatMap = (time: number) => {
			return formdata.videoDateFormat(time)
		}
		let videoFormat = (time: number) => {
			return formdata.durationFormat(time)
		}
		return {
			goto,
			sttrsCut,
			dateFormatMap,
			imgUrl,
			videoFormat,
		}
	},
})
