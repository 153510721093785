import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53f68c87"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "goods-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoodsItem = _resolveComponent("GoodsItem")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodslit, (item, index) => {
      return (_openBlock(), _createElementBlock("li", null, [
        _createVNode(_component_GoodsItem, { goodsinfo: item }, null, 8, ["goodsinfo"])
      ]))
    }), 256))
  ]))
}