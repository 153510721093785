
import { defineComponent, inject } from "vue"

export default defineComponent({
	components: {},
	props: {
		goods: {
			type: Object,
			default: () => ({}),
		},
	},
	setup() {
		const staticUrl = inject("staticUrl")
		let changeUrl = (val: any) => {
			if (val.startsWith("https")) {
				return val
			} else {
				return staticUrl + val
			}
		}
		let imgUrl = (goodsnames: string, cover: string) => {
			if (cover) {
				return cover
			} else {
				let modleName = goodsnames.split("：")[1]
				return staticUrl + "/filespath/files/video/img/" + modleName + ".jpg"
			}
		}
		return { changeUrl, imgUrl }
	},
})
