
import { goodsApi } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
import { showPrice, updateCartTotal } from "@/utils/public"
import { defineComponent, inject } from "vue"
import { useRouter } from "vue-router"

export default defineComponent({
	components: {},
	props: {
		goods: {
			type: Object,
			default: () => ({}),
		},
	},
	setup(props: any) {
		const staticUrl = inject("staticUrl")
		const router = useRouter()
		let changeUrl = (val: any) => {
			if (val.startsWith("https")) {
				return val
			} else {
				return staticUrl + val
			}
		}

		//加入购物车
		let addCart = () => {
			console.log(props.goods.is_real_goods, "ssss")

			goodsApi.addCart(props.goods.id, false, 1, 1).then((res: any) => {
				if (res.errno == 0) {
					updateCartTotal()
					sjyAlert("添加成功", "success")
				} else {
					sjyAlert(res.errmsg, "error")
				}
			})
		}
		let goto = () => {
			router.push({ name: "productDetail", params: { id: props.goods.id } })
		}
		return { changeUrl, addCart, goto, showPrice }
	},
})
