
import { ref, defineComponent, reactive, onMounted } from "vue";
import { getVipInfo } from "@/utils/public";
import { userStore } from "@/stores/userStore";
export default defineComponent({
  components: {},

  props: {
    getInformation: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any) {
    //console.log(props);

    let user = props.getInformation;
    //console.log(user);

    let vipinfo = getVipInfo({
      vipInfo: { vipid: user.vip_id, vipType: user.vip_type },
    });

    let userinfot = userStore();

    onMounted(() => {});
    console.log(user);
    // onMounted(() => {
    //   user();
    // });

    return {
      user,
      vipinfo,
      userinfot,
    };
  },
});
