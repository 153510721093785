
import { reactive, ref, defineComponent, toRefs, onMounted } from "vue"
import type { FormInstance, FormRules } from "element-plus"
import { sjyAlert } from "@/utils/alert"
import Dialog from "@/components/common/Dialog/Dialog.vue"
import { orderApi } from "@/service/api"
import { invoiceType } from "@/utils/apitype/invoiceType"
export default defineComponent({
	components: {
		Dialog,
	},
	setup() {
		const tableData = reactive({
			data: [] as Array<invoiceType>,
		})

		let getInvoiceList = () => {
			orderApi.getInvoiceList().then((res: any) => {
				if (res.errno == 0) {
					tableData.data = res.datas
				}
			})
		}

		// 发票表单信息
		const ruleForm = reactive({
			company: "",
			duty: "",
			com_address: "",
			com_mobile: "",
			bank: "",
			bank_nu: "",
			id: 0,
		})

		const rules = reactive<FormRules>({
			company: [
				{
					required: true,
					message: "请输入单位名称",
					trigger: "blur",
				},
				{ min: 2, max: 5, message: "姓名长度为2-5字符", trigger: "blur" },
			],
			com_mobile: [
				{
					required: true,
					message: "请输入单位手机号",
					trigger: "blur",
				},
				{
					pattern: /^(\+\d{1,2}\s?)?1[3-9]\d{9}$|^0\d{2,3}-\d{7,8}$/,
					message: "手机号格式错误",
					trigger: "blur",
				},
			],
			com_address: [
				{
					required: true,
					message: "请输入单位地址",
					trigger: "blur",
				},
			],
			duty: [
				{
					required: true,
					message: "请输入单位税号",
					trigger: "blur",
				},
			],
			bank: [
				{
					required: true,
					message: "请输入开户银行名称",
					trigger: "blur",
				},
			],
			bank_nu: [
				{
					required: true,
					message: "请输入银行账号",
					trigger: "blur",
				},
			],
		})
		let deleteid = ref(0)
		//  确认删除弹窗
		let dialogVisibles = ref(false)
		let setDialogVisibles = (index: number) => {
			deleteid.value = parseInt(tableData.data[index].id)
			del.value?.setVisible(true)
		}
		const del = ref<InstanceType<typeof Dialog>>()
		// 关闭弹框
		let mcloses = (isdelete: Boolean) => {
			dialogVisibles.value = false
			del.value?.setVisible(dialogVisibles.value)
			if (isdelete) {
				orderApi.deleteInvoice(deleteid.value).then((res: any) => {
					if (res.errno == 0) {
						sjyAlert("已删除", "success", getInvoiceList)
					} else {
						sjyAlert(res.errmsg, "error")
					}
				})
			}
		}

		//  新增抬头弹窗
		let dialogVisible = ref(false)
		let setDialogVisible = (index: number) => {
			userinfor.value?.setVisible(true)
			initTable(index)
		}
		let initTable = (index: number) => {
			if (index < 0) {
				ruleForm.company = ""
				ruleForm.duty = ""
				ruleForm.com_address = ""
				ruleForm.com_mobile = ""
				ruleForm.bank = ""
				ruleForm.bank_nu = ""
				ruleForm.id = 0
			} else {
				ruleForm.company = tableData.data[index].company
				ruleForm.duty = tableData.data[index].duty
				ruleForm.com_address = tableData.data[index].com_address
				ruleForm.com_mobile = tableData.data[index].com_mobile
				ruleForm.bank = tableData.data[index].bank
				ruleForm.bank_nu = tableData.data[index].bank
				ruleForm.id = parseInt(tableData.data[index].id)
			}
		}

		const userinfor = ref<InstanceType<typeof Dialog>>()
		// 关闭弹框
		let mclose = () => {
			dialogVisible.value = false
			userinfor.value?.setVisible(dialogVisible.value)
			getInvoiceList()
		}
		const ruleFormRef = ref<FormInstance>()
		const submitForms = async (formEl: FormInstance | undefined) => {
			if (!formEl) return
			await formEl.validate((valid, fields) => {
				if (valid) {
					if (ruleForm.id > 0) {
						orderApi
							.editInvoiceInfo(
								ruleForm.company,
								ruleForm.duty,
								ruleForm.com_address,
								ruleForm.com_mobile,
								ruleForm.bank,
								ruleForm.bank_nu,
								ruleForm.id
							)
							.then((res: any) => {
								if (res.errno == 0) {
									sjyAlert("已修改", "success", mclose)
								} else {
									sjyAlert(res.errmsg, "error")
								}
							})
					} else {
						orderApi
							.addInvoiceInfo(
								ruleForm.company,
								ruleForm.duty,
								ruleForm.com_address,
								ruleForm.com_mobile,
								ruleForm.bank,
								ruleForm.bank_nu
							)
							.then((res: any) => {
								if (res.errno == 0) {
									sjyAlert("已保存", "success", mclose)
								} else {
									sjyAlert(res.errmsg, "error")
								}
							})
					}
					// 上传发票title
				} else {
					console.log("error submit!", fields)
				}
			})
		}
		onMounted(() => {
			getInvoiceList()
		})

		return {
			tableData,
			mcloses,
			setDialogVisibles,
			del,
			userinfor,
			dialogVisible,
			setDialogVisible,
			mclose,
			ruleForm,
			rules,
			submitForms,
			ruleFormRef,
		}
	},
})
