
import { defineComponent, reactive, ref, onMounted, watch } from "vue"
import HomeHead from "@/components/HomeHead.vue"
import CurrentAddress from "@/components/common/CurrentAddress.vue"
import HtmlFooter from "@/components/HtmlFooter.vue"
import LoginIntroduceVue from "@/components/guidance/LoginIntroduce.vue"
import Function from "@/components/guidance/Function.vue"
import Shopping from "@/components/guidance/Shopping.vue"
import CheckOrder from "@/components/guidance/CheckOrder.vue"
import Download from "@/components/guidance/Downloads.vue"
import Consult from "@/components/guidance/Consult.vue"
import Question from "@/components/guidance/Question.vue"
import WorkUpload from "@/components/guidance/WorkUpload.vue"
import WorkCheck from "@/components/guidance/WorkCheck.vue"
import Earnings from "@/components/guidance/Earnings.vue"
import ShiyongShuoming from "@/components/guidance/ShiyongShuoming.vue"
import { newUserGuide } from "@/utils/public"
import { useRoute, useRouter } from "vue-router"

export default defineComponent({
  components: {
    HomeHead,
    CurrentAddress,
    HtmlFooter,
    LoginIntroduceVue,
    Function,
    Shopping,
    CheckOrder,
    Download,
    Question,
    Consult,
    WorkUpload,
    WorkCheck,
    Earnings,
    ShiyongShuoming,
  },
  setup() {
    let route = useRoute()
    let router = useRouter()
    let comName = ref("LoginIntroduceVue")
    watch(comName, () => {
      router.push({
        name: "guidance",
        params: {
          comName: comName.value,
        },
      })
    })
    watch(route, () => {
      router.go(0)
    })
    let nowClassList = reactive({ data: newUserGuide })

    let clickClass = (index: number, indexson: number) => {
      for (let i = 0; i < nowClassList.data.length; i++) {
        nowClassList.data[i].isChecked = false
        for (let j = 0; j < nowClassList.data[i].list.length; j++) {
          nowClassList.data[i].list[j].isChecked = false
        }
      }
      nowClassList.data[index].isChecked = true
      if (nowClassList.data[index].list.length > 0) {
        nowClassList.data[index].list[indexson].isChecked = true
        comName.value = nowClassList.data[index].list[indexson].path
      } else {
        comName.value = nowClassList.data[index].path
      }
      window.scrollTo(0, 0)
    }

    let clickOpenClass = (index: number) => {
      nowClassList.data[index].open = !nowClassList.data[index].open
    }
    let initClass = () => {
      if (route.params.comName) {
        for (let i = 0; i < nowClassList.data.length; i++) {
          if (nowClassList.data[i].path == route.params.comName) {
            clickClass(i, 0)
            if (route.params.comName) {
              comName.value = route.params.comName as string
            }
            break
          }
          for (let j = 0; j < nowClassList.data[i].list.length; j++) {
            if (nowClassList.data[i].list[j].path == route.params.comName) {
              clickClass(i, j)
              clickOpenClass(i)
              if (route.params.comName) {
                comName.value = route.params.comName as string
              }
              break
            }
          }
        }
      }
    }
    onMounted(() => {
      initClass()
    })

    return { clickClass, nowClassList, comName, clickOpenClass }
  },
})
