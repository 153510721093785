
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  inject,
  provide,
} from "vue";
import HtmlFooter from "../../../components/HtmlFooter.vue";
import HomeHead from "../../../components/HomeHead.vue";
import CurrentAddress from "@/components/common/CurrentAddress.vue";
import type { TabsPaneContext } from "element-plus";
import Works from "./Works.vue";
import UserInformation from "./UserInformation.vue";
import FirmInformation from "./FirmInformation.vue";
import Fans from "./Fans.vue";
import Attention from "./Attention.vue";
import workLack from "./component/workLack.vue";
import fansLack from "./component/fansLack.vue";
import attenLack from "./component/attenLack.vue";
import { userApi } from "@/service/api";
import { useRoute, useRouter } from "vue-router";
import { userStore } from "@/stores/userStore";
export default defineComponent({
  components: {
    HtmlFooter,
    HomeHead,
    CurrentAddress,
    UserInformation,
    FirmInformation,
    Works,
    Fans,
    Attention,
    workLack,
    fansLack,
    attenLack,
  },
  setup() {
    let userId = useRoute().params.userid;
    //console.log("我是userid，我的值是：", userId);
    interface result {
      data: any;
    }
    // user信息容器
    let getInformation: result = reactive({
      data: {},
    });
    let self = ref(true);
    let userinfot = userStore();
    const activeName = ref("first");
    const handleClick = (tab: TabsPaneContext, event: Event) => {
      if (tab.index == "2") {
        tabs.show = "Fans";
      }
      if (tab.index == "0") {
        tabs.show = "Works";
      }
      if (tab.index == "1") {
        tabs.show = "Attention";
      }
    };

    let tabs = reactive({
      show: "works",
    });

    let information = reactive({
      show: "firm-information",
    });

    //  个人主页用户信息

    let getUserHome = () => {
      userApi.userHome(Number(userId)).then((res: any) => {
        getInformation.data = res.data;
        //console.log(getInformation.data, "aa");

        if (res.data.vis == 1) {
          self.value = true;
        }
        if (res.data.vis == 2) {
          self.value = false;
        }
        if (res.data.type == "1") {
          information.show = "firm-information";
        }
        if (res.data.type == "0") {
          information.show = "user-information";
        }
      });
    };
    let followId = reactive({
      follow: "",
      fan: "",
    });

    let userinfor: result = reactive({
      data: {},
    });

    let getuserInformation = () => {
      userApi.getInformation().then((res: any) => {
        userinfor.data = res.datas;
        // console.log(res);
      });
    };
    //点击顶部关注他 关注
    let dofollow = (val: number) => {
      followId.fan = userinfor.data.id;
      followId.follow = userId + "";
      //console.log(followId.follow, followId.fan);

      userApi.userFollow(followId).then((res: any) => {
        // console.log(res);
        location.reload();
      });
    };

    let getImgUrl = inject(
      "getImgUrl",
      (index: string) => {
        return "";
      },
      false
    );

    // 从子组件处获取头像信息 追加到页面
    let photo = ref("");
    let transmitData = (photos: any) => {
      console.log(photos, "111");
      photo.value = photos;
    };

    provide("self", self);

    onMounted(() => {
      getUserHome();
      getuserInformation();
    });

    return {
      self,
      handleClick,
      activeName,
      tabs,
      getUserHome,
      information,
      getInformation,
      followId,
      dofollow,
      getImgUrl,
      userinfot,
      transmitData,
      photo,
      userinfor,
    };
  },
});
