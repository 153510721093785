import { defineStore } from "pinia"
//import { user } from './modules/user'

// export default defineStore ({
//   state: {//根本，相当于数据库
//   },
//   getters: {//外部获取数据接口
//   },
//   mutations: { //修改数据唯一途径
//   },
//   actions: { //外部修改数据接口，交给mutations的方法处理数据
//   },
//   modules: {  //子模块
//     user:user
//   }
// })
export const userStore = defineStore("userInfo", {
  // 推荐使用 完整类型推断的箭头函数
  state: () => {
    return {
      // 所有这些属性都将自动推断其类型
      id: 0,
      mobile: "", //手机号
      vipid: 0,
      portrait: "", //头像
      token: "", //登录名称
      username: "", //用户名
      pid: 0,
      //姓名
      name: "",
      //公司名
      company: "",
      //职业
      vocation: "",
      //常用软件
      software: "",
      vipType: 0, //会员类型  1个人 2公司
      vipTitle: "", //会员名称
      vipEtime: 0, //会员结束时间
      vipId: 0, //会员结束时间
      cartTotal: 0,
      cartType:0,
    }
  },
  actions: {
    /**
     * 登录完成后保存用户信息
     */
    setUserInfo(userInfo: any) {
      this.id = userInfo.id
      this.pid = userInfo.pid
      this.mobile = userInfo.mobile
      this.vipid = userInfo.vipid
      this.portrait = userInfo.portrait
      this.token = userInfo.token
      this.username = userInfo.username

      if (userInfo.name) {
        this.name = userInfo.name
      }
      if (userInfo.company) {
        this.company = userInfo.company
      }
      if (userInfo.vocation) {
        this.vocation = userInfo.vocation
      }
      if (userInfo.software) {
        this.software = userInfo.software
      }
      if (userInfo.vip_id) {
        this.vipid = userInfo.vip_id
      }
      if (userInfo.vip_title) {
        this.vipTitle = userInfo.vip_title
      }
      if (userInfo.vip_etime) {
        this.vipEtime = userInfo.vip_etime
      }
      if (userInfo.vip_type) {
        this.vipType = userInfo.vip_type
      }

      //保存用户基本信息到缓存
      let user = {
        id: this.id,
        pid: this.pid,
        mobile: this.mobile,
        portrait: this.portrait,
        username: this.username,
        token: this.token,
        name: this.name,
        company: this.company,
        vocation: this.vocation,
        software: this.software,
        vipType: this.vipType,
        vipTitle: this.vipTitle,
        vipEtime: this.vipEtime,
        vipid: this.vipid,
      }
      //JSON.parse(localStorage.getItem(‘logo’))
      localStorage.setItem("userInfo", JSON.stringify(user))
      localStorage.setItem("token", this.token)
    },
    /**
     * gengxin store
     * @param userInfo
     */
    updateUserInfo(newUserInfo: any) {
      let userInfo = JSON.parse(localStorage.getItem("userInfo") as string)
      this.setUserInfo(Object.assign(userInfo, newUserInfo))
    },
    /**
     * 通过缓存保存stores
     * @param userInfo
     */
    setUserInfoByStorage(userInfo: any) {
      this.id = userInfo.id
      this.pid = userInfo.pid
      this.mobile = userInfo.mobile
      this.vipid = userInfo.vipid
      this.portrait = userInfo.portrait
      this.token = userInfo.token
      this.username = userInfo.username
      this.name = userInfo.name
      this.company = userInfo.company
      this.vocation = userInfo.vocation
      this.software = userInfo.software
      this.vipType = userInfo.vipType
      this.vipTitle = userInfo.vipTitle
      this.vipEtime = userInfo.vipEtime
      this.cartTotal = userInfo.cartTotal
    },
    /**
     * 退出登录
     */
    deleteUserInfo() {
      //console.log("我这是退出登录");
      this.id = 0
      this.mobile = ""
      this.pid = 0
      this.vipid = 0
      this.portrait = ""
      this.token = ""
      this.username = ""
      this.name = ""
      this.company = ""
      this.vocation = ""
      this.software = ""
      this.vipType = 0
      this.vipTitle = ""
      this.vipEtime = 0
      localStorage.removeItem("userInfo")
      localStorage.removeItem("token")
    },

    /**
     * 更新购物车
     */
    updateCartTotal(total: number) {
      this.cartTotal = total
      let userInfo = JSON.parse(localStorage.getItem("userInfo") as string)
      userInfo.cartTotal = total
      localStorage.setItem("userInfo", JSON.stringify(userInfo))
    },

    /**
     * 更新头像
     */
    updateHeaderImg(imgUrl: string) {
      this.portrait = imgUrl
      let userInfo = JSON.parse(localStorage.getItem("userInfo") as string)
      userInfo.portrait = imgUrl
      localStorage.setItem("userInfo", JSON.stringify(userInfo))
    },


    /**
     * 更新会员信息
     */
    updateVip(
      vipType: number,
      vipTitle: string,
      vipEtime: number,
      vipid: number
    ) {
      // vipType: 0, //会员类型  1个人 2公司
      // vipTitle: "", //会员名称
      // vipEtime: 0, //会员结束时间
      this.vipType = vipType
      this.vipTitle = vipTitle
      this.vipEtime = vipEtime
      this.vipid = vipid

      let userInfo = JSON.parse(localStorage.getItem("userInfo") as string)
      userInfo.vipType = vipType
      userInfo.vipTitle = vipTitle
      userInfo.vipEtime = vipEtime
      userInfo.vipid = vipid
      localStorage.setItem("userInfo", JSON.stringify(userInfo))
    },
  },

  getters: {
    /**
     * 获取折扣
     * @param state
     * @returns number 折扣
     */
    discount: (state) => {
      let nowtime = new Date()
      if (
        (state.vipType == 1 && state.vipEtime > nowtime.getTime() / 1000) ||
        state.vipType == 2 ||
        state.pid > 0
      ) {
        return 0.8
      }
      return 1
    },

    vipInfo: (state) => {
      let nowtime = new Date()
      if (
        (state.vipType == 1 && state.vipEtime > nowtime.getTime() / 1000) ||
        state.vipType == 2 ||
        state.pid > 0
      ) {
        return {
          vipType: state.vipType,
          vipTitle: state.vipTitle,
          vipid: state.vipid,
        }
      } else {
        return {
          vipType: 0,
          vipTitle: "普通用户",
          vipid: 0,
        }
      }
    },

    /**
     * 判断是否是会员
     * @param state
     * @returns
     */
    isVip: (state) => {
      let nowtime = new Date()
      return (
        (state.vipType == 1 && state.vipEtime > nowtime.getTime() / 1000) ||
        state.vipType == 2 ||
        state.pid > 0
      )
    },
  },
})
