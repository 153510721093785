
import { defineComponent } from "vue";
import CustomerTitle from "./common/CustomerTitle.vue";
import { fangankefu } from "@/utils/alert";

export default defineComponent({
  components: { CustomerTitle },
  setup() {
    let titleList = ["支付方式", "支付遇到问题"];
    return { titleList, fangankefu };
  },
});
