
import {
	defineComponent,
	onMounted,
	reactive,
	ref,
	computed,
	inject,
	watch,
	nextTick,
} from "vue"
import Share from "@/components/common/Share.vue"
export default defineComponent({
	components: { Share },
})
