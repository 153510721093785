
import { defineComponent, onMounted, provide, reactive, ref } from "vue"
import MyOrderList from "@/components/user/order/MyOrderList.vue"
import { orderApi } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
import Upload from "@/components/common/Upload.vue"
import { useRoute } from "vue-router"

export default defineComponent({
  components: { MyOrderList, Upload },
  setup() {
    let goodsWhere = reactive({
      data: {
        name: "",
        nu: "",
        status: 0,
        uid: "",
        start: 0,
        end: 0,
        pageIndex: 1,
        pageSize: 20,
      },
    })

    let route = useRoute()

    if (parseInt(route.params.status as string) == 1) {
      //待付款
      goodsWhere.data.status = 1
    } else if (parseInt(route.params.status as string) == 4) {
      goodsWhere.data.status = 4
    }

    let total = ref(0)
    let loading = ref(true)
    let getMyOrder = (type: number = 0) => {
      loading.value = true
      orderApi.getMyOrder(goodsWhere.data).then((res: any) => {
        if (res.errno == 0) {
          orderList.value = res.datas.list
          total.value = res.datas.total

          loading.value = false
        } else {
          sjyAlert(res.errmsg, "error")
        }
      })
    }

    let setWhere = (where: any) => {
      //console.log("我执行了", where, goodsWhere.data)

      if (where.name) {
        goodsWhere.data.name = where.name
      }
      if (where.uid) {
        goodsWhere.data.uid = where.uid
      }
      if (where.start) {
        goodsWhere.data.start = where.start[0]
        goodsWhere.data.end = where.start[1]
      } else {
        goodsWhere.data.start = 0
        goodsWhere.data.end = 0
      }
      goodsWhere.data.pageIndex = 1
      getMyOrder()
      //console.log("我执行了", where, goodsWhere.data)
      //goodsWhere.data = where;
    }

    interface goods {
      id: number
      money: number
      names: string
      photo: string
    }
    interface order {
      id: number
      create_time: string
      forRrestof: number
      oid: string
      price: number
      send_status: number
      son: Array<goods>
      status: number
    }
    let orderList = ref<order[]>([])

    let handleCurrentChange = (val: number) => {
      //console.log(goodsWhere.data)

      goodsWhere.data.pageIndex = val
      getMyOrder()
    }
    let setOrderStatus = (orderStatus: number) => {
      goodsWhere.data.name = ""
      goodsWhere.data.uid = ""
      goodsWhere.data.start = 0
      goodsWhere.data.end = 0
      goodsWhere.data.status = orderStatus
      getMyOrder()
    }

    /**删除订单 */
    let deleteOrder = (index: number) => {
      orderList.value.splice(index, 1)
    }
    provide("deleteOrder", deleteOrder)

    /**取消订单 */
    let cancelOrder = (index: number) => {
      orderList.value[index].status = 3
    }
    provide("cancelOrder", cancelOrder)
    // provide('getMyOrder',getMyOrder);
    onMounted(() => {
      getMyOrder()
    })

    return {
      setWhere,
      goodsWhere,
      orderList,
      total,
      handleCurrentChange,
      setOrderStatus,
      loading,
    }
  },
})
