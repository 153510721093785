import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65386a20"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "img-info" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imgUrl, (item, index) => {
      return (_openBlock(), _createElementBlock("img", {
        src: _ctx.getimgUrl(item)
      }, null, 8, _hoisted_2))
    }), 256))
  ]))
}