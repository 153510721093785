import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bc48495"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "edit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "header",
    style: _normalizeStyle({
      height: _ctx.headerHeight + 'px',
      'line-height': _ctx.headerHeight + 'px',
      'background-color': _ctx.headerBC,
    })
  }, [
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "title", {}, undefined, true)
    ]),
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "edit", {}, undefined, true)
    ])
  ], 4))
}