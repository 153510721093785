
import { defineComponent, ref, nextTick, inject } from "vue"
import "vue3-video-play/dist/style.css"
//@ts-expect-error
import vue3VideoPlay from "vue3-video-play"
export default defineComponent({
	components: { vue3VideoPlay, },
	props: ["goodsDetail"],
	setup(props: any) {
		let videoUrl = ref("")
		let staticUrl = inject("staticUrl")
		let isImg = ref(false)
		let getImgUrl = inject(
			"getImgUrl",
			(index: string) => {
				return ""
			},
			false
		)
		videoUrl.value = getImgUrl(props.goodsDetail.bodys2[0])
		let suffix = ""
		try {
			var flieArr = videoUrl.value.split(".")
			suffix = flieArr[flieArr.length - 1]
		} catch (err) {
			suffix = ""
		}
		let imglist = ["png", "jpg", "jpeg", "bmp", "gif"]
		// 进行图片匹配
		let result = imglist.some(function (item) {
			return item == suffix
		})

		if (result) {
			isImg.value = true
		}

		return { videoUrl, isImg, getImgUrl }
	},
})
