<template>
	<div class="contents-bottom-info-contents">
		<div class="left">
			<img :src="info.imgurl" />
		</div>
		<div class="right">
			<div v-for="(item, index) in info.list" class="item">
				<p class="title">要点{{ index + 1 }}</p>
				<p class="title1">{{ item.T1 }}</p>
				<template v-if="item.T2">
					<p v-for="(items, indexs) in item.T2.split('|')" class="title2">
						{{ items }}
					</p>
				</template>

				<img v-if="check_is_img(item.pic)" :src="item.pic" />
			</div>
		</div>
	</div>
</template>
<script>
import { reactive, toRefs, props, defineComponent } from "vue"

export default defineComponent({
	setup() {
		const state = reactive({
			count: 0,
		})

		let check_is_img = (url) => {
			return url.match(/\.(jpeg|jpg|gif|png)$/) != null
		}

		return {
			...toRefs(state),
			check_is_img,
		}
	},
	props: {
		info: {
			type: Array,
			default: () => [],
		},
	},
})
</script>

<style scoped lang="scss">
.contents-bottom-info-contents {
	width: 90% !important;
	margin: 0 auto;
	overflow: hidden;

	.left {
		width: 610px;
		float: left;
		img {
			width: 100%;
		}
	}
	.right {
		width: 614px;
		float: left;
		padding-top: 40px;
		.item {
			padding-left: 20px;
			font-size: 16px;
			margin-bottom: 40px;
			.title {
				color: #fbb113;
				margin-bottom: 9px;
			}
			.title1 {
				color: #333333;
				margin-bottom: 9px;
			}
			.title2 {
				font-size: 14px;
				color: #666666;
				line-height: 1.5;
			}
			img {
				max-width: 80%;
				margin-left: 10%;
			}
		}
	}
}
</style>
