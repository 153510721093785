
import { defineComponent, onMounted, reactive, ref } from "vue"
import { sjyAlert } from "@/utils/alert"
import { goodsApi } from "@/service/api"
import { useRouter } from "vue-router"
export default defineComponent({
	components: {},
	setup() {
		const isActive = ref(0)
		const title = [
			{ name: "全部", cid: 0 },
			{ name: "自动化机构案例", cid: 2 },
			{ name: "机械手", cid: 3 },
			{ name: "输送机构", cid: 4 },
			{ name: "提升移载类", cid: 5 },
			{ name: "非标设备", cid: 6 },
			{ name: "自动化单元", cid: 7 },
			{ name: "末端执行器", cid: 8 },
		]
		let cid = ref(2)
		let active = (val: number) => {
			isActive.value = val
			if (val == 0) {
				cid.value = title[val].cid
				sort.value = 2
				pageIndex.value = 1
			} else if (val == 1) {
				cid.value = title[val].cid
				sort.value = 2
				pageIndex.value = 2
			} else {
				cid.value = title[val].cid
				sort.value = 0
				pageIndex.value = 1
			}
			getFreeGoodsList()
		}
		interface goodsListType {
			data: any
			errno: number
			errmsg: string
			total: number
			pageSize: number
		}
		let goodsList: goodsListType = reactive({
			data: [],
			errno: 0,
			errmsg: "",
			total: 0,
			pageSize: 20,
		})

		let sort = ref(2)
		let pageIndex = ref(1)
		let getFreeGoodsList = () => {
			goodsApi
				.getFreeGoodsList(cid.value, [], pageIndex.value, 5, sort.value)
				.then((res: any) => {
					if (res.errno == 0) {
						goodsList.data = res.datas.data
						goodsList.errno = res.errno
						goodsList.errmsg = res.errmsg
						goodsList.pageSize = res.datas.pageSize
						goodsList.total = res.datas.total
						//console.log(res.datas)
					} else {
						sjyAlert(res.errmsg, "error")
					}
				})
		}
		let router = useRouter()
		let goto = (id: number) => {
			const { href } = router.resolve({
				name: "free-goods",
				params: {
					id: id,
				},
			})
			window.open(href, "_blank")
		}
		let goClass = () => {
			const { href } = router.resolve({
				name: "free",
				params: {
					cid: title[isActive.value].cid,
				},
			})
			window.open(href, "_blank")
			return false
		}
		onMounted(() => {
			getFreeGoodsList()
		})
		return {
			isActive,
			title,
			active,
			goodsList,
			goto,
			goClass,
		}
	},
})
