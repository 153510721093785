
import {
	defineComponent,
	ref,
	nextTick,
	reactive,
	onMounted,
	onUnmounted,
	inject,
} from "vue"
import HeaderNav from "@/components/HeaderNav.vue"
import GoodsSwiper from "@/components/goods/FreeGoodsSwiper.vue"
import FreeGoodsImg from "@/components/goods/FreeGoodsImg.vue"
import NavList from "@/components/NavList.vue"
import HtmlFooter from "@/components/HtmlFooter.vue"
import BottomItem from "@/components/goods/BottomItem.vue"
import GoodsCollection from "@/components/goods/GoodsCollection.vue"
import { goodsApi } from "@/service/api"
import { useRouter } from "vue-router"
import HomeHead from "@/components/HomeHead.vue"
import { userStore } from "@/stores/userStore"
var ws: any
export default defineComponent({
	name: "Index",
	components: {
		HeaderNav,
		GoodsSwiper,
		NavList,
		HtmlFooter,
		BottomItem,
		GoodsCollection,
		HomeHead,
		FreeGoodsImg,
	},
	setup() {
		let getPicList = () => {
			if (goodsList.datas.pic_cs.length == 4) {
				return goodsList.datas.pic_cs
			} else {
				return goodsList.datas.pic_cs
			}
		}
		const router = useRouter()
		let id = router.currentRoute.value.params.id
		let goodsList = reactive<any>({})

		let showmore = ref(true)
		let toShowMore = () => {
			showmore.value = !showmore.value
			contentsHeight()
		}
		let isFixed = ref(false)
		let isFixedBottom = ref(false)
		let header: any = reactive({})
		let infoList: any = reactive({})
		let Mapp: any = reactive({})

		let headerTop = ref(0)
		let headerHeight = ref(0)
		let mTotalHeight = ref(0)
		let isShowHeight = ref(false)

		//获取滚动的高度
		const onScroll = () => {
			// 得到页面滚动的距离，兼容写法
			const scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop
			nextTick(() => {
				if (goodsList.datas) {
					isFixed.value = scrollTop >= headerTop.value + headerHeight.value
					isFixedBottom.value = mTotalHeight.value - scrollTop < 1024
				}
			})
			// 滚动到固定距离吸顶

			//console.log(isFixedBottom.value)
			// console.log(mTotalHeight.value-scrollTop)
		}

		//获取上面的高度
		const contentsHeight = () => {
			nextTick(() => {
				header = document.getElementById("headerTop")
				infoList = document.getElementById("infoList")
				Mapp = document.getElementById("app")
				setTimeout(() => {
					headerTop.value = header.offsetTop
					headerHeight.value = header.offsetHeight
					isShowHeight.value = infoList.offsetHeight > 570
					mTotalHeight.value = Mapp.offsetHeight
				}, 1000)

				// console.log('开始了',mTotalHeight.value,infoList.offsetHeight,headerHeight.value,'结束了')
			})
		}

		let updateHeight = () => {
			nextTick(() => {
				mTotalHeight.value = Mapp.offsetHeight
				isFixedBottom.value = false
			})
		}

		//获取产品详细信息
		let getGoodsInfo = () => {
			let goodsWhere = {
				id: id,
			}
			let userinfo = userStore()
			goodsApi.getFreeGoodsInfo(goodsWhere).then((res: any) => {
				if (res.errno == 0) {
					goodsList.datas = res.datas
					swiperShow.value = goodsList.datas.type == 2
					contentsHeight()
					if (userinfo.id > 0) {
						goodsApi
							.addGoodsTrack(goodsList.datas.id, 2, 1)
							.then((res: any) => {
								//console.log(res);
							})
					}
				}
			})
		}
		//let router = useRouter()
		let gotoClass = (id: string) => {
			router.push({
				name: "free",
				params: {
					cid: goodsList.datas.cid,
					aid: id,
				},
			})
		}

		onMounted(() => {
			window.addEventListener("scroll", onScroll)
			getGoodsInfo()
		})
		let entryTime = Date.now()
		const addDuration = () => {
			const leaveTime = Date.now()
			const duration = leaveTime - entryTime
			localStorage.setItem(
				"duration",
				goodsList.datas.id + "_" + 2 + "_" + duration
			)

			goodsApi
				.addGoodsDuration(goodsList.datas.id, 2, duration)
				.then((res: any) => {
					localStorage.removeItem("duration")
				})
		}
		onUnmounted(() => {
			addDuration()
			window.removeEventListener("scroll", onScroll)
		})
		addEventListener("beforeunload", addDuration)

		let staticUrl = inject("staticUrl")
		let resetImg = () => {
			let tmepImg = []
			if (goodsList.datas.old_pics && goodsList.datas.old_pics.length > 0) {
				for (let i = 0; i < goodsList.datas.old_pics.length; i++) {
					tmepImg.push(goodsList.datas.old_pics[i] as string)
				}
			}

			return tmepImg
		}
		let fisOutside = ref(false)
		let position = reactive({
			x: 0,
			y: 0,
		})
		let nowImgUrl = ref("")

		let setPosition = (
			x: number,
			y: number,
			isOutside: boolean,
			activeIndex: number = 0
		) => {
			position.x = x
			position.y = y
			fisOutside.value = isOutside
			let imgList = resetImg()
			nowImgUrl.value = imgList[activeIndex]
		}

		let setPositionImg = (
			x: number,
			y: number,
			isOutside: boolean,
			activeIndex: number = 0
		) => {
			position.x = x
			position.y = y
			fisOutside.value = isOutside
			let imgList = resetImg()
			nowImgUrl.value = imgList[activeIndex]
			//console.log(imgList, "视频列表")
		}

		let swiperShow = ref(false)

		return {
			showmore,
			toShowMore,
			onScroll,
			isFixed,
			contentsHeight,
			isShowHeight,
			goodsList,
			updateHeight,
			isFixedBottom,
			id,
			fisOutside,
			nowImgUrl,
			position,
			setPosition,
			resetImg,
			gotoClass,
			setPositionImg,
			swiperShow,
			getPicList,
		}
	},
})
