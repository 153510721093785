
import { useRouter } from "vue-router";
import { defineComponent, inject } from "vue";
import { showPrice } from "@/utils/public";

export default defineComponent({
  components: {},
  props: {
    goodslit: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    listwidth: {
      type: Number,
      default: 1450,
    },
  },
  setup(props: any) {
    let router = useRouter();
    let gotoGoods = (goodsid: number) => {
      const { href } = router.resolve({
        name: "productDetail",
        params: {
          id: goodsid,
        },
      });
      window.open(href, "_blank");
      return false;
    };
    let getImgUrl = inject(
      "getImgUrl",
      (index: string) => {
        return "";
      },
      false
    );
    return { gotoGoods, getImgUrl, showPrice };
  },
});
