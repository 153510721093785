
import { useRouter } from "vue-router"
import { defineComponent, reactive, ref } from "vue"
import VideoGoodsItem from "./FreeVideoGoodsItem.vue"

export default defineComponent({
	components: { VideoGoodsItem },
	props: {
		goodsList: {
			type: Array,
			default: () => [],
		},
		/**
		 * 0 请求中  1请求完成
		 */
		loadingType: {
			type: Number,
			default: 1,
		},
		aid: {
			type: Number,
			default: 0,
		},
		sort: {
			type: Number,
			default: 0,
		},
	},
	setup() {
		// let loadingType = ref(1);
		// return {
		//   loadingType,
		// };
		let router = useRouter()
		let gotoHome = () => {
			router.push({
				name: "home",
			})
		}
		return { gotoHome }
	},
})
