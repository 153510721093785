
import HeaderNav from "@/components/HeaderNav.vue"
import HtmlFooter from "@/components/HtmlFooter.vue"
import VideoClassHeader from "@/components/video/VideoClassHeader.vue"
import VideoGoodsLine from "@/components/video/VideoGoodsLine.vue"
import NavList from "@/components/NavList.vue"
import { videoApi } from "@/service/api"
import { useRouter, useRoute } from "vue-router"
import { defineComponent, ref, reactive, onMounted, watch } from "vue"
import HomeHead from "@/components/HomeHead.vue"

import PageShow from "@/components/common/PageShow.vue"

export default defineComponent({
  components: {
    HeaderNav,
    NavList,
    HtmlFooter,
    VideoClassHeader,
    VideoGoodsLine,
    PageShow,
    HomeHead,
  },
  setup(props: any, context) {
    const limit = ref(1)
    const total = ref(0)
    const showPage = ref(true)
    const currentPage = ref(1)
    const prev = ref("<")
    const next = ref(">")
    let loadingType = ref(1)
    let jump = (current: any) => {
      //console.log(current)
      getGoodsList(
        route.params.cid,
        classList.datas.son[0].aid,
        sort.value,
        current
      )
    }

    let sort = ref(0)

    interface resType {
      datas: any
      errno: number
      errmsg: string
    }

    let classList: resType = reactive({
      datas: [],
      errno: 0,
      errmsg: "",
    })

    //参数
    let route = useRoute()
    let router = useRouter()
    watch(route, () => {
      router.go(0)
    })
    /**
     * 记录选中的子分类  $route.params.id
     */
    let checkedClass: Array<number> = reactive([])

    let getClass = () => {
      let where = {
        cid: route.params.cid,
      }
      videoApi.attrList(where).then((res) => {
        classList.datas = (res as resType).datas
        classList.errno = (res as resType).errno
        classList.errmsg = (res as resType).errmsg
        //初始化选中的子分类
        let aid = classList.datas.son[0].aid
        if (route.params.aid && parseInt(route.params.aid as string) > 0) {
          var index = classList.datas.son.findIndex((item: any) => {
            if (item.aid == parseInt(route.params.aid as string)) {
              return true
            }
          })

          if (index > 0) {
            aid = classList.datas.son[index].aid
            checkedClass[0] = index
          } else {
            checkedClass[0] = 0
          }
        }

        getGoodsList(route.params.cid, aid, 0, 1)
      })
    }
    let loading = ref(true)
    /**
     * 获取商品列表
     */
    let getGoodsList = (
      cid: any,
      aid: any,
      sort: number = 0,
      pageIndex: number = 1
    ) => {
      loadingType.value = 0
      loading.value = true
      classList.datas.goodslist = []
      let where = {
        sort,
        pageIndex: pageIndex,
        pageSize: 25,
        cid: cid,
        aid: aid,
      }

      videoApi.videoList(where).then((res: any) => {
        classList.datas.goodslist = res.datas.data
        total.value = res.datas.total
        limit.value = res.datas.pageSize
        loadingType.value = 1
        loading.value = false
      })
    }
    let ssindex = ref(0)
    /**
     * 切换子分类
     */
    let changeClass = (cindex: number, sindex: number) => {
      checkedClass[cindex] = sindex
      ssindex.value = sindex
      currentPage.value = 1
      getGoodsList(
        route.params.cid,
        classList.datas.son[sindex].aid,
        sort.value,
        1
      )
    }

    /**
     *
     * @param sorttemp 切换排序方式
     */
    let changeSort = (sorttemp: number) => {
      sort.value = sorttemp
      currentPage.value = 1
      getGoodsList(route.params.cid, classList.datas.son[ssindex.value].aid, sort.value, 1)
    }
    let gotoHome = ()=>{
      router.push({
        name:"home"
      })
    }

    onMounted(() => {
      getClass()
    })

    return {
      limit,
      total,
      showPage,
      currentPage,
      prev,
      next,
      jump,
      changeClass,
      classList,
      checkedClass,
      changeSort,
      loadingType,
      sort,
      loading,
      gotoHome
    }
  },
})
