import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d92d45c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "goods-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoodsInfoCollection = _resolveComponent("GoodsInfoCollection")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodslit, (item, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: index,
        class: _normalizeClass({ 'delete-goods': _ctx.step == 2 })
      }, [
        _createVNode(_component_GoodsInfoCollection, {
          goods: item,
          mindex: _ctx.getmindex(index)
        }, null, 8, ["goods", "mindex"])
      ], 2))
    }), 128))
  ]))
}