
import { defineComponent,inject } from "vue";

export default defineComponent({
  components: {},
  props:{
    goods:{
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    let staticUrl = inject("staticUrl");
    return {staticUrl};
  },
});
