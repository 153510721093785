import axios from "../service";

let { get, post } = axios;

const works = {
  // 上传作品类别
  workType() {
    return post("pc/works/type", {});
  },
  workSubmit(data: any) {
    return post("pc/works/submit", data);
  },
};
export default works;
