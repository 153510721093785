
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import GoodsInfo from "@/components/order/GoodsInfo.vue";
import { defineComponent } from "vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// Import Swiper styles
export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    GoodsInfo,
  },
  emits: ["changeGoodsList"],
  props: {
    isShow: {
      type: Boolean,
      default: true,
    },
    goodsList:{
      type: Array,
      default: () => []
    }
  },
  setup(props: any, context) {
    const onSwiper = (swiper: any) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    let close = () => {
      //console.log("asdfsdf");
      context.emit("changeGoodsList");
    };
    return {
      onSwiper,
      onSlideChange,
      close,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
});
