import { vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53b31aa2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "goods-image" }
const _hoisted_2 = {
  class: "middle",
  ref: "target"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      class: "large",
      style: _normalizeStyle([
        {
          backgroundImage: 'url(' + _ctx.images + ')',
          backgroundPosition: `-${_ctx.position.x * 2}px -${_ctx.position.y * 2}px`,
        },
      ])
    }, null, 4), [
      [_vShow, !_ctx.isOutside]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.images,
        alt: ""
      }, null, 8, _hoisted_3),
      _withDirectives(_createElementVNode("div", {
        class: "layer",
        ref: "target",
        style: _normalizeStyle({ left: _ctx.position.x + 'px', top: _ctx.position.y + 'px' })
      }, null, 4), [
        [_vShow, !_ctx.isOutside]
      ])
    ], 512)
  ]))
}