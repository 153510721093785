
import { reactive, ref, defineComponent, onMounted } from "vue";
import PageShow from "@/components/common/PageShow.vue";
import lackRecordVue from "../lackRecord.vue";
import { userApi } from "@/service/api";
export default defineComponent({
  components: { PageShow, lackRecordVue },
  props: {
    AccountToSon: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, context) {
    const total = ref(100);
    // let handleCurrentChange = (val: number) => {
    //   console.log(val);
    // };
    //获取用户信息 拿 可提现余额
    interface result {
      data: any;
    }
    let getInformation: result = reactive({
      data: {},
    });

    let getuserInformation = () => {
      userApi.getInformation().then((res: any) => {
        getInformation.data = res.datas;
        //console.log(res);
      });
    };

    onMounted(() => {
      getuserInformation();
    });

    return {
      total,
      getInformation,
    };
  },
});
