
import { defineComponent } from "vue"
import CustomerTitle from "./common/CustomerTitle.vue"

export default defineComponent({
  components: { CustomerTitle },
  setup() {
    let titleList = ["会员制度", "企业会员"]
    return { titleList }
  },
})
