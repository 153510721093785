
import { reactive, defineComponent, ref } from "vue";
import HtmlFooter from "../../components/HtmlFooter.vue";
import HomeHead from "../../components/HomeHead.vue";

export default defineComponent({
  components: { HtmlFooter, HomeHead },
  setup() {

    
    return {};
  },
});
