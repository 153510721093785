
import { defineComponent, reactive, ref, onMounted, inject } from "vue"
import GoodsList from "@/components/goods/project/GoodsList.vue"
import { goodsApi, userApi } from "@/service/api"
import PageShow from "@/components/common/PageShow.vue"
import { useRoute, useRouter } from "vue-router"
import Lack from "./component/workLack.vue"
import { userStore } from "@/stores/userStore"
import dateFormat from "@/utils/dateFormat"
import { id } from "node_modules/element-plus/es/locale"
export default defineComponent({
	components: {
		GoodsList,
		PageShow,
		Lack,
	},
	setup() {
		// 一共多少条
		const total = ref(0)
		// 一页多少
		let pageSize = ref(20)

		let handleCurrentChange = (val: number) => {
			//console.log(val);
		}

		interface result {
			data: any
		}
		let workList: result = reactive({
			data: {},
		})
		// 作品列表
		let userId = useRoute().params.userid
		let userWorklist = reactive({
			author: userId,
			pageIndex: 1,
			pageSize,
		})

		//判断一下是否是会员 是否打折
		let userInfo = userStore()
		let isVip = userInfo.isVip

		let getUserWorklist = () => {
			//console.log(userWorklist.author);
			userApi.userWorklist(userWorklist).then((res: any) => {
				console.log(res, "作品")
				workList.data = res.data
				total.value = res.data.total
				pageSize.value = res.data.pageSize
			})
		}

		// 时间戳转化为日期
		let mday = (index: number) => {
			let a = ref("")
			// console.log(workList.data.list[index].create_time * 1000);
			if (workList.data.list[index].create_time > 31104000000) {
				a.value = dateFormat.dateFormat(
					Number(workList.data.list[index].create_time * 1000),
					"yyyy-MM-dd"
				)
			} else {
				a.value = dateFormat.dateFormat(
					Number(workList.data.list[index].create_time * 1000),
					"月-日"
				)
			}

			return a.value
		}

		let changeMoney = (index: number) => {
			if (isVip) {
				return Number(workList.data.list[index].price) * 0.8
			} else {
				//console.log("不是会员");
				return workList.data.list[index].price
			}
		}
		// 处理返回的图片地址
		let getImgUrl = inject(
			"getImgUrl",
			(index: string) => {
				return ""
			},
			false
		)
		let router = useRouter()
		let goto = (id: number) => {
			const { href } = router.resolve({
				name: "productDetail",
				params: {
					id: id,
				},
			})
			window.open(href, "_blank")
		}
		onMounted(() => {
			getUserWorklist()
			// changeMoney();
		})
		const loading = ref(true)
		return {
			total,
			loading,
			handleCurrentChange,
			getUserWorklist,
			userId,
			userWorklist,
			workList,
			pageSize,
			getImgUrl,
			isVip,
			changeMoney,
			mday,
			goto,
		}
	},
})
