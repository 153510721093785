
import { reactive, ref, defineComponent, onMounted } from "vue";
import PageShow from "@/components/common/PageShow.vue";
import { uploadHistory } from "@/service/api";
import lackRecordVue from "../lackRecord.vue";
export default defineComponent({
  components: { PageShow, lackRecordVue },
  props: {
    AccountToSon: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, context) {
    const total = ref(100);
    // let handleCurrentChange = (val: number) => {
    //   console.log(val);
    // };
    interface result {
      data: any;
    }

    let getInformation: result = reactive({
      data: {},
    });
    let worksHistory = () => {
      uploadHistory.uploadHistory().then((res: any) => {
        console.log(res, "历史");
        getInformation.data = res.datas.statusArr;
        console.log(getInformation.data.length, "历史");
      });
    };

    onMounted(() => {
      worksHistory();
    });
    return {
      total,
      getInformation,
      // handleCurrentChange,
    };
  },
});
