
import { reactive, defineComponent, ref, onMounted } from "vue";
import type { UploadProps, UploadUserFile } from "element-plus";
import type { FormInstance, FormRules } from "element-plus";
import { userApi } from "@/service/api";
import { userStore } from "@/stores/userStore";
import { sjyAlert } from "@/utils/alert";
export default defineComponent({
  emits: ["getStatus"],

  setup(props: any, context) {
    interface CardType {
      name: string;
      url: string;
    }
    interface userinfoType {
      name: string;
      card_id: string;
      card_pic: Array<CardType>;
      card_pic_other: Array<CardType>;
    }

    const selfverifyForm: userinfoType = reactive({
      name: "",
      card_id: "",
      card_pic: [],
      card_pic_other: [],
    });

    let show = ref(true);

    let beforeUpload: UploadProps["beforeUpload"] = (rawFile: any) => {
      //获取上传的签名
      // console.log("开始了", rawFile, "结束了");
      if (rawFile.type !== "image/jpeg" && rawFile.type !== "image/png") {
        sjyAlert("请上传正确的图片格式", "error");
        return false;
      } else if (rawFile.size / 1024 / 1024 > 5) {
        sjyAlert("图片单张大小不能超过5M", "error");
        return false;
      }
      userApi.getOSSToken().then((res: any) => {
        let url = res.data.host;
        let new_multipart_params = {
          // key表示上传到Bucket内的Object的完整路径，例如exampledir/exampleobject.txtObject，完整路径中不能包含Bucket名称。
          // filename表示待上传的本地文件名称。
          key: res.data.dir + rawFile.uid + rawFile.name,
          policy: res.data.policy,
          OSSAccessKeyId: res.data.accessid,
          // 设置服务端返回状态码为200，不设置则默认返回状态码204。
          success_action_status: "200",
          signature: res.data.signature,
          file: rawFile,
        };

        userApi.updateOss(url, new_multipart_params).then((res: any) => {
          //console.log("返回信息",res,"返回信息结束")

          selfverifyForm.card_pic = [
            {
              name: rawFile.uid + rawFile.name,
              url:
                "https://image.sjyuan.net.cn/img/sjy/" +
                rawFile.uid +
                rawFile.name,
            },
          ];
        });
      });
      return false;
    };

    let beforeUploads: UploadProps["beforeUpload"] = (rawFile: any) => {
      //获取上传的签名
      // console.log("开始了", rawFile, "结束了");
      if (rawFile.type !== "image/jpeg" && rawFile.type !== "image/png") {
        sjyAlert("请上传正确的图片格式", "error");
        return false;
      } else if (rawFile.size / 1024 / 1024 > 5) {
        sjyAlert("图片单张大小不能超过5M", "error");
        return false;
      }
      userApi.getOSSToken().then((res: any) => {
        let url = res.data.host;
        let new_multipart_params = {
          // key表示上传到Bucket内的Object的完整路径，例如exampledir/exampleobject.txtObject，完整路径中不能包含Bucket名称。
          // filename表示待上传的本地文件名称。
          key: res.data.dir + rawFile.uid + rawFile.name,
          policy: res.data.policy,
          OSSAccessKeyId: res.data.accessid,
          // 设置服务端返回状态码为200，不设置则默认返回状态码204。
          success_action_status: "200",
          signature: res.data.signature,
          file: rawFile,
        };

        userApi.updateOss(url, new_multipart_params).then((res: any) => {
          //console.log("返回信息",res,"返回信息结束")

          selfverifyForm.card_pic_other = [
            {
              name: rawFile.uid + rawFile.name,
              url:
                "https://image.sjyuan.net.cn/img/sjy/" +
                rawFile.uid +
                rawFile.name,
            },
          ];
        });
      });
      return false;
    };

    const ruleFormRef = ref<FormInstance>();

    const rules = reactive<FormRules>({
      name: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
      card_pic: [
        { required: true, message: "请上传身份证人像面", trigger: "blur" },
      ],
      card_id: [
        { required: true, message: "请输入身份证号", trigger: "blur" },
        {
          pattern:
            "^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$",
          message: "身份证号格式不正确",
          trigger: "blur",
        },
      ],
      card_pic_other: [
        { required: true, message: "请上传身份证国徽面", trigger: "blur" },
      ],
    });

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          let toSubmitFrom = {
            name: selfverifyForm.name,
            card_id: selfverifyForm.card_id,
            card_pic: selfverifyForm.card_pic[0].url,
            card_pic_other: selfverifyForm.card_pic_other[0].url,
          };
          userApi.userVerify(toSubmitFrom).then((res: any) => {
            console.log(res);
            if (res.errno == 0) {
              location.reload();
            }
          });
        } else {
          //console.log("error submit!", fields);
        }
      });
    };

    // 获取用户信息 做回显
    interface result {
      data: any;
    }

    let getInformation: result = reactive({
      data: {},
    });
    let getuserInformation = () => {
      userApi.getInformation().then((res: any) => {
        getInformation.data = res.datas;
        console.log(getInformation.data);
        selfverifyForm.name = getInformation.data.name;
        selfverifyForm.card_id = getInformation.data.card_id;
        // console.log(res);
      });
    };

    let userinfot = userStore();
    //console.log(userinfot, "aa")
    const dialogImageUrl = ref("");
    const dialogVisible = ref(false);

    const dialogImageUrls = ref("");
    const dialogVisibles = ref(false);

    const handlePictureCardPreview: UploadProps["onPreview"] = (uploadFile) => {
      dialogImageUrl.value = uploadFile.url!;
      dialogVisible.value = true;
    };
    const handlePictureCardPreviews: UploadProps["onPreview"] = (
      uploadFile
    ) => {
      dialogImageUrls.value = uploadFile.url!;
      dialogVisibles.value = true;
    };

    onMounted(() => {
      getuserInformation();
    });

    return {
      selfverifyForm,
      rules,
      submitForm,
      ruleFormRef,
      beforeUpload,
      beforeUploads,
      show,
      getInformation,
      getuserInformation,
      userinfot,
      handlePictureCardPreview,
      dialogVisible,
      dialogImageUrl,
      handlePictureCardPreviews,
      dialogVisibles,
      dialogImageUrls,
    };
  },
});
