import axios from "../service";

let { get, post, getImg } = axios;

const brand = {
  classList() {
    return get("/class/list", {});
  },
  //获取图形验证码
  // getImgcode(){
  // 	let nowtime = new Date().getTime();
  // 	return "https://api.sjyuan.net.cn/api/pc/user/verify?nowtime="+nowtime
  // },
  getImgcode() {
    return getImg("pc/user/verify", {});
  },
  //获取手机验证码
  getPhoneCode(phone: string) {
    return post("pc/user/code", { mobile: phone });
  },

  //验证手机验证码是否正确
  verifyCode(data: any) {
    return post("/pc/user/mobilecode", data);
  },

  //企业对公支付短信
  bankCode() {
    return post("/pc/user/bank", {});
  },

  getMulu() {
    let mulu = [
      {
        name: "产品",
        url: "/class/index/1",
        list: [
          {
            name: "RV减速机",
            url: "/class/index/1",
            list: [
              {
                name: "CT-CRV系列无法兰",
                url: "/goods/list/1/11/RV减速机",
              },
              {
                name: "CT-CRD系列有法兰",
                url: "/goods/list/1/12/RV减速机",
              },
            ],
          },
          //   {
          // 	'name':"RV减速机",
          // 	'url':"/class/index/1",
          // 	'list':[
          // 		{
          // 		  'name':"CT-CRV系列无法兰",
          // 		  'url':"/goods/list/1/11/RV减速机"
          // 		},
          // 		{
          // 		  'name':"CT-CRD系列有法兰",
          // 		  'url':"/goods/list/1/12/RV减速机"
          // 		}
          // 	  ]
          //   },
          {
            name: "行星减速机",
            url: "/class/index/42",
            list: [
              {
                name: "同心型减速机",
                url: "/goods/list/42/43/行星减速机",
              },
              {
                name: "直交型减速机",
                url: "/goods/list/42/44/行星减速机",
              },
            ],
          },
          {
            name: "伺服电机",
            url: "/class/index/2",
            list: [
              {
                name: "TDA040伺服电机",
                url: "/goods/list/2/13/伺服电机",
              },
              {
                name: "TDA060伺服电机",
                url: "/goods/list/2/14/伺服电机",
              },
              {
                name: "TDA080伺服电机",
                url: "/goods/list/2/15/伺服电机",
              },
              {
                name: "TDA130伺服电机",
                url: "/goods/list/2/16/伺服电机",
              },
              {
                name: "TDA180伺服电机",
                url: "/goods/list/2/17/伺服电机",
              },
            ],
          },
          {
            name: "伺服驱动器",
            url: "/class/index/3",
            list: [
              {
                name: "多轴伺服驱动器",
                url: "/goods/list/3/18/伺服驱动器",
              },
              {
                name: "单轴伺服驱动器",
                url: "/goods/list/3/21/单轴伺服驱动器",
              },
            ],
          },
          {
            name: "并联机器人",
            url: "/class/index/4",
            list: [
              {
                name: "2自由度并联机器人",
                url: "/goods/list/4/22/并联机器人",
              },
              {
                name: "3自由度并联机器人",
                url: "/goods/list/4/23/并联机器人",
              },
              {
                name: "4自由度并联机器人",
                url: "/goods/list/4/24/并联机器人",
              },
              {
                name: "5自由度并联机器人",
                url: "/goods/list/4/27/并联机器人",
              },
              {
                name: "6自由度并联机器人",
                url: "/goods/list/4/28/并联机器人",
              },
            ],
          },
          {
            name: "串联机器人",
            url: "/class/index/6",
            list: [
              {
                name: "Scara机器人",
                url: "/goods/list/6/29/串联机器人",
              },
              {
                name: "码垛机器人",
                url: "/goods/list/6/30/串联机器人",
              },
            ],
          },
          {
            name: "协作机器人",
            url: "/class/index/7",
            list: [
              {
                name: "LM3轻型机器人",
                url: "/goods/list/7/31/协作机器人",
              },
            ],
          },
          {
            name: "真空元件",
            url: "/class/index/8",
            list: [
              {
                name: "真空吸盘",
                url: "/goods/list/8/32/真空元件",
              },
              {
                name: "真空发生器",
                url: "/goods/list/8/33/真空元件",
              },
              {
                name: "真空吸具",
                url: "/goods/list/8/45/真空元件",
              },
            ],
          },

          {
            name: "控制系统",
            url: "/class/index/9",
            list: [
              {
                name: "并联机器人控制器",
                url: "/goods/list/9/34/控制系统",
              },
            ],
          },
          {
            name: "机器视觉系统",
            url: "/class/index/10",
            list: [
              {
                name: "2D视觉系统",
                url: "/goods/list/10/35/机器视觉系统",
              },
              {
                name: "3D视觉系统",
                url: "/goods/list/10/36/机器视觉系统",
              },
              {
                name: "深度学习",
                url: "/goods/list/10/37/机器视觉系统",
              },
            ],
          },
        ],
        urllist: ["goodslist", "classIndex"],
      },
      {
        name: "品牌",
        url: "/brand/list",
        urllist: ["brand", "brandList", "brandinfo"],
        list: [
          {
            name: "阿童木机器人",
            url: "/brand/index/1",
          },
          {
            name: "合作品牌",
            url: "/brand/list",
          },
        ],
      },
      {
        name: "服务行业",
        url: "/industry/index/1",
        urllist: ["industryinfo"],
        list: [
          {
            name: "工业机器人零部件",
            url: "/industry/index/1",
          },
          {
            name: "消费品",
            url: "/industry/index/2",
          },
          {
            name: "商业智能服务",
            url: "/industry/index/3",
          },
          {
            name: "仓储物流",
            url: "/industry/index/4",
          },
          {
            name: "食品",
            url: "/industry/index/5",
          },
          {
            name: "医药",
            url: "/industry/index/6",
          },
          {
            name: "汽车及零部件",
            url: "/industry/index/7",
          },
          {
            name: "机械设备",
            url: "/industry/index/8",
          },
          {
            name: "电子",
            url: "/industry/index/9",
          },
          {
            name: "原材料加工",
            url: "/industry/index/10",
          },
          {
            name: "新能源",
            url: "/industry/index/11",
          },
        ],
      },
      {
        name: "关于我们",
        urllist: ["contacus"],
        url: "/user/contacus",
      },
      {
        name: "支持",
        urllist: ["helpindex", "helpfqa", "helppurchase", "helpcooperation"],
        url: "/help/index",
      },
    ];
    return mulu;
  },
};
export default brand;
