import router from "../router"
import { orderApi, goodsApi, userApi } from "@/service/api"
import { userStore } from "@/stores/userStore"
import { reactive } from "vue"
/**
 * 节流
 */
//  let throttle = (fn:any,delay:any) => {
//     let lastTime = 0;
//     console.log("......")
//     return function (){
//       let nowTime = Date.now();
//       console.log("在这里")
//       if(nowTime - lastTime > delay){
//         console.log("节流")
//         fn().apply()
//         lastTime = nowTime;
//       }else{
//         console.log("else节流")
//       }
//     }
//   }

/**
 * 防抖
 */
let debounce = (fn: any, delay: any) => {
  //记录上一次的延时器
  var timer: any = null
  return function () {
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn()
    }, delay)
  }
}
let getDeliveryStatus = (status: number) => {
  let deliveryList = [
    "快递收件(揽件)",
    "在途中",
    "正在派件",
    "已签收",
    "派送失败",
    "疑难件",
    "退件签收",
  ]
  return deliveryList[status]
}
/**
 * 返回订单状态
 */
let orderStatusText = (
  index: number,
  forRrestOf: number,
  order_type: number = 0,
  send_status: number = 0
) => {
  if (index == 1) {
    let time = new Date().getTime() / 1000
    if (forRrestOf - time > 0) {
      return "待支付"
    } else {
      return "已取消"
    }
  } else if (index == 2) {
    if (order_type == 1) {
      if (send_status == 2) {
        return "待收货"
      }
      if (send_status == 3) {
        return "已收货"
      }
    }
    return "已支付"
  } else if (index == 3) {
    return "已取消"
  }else if (index == 5) {
    return "已退款"
  }
}

/**
 * 返回上一层
 */
let goBack = () => {
  router.back()
}
//获取使用的版本
let getSolidWorksVersion = (modelname: any) => {
  let solidlist = [
    "SJ0102025",
    "SJ0103025",
    "SJ0103026",
    "SJ0103027",
    "GZZ01-20",
    "GZZ01-21",
    "GZZ01-36",
    "GZZ01-47",
    "SS04008",
    "ZS0500118",
    "ZS0500117",
    "ZS0500116",
    "ZS0500115",
    "ZS0100104",
    "ZS0100103",
  ]
  var a = solidlist.indexOf(modelname)
  if (a > -1) {
    return "SolidWorks 2018"
  } else {
    return "SolidWorks 2017"
  }
}

let notInRouter = (modelname: any) => {
  let notInlist = [
    "login",
    "sonlogin",
    "numberlogin",
    "sonnumber",
    "register",
    "findpwd",
    "surepwd",
  ]
  var a = notInlist.indexOf(modelname)
  if (a > -1) {
    return false
  } else {
    return true
  }
}

/**
 * 更新购物车
 */
let updateCartTotal = (datasLenght: number = 0) => {
  if (datasLenght == 0) {
    orderApi.getCartGoodsList().then((res: any) => {
      if (res.errno == 0) {
        userStore().updateCartTotal(res.datas.length)
      }
    })
  } else {
    userStore().updateCartTotal(datasLenght)
  }
}
interface vipinfoType {
  vipInfo?: {
    vipType: number
    vipid: number
  }
}
let getSuffix = (url: string) => {
  return url.substring(url.lastIndexOf("."))
}

let showPrice = (price: number | string, type: number = 0) => {
  if (typeof price == "string") {
    price = parseFloat(price)
  }
  if (type == 1) {
    return  Math.round(parseFloat(changeTwoDecimal_f(price))*100)/100
  } else {
    return Math.round(price)
  }
}

function changeTwoDecimal_f(x: number) {
  var f_x = Math.round(x * 100) / 100

  var s_x = f_x.toString()

  var pos_decimal = s_x.indexOf(".")

  if (pos_decimal < 0) {
    pos_decimal = s_x.length

    s_x += "."
  }

  while (s_x.length <= pos_decimal + 2) {
    s_x += "0"
  }

  return s_x
}

let getVipInfo = (vipInfo: vipinfoType = {}) => {
  let userinfot: any = {}
  if (!vipInfo.vipInfo) {
    userinfot = userStore()
    //console.log(userinfot, "userinfot是什么", vipInfo);
  } else {
    //console.log(userinfot, "vipInfo", vipInfo);
    userinfot = vipInfo
  }

  let vipinfo = {
    imgurl: "",
    vipimgurl: "",
    title: "普通用户",
    color: "#7EB2BE",
    videocolor: "#FA5151",
    type: 0,
    backgroundcolor:
      "linear-gradient(265.38deg, #6481D0 -0.42%, #D9E2FA 52.48%)",
    backgroundimg: "",
    userimgurl: "",
    usercolor: "7EB2BE",
    homeimgurl: "",
    homecolor: "7EB2BE",
  }
  if (userinfot.vipInfo.vipType == 2 && userinfot.vipInfo.vipid == 16) {
    vipinfo.userimgurl = require("@/assets/icon/vip/huangjin.png")
    vipinfo.usercolor = "#924505"

    vipinfo.homeimgurl = require("@/assets/icon/vip1.png")
    vipinfo.homecolor = "#ffbd34"

    vipinfo.imgurl = require("@/assets/icon/vip/huangjinicon.png")
    vipinfo.vipimgurl = require("@/assets/icon/huangjinvip.png")
    vipinfo.title = "黄金"
    vipinfo.type = 2
    vipinfo.color = "#924505"
    vipinfo.videocolor = "#924505"
    vipinfo.backgroundcolor =
      "linear-gradient(265.38deg, #F0BF51 -0.42%, #FCDF9F 52.48%)"
    vipinfo.backgroundimg = require("@/assets/icon/vip/huangjinbj.png")
  }
  if (userinfot.vipInfo.vipType == 2 && userinfot.vipInfo.vipid == 17) {
    vipinfo.userimgurl = require("@/assets/icon/vip/baijin.png")
    vipinfo.usercolor = "#7A6755"
    vipinfo.homeimgurl = require("@/assets/icon/vip2.png")
    vipinfo.homecolor = "#b8d3ec"
    vipinfo.imgurl = require("@/assets/icon/vip/baijinicon.png")
    vipinfo.vipimgurl = require("@/assets/icon/baijinvip.png")
    vipinfo.title = "白金"
    vipinfo.type = 2
    vipinfo.color = "#7A6755"
    vipinfo.videocolor = "#53442B"
    vipinfo.backgroundcolor =
      "linear-gradient(265.38deg, #649BD1 -0.42%, #D9EBFA 52.48%)"
    vipinfo.backgroundimg = require("@/assets/icon/vip/baijinbj.png")
  }
  if (userinfot.vipInfo.vipType == 2 && userinfot.vipInfo.vipid == 18) {
    vipinfo.userimgurl = require("@/assets/icon/vip/zuanshi.png")
    vipinfo.usercolor = "#31343A"

    vipinfo.homeimgurl = require("@/assets/icon/vip3.png")
    vipinfo.homecolor = "#7aabf4"

    vipinfo.imgurl = require("@/assets/icon/vip/zuanshiicon.png")
    vipinfo.vipimgurl = require("@/assets/icon/zuanshivip.png")

    vipinfo.title = "钻石"
    vipinfo.type = 2
    vipinfo.color = "#31343A"
    vipinfo.videocolor = "#31343A"
    vipinfo.backgroundcolor =
      "linear-gradient(265.38deg, #6481D0 -0.42%, #D9E2FA 52.48%)"
    vipinfo.backgroundimg = require("@/assets/icon/vip/zuanshibj.png")
  }
  if (userinfot.vipInfo.vipType == 1 && userinfot.vipInfo.vipid == 16) {
    vipinfo.userimgurl = require("@/assets/icon/vip/yuedu.png")
    vipinfo.usercolor = "#6A97FA"

    vipinfo.homeimgurl = require("@/assets/icon/vip4.png")
    vipinfo.homecolor = "#6493FA"

    vipinfo.imgurl = require("@/assets/icon/vip/yueduicon.png")
    vipinfo.vipimgurl = require("@/assets/icon/yueduhuiyuan.png")

    vipinfo.title = "月度会员"
    vipinfo.type = 1
    vipinfo.color = "#ffffff"
    vipinfo.videocolor = "#6493FA"
    vipinfo.backgroundcolor =
      "linear-gradient(267.26deg, #578AFA -3.12%, #84A9FA 52.28%)"
    vipinfo.backgroundimg = require("@/assets/icon/vip/yuedubj.png")
  }
  if (userinfot.vipInfo.vipType == 1 && userinfot.vipInfo.vipid == 17) {
    vipinfo.userimgurl = require("@/assets/icon/vip/jidu.png")
    vipinfo.usercolor = "#9289F0"

    vipinfo.homeimgurl = require("@/assets/icon/vip5.png")
    vipinfo.homecolor = "#9289F0"

    vipinfo.imgurl = require("@/assets/icon/vip/jiduicon.png")
    vipinfo.vipimgurl = require("@/assets/icon/jiduhuiyuan.png")

    vipinfo.title = "季度会员"
    vipinfo.type = 1
    vipinfo.color = "#ffffff"
    vipinfo.videocolor = "#9289F0"
    vipinfo.backgroundcolor =
      "linear-gradient(265.4deg, #948BF8 -0.06%, #A59DFA 52.65%)"
    vipinfo.backgroundimg = require("@/assets/icon/vip/jidubj.png")
  }
  if (userinfot.vipInfo.vipType == 1 && userinfot.vipInfo.vipid == 18) {
    vipinfo.userimgurl = require("@/assets/icon/vip/niandu.png")
    vipinfo.usercolor = "#FF7E7C"

    vipinfo.homeimgurl = require("@/assets/icon/vip6.png")
    vipinfo.homecolor = "#FF7E7C"

    vipinfo.imgurl = require("@/assets/icon/vip/nianduicon.png")
    vipinfo.vipimgurl = require("@/assets/icon/nianduhuiyuan.png")
    vipinfo.title = "年度会员"
    vipinfo.type = 1
    vipinfo.color = "#ffffff"
    vipinfo.videocolor = "#FF7E7C"
    vipinfo.backgroundcolor =
      "linear-gradient(87.41deg, #FF7A73 49.97%, #F95C58 100.34%)"
    vipinfo.backgroundimg = require("@/assets/icon/vip/niandubj.png")
  }
  return vipinfo
}

interface result {
  datas: any
}

let getSonVip = (pid: number) => {
  let userinfot = userStore()
  userApi.getInformation({ uid: pid }).then((res: any) => {
    userinfot.updateVip(
      res.datas.vip_type,
      res.datas.vip_title,
      res.datas.vip_etime,
      res.datas.vip_id
    )
  })
}
/**
 * 验证邮箱格式
 * @param email
 * @returns
 */
let emailRegExp = (email: string) => {
  let regExp = /^(.+)@(.+)$/
  if (regExp.test(email)) {
    return true
  } else {
    return false
  }
}
/**
 * 验证phone格式
 * @param phone
 * @returns
 */
let phoneRegExp = (phone: string) => {
  let regExp = /^1[1-9]\d{9}$/
  if (regExp.test(phone)) {
    return true
  } else {
    return false
  }
}

let getStringSuffix = (name: string) => {
  return name.substr(name.lastIndexOf(".") + 1).toLowerCase()
}

const classList = [
  {
    name: "客户",
    path: "",
    type: "master",
    isChecked: true,
    list: [],
    open: false,
  },
  {
    name: "热点问题",
    path: "HotProblem",
    isChecked: true,
    list: [],
    open: false,
  },
  {
    name: "会员制度",
    path: "",
    isChecked: false,
    open: false,
    list: [
      { name: "个人会员", path: "GeRenVip", isChecked: false },
      { name: "企业会员", path: "GongSiVip", isChecked: false },
    ],
  },
  {
    name: "账户管理",
    path: "",
    isChecked: false,
    open: false,
    list: [
      { name: "齿轮币常见问题", path: "Chilunbi", isChecked: false },
      { name: "优惠券使用说明", path: "Youhuiquan", isChecked: false },
    ],
  },
  {
    name: "支付方式",
    path: "",
    isChecked: false,
    open: false,
    list: [
      { name: "在线支付", path: "ZaixianZhifu", isChecked: false },
      { name: "企业对公支付", path: "DuigongZhifu", isChecked: false },
      { name: "齿轮币支付", path: "ChilunbiZhifu", isChecked: false },
      { name: "支付遇到问题", path: "ZhifuWenti", isChecked: false },
    ],
  },
  {
    name: "售后服务",
    path: "",
    isChecked: false,
    open: false,
    list: [
      { name: "发票制度", path: "Fapiao", isChecked: false },
      { name: "退款说明", path: "TuikuanShuoming", isChecked: false },
    ],
  },
  {
    name: "实名认证",
    path: "",
    isChecked: false,
    open: false,
    list: [{ name: "个人认证", path: "GerenRenzheng", isChecked: false }],
  },
  {
    name: "设计师合作",
    path: "ShejishiHezuo",
    isChecked: false,
    open: false,
    list: [],
  },
  {
    name: "商户",
    path: "",
    type: "master",
    isChecked: true,
    list: [],
    open: false,
  },
  {
    name: "企业合作",
    path: "QiyeHezuo",
    isChecked: false,
    list: [],
    open: false,
  },
  {
    name: "企业认证",
    path: "QiyeRenzheng",
    isChecked: false,
    list: [],
    open: false,
  },
  {
    name: "作品上传",
    path: "ZuopinShangchuan",
    isChecked: false,
    list: [],
    open: false,
  },
]

const newUserGuide = [
  {
    name: "功能介绍",
    path: "LoginIntroduceVue",
    isChecked: true,
    list: [],
    open: false,
  },
  {
    name: "登录设纪元",
    path: "Function",
    isChecked: false,
    list: [],
    open: false,
  },
  {
    name: "购物流程",
    path: "",
    isChecked: false,
    open: false,
    list: [
      { name: "购买及支付", path: "Shopping", isChecked: false },
      { name: "查看订单", path: "CheckOrder", isChecked: false },
    ],
  },
  {
    name: "设计方案下载",
    path: "",
    isChecked: false,
    open: false,
    list: [
      { name: "下载规则", path: "Download", isChecked: false },
      { name: "文件使用说明", path: "ShiyongShuoming", isChecked: false },
    ],
  },
  {
    name: "咨询服务",
    path: "",
    isChecked: false,
    open: false,
    list: [
      { name: "获取咨询服务", path: "Consult", isChecked: false },
      { name: "提问", path: "Question", isChecked: false },
    ],
  },
  {
    name: "作品上传及发布",
    path: "",
    isChecked: false,
    open: false,
    list: [
      { name: "作品上传流程", path: "WorkUpload", isChecked: false },
      { name: "作品审核", path: "WorkCheck", isChecked: false },
      { name: "作品收益提现", path: "Earnings", isChecked: false },
    ],
  },
]

export {
  // throttle,
  debounce,
  goBack,
  getSolidWorksVersion,
  orderStatusText,
  updateCartTotal,
  getVipInfo,
  notInRouter,
  getSonVip,
  emailRegExp,
  phoneRegExp,
  getStringSuffix,
  classList,
  newUserGuide,
  getSuffix,
  getDeliveryStatus,
  showPrice,
  // getGoodsPrice.
}
