
import {
	defineComponent,
	ref,
	nextTick,
	onMounted,
	reactive,
	inject,
} from "vue"
import { videoApi } from "@/service/api"
import { useRouter, useRoute } from "vue-router"
import formdata from "@/utils/dateFormat"

export default defineComponent({
	emits: ["sedGoodsInfo"],
	props: {
		cid: {
			type: Number,
			default: 1,
		},
		sort: {
			type: Number,
			default: 0,
		},
		aid: {
			type: Number,
			default: 0,
		},
		curIndex: {
			type: Number,
			default: 0,
		},
		nowid: {
			type: String,
			default: "",
		},
	},
	components: {},
	setup(props: any, context) {
		let route = useRoute()
		let sort = ref(props.sort)
		let cid = ref(props.cid)
		let aid = ref(props.aid)
		let videoHeight = ref(0)
		let total = ref(0)
		let curIndex = ref(props.curIndex)
		let lastIndex = ref(props.curIndex)
		let beforIndex = ref(props.curIndex)
		let lock = ref(false)
		let topMore = ref(true)
		let bottomMore = ref(true)
		let loadEnd = ref(false)

		let contentsHeight = () => {
			nextTick(() => {
				let infoBox = document.getElementById("info-box")
				let infoList = document.getElementById("info-list")
				videoHeight.value = (infoBox as HTMLElement).offsetHeight
				;(infoList as HTMLElement).addEventListener("scroll", () => {
					// 滚动距离
					const scrollTop = (infoList as HTMLElement).scrollTop
					// 变量windowHeight是可视区的高度
					const windowHeight =
						(infoList as HTMLElement).clientHeight ||
						(infoList as HTMLElement).clientHeight
					// 变量scrollHeight是滚动条的总高度
					const scrollHeight =
						(infoList as HTMLElement).scrollHeight ||
						(infoBox as HTMLElement).scrollHeight
					if (loadEnd.value) {
						// console.log("开始了向上滚动",loadEnd.value)
						if (scrollTop + windowHeight + 20 > scrollHeight) {
							//禁用绑定
							if (!lock.value) {
								lock.value = true
								// console.log(beforIndex.value * 6,total.value)
								if (beforIndex.value * 6 > total.value) {
									lock.value = false
									bottomMore.value = false
								} else {
									bottomMore.value = true
									beforIndex.value = beforIndex.value + 1
									getGoodsList(
										cid.value,
										aid.value,
										sort.value,
										beforIndex.value
									)
								}
							}
						}
						if (scrollTop < 30) {
							// console.log("开始了向下滚动",loadEnd.value)
							//禁用绑定
							if (!lock.value) {
								lock.value = true
								lastIndex.value = lastIndex.value - 1
								if (lastIndex.value > 0) {
									topMore.value = true
									getGoodsList(
										cid.value,
										aid.value,
										sort.value,
										lastIndex.value
									)
								} else {
									topMore.value = false
									lock.value = false
								}
							}
						}
					}
				})
			})
		}
		let dateFormatMap = (time: number) => {
			return formdata.videoDateFormat(time)
		}
		let aboutGoodsList: aboutGoodsType = reactive({
			data: [],
			errno: 0,
			errmsg: "",
		})
		//更改排序
		let changeSort = (sorttemp: number) => {
			sort.value = sorttemp
			aboutGoodsList.data = []
			loadEnd.value = false
			getGoodsInfo(props.nowid, cid.value, aid.value, sort.value)
		}
		interface aboutGoodsType {
			data: any
			errno: number
			errmsg: string
		}

		//查询商品列表
		let getGoodsList = (
			cid: number,
			aid: number,
			sort: number,
			Index: number, //页码
			firstload: boolean = false
		) => {
			// console.log("页码：",Index);
			let where = {
				sort,
				pageIndex: Index,
				pageSize: 6,
				cid: cid,
				aid: aid,
			}
			videoApi.videoList(where).then((res: any) => {
				let infoList = document.getElementById("info-list")
				if (res.datas.pageIndex >= curIndex.value) {
					// console.log("push", res.datas.pageIndex, curIndex.value);
					aboutGoodsList.data.push(...res.datas.data)
				} else {
					// console.log("unshift", res.datas.pageIndex, curIndex.value);
					aboutGoodsList.data.unshift(...res.datas.data)
					nextTick(() => {
						;(infoList as HTMLElement).scrollTo(
							0,
							res.datas.data.length * 87 - 45
						)
					})
				}
				if (firstload) {
					nextTick(() => {
						;(infoList as HTMLElement).scrollTo(0, 40)
						loadEnd.value = true
					})
				}
				aboutGoodsList.errno = res.errno
				aboutGoodsList.errmsg = res.errmsg
				total.value = res.datas.total
				lock.value = false
				if (firstload && res.datas.data.length < 6 && res.datas.total > 6) {
					getGoodsList(cid, aid, sort, curIndex.value - 1)
				}
			})
		}

		interface resType {
			datas: any
		}

		let getGoodsInfo = (id: string, cid: number, aid: number, sort: number) => {
			let goodswhere = {
				id,
				cid,
				aid,
				sort,
			}

			videoApi.videoInfo(goodswhere).then((res) => {
				curIndex.value = (res as resType).datas.curIndex
				lastIndex.value = (res as resType).datas.curIndex
				beforIndex.value = (res as resType).datas.curIndex
				// console.log("更新beforindex:",beforIndex.value)
				getGoodsList(cid, aid, sort, curIndex.value, true)
			})
		}

		let sedGoodsInfo = (index: number) => {
			let obj2 = { ...aboutGoodsList.data[index] }
			obj2.attr = obj2.attr.split(" ").filter(Boolean)
			context.emit("sedGoodsInfo", obj2)
		}
		const staticUrl = inject("staticUrl")
		let imgUrl = (goodsnames: string, cover: string) => {
			if (cover) {
				return cover
			} else {
				let modleName = goodsnames.split("：")[1]
				return staticUrl + "/filespath/files/video/img/" + modleName + ".jpg"
			}
		}

		onMounted(() => {
			contentsHeight()
			getGoodsList(cid.value, aid.value, sort.value, curIndex.value, true)
		})

		return {
			changeSort,
			sort,
			contentsHeight,
			videoHeight,
			total,
			aboutGoodsList,
			dateFormatMap,
			topMore,
			bottomMore,
			sedGoodsInfo,
			imgUrl,
		}
	},
})
