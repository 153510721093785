
import { defineComponent } from "vue"

export default defineComponent({
  components: {},
  name: "dialog",
  props: {
    type: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    tips: {
      type: String,
      default: "是否删除?",
    },
  },
  emits: ["dele", "cancel"],
  setup(props: any, { emit }) {
    let dele = () => {
      emit("dele", props.type, props.index)
    }
    let cancel = () => {
      emit("cancel")
    }
    return { dele, cancel }
  },
})
