
import { defineComponent, ref,inject } from "vue";

export default defineComponent({
  props: {
    showDelete: {
      type: Boolean,
      default: false,
    },
    iconType: {
      type: Number,
      default: 0,
    },
    isselect:{
      type: Boolean,
      default: false,
    },
    mindex:{
      type:Number,
      default:0
    }
  },
  components: {},
  emits: ["clickDelete"],
  
  setup(props: any, { emit }) {
    let clickDelete = () => {
      emit("clickDelete");
    };
    let isSelect = ref(true);
    const goodsselect = inject('goodsselect',(index:number)=>{console.log("我被点击了")},false)

    return { clickDelete, isSelect,goodsselect };
  },
});
