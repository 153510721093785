
import {
	computed,
	defineComponent,
	onMounted,
	ref,
	reactive,
	inject,
	watch,
	nextTick,
} from "vue"
import HtmlFooter from "../../components/HtmlFooter.vue"
import HomeHead from "../../components/HomeHead.vue"
import Video from "./components/video.vue"
import Scheme from "./components/scheme.vue"
import Animation from "./components/animation.vue"
import Free from "./components/free.vue"
import Beijian from "./components/beijian.vue"
import Supply from "./components/supply.vue"
import { goodsApi, publicApi, userApi } from "../../service/api"
import { getSonVip, getVipInfo } from "@/utils/public"
import { userStore } from "@/stores/userStore"
import { useRoute, useRouter } from "vue-router"
import { sjyAlert } from "@/utils/alert"
import InvoiceDialog from "@/components/common/Dialog/InvoiceDialog.vue"
export default defineComponent({
	components: {
		HtmlFooter,
		HomeHead,
		InvoiceDialog,
		Video,
		Scheme,
		Animation,
		Free,
		Beijian,
		Supply,
	},
	setup() {
		interface result {
			datas: any
		}
		let userInfo = userStore()
		let tabPosition = ref("left")
		let activeName = ref("second")
		let state = ref(0)
		let userInfor: result = reactive({
			datas: {},
		})

		let arr1: result = reactive({
			datas: {},
		})
		let fansInfor: result = reactive({
			datas: {},
		})
		let a = ref(0)
		let author = ref(0)
		let mobile = ref("")
		let userName = ref("")

		userName.value = userStore().username
		mobile.value = userStore().mobile
		let router = useRouter()
		let vipInfor: any = reactive({ data: {} })
		vipInfor.data = getVipInfo()
		let carouselshare: any = ref()
		let changelist = (a: number) => {
			state.value = a
		}
		// 处理头像
		let getImgUrl = inject(
			"getImgUrl",
			(index: string) => {
				return ""
			},
			false
		)

		// 退出登录
		let loginOut = () => {
			userStore().deleteUserInfo()
			sjyAlert("已退出", "success", () => {
				// router.push({ name: "login" }); // userRouter
				location.reload()
			})
		}
		// 总齿轮币
		let money = () => {
			if (userInfor.datas.sgear == null) {
				a.value = 0
			} else {
				a = userInfor.datas.sgear
			}
		}
		// 判断是否登录
		const showName = computed(() => {
			return userName.value ? userName.value : mobile.value
		})
		let movibotClass: any = publicApi.getMulu()
		let gotoMovibot = (url: any) => {
			window.open("https://www.movibot-art.com" + url)
		}

		let gotoMovibots = (url: any) => {
			window.open(url)
		}

		let goSkip = () => {
			if (state.value == 0) {
				let goskip = router.resolve({ name: "goods-class" })
				window.open(goskip.href, "_blank")
			}
			if (state.value == 1) {
				let goskip = router.resolve({ name: "video" })
				window.open(goskip.href, "_blank")
			}
			if (state.value == 2) {
				let goskip = router.resolve({ name: "free" })
				window.open(goskip.href, "_blank")
			}
			if (state.value == 3) {
				window.open(
					"https://www.movibot-art.com/goods/list/1/11/RV%E5%87%8F%E9%80%9F%E6%9C%BA"
				)
			}
		}
		// 认证弹窗
		let dialogVisibles = ref(false)

		let setDialogVisibles = () => {
			sonRefs.value?.setVisible(true)
		}
		const sonRefs = ref<InstanceType<typeof InvoiceDialog>>()
		// 关闭弹框
		let mcloses = () => {
			dialogVisibles.value = false
			sonRefs.value?.setVisible(dialogVisibles.value)
		}

		let isVip = userInfo.isVip

		let fatherInformation: result = reactive({
			datas: {},
		})

		let getuserInformation = () => {
			if (userInfo.id > 0) {
				userApi.getInformation().then((res: any) => {
					userInfor.datas = res.datas
					author.value = res.datas.id
					let uid = userInfor.datas.pid
					userApi.userHome(author.value).then((res: any) => {
						fansInfor.datas = res.data
					})
					userApi.getInformation({ uid: uid }).then((res: any) => {
						fatherInformation.datas = res.datas
					})

					if (res.datas.pid > 0) {
						getSonVip(res.datas.pid)
					} else {
						userInfo.updateVip(
							res.datas.vip_type,
							res.datas.vip_title,
							res.datas.vip_etime,
							res.datas.vip_id
						)
					}
				})
			}
		}

		watch(userInfo, () => {
			vipInfor.data = getVipInfo()
		})
		let goUser = () => {
			if (userInfor.datas.pid == 0) {
				router.push({
					name: "personalcenter",
					params: { userid: userInfor.datas.id },
				})
			}
			if (userInfor.datas.pid != 0) {
				router.push({
					name: "personalcenter",
					params: { userid: userInfor.datas.pid },
				})
			}
		}

		onMounted(() => {
			goodsApi.getGoodsClass().then((res: any) => {
				if (res.datas) {
					arr1.datas = res.datas
				}
				getuserInformation()
			})
		})
		return {
			tabPosition,
			activeName,
			state,
			arr1,
			userInfor,
			vipInfor,
			a,
			router,
			author,
			fansInfor,
			userName,
			mobile,
			loginOut,
			getImgUrl,
			money,
			changelist,
			showName,
			userInfo,
			movibotClass,
			gotoMovibot,
			gotoMovibots,
			goSkip,
			isVip,
			dialogVisibles,
			setDialogVisibles,
			mcloses,
			sonRefs,
			carouselshare,
			getuserInformation,
			goUser,
			fatherInformation,
		}
	},
})
