
import { defineComponent, inject, reactive, ref, watch } from "vue"
import type { UploadProps, UploadUserFile } from "element-plus"
import { userApi } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
export default defineComponent({
  components: {},
  emits: ["addToUnCommitted"],
  props: {
    questionInfo: {
      type: Object,
      default: () => ({}),
    },
    index:{
      type:Number,
      default:100
    }
  },
  setup(props: any, { emit }) {
    let question = reactive({
      info: "",
    })

    let imgUrl = inject("imgUrl")

    const fileList = ref<UploadUserFile[]>([])

    let initInfo = () => {
      fileList.value=[]
      if (props.questionInfo.content) {
        question.info = props.questionInfo.content
        console.log("开始了", props.questionInfo, "开始了")

        if (props.questionInfo.pics && props.questionInfo.pics.length > 0) {
          for (let i = 0; i < props.questionInfo.pics.length; i++) {
              fileList.value.push({
                name: i + ".jpg",
                url: props.questionInfo.pics[i],
              })
          
          }
        }
      }
    }

    watch(props, () => {initInfo()})

    const dialogImageUrl = ref("")
    const dialogVisible = ref(false)
    // const handleRemove: UploadProps["onRemove"] = (uploadFile, uploadFiles) => {
    //   //fileList.value = uploadFiles;
    //   //console.log(uploadFile, uploadFiles);
    //   // fileList.value.findIndex((item:any)=>
    //   //           {
    //   //               if(item.id == id){
    //   //                   return true;
    //   //               }
    //   //           }
    //   //       )
    // };
    const handlePictureCardPreview: UploadProps["onPreview"] = (uploadFile) => {
      dialogImageUrl.value = uploadFile.url!
      dialogVisible.value = true
    }

    //let handleSuccess: UploadProps["onSuccess"] = (res, fileLists) => {
    //console.log("开始",fileList,"结束");
    //上传成功之后放到囊中
    // fileList.value.push(res.data.url);
    // ElMessage.success("上传成功");
    //};
    let handleProgress: UploadProps["onChange"] = (
      file: any,
      fileList: any
    ) => {
      const size =
        file.size / 1024 / 1024 > 0.1
          ? `(${(file.size / 1024 / 1024).toFixed(1)}M)`
          : `(${(file.size / 1024).toFixed(1)}KB)`
      file.name.indexOf("M") > -1 || file.name.indexOf("KB") > -1
        ? file.name
        : (file.name += size)
      console.log(size)
    }
    let beforeUpload: UploadProps["beforeUpload"] = (rawFile: any) => {
      //获取上传的签名
      if (rawFile.size / 1024 / 1024 > 5) {
        sjyAlert("该文件类别上传文件大小上传文件大小最大为5MB", "error")
        return false
      }
      userApi.getOSSToken().then((res: any) => {
        let url = res.data.host
        let new_multipart_params = {
          // key表示上传到Bucket内的Object的完整路径，例如exampledir/exampleobject.txtObject，完整路径中不能包含Bucket名称。
          // filename表示待上传的本地文件名称。
          key: res.data.dir + rawFile.uid + rawFile.name,
          policy: res.data.policy,
          OSSAccessKeyId: res.data.accessid,
          // 设置服务端返回状态码为200，不设置则默认返回状态码204。
          success_action_status: "200",
          signature: res.data.signature,
          file: rawFile,
        }

        userApi.updateOss(url, new_multipart_params).then((res: any) => {
          fileList.value.push({
            name: rawFile.uid + rawFile.name,
            url: imgUrl + "/img/sjy/" + rawFile.uid + rawFile.name,
          })
        })
      })
      return false
    }

    let addQuestion = () => {
      let questionItem = {
        content: question.info,
        pics: [] as any,
        isShow: false,
        status: 0,
      }
      if (question.info && question.info.length > 14) {
        for (let i = 0; i < fileList.value.length; i++) {
          if (fileList.value[i].url) {
            questionItem.pics.push(fileList.value[i].url)
          }
        }
        emit("addToUnCommitted", questionItem,props.index)
        question.info = ""
        fileList.value = []
      } else {
        sjyAlert("问题描述不能少于15字", "error")
      }
    }

    return {
      dialogImageUrl,
      dialogVisible,
      handlePictureCardPreview,
      fileList,
      question,
      beforeUpload,
      addQuestion,
      handleProgress,
    }
  },
})
