
import { defineComponent } from "vue";
import CustomerTitle from "./common/CustomerTitle.vue";

export default defineComponent({
  components: { CustomerTitle },
  setup() {
    let titleList = ["售后服务", "退款说明"];
    return { titleList };
  },
});
