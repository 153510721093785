
import { defineComponent, reactive, ref, watch } from "vue"

interface goodsType {
  numbers: number
  minimum: number
  stock: number
}
const goodsTypeHelper = function (): goodsType {
  return {} as goodsType
}
export default defineComponent({
  components: {},

  props: {
    data: {
      type: goodsTypeHelper,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  emits: ["handleNumbersChange"],
  setup(props: any, { emit }) {
    let goodsInfo = reactive({ data: {} as any })
    let num = ref(1)
    watch(props, () => {
      goodsInfo.data = props.data
      goodsInfo.data.minimum = parseInt(props.data.minimum)
      goodsInfo.data.stock = parseInt(props.data.stock)
      
      num.value = parseInt(goodsInfo.data.numbers)
      if (goodsInfo.data.minimum > goodsInfo.data.stock) {
        goodsInfo.data.minimum = 0
        goodsInfo.data.stock = 0
        num.value = 0
      }
    },{immediate:true})

    let handleChange = (val: number) => {
      emit("handleNumbersChange", val, props.index)
    }
    return { num, goodsInfo, handleChange }
  },
})
