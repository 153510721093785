
import { reactive, defineComponent, ref } from "vue";
import { userStore } from "@/stores/userStore";
export default defineComponent({
  setup() {
    const tableData = [
      {
        number: "pw202207231107561908",
        name: "末端执行器20220722.zip",
        type: "末端执行器(100-500)币",
        state: "审核中",
      },
    ];

    let userinfot = userStore();
    console.log(userinfot.id);

    return {
      tableData,
      userinfot,
    };
  },
});
