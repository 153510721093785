
import {
	defineComponent,
	ref,
	onMounted,
	onUnmounted,
	nextTick,
	reactive,
	computed,
	inject,
	provide,
	watch,
} from "vue"
import { useRoute, useRouter } from "vue-router"
import HtmlFooter from "@/components/HtmlFooter.vue"
import HomeHead from "@/components/HomeHead.vue"
import CurrentAddress from "@/components/common/CurrentAddress.vue"
import { ElTable } from "element-plus"
import GoodsItem from "@/components/order/GoodsItem.vue"
import GoodsRealItem from "@/components/order/GoodsRealItem.vue"
import GoodsModel from "@/components/order/GoodsModel.vue"
import ConsultingSelect from "@/components/order/ConsultingSelect.vue"
import GoodsPrice from "@/components/order/GoodsPrice.vue"
import GoodsList from "../../components/order/GoodsList.vue"
import PriceDetails from "../../components/order/PriceDetails.vue"
import Youhuiquan from "../../components/order/Youhuiquan.vue"
import { goodsApi, orderApi, userApi } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
import { userStore } from "@/stores/userStore"
import { updateCartTotal } from "@/utils/public"
import CartNumbers from "@/components/order/CartNumbers.vue"
import DialogIndex from "@/components/dialog/DialogIndex.vue"

export default defineComponent({
	components: {
		HomeHead,
		HtmlFooter,
		CurrentAddress,
		GoodsItem,
		GoodsModel,
		ConsultingSelect,
		GoodsPrice,
		GoodsList,
		PriceDetails,
		Youhuiquan,
		CartNumbers,
		DialogIndex,
		GoodsRealItem,
	},
	setup() {
		let cartType = ref(0)

		let router = useRouter()
		let route = useRoute()

		if (parseInt(route.params.type as string) == 1) {
			cartType.value = 1
		}
		let isShowDialog = ref(false)
		let region = ref("")
		let isShow = ref(false)
		let showDetails = ref(false)
		let isFixed = ref(true)
		let loading = ref(true)
		let dialogType = ref(0)
		let cartIndex = ref(0)
		const multipleTableRef = ref<InstanceType<typeof ElTable>>()
		let toggleAllSelection = () => {
			multipleTableRef.value!.toggleAllSelection()
		}
		let userInfo = userStore()
		let chageType = (val: number) => {
			cartType.value = val
			if (multipleTableRef.value != null) {
				multipleTableRef.value!.clearSelection()
			}
			isShow.value = false
			showDetails.value = false
			getCartGoodsList()
		}
		interface multipleSelectionType {
			id: number
			names: string
			price: number
			photo: string
			cartId: number
			counsel: number
			cid: number
			numbers: number
		}
		const multipleSelection = ref<multipleSelectionType[]>([])
		const toggleSelection = (rows?: multipleSelectionType[]) => {
			if (rows) {
				rows.forEach((row) => {
					// TODO: improvement typing when refactor table
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-expect-error
					multipleTableRef.value!.toggleRowSelection(row, undefined)
				})
			} else {
				multipleTableRef.value!.clearSelection()
			}
		}

		const handleSelectionChange = (val: multipleSelectionType[]) => {
			//console.log(val);
			multipleSelection.value = val
		}

		interface cartType {
			errno: number
			datas: any
			errmsg: string
		}
		const cartData: cartType = reactive({
			errno: 0,
			errmsg: "",
			datas: {},
		})
		//删除购物车
		let submitDelete = (ids: Array<number>, msg: string = "删除成功") => {
			if (ids.length < 1) {
				sjyAlert("请选择要删除的商品", "error")
			} else {
				goodsApi.deleteCart(ids).then((res: any) => {
					if (res.errno == 0) {
						initCart(ids)
						updateCartTotal()

						sjyAlert(msg, "success")
					}
				})
			}
		}
		//删除按钮
		let handleClick = (type: number, index: number) => {
			//删除购物车里的商品
			dialogType.value = type
			cartIndex.value = index
			isShowDialog.value = true
			//submitDelete([cartData.datas[index].cartId])
		}

		//批量删除
		let deleteAll = (msg: string = "删除成功") => {
			let ids: Array<number> = reactive([])
			multipleSelection.value.findIndex((item) => {
				if (item.cartId) {
					ids.push(item.cartId)
				}
			})
			submitDelete(ids, msg)
		}

		//整理购物车
		let initCart = (ids: Array<number>) => {
			for (let i = 0; i < ids.length; i++) {
				for (let j = 0; j < cartData.datas.length; j++) {
					if (cartData.datas[j].cartId == ids[i]) {
						cartData.datas.splice(j, 1)
					}
				}
				for (let k = 0; k < multipleSelection.value.length; k++) {
					if (multipleSelection.value[k].cartId == ids[i]) {
						multipleSelection.value.splice(k, 1)
					}
				}
			}
			//请求接口
		}

		//获取购物车商品信息
		let getCartGoodsList = () => {
			loading.value = true

			orderApi.getCartGoodsList(cartType.value).then((res: any) => {
				if (res.errno == 0) {
					loading.value = false
					cartData.datas = res.datas
					updateCartTotal(res.datas.length)
				}
			})
		}

		// watch(cartData,()=>{

		// })

		//查看明细
		let priceDetails = () => {
			isShow.value = false
			showDetails.value = !showDetails.value
		}
		let changeGoodsList = () => {
			showDetails.value = false
			if (multipleSelection.value.length > 0) {
				isShow.value = !isShow.value
			}
		}
		interface orderInfoType {
			payPrice: number //实际支付价格
			goodsPrice: number //商品价格
			vipPrice: number //vip优惠金额
			servicePrice: number //咨询服务金额
			couponPrice: number //优惠券金额
			actPrice: number //活动优惠金额
		}
		let orderInfo = computed(() => {
			let info: orderInfoType = {
				payPrice: 0,
				goodsPrice: 0, //商品价格
				vipPrice: 0, //vip优惠金额
				servicePrice: 0, //咨询服务金额
				couponPrice: 0,
				actPrice: 0,
			}

			// if (multipleSelection.value.length > 0) {
			//   multipleSelection.value.findIndex((item) => {
			//     info.payPrice =
			//       Number(info.payPrice) +
			//       Math.ceil(
			//         (Number(item.price) + Number(item.counsel * servicePice)) *
			//           userInfo.discount
			//       )
			//   })
			// }

			// return info

			if (multipleSelection.value.length > 0) {
				multipleSelection.value.findIndex((item: any) => {
					if (cartType.value == 0) {
						if (item.activity_price && item.activity_price > 0) {
							info.payPrice =
								Number(info.payPrice) + Number(item.activity_price)
							info.actPrice = Math.round(
								Number(info.actPrice) +
									(Number(item.price) - Number(item.activity_price))
							)
						} else {
							info.payPrice =
								Number(info.payPrice) +
								(Number(item.price) + Number(item.counsel * servicePice)) *
									userInfo.discount

							info.vipPrice =
								Number(info.vipPrice) +
								(Number(item.price) + Number(item.counsel * servicePice)) *
									(1 - userInfo.discount)
						}
						info.goodsPrice = Number(info.goodsPrice) + Number(item.price)
						console.log(info.servicePrice, parseInt(item.counsel), servicePice)

						info.servicePrice =
							Number(info.servicePrice) +
							parseInt(item.counsel) * Number(servicePice)
					} else {
						info.payPrice =
							Number(info.payPrice) + Number(item.price) * Number(item.numbers)
					}
				})

				info.payPrice = Math.round(info.payPrice)
				info.vipPrice = Math.round(info.vipPrice)
				if (
					couponList.data &&
					couponList.data.length > 0 &&
					cartType.value == 0
				) {
					info.couponPrice = couponList.data[couponsIndex.value].amount
				} else {
					info.couponPrice = 0
				}

				info.payPrice = info.payPrice - info.couponPrice
			}
			return info
		})

		let totalHeight = ref(0)
		let footerHeight = ref(0)
		let htmlObj: any = reactive({})
		let footerObj: any = reactive({})
		const contentsHeight = () => {
			nextTick(() => {
				let timer = setTimeout(function () {
					htmlObj = document.getElementById("app")
					footerObj = document.getElementById("footer")
					footerHeight.value = footerObj.clientHeight
					totalHeight.value = htmlObj.scrollHeight
				}, 500)
			})
		}
		//获取滚动的高度
		const onScroll = () => {
			// 得到页面滚动的距离，兼容写法
			const scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop
			// 滚动到固定距离吸顶
			isFixed.value = !(
				scrollTop >=
				totalHeight.value -
					footerHeight.value -
					document.documentElement.clientHeight
			)
		}
		/**
		 * 防抖
		 */
		let debounce = (fn: any, delay: number) => {
			//记录上一次的延时器
			var timer: any = null
			return function () {
				clearTimeout(timer)
				timer = setTimeout(function () {
					fn()
				}, delay)
			}
		}

		let couponList = reactive<any>({
			data: [],
		})
		//获取优惠券
		let getCouponsList = () => {
			userApi.getCouponsList().then((res: any) => {
				if (res.errno == 0) {
					if (res.data.list.length > 0) {
						res.data.list.findIndex((item: any) => {
							if (item.status == "正常") {
								couponList.data.push(item)
							}
						})
					}

					if (couponList.data.length > 0) {
						couponsIndex.value = 0
					}
				} else {
					sjyAlert(res.errmsg, "error")
				}
			})
		}
		//选择的优惠券的下标
		let couponsIndex = ref(0)
		let setCouponsIndex = (val: number) => {
			couponsIndex.value = val
		}
		provide("setCouponsIndex", setCouponsIndex)

		let gotoHome = () => {
			if (cartType.value == 1) {
				router.push({
					name: "spareHome",
				})
			} else {
				router.push({
					name: "home",
				})
			}
		}

		let handleNumbersChange = (val: number, index: number) => {
			cartData.datas[index].numbers = val
		}

		onMounted(() => {
			getCartGoodsList()
			getCouponsList()
			window.addEventListener("scroll", debounce(onScroll, 100))
			contentsHeight()
			nextTick(() => {
				let timer1 = setTimeout(function () {
					isFixed.value =
						totalHeight.value - footerHeight.value >
						document.documentElement.clientHeight
				}, 1000)
			})
		})
		let changeCounsel = (val: number, cartId: number) => {
			for (let j = 0; j < cartData.datas.length; j++) {
				if (cartData.datas[j].cartId == cartId) {
					cartData.datas[j].counsel = val
				}
			}
			for (let k = 0; k < multipleSelection.value.length; k++) {
				if (multipleSelection.value[k].cartId == cartId) {
					multipleSelection.value[k].counsel = val
				}
			}
			orderApi.upsetCartCounsel(cartId, val).then((res: any) => {
				if (res.errno == 0) {
					sjyAlert("修改成功", "success")
				} else {
					sjyAlert(res.errmsg, "error")
				}
			})
			//更改本地数据
		}
		//获取小计
		let servicePice = inject("servicePice", 25)
		let getItemPrice = (item: any) => {
			let goodsprice = 0
			if (cartType.value == 0) {
				goodsprice =
					item.activity_price > 0
						? Math.round(item.activity_price) +
						  Math.round(Number(item.counsel * servicePice) * userInfo.discount)
						: Math.round(
								(Number(item.price) + Number(item.counsel * servicePice)) *
									userInfo.discount
						  )
			} else {
				goodsprice = Number(item.price) * item.numbers
			}

			return goodsprice
		}
		/**
		 * 去结算
		 */
		let confirmedOrder = () => {
			//获取选择的商品
			let ids = ""
			for (let k = 0; k < multipleSelection.value.length; k++) {
				if (ids == "") {
					ids =
						"" +
						multipleSelection.value[k].id +
						"-" +
						multipleSelection.value[k].counsel +
						"-" +
						multipleSelection.value[k].numbers
				} else {
					ids =
						ids +
						"_" +
						multipleSelection.value[k].id +
						"-" +
						multipleSelection.value[k].counsel +
						"-" +
						multipleSelection.value[k].numbers
				}
			}
			router.push({ name: "confirmed", params: { ids: ids } })
		}
		let addshoucang = () => {
			console.log(multipleSelection.value, "选中的商品")
			if (multipleSelection.value.length > 0) {
				let mdatas = []
				for (let i = 0; i < multipleSelection.value.length; i++) {
					mdatas.push({
						product_id: multipleSelection.value[i].id,
						type: 1,
						status: 1,
						product_name: multipleSelection.value[i].names,
						cid: multipleSelection.value[i].cid,
					})

					// goodsApi
					//   .addStore(
					//     multipleSelection.value[i].id,
					//     1,
					//     1,
					//     multipleSelection.value[i].names,
					//     multipleSelection.value[i].cid
					//   )
					//   .then((res: any) => {
					//     if (res.errno == 0) {
					//       deleteAll("收藏成功")
					//       //sjyAlert("收藏成功", "success",deleteAll)
					//     } else {
					//       sjyAlert(res.errmsg, "warning")
					//     }
					//   })
				}
				goodsApi.addStore(mdatas).then((res: any) => {
					if (res.errno == 0) {
						deleteAll("收藏成功")
						//sjyAlert("收藏成功", "success",deleteAll)
					} else {
						sjyAlert(res.errmsg, "warning")
					}
				})
			} else {
				sjyAlert("没有选中的商品", "warning")
			}
		}
		//删除
		let dele = (type: number, index: number) => {
			isShowDialog.value = false
			console.log(type)

			if (type == 0) {
				submitDelete([cartData.datas[index].cartId])
			} else {
				deleteAll()
			}
		}
		let cancel = () => {
			isShowDialog.value = false
		}

		onUnmounted(() => {
			window.removeEventListener("scroll", onScroll)
		})
		return {
			cartData,
			handleSelectionChange,
			toggleSelection,
			multipleSelection,
			toggleAllSelection,
			multipleTableRef,
			region,
			handleClick,
			isShow,
			changeGoodsList,
			priceDetails,
			showDetails,
			isFixed,
			deleteAll,
			orderInfo,
			changeCounsel,
			getItemPrice,
			confirmedOrder,
			couponsIndex,
			couponList,
			addshoucang,
			loading,
			gotoHome,
			cartType,
			chageType,
			handleNumbersChange,
			dele,
			cancel,
			isShowDialog,
			dialogType,
			cartIndex,
		}
	},
})
