
import { defineComponent, reactive, onMounted, ref } from "vue"
import { goodsApi } from "@/service/api"
import CurrentAddress from "../../common/CurrentAddress.vue"
import { useRoute, useRouter } from "vue-router"

export default defineComponent({
	components: { CurrentAddress },
	emits: ["setGoodsWhere"],
	props: {
		id: {
			type: Number,
			default: 0,
		},
	},
	setup(props: any, context) {
		interface goodsAttrType {
			datas: any
			errno: number
			errmsg: string
		}

		let params = useRoute().params

		//当前选择的分类下标
		let nowIndex = ref(0)

		let goodsAttr: goodsAttrType
		//获取aids
		let aids: Array<number> = reactive([])
		let moreindex = ref(100)

		goodsAttr = reactive({
			datas: [{}],
			errno: 0,
			errmsg: "",
		})

		let getGoodsAttr = () => {
			goodsApi.getGoodsClass().then((res) => {
				goodsAttr.datas = (res as goodsAttrType).datas.自动化设计方案
				goodsAttr.errno = (res as goodsAttrType).errno
				goodsAttr.errmsg = (res as goodsAttrType).errmsg
				initWhere()
				getGoodsListWhere()
			})
		}

		//切换分类
		let selectClass = (val: number) => {
			nowIndex.value = val
			//清空所有选择的属性
			deleteAttr()
			getGoodsListWhere()
		}
		let toSubmit = () => {
			moreindex.value = 100
			getGoodsListWhere()
		}
		let toCancel = (indexs: number) => {
			moreindex.value = 100
			radioAttr(indexs)
			getGoodsListWhere()
		}
		//取消所有选择的属性
		let deleteAttr = () => {
			for (let i = 0; i < goodsAttr.datas.length; i++) {
				for (let j = 0; j < goodsAttr.datas[i].son.length; j++) {
					for (let m = 0; m < goodsAttr.datas[i].son[j].son1.length; m++) {
						goodsAttr.datas[i].son[j].son1[m].status = 0
					}
				}
			}
		}

		//单选 ， 同类型只能选择一个
		let radioAttr = (indexs: number) => {
			for (
				let m = 0;
				m < goodsAttr.datas[nowIndex.value].son[indexs].son1.length;
				m++
			) {
				goodsAttr.datas[nowIndex.value].son[indexs].son1[m].status = 0
			}
		}

		//取消所有的选项
		let reset = () => {
			for (let m = 0; m < goodsAttr.datas[nowIndex.value].son.length; m++) {
				radioAttr(m)
			}
			getGoodsListWhere()
		}

		//选择属性
		let selectAttr = (indexs: number, indexo: number) => {
			//单选
			if (
				goodsAttr.datas[nowIndex.value].son[indexs].son1[indexo].status == 1
			) {
				goodsAttr.datas[nowIndex.value].son[indexs].son1[indexo].status = 0
			} else {
				if (indexs != moreindex.value) {
					radioAttr(indexs)
				}
				goodsAttr.datas[nowIndex.value].son[indexs].son1[indexo].status = 1
			}
			if (indexs != moreindex.value) {
				getGoodsListWhere()
			}
		}

		let getMoreIndex = (val: number) => {
			if (moreindex.value == val) {
				moreindex.value = 100
			} else {
				moreindex.value = val
			}
		}
		//整理查询条件,传递出去
		let getGoodsListWhere = () => {
			let aidss = []
			let selectAttr = ""
			for (let i = 0; i < goodsAttr.datas.length; i++) {
				for (let j = 0; j < goodsAttr.datas[i].son.length; j++) {
					selectAttr = ""
					for (let m = 0; m < goodsAttr.datas[i].son[j].son1.length; m++) {
						if (goodsAttr.datas[i].son[j].son1[m].status == 1) {
							if (selectAttr == "") {
								selectAttr = goodsAttr.datas[i].son[j].son1[m].id
							} else {
								selectAttr =
									selectAttr + " " + goodsAttr.datas[i].son[j].son1[m].id
							}
						}
					}

					if (selectAttr != "") {
						var tempAttr = selectAttr.split(" ")
						if (tempAttr.length > 1) {
							aidss.push(tempAttr)
						} else {
							aidss.push(tempAttr[0])
						}
					}
				}
			}

			interface whereType {
				params: {
					cid: number
					aids: Array<any>
				}
			}

			let where: whereType = reactive({
				params: {
					cid: 0,
					aids: [],
				},
			})

			where.params.cid = goodsAttr.datas[nowIndex.value].id
			where.params.aids.push(...aidss)

			context.emit("setGoodsWhere", where)
		}

		//初始化选项
		let initWhere = () => {
			let cid = params.classid
			let aid = params.attrid
			if (cid && parseInt(cid as string) > 0) {
				var index = goodsAttr.datas.findIndex((item: any) => {
					if (item.id == cid) {
						return true
					}
				})
				if (index > -1) {
					nowIndex.value = index
					for (let j = 0; j < goodsAttr.datas[nowIndex.value].son.length; j++) {
						for (
							let m = 0;
							m < goodsAttr.datas[nowIndex.value].son[j].son1.length;
							m++
						) {
							if (goodsAttr.datas[nowIndex.value].son[j].son1[m].id == aid) {
								goodsAttr.datas[nowIndex.value].son[j].son1[m].status = 1
								break
							}
						}
					}
				}
			}
		}

		onMounted(() => {
			getGoodsAttr()
		})
		return {
			getGoodsAttr,
			goodsAttr,
			nowIndex,
			selectClass,
			selectAttr,
			moreindex,
			getMoreIndex,
			toSubmit,
			toCancel,
			reset,
		}
	},
})
