
import { reactive, defineComponent, ref, onMounted, inject } from "vue"
import type { TabsPaneContext, FormRules, FormInstance } from "element-plus"
import type { UploadProps, UploadUserFile } from "element-plus"
import { userApi, works } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
import InvoiceDialog from "@/components/common/Dialog/InvoiceDialog.vue"
import * as _ from "lodash"

export default defineComponent({
	name: "submitworks",
	emits: ["payStatus", "changeNumber"],
	components: {
		InvoiceDialog,
	},
	setup(props, context) {
		// 是否上传完成 渲染上传页还是待审核返回页
		let upWorkIng = ref(false)
		let fileTotal = ref(0)
		let upTotal = ref(0)
		let handepro = ref(0)

		const worksForm: any = reactive({
			names: "",
			// 方案类别选择框
			type_id: 0,
			agreement: [] as any[],
			package_url: "",
			radio: "自动化单元",
			work: [],
		})
		let changeUpdate = () => {
			worksForm.work = []
			uploadError.value = false
		}
		//ruleFormRef
		const validateWork = (rule: any, work: any, callback: any) => {
			//console.log(work);

			if (worksForm.work.length == 0) {
				callback(new Error("请上传作品"))
			} else if (fileTotal.value > worksize(worktype, worksForm)) {
				callback(new Error("上传文件超出了允许大小"))
			} else {
				callback()
			}
		}

		const ruleFormRef = ref<FormInstance>()
		const rules = reactive<FormRules>({
			agreement: [
				{
					type: "array",
					// required 是否为必选项
					required: true,
					message: "请勾选!",
					//验证逻辑的触发方式
					trigger: "change",
				},
			],
			work: [{ validator: validateWork, trigger: "change" }],

			radio: [
				{
					required: true,
					message: "请选择方案类别!",
					//验证逻辑的触发方式
					trigger: "change",
				},
			],
		})
		let updateUrl = ref("")
		let additional = reactive({
			key: "",
			policy: "",
			OSSAccessKeyId: "",
			success_action_status: "",
			signature: "",
		})

		let initConfig = () => {
			userApi.getOSSToken().then((res: any) => {
				updateUrl.value = res.data.host
				//let new_multipart_params = {
				// key表示上传到Bucket内的Object的完整路径，例如exampledir/exampleobject.txtObject，完整路径中不能包含Bucket名称。
				// filename表示待上传的本地文件名称。
				additional.key = res.data.dir
				additional.policy = res.data.policy
				additional.OSSAccessKeyId = res.data.accessid
				// 设置服务端返回状态码为200，不设置则默认返回状态码204。
				additional.success_action_status = "200"
				additional.signature = res.data.signature

				// }
				//console.log(new_multipart_params);
			})
		}

		let beforeUpload: UploadProps["beforeUpload"] = (rawFile: any) => {
			// 判断是否个人认证或者企业认证 没有认证就先提示去认证
			//userApi.getInformation().then((res: any) => {
			//if (sureVerify.value) {
			//限制文件上传类型与大小
			// const fileType =
			//   rawFile.type == "application/zip" ||
			//   rawFile.type == "application/x-rar-compressed" ||
			//   rawFile.type == "application/x-zip-compressed"

			if (sureVerify.value) {
				//限制文件上传类型与大小
				const fileType =
					rawFile.type == "application/zip" ||
					rawFile.type == "application/x-rar-compressed" ||
					rawFile.type == "application/x-zip-compressed" ||
					rawFile.type == "application/x-tar"
				// if (true) {
				//   sjyAlert("该文件类别上传文件大小最大为4608MB", "error")
				//   return false
				// }
				if (
					worksForm.radio == "自动化单元" &&
					rawFile.size / 1024 / 1024 > 4500
				) {
					sjyAlert("该文件类别上传文件大小上传文件大小最大为4500MB", "error")
					return false
				}
				if (
					worksForm.radio == "工业机器人" &&
					rawFile.size / 1024 / 1024 > 2048
				) {
					sjyAlert("该文件类别上传文件大小上传文件大小最大为2048MB", "error")
					return false
				}
				if (
					worksForm.radio == "末端执行器" &&
					rawFile.size / 1024 / 1024 > 500
				) {
					sjyAlert("该文件类别上传文件大小最大为500MB", "error")
					return false
				}
				if (
					worksForm.radio == "视觉方案" &&
					rawFile.size / 1024 / 1024 > 2048
				) {
					sjyAlert("该文件类别上传文件大小上传文件大小最大为2048MB", "error")
					return false
				}
				if (
					worksForm.radio == "输送机构" &&
					rawFile.size / 1024 / 1024 > 2000
				) {
					sjyAlert("该文件类别上传文件大小上传文件大小最大为2000MB", "error")
					return false
				}
				if (worksForm.radio == "控制柜" && rawFile.size / 1024 / 1024 > 2048) {
					sjyAlert("该文件类别上传文件大小上传文件大小最大为2048MB", "error")
					return false
				}

				//console.log(rawFile.type, fileType);

				if (!fileType) {
					//   this.$message.error("上传图片只能是 JPG和png 格式!");
					sjyAlert("上传文件类型应为ZIP或RAR" + rawFile.type + "123", "error")
					return false
				}

				//获取上传的签名

				//console.log("开始了", rawFile, "结束了")
				additional.key = additional.key + rawFile.uid + rawFile.name
				worksupForm.names = rawFile.name
				//"https://image.sjyuan.net.cn/img/sjy/"+ rawFile.uid + rawFile.name
			} else {
				setDialogVisiblees()
				return false
			}
		}

		// 上传进度条
		let handelProgress: UploadProps["onProgress"] = (
			evt: any,
			uploadFile: any,
			uploadFiles: any
		) => {
			progressWidth.value = Math.ceil(344 * (evt.percent / 100))
			fileTotal.value = Math.ceil(uploadFile.size / 1024 / 1024)
			handepro.value = Math.ceil(evt.percent)
			upTotal.value = Math.ceil(fileTotal.value * (evt.percent / 100))
			watchShowError(evt.percent)
			watchShowIng(evt.percent)
			//_.debounce(showError(evt.percent), 5000)
			//console.log(evt.percent, uploadFile, uploadFiles)
		}
		//  上传作品

		const worksupForm = reactive({
			names: "",
			type_id: 0,
			package_url: "",
		})

		const submitForm = async (formEl: FormInstance | undefined) => {
			if (!formEl) return
			await formEl.validate((valid, fields) => {
				if (valid) {
					// console.log("submit!");

					if (worksForm.radio == "自动化单元") {
						worksupForm.type_id = worksForm.type_id = 1
					}
					if (worksForm.radio == "工业机器人") {
						worksupForm.type_id = worksForm.type_id = 2
					}
					if (worksForm.radio == "末端执行器") {
						worksupForm.type_id = worksForm.type_id = 3
					}
					if (worksForm.radio == "视觉方案") {
						worksupForm.type_id = worksForm.type_id = 4
					}
					if (worksForm.radio == "输送机构") {
						worksupForm.type_id = worksForm.type_id = 5
					}
					if (worksForm.radio == "控制柜") {
						worksupForm.type_id = worksForm.type_id = 6
					}
					worksupForm.package_url = additional.key

					// console.log(additional.key, "aaa");
					// console.log(worksForm.type_id, "查看id");
					console.log(worksupForm)

					//  作品上传接口
					works.workSubmit(worksupForm).then((res: any) => {
						// console.log(res);
						if (res.errno == 0) {
							sjyAlert("上传成功", "success")
							context.emit("payStatus")
							context.emit("changeNumber")
						} else {
							sjyAlert(res.errmsg, "error")
						}
					})
				} else {
					console.log("error submit!", fields)
					setTimeout(() => {
						window.scrollTo(0, 0)
						var isError = document.getElementsByClassName("is-error")
						;(isError[0].querySelector("input") as HTMLInputElement).focus()
					}, 100)
				}
			})
		}

		// 上传作品类别
		interface result {
			data: any
		}

		let worktype: result = reactive({
			data: {},
		})
		let getWorktype = () => {
			works.workType().then((res: any) => {
				worktype.data = res.datas
				changeMoney(worktype, worksForm)
				worksize(worktype, worksForm)
			})
		}
		let progressWidth = ref(0)
		const radio = ref()

		let changeMoney = (worktype: any, worksForm: any) => {
			//console.log("我改变了");

			if (worksForm.radio == "自动化单元") {
				return worktype.data[0].gear_scope
			}
			if (worksForm.radio == "工业机器人") {
				return worktype.data[1].gear_scope
			}
			if (worksForm.radio == "末端执行器") {
				return worktype.data[2].gear_scope
			}
			if (worksForm.radio == "视觉方案") {
				return worktype.data[3].gear_scope
			}
			if (worksForm.radio == "输送机构") {
				return worktype.data[4].gear_scope
			}
			if (worksForm.radio == "控制柜") {
				return worktype.data[5].gear_scope
			}
		}

		let worksize = (worktype: any, worksForm: any) => {
			let worktypeData = 0
			if (worksForm.radio == "自动化单元") {
				worktypeData = worktype.data[0].size / 1000
			}
			if (worksForm.radio == "工业机器人") {
				worktypeData = worktype.data[1].size / 1000
			}
			if (worksForm.radio == "末端执行器") {
				worktypeData = worktype.data[2].size / 1000
			}
			if (worksForm.radio == "视觉方案") {
				worktypeData = worktype.data[3].size / 1000
			}
			if (worksForm.radio == "输送机构") {
				worktypeData = worktype.data[4].size / 1000
			}
			if (worksForm.radio == "控制柜") {
				worktypeData = worktype.data[5].size / 1000
			}
			//触发
			if (fileTotal.value > 0) {
				ruleFormRef.value?.validateField("work")
			}

			return worktypeData
		}

		//  原创声明弹框
		let dialogVisible = ref(false)
		interface addressType {
			data: any
		}
		const sonRef = ref<InstanceType<typeof InvoiceDialog>>()
		let setDialogVisible = () => {
			sonRef.value?.setVisible(true)
			//console.log();
		}

		// 关闭弹框
		let mclose = () => {
			dialogVisible.value = false
			sonRef.value?.setVisible(dialogVisible.value)
		}
		let check = ref(false)
		let know = () => {
			worksForm.agreement.push("我已阅读")
			mclose()
			return true
		}

		//  评价机制弹窗
		let dialogVisibles = ref(false)

		let setDialogVisibles = () => {
			sonRefs.value?.setVisible(true)
		}
		const sonRefs = ref<InstanceType<typeof InvoiceDialog>>()
		// 关闭弹框
		let mcloses = () => {
			dialogVisibles.value = false
			sonRefs.value?.setVisible(dialogVisibles.value)
		}

		//  去认证弹窗
		let dialogVisiblees = ref(false)

		let setDialogVisiblees = () => {
			sonRefes.value?.setVisible(true)
		}
		const sonRefes = ref<InstanceType<typeof InvoiceDialog>>()
		// 关闭弹框
		let mclosees = () => {
			dialogVisiblees.value = false
			sonRefes.value?.setVisible(dialogVisibles.value)
		}
		let imgUrl = inject("imgUrl")

		let download = () => {
			window.open(
				imgUrl +
					"/files/%E6%9C%AB%E7%AB%AF%E6%89%A7%E8%A1%8C%E5%99%A8%20%2020221105.zip"
			)
		}

		let downloads = () => {
			window.open(
				imgUrl +
					"/files/%E4%B8%8A%E4%BC%A0%E6%96%87%E4%BB%B6%E8%AF%B4%E6%98%8E%E5%8F%8A%E6%8A%80%E6%9C%AF%E5%8F%82%E6%95%B0.zip"
			)
		}
		let uploadError = ref(false)
		let showError = (val: any) => {
			if (Number(val) == 100) {
			} else {
				uploadError.value = true
			}
		}
		let ShowIng = () => {
			uploadError.value = false
		}

		let watchShowError = _.debounce(showError, 10000) //节流

		let watchShowIng = _.throttle(ShowIng, 5000)
		let getWorkInfor = () => {
			userApi.getInformation().then((res: any) => {
				//console.log(res, "信息")
				if (res.datas.identification == 3 || res.datas.company_id != null) {
					// console.log("123");
					sureVerify.value = true
				} else {
					// console.log("000");
					sureVerify.value = false
				}
			})
		}

		let sureVerify = ref()
		onMounted(() => {
			//console.log("我执行了onmounted")

			initConfig()
			getWorkInfor()
			getWorktype()
		})

		return {
			worksForm,
			submitForm,
			ruleFormRef,
			rules,
			getWorktype,
			worktype,
			radio,
			changeMoney,
			worksize,
			beforeUpload,
			updateUrl,
			additional,
			handelProgress,
			worksupForm,
			dialogVisible,
			mclose,
			sonRef,
			setDialogVisible,
			know,
			upWorkIng,
			check,
			download,
			progressWidth,
			fileTotal,
			upTotal,
			handepro,
			changeUpdate,
			downloads,
			setDialogVisibles,
			dialogVisibles,
			mcloses,
			sonRefs,
			sureVerify,
			dialogVisiblees,
			setDialogVisiblees,
			sonRefes,
			mclosees,
			uploadError,
			getWorkInfor,
		}
	},
})
