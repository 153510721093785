
import goodsApi from "@/service/module/goodsApi"
import { sjyAlert } from "@/utils/alert"
import { useRoute } from "vue-router"
import { defineComponent, inject, ref, watchEffect } from "vue"
export default defineComponent({
  name: "collection",
  props: {
    marginTop: {
      type: Number,
      default: 20,
    },
    id: {
      type: Number,
      require: true,
    },
    names: {
      type: String,
      require: true,
    },
    store: {
      type: Number,
      default: 0,
      require: true,
    },
    cid: {
      type: Number,
      default: 0,
      require: true,
    },
    type: {
      type: Number,
      default: 1,
    },
    goods: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any) {
    let isStore = ref(props.store)
    watchEffect(() => {
      isStore.value = props.store
    })
    let baseurl = inject("BASEURL", "")

    //console.log(props.store, isStore.value, "kaishile")
    //收藏
    const addStart = () => {
      if (isStore.value == 1) {
        //取消删除
        let mdata = [{
          product_id: props.id,
          type: props.type,
          status: 0,
          product_name: props.names,
          cid: props.cid,
        }]
        goodsApi.addStore(mdata).then((res: any) => {
          if (res.errno == 0) {
            isStore.value = 0
            sjyAlert("已取消收藏", "success")
          } else {
            sjyAlert(res.errmsg, "warning")
          }
        })
      } else {
        let mdata = [{
          product_id: props.id,
          type: props.type,
          status: 1,
          product_name: props.names,
          cid: props.cid,
        }]
        goodsApi.addStore(mdata).then((res: any) => {
          if (res.errno == 0) {
            isStore.value = 1
            sjyAlert("收藏成功", "success")
          } 
          // else {
          //   sjyAlert(res.errmsg, "warning")
          // }
        })
      }
    }

    let getImgUrl = inject(
      "getImgUrl",
      (index: string) => {
        return ""
      },
      false
    )
    let routerPath = ref("")
    routerPath.value = baseurl + useRoute().path
    let getLocalImgurl = (goods: any) => {
      if (goods.pics) {
        getImgUrl(goods.pics[0])
      } else if (goods.old_pics) {
        getImgUrl(goods.old_pics[0])
      } else {
        getImgUrl("")
      }
    }

    return {
      addStart,
      isStore,
      getImgUrl,
      routerPath,
      getLocalImgurl,
    }
  },
})
