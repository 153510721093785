
import { publicApi } from "@/service/api";
import { defineComponent, reactive, onMounted } from "vue";
export default defineComponent({
  
  setup() {
    interface result {
      data: Array<any>;
    }

    let classList: result = reactive({
      data: [{}],
    });

    let getClassList = () => {
      // publicApi.classList().then((res) => {
      //   classList.data = (res as result).data;
      // });
    };

    onMounted(() => {
      getClassList();
    });

    return {
      getClassList,
      classList,
    };
  },
});
