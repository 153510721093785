
import { defineComponent, inject } from "vue"

export default defineComponent({
	components: {},
	props: {
		goods: {
			type: Object,
			default: () => ({}),
		},
	},
	setup() {
		const staticUrl = inject("staticUrl")
		let changeUrl = (val: any) => {
			if (val.startsWith("https")) {
				return val
			} else {
				return staticUrl + val
			}
		}
		return { changeUrl }
	},
})
