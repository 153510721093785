
import { defineComponent, ref } from "vue"
import { useMouseInElement } from "@vueuse/core"
import { computed } from "@vue/reactivity"
export default defineComponent({
  setup() {
    const target = ref(null)
    // isOutside是否进入指定区域 进入为false 否则为true
    // elementX 鼠标X位置
    // elementY 鼠标Y位置
    const { isOutside, elementX, elementY } = useMouseInElement(target) // useMouseInElement(指定的区域)鼠标进入的位置
    const active = ref(0)
    const images = ref(
      "https://images.mepai.me/app/works/178221/2022-07-14/w_62d01aa163e45/062d01aa163f41.jpg!1200w.jpg"
    )
    const position = computed(() => {
      let x = elementX.value - 100
      let y = elementY.value - 100
      console.log(x,y)
      x = x < 0 ? 0 : x
      y = y < 0 ? 0 : y
      x = x > 200 ? 200 : x
      y = y > 200 ? 200 : y
      return {
        x,
        y,
      }
    })
    return {
      target,
      isOutside,
      elementX,
      elementY,
      active,
      images,
      position
    }
  },
})
