
import { defineComponent, reactive, ref } from "vue"
import type { FormInstance } from "element-plus"
import { Search } from "@element-plus/icons-vue"
export default defineComponent({
  components: {},
  emits: ["setWhere","showMoreWhere"],
  name: "searchorderno",
  setup(props: any, { emit }) {
    const orderSearch = reactive({
      name: "",
      status: "0",
      pageIndex: 1,
      pageSize: 20,
    })

    // const onSubmit = () => {
    //   console.log("submit!");
    // };
    const ruleFormRef = ref<FormInstance>()

    const resetForm = () => {
      //if (!formEl) return;
      orderSearch.name = ""
    }

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          emit("setWhere", orderSearch)
        } else {
          console.log("error submit!", fields)
        }
      })
    }

    let isShowMoreWhere = ref(false)
    let showMoreWhere = () => {
      isShowMoreWhere.value = !isShowMoreWhere.value
      emit("showMoreWhere",isShowMoreWhere.value)
    }

    return {
      orderSearch,
      resetForm,
      ruleFormRef,
      submitForm,
      Search,
      isShowMoreWhere,
      showMoreWhere,
    }
  },
})
