
import { reactive, defineComponent, ref, provide } from "vue";
import type { TabsPaneContext, FormRules, FormInstance } from "element-plus";
import SubmitWorks from "./SubmitWorks.vue";
import Audit from "./Audit.vue";
import UploadHistory from "./UploadHistory.vue";
import PassAudit from "./PassAudit.vue";
import FailureAudit from "./FailureAudit.vue";
import ReleaseSuccess from "./ReleaseSuccess.vue";
import RevisionWorks from "./RevisionWorks.vue";
import ReviewAndReturn from "./ReviewAndReturn.vue";
import AuditAndReturn from "./AuditAndReturn.vue";
export default defineComponent({
  components: {
    SubmitWorks,
    Audit,
    UploadHistory,
    //发布成功
    RevisionWorks,
    ReviewAndReturn,
    // 审核中
    AuditAndReturn,
    //未通过
    FailureAudit,
    //通过
    PassAudit,
    //发布成功
    ReleaseSuccess,
  },
  setup() {
    interface result {
      data: any;
    }
    const activeName = ref("first");
    const handleClick = (tab: TabsPaneContext, event: Event) => {
      // console.log(tab, event)
    };
    let changeEd = ref(0);
    let changeNumber = () => {
      changeEd.value = changeEd.value + 1;
      //console.log(changeEd.value, "执行了");
    };

    // 切换页面
    let tabs = reactive({
      currentView: "SubmitWorks",
      UploadRecord: "UploadHistory",
      RevisionWorks: "RevisionWorks",
      // status: "",
      // changePays: "",
      // payStatus: "",
    });

    // 作品上传成功 待审核
    let payStatus = () => {
      //console.log("aaa");
      tabs.currentView = "Audit";
    };
    let work: result = reactive({
      data: [],
    });

    // 上传历史 状态切换
    let changeState = (workData: any) => {
      work.data = [];
      work.data.push(workData);

      //console.log(work.data,"aaa");
      if (workData.status == 0) {
        tabs.UploadRecord = "UploadHistory";
      }
      if (workData.status == 1) {
        // console.log(workData);
        tabs.UploadRecord = "AuditAndReturn";
      }
      if (workData.status == 2) {
        tabs.UploadRecord = "PassAudit";
      }
      if (workData.status == 3) {
        tabs.UploadRecord = "FailureAudit";
      }
      if (workData.status == 4) {
        tabs.UploadRecord = "ReleaseSuccess";
      }
    };

    // 去上传
    let returnSubmit = () => {
      tabs.currentView = "SubmitWorks";
    };

    // 跳转到上传历史
    let returnChange = () => {
      activeName.value = "second";
      // console.log("aaa");
      altershow.value = false;
    };

    let altershow = ref(false);
    //
    let showsss = () => {
      altershow.value = true;
      //console.log(activeName);
      activeName.value = "thirdly";
    };

    let goUpWork = () => {
      activeName.value = "first";
    };
    return {
      activeName,
      handleClick,
      tabs,
      payStatus,
      returnSubmit,
      showsss,
      altershow,
      returnChange,
      goUpWork,
      changeState,
      work,
      changeNumber,
      changeEd,
    };
  },
});
