
import {
	defineComponent,
	inject,
	ref,
	computed,
	reactive,
	onMounted,
	provide,
} from "vue"
import HtmlFooter from "@/components/HtmlFooter.vue"
import HomeHead from "@/components/HomeHead.vue"
import CurrentAddress from "@/components/common/CurrentAddress.vue"
import GoodsItem from "@/components/order/GoodsItem.vue"
import GoodsModel from "@/components/order/GoodsModel.vue"
import GoodsPrice from "@/components/order/GoodsPrice.vue"
import ConsultingSelect from "@/components/order/ConsultingSelect.vue"
import GoodsPart from "@/components/order/GoodsPart.vue"
import Ayouhuiquan from "@/components/order/Ayouhuiquan.vue"
import DialogTitle from "@/components/common/DialogTitle.vue"
import Dialog from "@/components/common/Dialog/Dialog.vue"
import Title from "@/components/common/Dialog/item/Title.vue"
import AddressFrom from "../../components/common/Dialog/item/AddressFrom.vue"
import { userStore } from "@/stores/userStore"
import PayDialog from "@/components/common/Dialog/item/PayDialog.vue"
import { RouteRecordName, useRoute, useRouter } from "vue-router"
import { orderApi, parts, userApi } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
import { showPrice } from "@/utils/public"
import GoodsRealItem from "@/components/order/GoodsRealItem.vue"
import DialogShare from "@/components/dialog/DialogShare.vue"
import CartNumbers from "@/components/order/CartNumbers.vue"
import type { FormInstance, FormRules } from "element-plus"
export default defineComponent({
	components: {
		HomeHead,
		ConsultingSelect,
		HtmlFooter,
		CurrentAddress,
		GoodsPrice,
		GoodsItem,
		GoodsModel,
		Ayouhuiquan,
		DialogTitle,
		Dialog,
		Title,
		AddressFrom,
		PayDialog,
		GoodsPart,
		GoodsRealItem,
		DialogShare,
		CartNumbers,
	},
	setup(props: any) {
		//获取参数
		interface addressType {
			data: any
		}
		const ruleForm = reactive({
			remarks: "",
		})
		const ruleFormRef = ref<FormInstance>()
		const rules = reactive<FormRules>({
			remarks: [{ max: 200, message: "最多200个字符", trigger: "blur" }],
		})
		let showShareUrl = ref(0)
		//订单类型
		let orderType = ref(0)

		let router = useRouter()

		//addressListType
		let addressList: addressType = reactive({
			data: [],
		})
		let addressItem = reactive({
			data: {},
		})
		interface paramsType {
			id: number
			counsel: number
			numbers: number
		}
		let params: Array<paramsType> = reactive([])
		let tempids = (useRoute().params.ids as string).split("_")
		for (let i = 0; i < tempids.length; i++) {
			let tempitem = tempids[i].split("-")

			let item: paramsType = {
				id: 0,
				counsel: 0,
				numbers: 0,
			}
			item.id = parseInt(tempitem[0])
			if (tempitem[1] && tempitem[1] == "1") {
				item.counsel = 1
			} else {
				item.counsel = 0
			}

			item.numbers = parseInt(tempitem[2])
			params.push(item)
		}

		let userInfo = userStore()
		interface cartType {
			errno: number
			datas: any
			errmsg: string
		}
		const cartData: cartType = reactive({
			errno: 0,
			errmsg: "",
			datas: [],
		})
		let isSend = ref(false)
		//获取购物车商品信息
		let getCartGoodsList = () => {
			orderApi.getBuyGoodsInfo(params).then((res: any) => {
				getAddressList()
				cartData.datas = res.datas
				for (let i = 0; i < cartData.datas.length; i++) {
					if (i == 0) {
						orderType.value = cartData.datas[i].is_real_goods
					}
					if (cartData.datas[i].is_send == "2") {
						isSend.value = true
					}
					cartData.datas[i].type = cartData.datas[i].is_real_goods
					cartData.datas[i].partsNumber = 1
					for (let j = 0; j < params.length; j++) {
						if (params[j].id == cartData.datas[i].id) {
							cartData.datas[i].counsel = params[j].counsel
							cartData.datas[i].numbers = params[j].numbers
							break
						}
					}
				}
			})
		}
		let selectAddress = ref("")
		let getSelectAddress = () => {
			for (let i = 0; i < addressList.data.length; i++) {
				if (addressList.data[i].isSelect == 1) {
					selectAddress.value =
						addressList.data[i].province +
						addressList.data[i].city +
						addressList.data[i].area +
						addressList.data[i].address +
						"（" +
						addressList.data[i].contacts +
						"）" +
						addressList.data[i].phonenumber
				}
			}
		}

		//获取用户的收货地址
		let getAddressList = () => {
			//todo
			userApi.addressList().then((res: any) => {
				addressList.data = res.datas
				let isDefault = false
				for (let i = 0; i < addressList.data.length; i++) {
					if (addressList.data[i].default == 1) {
						if (!isDefault) {
							addressList.data[i].isSelect = true
							isDefault = true
						}
					} else {
						addressList.data[i].isSelect = false
					}
				}
				if (!isDefault) {
					addressList.data[0].isSelect = true
					addressList.data[0].default = 1
				}
				getSelectAddress()
			})
		}
		//设置默认地址
		let setDefaultAddress = (index: number) => {
			userApi.delAddress(addressList.data[index].id, 1).then((res: any) => {
				if (res.errno == 0) {
					sjyAlert("设置成功", "success")
					for (let i = 0; i < addressList.data.length; i++) {
						addressList.data[i].default = false
					}
					addressList.data[index].default = true
				}
			})
		}

		let changeAddress = (index: number) => {
			for (let i = 0; i < addressList.data.length; i++) {
				addressList.data[i].isSelect = false
			}
			addressList.data[index].isSelect = true
			getSelectAddress()
		}

		let dialogVisible = ref(false)
		const sonRef = ref<InstanceType<typeof Dialog>>()
		const toPay = ref<InstanceType<typeof Dialog>>()
		let setDialogVisible = () => {
			sonRef.value?.setVisible(true)
		}

		/**
		 * 修改收货地址
		 */
		let updateAddress = (address: any) => {
			sonRef.value?.setVisible(true)
			addressItem.data = address
		}

		/**
		 * 提交订单
		 */
		let showPay = () => {
			//生成订单
			let submitData = {
				addId: 0, //收货地址
				params: [{}], //商品信息{id:1,counsel:2}
				cou_id: 0, //优惠券id
				goods_price: 0, //商品价格
				consult_price: 0, //咨询服务金额
				vip_price: 0, //会员优惠金额
				act_price: 0, //折扣优惠金额
				discount_price: 0, //优惠券金额
				price: 0, //实付金额
				remarks: "",
			}
			submitData.remarks = ruleForm.remarks
			//获取选中的地址
			if (isSend.value) {
				var index = addressList.data.findIndex((item: any) => {
					if (item.isSelect == 1) {
						return true
					}
				})
				if (index < 0) {
					sjyAlert("请选择收货地址", "error")
					return false
				}
				submitData.addId = addressList.data[index].id
			} else {
				submitData.addId = 0
			}

			//获取选中的地址end

			//获取优惠券信息
			if (couponList.data && couponList.data.length > 0) {
				submitData.cou_id = couponList.data[couponsIndex.value].id
			}

			//获取优惠券信息end

			//整理商品信息
			// let cartGoodsList = [];
			// interface cartGoodsType{
			//   id:number,
			//   counsel:number
			// }
			// let cartGoods = {
			//   id: 0,
			//   counsel: 0,
			// };
			submitData.params = []
			for (let i = 0; i < cartData.datas.length; i++) {
				submitData.params.push({
					id: cartData.datas[i].id,
					counsel: cartData.datas[i].counsel,
					numbers: cartData.datas[i].numbers,
				})
			}

			//整理商品信息end
			//submitData.params.push(...cartGoodsList);
			submitData.goods_price = orderInfo.value.goodsPrice
			submitData.consult_price = orderInfo.value.servicePrice
			submitData.vip_price = orderInfo.value.vipPrice
			submitData.act_price = orderInfo.value.actPrice
			submitData.discount_price = orderInfo.value.couponPrice
			submitData.price = orderInfo.value.payPrice
			//toPay.value?.setVisible(true);
			if (orderType.value == 1) {
				parts
					.createOrder(
						submitData.addId,
						submitData.price,
						submitData.params,
						submitData.remarks
					)
					.then((res: any) => {
						console.log(res)

						if (res.errno == 0) {
							router.push({
								name: "payorder",
								params: {
									id: res.datas.id,
								},
							})
						}
					})
			} else {
				orderApi.createOrder(submitData).then((res: any) => {
					if (res.errno == 0) {
						if (orderType.value == 1) {
							router.push({
								name: "payorder",
							})
						} else {
							creatOrderInfo.data = res.datas
							if (res.datas.payment_error_times == 0) {
								localStorage.setItem("wrongtimes", "0")
								wrongtimes.value = 0
							}
							toPay.value?.setVisible(true)
						}
					} else {
						sjyAlert(res.errmsg, "error")
					}
				})
			}
		}
		let creatOrderInfo = reactive({
			data: {
				gear: 0,
				id: 0,
				nu: "",
				pay_password: 1,
				price: 2696,
			},
		})
		let gotoToBe = () => {
			router.replace({
				name: "tobepaid",
				params: {
					id: creatOrderInfo.data.id,
				},
			})
		}

		/**
		 * type:1
		 */
		let mclose = (type: number = 0) => {
			dialogVisible.value = false
			sonRef.value?.setVisible(dialogVisible.value)
			toPay.value?.setVisible(dialogVisible.value)
			if (type == 1) {
				getAddressList()
			} else {
				//跳转到待付款页面
				//replace
				gotoToBe()
			}
			addressItem.data = {}
		}
		/**咨询服务 */
		let changeCounsel = (val: number, cartId: number) => {
			for (let j = 0; j < cartData.datas.length; j++) {
				if (cartData.datas[j].id == cartId) {
					cartData.datas[j].counsel = val
					break
				}
			}
		}

		//获取小计
		let servicePice = inject("servicePice", 25)
		let getItemPrice = (item: any) => {
			let goodsprice = 0
			if (orderType.value == 0) {
				goodsprice =
					item.activity_price > 0
						? Math.round(item.activity_price) +
						  Math.round(Number(item.counsel * servicePice) * userInfo.discount)
						: Math.round(
								(Number(item.price) + Number(item.counsel * servicePice)) *
									userInfo.discount
						  )
			} else {
				if (item.is_real_goods == 1) {
					goodsprice = Math.round(Number(item.price) * item.numbers * 100) / 100
				} else {
					goodsprice = Math.round(Number(item.price) * item.numbers)
				}
			}

			return goodsprice
		}

		interface orderInfoType {
			payPrice: number //实际支付价格
			goodsPrice: number //商品价格
			vipPrice: number //vip优惠金额
			servicePrice: number //咨询服务金额
			couponPrice: number //优惠券金额
			actPrice: number //活动优惠金额
		}
		let orderInfo = computed(() => {
			let info: orderInfoType = {
				payPrice: 0,
				goodsPrice: 0, //商品价格
				vipPrice: 0, //vip优惠金额
				servicePrice: 0, //咨询服务金额
				couponPrice: 0,
				actPrice: 0,
			}
			if (cartData.datas.length > 0) {
				cartData.datas.findIndex((item: any) => {
					if (item.activity_price && item.activity_price > 0) {
						info.payPrice =
							Number(info.payPrice) +
							Number(item.activity_price) +
							Number(item.counsel * servicePice) * userInfo.discount
						info.actPrice = Math.round(
							Number(info.actPrice) +
								(Number(item.price) - Number(item.activity_price))
						)

						info.vipPrice =
							Number(info.vipPrice) +
							Number(item.counsel * servicePice) * (1 - userInfo.discount)
					} else {
						if (orderType.value == 1) {
							//配件专区
							info.payPrice =
								Number(info.payPrice) +
								Math.round(Number(item.price * item.numbers * 100)) / 100
							info.vipPrice = 0
						} else {
							info.payPrice =
								Number(info.payPrice) +
								(Number(item.price) + Number(item.counsel * servicePice)) *
									userInfo.discount

							info.vipPrice =
								Number(info.vipPrice) +
								(Number(item.price) + Number(item.counsel * servicePice)) *
									(1 - userInfo.discount)
						}
					}
					info.goodsPrice =
						Number(info.goodsPrice) + Number(item.price * item.numbers)

					info.servicePrice =
						Number(info.servicePrice) +
						parseInt(item.counsel) * Number(servicePice)
				})
				if (orderType.value == 0) {
					info.servicePrice = Math.round(info.servicePrice)
					info.goodsPrice = Math.round(info.goodsPrice)
					info.vipPrice = Math.round(info.vipPrice)
					info.actPrice = Math.round(info.actPrice)
					info.payPrice = Math.round(info.payPrice)
				}
				// info.servicePrice = Math.round()
				if (couponList.data && couponList.data.length > 0) {
					info.couponPrice = couponList.data[couponsIndex.value].amount
				} else {
					info.couponPrice = 0
				}
				info.payPrice = showPrice(
					info.payPrice - info.couponPrice,
					orderType.value
				)
			}
			console.log(info, "goodsinfo")

			return info
		})
		let getCounsel = (counselNum: any) => {
			if (counselNum == "1") {
				return 1
			} else {
				return 0
			}
		}
		let couponList = reactive<any>({
			data: [],
		})
		//获取优惠券
		let getCouponsList = () => {
			userApi.getCouponsList().then((res: any) => {
				if (res.errno == 0) {
					if (res.data.list.length > 0) {
						res.data.list.findIndex((item: any) => {
							if (item.status == "已领取") {
								couponList.data.push(item)
							}
						})
					}

					if (couponList.data.length > 0) {
						couponsIndex.value = 0
					}
				} else {
					sjyAlert(res.errmsg, "error")
				}
			})
		}
		//选择的优惠券的下标
		let couponsIndex = ref(0)
		let setCouponsIndex = (val: number) => {
			couponsIndex.value = val
		}
		let wrongtimes = ref(0)

		if (localStorage.getItem("wrongtimes") != null) {
			if (parseInt(localStorage.getItem("wrongtimes") as string) > 0) {
				wrongtimes.value = parseInt(
					localStorage.getItem("wrongtimes") as string
				)
			}
		}
		let successPay = (params: any) => {
			orderApi.payOrder(params).then((res: any) => {
				if (res.errno == 0) {
					//清空错误次数
					localStorage.setItem("wrongtimes", "0")

					//localStorage.setItem("wrongtimes", "0");
					sjyAlert("支付成功", "success")
					router.push({ name: "paysuccess", params: { id: params.id } })
					//跳转到订单下载页面
				} else {
					if (localStorage.getItem("wrongtimes")) {
						localStorage.setItem(
							"wrongtimes",
							Number(localStorage.getItem("wrongtimes") as string) + 1 + ""
						)
					} else {
						localStorage.setItem("wrongtimes", "1")
					}
					wrongtimes.value = Number(localStorage.getItem("wrongtimes"))
					//记录错误次数
					sjyAlert(res.errmsg, "error")
				}
				return false
			})
		}
		provide("setCouponsIndex", setCouponsIndex)
		provide("successPay", successPay)

		let cancel = () => {
			showShareUrl.value = 0
		}
		let hiddenShareUrl = () => {
			showShareUrl.value = 1
		}

		let handleNumbersChange = (val: number, index: number) => {
			cartData.datas[index].numbers = val
			goBuy()
		}

		let goBuy = () => {
			console.log(cartData)

			let ids = ""
			//整理订单数据
			for (let i = 0; i < cartData.datas.length; i++) {
				if (ids == "") {
					ids +=
						cartData.datas[i].id +
						"-" +
						cartData.datas[i].counsel +
						"-" +
						cartData.datas[i].numbers
				} else {
					ids +=
						"_" +
						cartData.datas[i].id +
						"-" +
						cartData.datas[i].counsel +
						"-" +
						cartData.datas[i].numbers
				}
			}
			router.push({
				name: "confirmed",
				params: {
					ids,
				},
			})

			//router.push({ name: "confirmed", params: { ids: ids } })
		}

		onMounted(() => {
			getCartGoodsList()
			getCouponsList()
		})
		return {
			showPay,
			dialogVisible,
			setDialogVisible,
			sonRef,
			toPay,
			mclose,
			cartData,
			changeCounsel,
			getItemPrice,
			orderInfo,
			isSend,
			addressList,
			changeAddress,
			setDefaultAddress,
			getCounsel,
			updateAddress,
			addressItem,
			getCouponsList,
			couponList,
			couponsIndex,
			selectAddress,
			getSelectAddress,
			creatOrderInfo,
			wrongtimes,
			orderType,
			cancel,
			showShareUrl,
			hiddenShareUrl,
			handleNumbersChange,
			ruleForm,
			rules,
			showPrice,
		}
	},
})
