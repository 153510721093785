
import { defineComponent, onMounted, reactive, ref } from "vue";
import HomeHead from "@/components/HomeHead.vue";
import HtmlFooter from "@/components/HtmlFooter.vue";
import NewGoodsList from "@/components/NewGoodsList.vue";
import NewGoodsInfo from "@/components/goods/project/NewGoodsInfo.vue";
import { useHead } from "@vueuse/head";

import { goodsApi } from "@/service/api";
import { useRoute } from "vue-router";
export default defineComponent({
  components: {
    HomeHead,
    HtmlFooter,
    NewGoodsList,
    NewGoodsInfo,
  },
  setup() {
    let newGoodsList = reactive<any>({
      data: [],
    });
    let activityGoodsList = reactive<any>({
      data: [],
    });
    let loading = ref(false); //折扣商品
    let newLoading = ref(false);

    let loadingActivityGoods = ref(false);

    let pageIndex = ref(1);
    //获取折扣商品
    let getGoodsList = () => {
      goodsApi.discountGoods(1, 20).then((res: any) => {
        loading.value = false;
        activityGoodsList.data = res.datas.list;
        //console.log(newGoodsList.data)
      });
    };

    //获取新品
    let getMonthProduct = () => {
      newLoading.value = true;
      goodsApi.monthProduct().then((res: any) => {
        newLoading.value = false;
        newGoodsList.data = res.datas;
      });
    };

    const load = () => {
      if (loadingActivityGoods.value == true) {
        //console.log("zhxingzhong")
        return false;
      } else {
        //console.log("zhxingzhong222")
        loadingActivityGoods.value = true;
        goodsApi.discountGoods(pageIndex.value, 20).then((res: any) => {
          loadingActivityGoods.value = false;
          activityGoodsList.data.push(...res.datas.list);
        });
      }
    };
    let moretext = ref("没有更多了"); //没有更多了
    let route = useRoute();

    onMounted(() => {
      getGoodsList();
      getMonthProduct();
    });
    return {
      getGoodsList,
      newGoodsList,
      load,
      moretext,
      loading,
      loadingActivityGoods,
      activityGoodsList,
      newLoading,
    };
  },
});
