
import {
  reactive,
  defineComponent,
  ref,
  onMounted,
  onBeforeUnmount,
} from "vue";
import Lack from "./messageLack.vue";
import { userApi } from "@/service/api";
import dateFormat from "@/utils/dateFormat";
export default defineComponent({
  components: { Lack },
  setup() {
    interface result {
      data: any;
    }
    let userMessage: result = reactive({
      data: {},
    });

    let getMessagelist = () => {
      userApi.userMessage().then((res: any) => {
        // console.log(res);
        userMessage.data = res.datas;
      });
    };

    // 页面销毁前发送请求改变状态
    let unRead = () => {
      userApi.unRead().then((res: any) => {
        // console.log(res,"消息已读");
      });
    };

    // 时间戳转化为日期
    let mday = (indexs: number) => {
      let a = ref("");
      // console.log(userMessage.data[indexs].create_time);
      a.value = dateFormat.dateFormat(
        Number(userMessage.data[indexs].create_time * 1000),
        "yyyy-MM-dd"
      );
      return a.value;
    };

    let changeStatus = (index: number) => {
      if (userMessage.data[index].status == 0) {
        userMessage.data[index].status = 1;
      }
    };

    onMounted(() => {
      getMessagelist();
      unRead();
      //unRead();
    });
    onBeforeUnmount(() => {});
    return {
      userMessage,
      changeStatus,
      mday,
    };
  },
});
