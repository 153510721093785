
import { defineComponent, onMounted, reactive, ref } from "vue"
import CountDown from "@/components/common/CountDown.vue"
import dateFormat from "@/utils/dateFormat"

export default defineComponent({
  components: { CountDown },
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
    },
  },
  name: "orderstatusother",
  emits: ["countdownEnded", "updateOrderStatus", "updateOrderSendStatus"],
  setup(props: any, { emit }) {
    let orderData = props.orderData
    let orderTypeMsg = ref("")
    let orderTypeMsgTips = ref("")
    let showMobile = (mmobile: string) => {
      var reg = /^(\d{3})\d{4}(\d{4})$/
      return mmobile.replace(reg, "$1****$2")
    }
    let imgList = reactive({
      data: {
        over: "over-0.png",
        create: "create-2.png",
        pay: "pay-0.png",
        send: "send-0.png",
      },
    })
    let getsplittimestring = (timestring: string = "") => {
      return timestring.split(" ")
    }

    //let dateStrChangeTimeTamp

    let getEndTime = (val: string) => {
      let timeTamp = dateFormat.dateStrChangeTimeTamp(val)
      let time = new Date().getTime()
      return Math.ceil(((15*60*60*24)-(time - timeTamp)/1000)/(60*60*24))
    }

    let getOrderSetp = () => {
      //已完成
      if (orderData.datas.status == 2 && orderData.datas.send_status == 3) {
        imgList.data = {
          over: "over-2.png",
          create: "create-1.png",
          pay: "pay-1.png",
          send: "send-1.png",
        }
        orderTypeMsg.value = "已完成"
        orderTypeMsgTips.value = "已完成"
      } else if (
        orderData.datas.status == 2 &&
        orderData.datas.send_status == 2
      ) {
        //已发货
        imgList.data = {
          over: "over-0.png",
          create: "create-1.png",
          pay: "pay-1.png",
          send: "send-2.png",
        }
        orderTypeMsg.value = "已发货 等待收货"
        orderTypeMsgTips.value = "等待收货"
      } else if (
        orderData.datas.status == 2 &&
        orderData.datas.send_status == 1
      ) {
        //支付成功 未发货
        console.log("支付成功 等待发货", "我执行到了吗")

        imgList.data = {
          over: "over-0.png",
          create: "create-1.png",
          pay: "pay-2.png",
          send: "send-0.png",
        }
        orderTypeMsg.value = "支付成功 等待发货"
        orderTypeMsgTips.value = "等待发货"
      } else if (orderData.datas.status == 1) {
        //提交订单 待支付状态
        imgList.data = {
          over: "over-0.png",
          create: "create-2.png",
          pay: "pay-0.png",
          send: "send-0.png",
        }
        orderTypeMsg.value = "等待付款"
        orderTypeMsgTips.value = "等待付款"
      }
    }
    let getTime = (val: number = 0) => {
      let time = new Date().getTime() / 1000
      return val - time
    }

    //取消订单
    let cancelOrder = () => {
      emit("updateOrderStatus", 3, "确认取消该订单？取消订单后，不可恢复。")
    }
    //确认收货
    let receiptOrder = () => {
      console.log("我执行到了");
      
      emit("updateOrderSendStatus", 3)
    }

    onMounted(() => {
      getOrderSetp()
    })

    return {
      orderData,
      showMobile,
      getOrderSetp,
      imgList,
      getTime,
      cancelOrder,
      receiptOrder,
      orderTypeMsg,
      orderTypeMsgTips,
      getsplittimestring,
      getEndTime,
    }
  },
})
