
import HeaderNav from "@/components/HeaderNav.vue"
import HtmlFooter from "@/components/HtmlFooter.vue"
import VideoClassHeader from "@/components/video/VideoClassHeader.vue"
import VideoGoodsLine from "@/components/video/VideoGoodsLine.vue"
import NavList from "@/components/NavList.vue"
import { defineComponent, nextTick, onMounted, reactive, ref, watch } from "vue"
import Banner from "@/components/Banner.vue"
import { videoApi } from "@/service/api"
import HomeHead from "@/components/HomeHead.vue"
import CurrentAddress from "@/components/common/CurrentAddress.vue"
import { useRoute, useRouter } from "vue-router"

export default defineComponent({
	components: {
		HeaderNav,
		NavList,
		HtmlFooter,
		Banner,
		VideoClassHeader,
		VideoGoodsLine,
		HomeHead,
		CurrentAddress,
	},
	setup() {
		interface resType {
			datas: any
			errno: number
			errmsg: string
		}
		let loadingType = ref(1)
		let classList: resType = reactive({
			datas: [],
			errno: 0,
			errmsg: "",
		})
		let allGoodsList: any = reactive([])
		let header: any = reactive({})
		let id = ref(0)
		let route = useRoute()
		let router = useRouter()
		if (route.params.id) {
			id.value = parseInt(route.params.id as string)
		}
		watch(route, () => {
			router.go(0)
		})
		let toId = ref(true)
		let gotoId = () => {
			nextTick(() => {
				setTimeout(() => {
					if (id.value > 0 && toId.value) {
						header = document.getElementById("headerTop" + id.value)
						window.scrollTo({
							top: header.offsetTop - 80,
							behavior: "smooth",
						})
						toId.value = false
					}
				}, 500)
			})
		}

		/**
		 * 记录选中的子分类
		 */
		let checkedClass: Array<number> = reactive([])
		let sort = ref(0)

		let getClass = () => {
			videoApi.attrList().then((res) => {
				classList.datas = (res as resType).datas
				classList.errno = (res as resType).errno
				classList.errmsg = (res as resType).errmsg
				//初始化选中的子分类
				for (var i = 0; i < classList.datas.length; i++) {
					checkedClass[i] = 0
					//获取分类下面对应的商品
					getGoodsList(classList.datas[i].cid, classList.datas[i].son[0].aid)
				}
			})
		}

		/**
		 * 切换子分类
		 */
		let changeClass = (cindex: number, sindex: number) => {
			checkedClass[cindex] = sindex
			getGoodsList(
				classList.datas[cindex].cid,
				classList.datas[cindex].son[sindex].aid
			)
		}

		/**
		 * 获取商品列表
		 */
		let getGoodsList = (cid: any, aid: any, sort: number = 0) => {
			//loadingType.value=0
			if (cid == 0) {
				allGoodsList.loading = 0
				allGoodsList.data = []
			} else {
				var indexs = classList.datas.findIndex((item: any) => {
					if (item.cid == cid) {
						return true
					}
				})
				classList.datas[indexs].goodslist = []
				classList.datas[indexs].loading = 0
			}

			let layid: any = null

			let where = {
				sort,
				pageIndex: 1,
				pageSize: 10,
				cid: cid,
				aid: aid,
			}
			videoApi.videoList(where).then((res: any) => {
				if (res.datas.cid == 0) {
					allGoodsList.data = res.datas.data
					allGoodsList.loading = 1
					gotoId()
				} else {
					var index = classList.datas.findIndex((item: any) => {
						if (item.cid == res.datas.cid) {
							return true
						}
					})
					classList.datas[index].loading = 1
					classList.datas[index].goodslist = res.datas.data
				}
			})
		}
		/**
		 * 更改排序方式
		 */
		let changeSort = (sorttemp: number) => {
			sort.value = sorttemp
			getGoodsList(0, 0, sort.value)
		}

		onMounted(() => {
			getClass()
			getGoodsList(0, 0, 0)
			//gotoId()
		})

		return {
			getClass,
			classList,
			changeClass,
			checkedClass,
			changeSort,
			allGoodsList,
			loadingType,
		}
	},
})
