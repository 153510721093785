
import { reactive, ref, defineComponent } from "vue";
import PageShow from "@/components/common/PageShow.vue";
import lackRecordVue from "./lackRecord.vue";
import { userApi } from "@/service/api";
import InvoiceDialog from "@/components/common/Dialog/InvoiceDialog.vue";
import InvoiceFrom from "@/components/common/Dialog/item/InvoiceFrom.vue";
export default defineComponent({
  components: { PageShow, lackRecordVue, InvoiceDialog, InvoiceFrom },
  props: {
    AccountToSon: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, context) {
    let addressItem = reactive({
      data: {},
    });
    const total = ref(100);
    // const tableData = [
    //   {
    //     ctime: "2016-05-03",
    //     gear: "￥360",
    //     detail: "订单：PB202203280944451245",
    //     type: "RMB消费",
    //     status1: "1",
    //   },
    //   {
    //     ctime: "2016-05-02",
    //     gear: " ￥360",
    //     detail: "订单：PB202203280944451245",
    //     type: "RMB消费",
    //     status1: "2",
    //   },
    //   {
    //     ctime: "2016-05-04",
    //     gear: " ￥360",
    //     detail: "订单：PB202203280944451245",
    //     type: "RMB消费",
    //     status1: "1",
    //   },
    // ];

    // 发票弹框
    interface addressType {
      data: any;
    }

    let addressList: addressType = reactive({
      data: [],
    });
    let lastInfor: addressType = reactive({
      data: {},
    });
    let goShow = ref(true);

    let dialogVisible = ref(false);
    const sonRef = ref<InstanceType<typeof InvoiceDialog>>();
    let recordId = ref("");
    let setDialogVisible = (index: number) => {
      console.log("开始我的");
      
      sonRef.value?.setVisible(true);
      //console.log();
      recordId.value = props.AccountToSon.data.list[index].id;
      //todo  通过recrdid获取已经填写的信息
      //  调用一下发票显示接口
      userApi.invoiceShow().then((res: any) => {
        //console.log(res.datas.length, "对象的长度");
        //console.log(res.datas,"是否为空");

        if (res.datas.length != 0) {
          goShow.value = false;
          lastInfor.data = res.datas;
        }
      });
    };
    let mclose = (type: number = 0) => {
      dialogVisible.value = false;
      sonRef.value?.setVisible(dialogVisible.value);
      //if (type == 1) {
      getAddressList();
      context.emit("getAccounts");
      //}
      addressItem.data = {};
    };
    let changeGoShow = (val: boolean) => {
      goShow.value = val;
    };
    // 获取地址
    let getAddressList = () => {
      userApi.addressList().then((res: any) => {
        addressList.data = res.datas;
      });
    };
    // 区分发票弹框是RMB消费还是充值
    let from = ref(2);

    // 发票信息弹框
    let dialogVisibles = ref(false);
    const sonRefs = ref<InstanceType<typeof InvoiceDialog>>();
    let fid = ref("");
    let information: addressType = reactive({
      data: {},
    });
    let setDialogVisibles = (index: number) => {
      sonRefs.value?.setVisible(true);
      fid.value = props.AccountToSon.data.list[index].id;
      userApi.invoiceInfo(fid.value).then((res: any) => {
        information.data = res.datas;
      });
    };
    // console.log(props.AccountToSon.data.list, "123");

    let mcloses = (type: number = 0) => {
      dialogVisible.value = false;
      sonRefs.value?.setVisible(dialogVisible.value);
    };

    return {
      total,
      // handleCurrentChange,
      // tableData,
      addressItem,
      addressList,
      dialogVisible,
      lastInfor,
      sonRef,
      recordId,
      setDialogVisible,
      goShow,
      mclose,
      changeGoShow,
      getAddressList,
      from,
      sonRefs,
      dialogVisibles,
      setDialogVisibles,
      mcloses,
      information,
    };
  },
});
