
import { computed, defineComponent, ref } from "vue"
import { useMouseInElement } from "@vueuse/core"

export default defineComponent({
  components: {},
  emits: ["setPositionImg"],
  props: {
    goodsImg: {
      type: String,
      default: "",
    },
  },
  name: "free-goods-img",
  setup(props, { emit }) {
    const target = ref(null)
    const { isOutside, elementX, elementY } = useMouseInElement(target)
    const position = computed(() => {
      let x = elementX.value - 100
      let y = elementY.value - 100
      x = x < 50 ? 50 : x
      y = y < 60 ? 60 : y
      x = x > 560 ? 560 : x
      y = y > 350 ? 350 : y
      emit("setPositionImg", x - 50, y - 60, isOutside.value)
      return {
        x,
        y,
      }
    })
    return { target, position, isOutside }
  },
})
