<template>
  <div class="contents-bottom-item">
    <goods-navigation
      v-if="pic_cs.length == 3||true"
      :isFixed="isFixed"
      :nowIndex="nowIndex"
      :pic_cs="pic_cs"
      @selectLable="selectLable"
    />
    <!-- <goods-navigation-type
      v-if="pic_cs.length == 4"
      :isFixed="isFixed"
      :nowIndex="nowIndex"
      :pic_cs="pic_cs"
      @selectLable="selectLable"
    ></goods-navigation-type> -->
    
    <!--结构图-->
    <div v-for="(item,index) in pic_cs" class="contents-bottom-info" v-show="nowIndex == index+1">
      <structure-img :info="item" v-if="showType==1&&item.type==2"  :isFixed="isFixed" :isFixedBottom="isFixedBottom"/>
      <!-- <goods-content-attr :info="item.content" v-if="item.type==1&&showType==1" :isFixed="isFixed" /> -->
      <dimension-img  :imgurl="item.imgurl" v-if="showType==2&&(item.type==3||item.type==2||item.type==1)" :isFixed="isFixed" />
      <DimensionMoreImg  :info="item" v-if="item.type==3&&showType==1"></DimensionMoreImg>
      <down-list :info="item" :goodsname="goodsname" v-if="item.type==4" :showType="showType" />
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, props } from "vue";
import DimensionImg from "./DimensionImg.vue";
import DownList from "./DownList.vue";
import GoodsContentAttr from "./GoodsContentAttr.vue";
import DimensionMoreImg from "./DimensionMoreImg.vue";
import GoodsNavigation from "./GoodsNavigation.vue";
import GoodsNavigationType from "./GoodsNavigationType.vue";
import StructureImg from "./StructureImg.vue";

export default defineComponent({
  components: {
    DimensionImg,
    StructureImg,
    DownList,
    GoodsNavigation,
    GoodsNavigationType,
    GoodsContentAttr,
    DimensionMoreImg
  },
  emits: ["updateHeight"],
  setup(props,{emit}) {
    const state = reactive({
      count: 0,
      nowIndex: 1,
    });

    const selectLable = (nowIndex) => {
      state.nowIndex = nowIndex;
      if(nowIndex == 2){
        emit('updateHeight')
      }
    };

    return {
      ...toRefs(state),
      selectLable,
    };
  },
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
    isFixedBottom: {
      type: Boolean,
      default: false,
    },
    pic_cs: {
      type: Array,
      default: () => [],
    },
    goodsname:{
      type: String,
      default: "",
    },
    showType:{
      type: String,
      default: "",
    }
  },
});
</script>

<style scoped>
.is-fixed {
  position: fixed;
  top: 0;
}

.contents-bottom-item {
  width: 100%;
  overflow: hidden;
}

.contents-bottom-info-contents {
  width: 100%;
}
.contents-bottom-info {
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 50px;
  padding: 0 40px 40px 40px;
  position: relative;
}
</style>