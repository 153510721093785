
import { reactive, defineComponent, ref } from "vue";
// import type { TabsPaneContext } from "element-plus";
import type { FormInstance, FormRules } from "element-plus";
import { userApi } from "@/service/api";
import { sjyAlert } from "@/utils/alert";
export default defineComponent({
  setup() {
    const informationForm = reactive({
      //修改支付密码 短信验证码框
      pay_password: "",
    });

    const ruleFormRef = ref<FormInstance>();
    const rules = reactive<FormRules>({
      pay_password: [
        {
          pattern: /^\d{6}$/,
          message: "请输入六位数字作为支付密码",
          trigger: "blur",
        },
      ],
    });

    let change = () => {
      informationForm.pay_password = informationForm.pay_password.toString();
      //console.log(informationForm.pay_password);
      userApi.changePay(informationForm).then((res: any) => {
        //console.log(res);
        if (res.errno == 0) {
          sjyAlert("支付密码修改成功", "success");
        }
        if (res.errno == 10003) {
          sjyAlert("支付密码不能为空", "error");
        }
      });
    };

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          change();
        } else {
          // console.log("error submit!", fields);
        }
      });
    };

    return {
      informationForm,
      change,
      submitForm,
      ruleFormRef,
      rules,
    };
  },
});
