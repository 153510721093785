
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { goodsApi } from "@/service/api"
export default defineComponent({
    components: {},
    setup() {
        const isActive = ref(0)
        const title = [
            { name: 'RV减速机', cid: 1 },
            { name: '行星减速机', cid: 42 },
            { name: '伺服电机', cid: 2 },
            { name: '伺服驱动器', cid: 3 },
            { name: '并联机器人', cid: 4 },
            { name: '串联机器人', cid: 6 },
            { name: '协作机器人', cid: 7 },
            { name: '真空元件', cid: 8 },
            { name: '控制系统', cid: 9 },
            { name: '机器视觉系统', cid: 10 }]

        let active = (val: number) => {
            isActive.value = val
            cid.value = title[val].cid
            getGoodsList()
        }
        let lightLocation = [
            {
                top: '119px',
                left: '790px',
            },
            {
                top: '96px',
                left: '456px',
            },
            {
                top: '269px',
                left: '189px',
            },
            {
                top: '153px',
                left: '224px',
            },

            {
                top: '270px',
                left: '893px',
            },
            {
                top: '239px',
                left: '1145px',
            },
            {
                top: '214px',
                left: '613px',
            },
            {
                top: '305px',
                left: '397px',
            },
            {
                top: '123px',
                left: '1075px',
            },
            {
                top: '176px',
                left: '294px',
            },
        ]
        let titleLocation = [
            {
                name: 'RV减速机',
                top: '114px',
                left: '810px',
            },
            {
                name: '行星减速机',
                top: '91px',
                left: '486px',
            },
            {
                name: '伺服电机',
                top: '264px',
                left: '209px',
                id: 2
            },
            {
                name: '伺服驱动器',
                top: '148px',
                left: '244px',
            },
            {
                name: '并联机器人',
                top: '265px',
                left: '913px',
                id: 4
            },
            {
                name: '串联机器人',
                top: '234px',
                left: '1165px',
            },
            {
                name: '协作机器人',
                top: '209px',
                left: '633px',
                id: 6
            },
            {
                name: '真空元件',
                top: '300px',
                left: '417px',
                id: 7
            },
            {
                name: '控制系统',
                top: '118px',
                left: '1095px',
                id: 8
            },
            {
                name: '机器视觉系统',
                top: '171px',
                left: '314px',

            },
        ]

        let cid = ref(1)
        let goodslit = reactive({
            datas: {
                data: [] as any[],
                pageSize: 0,
                total: 0,
            },
        })
        let getGoodsList = () => {
            goodsApi.getEquipmentList(cid.value, 5, 1, 3).then((res: any) => {
                if (res.errno == 0) {
                    goodslit.datas = res.datas

                }
            })
        }
        let goto = (val: string) => {
            window.open('https://www.movibot-art.com/goods/info/' + val)
        }
        onMounted(() => {
            getGoodsList()
        })
        return {
            isActive,
            title,
            active,
            lightLocation,
            titleLocation,
            goodslit,
            goto
        };
    },
})

