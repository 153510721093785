import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import "./assets/iconfont/iconfont.css"
import lazyPlugin from "vue3-lazy"
import ElementPlus from "element-plus"
import "element-plus/dist/index.css"
import "./assets/common/css/button.css"
import { createPinia } from "pinia"
import Share from "vue3-social-share"
import { createHead } from "@vueuse/head"
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import "vue3-social-share/lib/index.css"
import axios from "axios"
import VueAxios from "vue-axios"
import { userStore } from "@/stores/userStore"
import { goodsApi, userApi } from "@/service/api"
import zhLocale from "element-plus/lib/locale/lang/zh-cn"
import "./assets/common/css/global.css"
import { useCookies } from "vue3-cookies";
//@ts-ignore
//import Confirm from "./components/dialog/index.js"
//@ts-ignore
import ConfirmDialog from "./components/ConfirmDialog/index.js"
//@ts-ignore
import PostDialog from "./components/PostDialog/index.js"

const head = createHead()

const app = createApp(App)

app.use(router)
app.use(VueAxios, axios)
app.use(ElementPlus, {
  locale: zhLocale,
})
app.use(ElementPlus)
app.use(createPinia())
app.use(Share)
app.use(head)
// app.use(Confirm)
app.use(ConfirmDialog)
app.use(PostDialog)
app.use(lazyPlugin, {
  loading: require("@/assets/common/images/default.jpg"), // 图片加载时默认图片
  error: require("@/assets/common/images/default.jpg"), // 图片加载失败时默认图片
})

app.use(VueVideoPlayer)

app.provide("imgUrl", "https://image.sjyuan.net.cn")
app.provide("WSURL", `wss://api.sjyuan.net.cn/wss`)
app.provide("APIURL", `https://api.sjyuan.net.cn`)

//当前域名
app.provide("BASEURL", "https://www.sjyuan.net.cn")
app.provide("staticUrl", "https://img2.sjyuan.net.cn")
app.provide("servicePice", 25)
app.provide("getImgUrl", (imgurl: string) => {
  if (imgurl == "" || imgurl == null) {
    return require("@/assets/image/morentouxiang.png")
  } else {
    let rx = /^http?/i
    if (rx.test(imgurl)) {
      return imgurl
    } else {
      return "https://img2.sjyuan.net.cn" + imgurl
    }
  }
})

app.config.errorHandler = (err, vm, info) => {
  console.log("[全局异常]", err, vm, info)
}

//注册为全局变量
app.mount("#app")

router.beforeEach((to, from, next) => {
  const userCommonStore = userStore()
  const MCookies = useCookies()
  // userApi.getUserLoginInfo().then((res)=>{
  //     console.log(res,'main.js')//todo
  // })

  //MCookies.cookies.set("userLogin","input_value",60 * 60)
  if (MCookies.cookies.isKey("userLogin")) {
    MCookies.cookies.set("userLogin", "true", 60 * 60)
    if (localStorage.getItem("token")) {
      const userCommonStore = userStore()
      if (userCommonStore.id == 0 && localStorage.getItem("userInfo")) {

        userCommonStore.setUserInfoByStorage(
          JSON.parse(localStorage.getItem("userInfo") as string)
          //获取用户的基本信息
        )
      }
      //登录状态禁止访问的页面   login , numberlogin register sonlogin sonnumber

    }
  } else {
    userStore().deleteUserInfo();
  }

  if (userCommonStore.id > 0) {
    if (
      to.name == "login" ||
      to.name == "sonlogin" ||
      to.name == "numberlogin" ||
      to.name == "sonnumber" ||
      to.name == "register"
    ) {
      next({ name: "user" })
    }
  }

  next()
})
router.afterEach((to, from, next) => {
  //console.log(to,from);
  if (!(to.name == "confirmed" && to.name == from.name)) {
    window.scrollTo(0, 0)
  }
  let duration = localStorage.getItem("duration")
  if (duration) {
    localStorage.removeItem("duration")
    let arr = duration.split("_");
    // console.log(arr, parseInt(arr[2]), Math.fround(parseInt(arr[2]) / 1000));

    goodsApi
      .addGoodsDuration(parseInt(arr[0]), parseInt(arr[1]), parseInt(arr[2]))

  }

})
