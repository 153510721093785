
import { defineComponent, reactive, ref } from "vue";
export default defineComponent({
  components: {},
  props: {
    question: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any) {
    let myquestion = props.question;

    return {
      myquestion,
    };
  },
});
