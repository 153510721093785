
import { defineComponent, nextTick, reactive, ref } from "vue"
import OrderListItem from "./OrderListItem.vue"
import PageShow from "@/components/common/PageShow.vue"
import Search from "@/components/user/order/Search.vue"
import SearchOrderNo from "@/components/user/order/SearchOrderNo.vue"
import { useRoute, useRouter } from "vue-router"

export default defineComponent({
  components: { OrderListItem, PageShow, Search, SearchOrderNo },
  emits: ["handleCurrentChange", "setOrderStatus", "setWhere"],
  props: {
    orderList: {
      type: Object,
      default: () => ({}),
    },
    goodstotal: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    ostatus: {
      type: Number,
      default: 0,
    },
  },
  setup(props: any, { emit }) {
    let pageIndex = ref(1)
    let orderStatus = ref(0)
    let isShow = ref(false)
    orderStatus.value = props.ostatus

    let setWhere = (where: any) => {
      emit("setWhere", where)
      isShow.value = false
      nextTick(() => {
        isShow.value = true
      })
    }
    let handleCurrentChange = (val: any) => {
      pageIndex.value = val
      emit("handleCurrentChange", val)
    }
    let changeOrderStatus = (val: number) => {
      orderStatus.value = val
      emit("setOrderStatus", val)
    }
    let router = useRouter()
    let gohome = () => {
      router.push({
        name: "home",
      })
    }
    let isShowMoreWhere = ref(false)
    let showMoreWhere = (val: boolean) => {
      isShowMoreWhere.value = val
    }

    

    return {
      handleCurrentChange,
      pageIndex,
      changeOrderStatus,
      orderStatus,
      setWhere,
      gohome,
      showMoreWhere,
      isShowMoreWhere,
      isShow
    }
  },
})
