<template>
  <div class="contents-bottom-info-contents">
    <img :src="imgurl" />
  </div>
</template>
<script>
import { reactive, toRefs, props, defineComponent } from "vue";

export default defineComponent({
  setup() {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
    imgurl:{
      type: String,
      default: ''
    }
  },
});
</script>

<style scoped lang="scss">
.contents-bottom-info-contents {
  img{
    width: 100%;
  }
  width: 100%;
}
</style>