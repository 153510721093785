
import { defineComponent, ref, inject } from "vue"
import { getSolidWorksVersion } from "@/utils/public"
export default defineComponent({
	components: {},
	props: ["goodsDetail", "goodsModel"],
	setup(props: any) {
		let imgUrl = ref([])
		let staticUrl = inject("staticUrl")
		imgUrl.value = props.goodsDetail.bodys2
		// let getimgUrl = (imgval:string)=>{
		//   return staticUrl+imgval;
		// }

		let getimgUrl = (val: any) => {
			if (val.startsWith("https")) {
				return val
			} else {
				return staticUrl + val
			}
		}
		return { imgUrl, getimgUrl, getSolidWorksVersion }
	},
})
