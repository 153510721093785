
import { defineComponent, reactive, ref, inject, watch } from "vue"
import * as _ from "lodash"

export default defineComponent({
  components: {},
  name:"goodspart",
  props: {
    numbers: {
      type: Number,
      default: 0,
    },
  },
  setup(props: any) {
    let numbers = props.numbers

    return { numbers }
  },
})
