
import { defineComponent, onMounted, reactive, ref, inject } from 'vue'
import { parts } from "@/service/api"
import { useRouter } from "vue-router";
export default defineComponent({
    components: {},
    setup() {
        const attrArr = [
            { name: '并联机器人', cid: 48 },
            { name: '真空发生器', cid: 65 },
            { name: '控制器', cid: 76 },
            { name: '机器人控制器配件', cid: 95 },
            { name: '机器人本体配件', cid: 86 },
            { name: '驱动器配件', cid: 66 },
            { name: '末端执行器配件', cid: 49 },
            { name: '串联机器人', cid: 61 },
            { name: '十字万向节', cid: 71 },
            { name: '视觉系统', cid: 79 },
            { name: '装箱工作站', cid: 82 },
            { name: 'io模块配件', cid: 11 },
            { name: '气路系统配件', cid: 53 },
            { name: '外围设备配件', cid: 45 },
            { name: '机器人伺服电机', cid: 102 },
            { name: '机器人减速机', cid: 103 },
            { name: '服务', cid: 15 },
        ]
        const isActive = ref(0)
        let active = (val: number) => {
            isActive.value = val
            cid.value = attrArr[val].cid
            const { href } = router.resolve({
                name: "partssearch",
                params: {
                    pid: cid.value,
                    sid: 0
                },
            });
            window.open(href, "_blank");
            return false;
        }

        let goodslit = reactive({
            datas: {
                data: [] as any[],
                pageSize: 0,
                total: 0,
            },
        })
        let cid = ref(48)
        //获取商品列表
        let getGoodsList = () => {
            parts.elementSell().then((res: any) => {
                if (res.errno == 0) {
                    goodslit.datas.data = res.datas
                    //console.log(goodslit.datas)
                }
            })
        }
        let gotoGoods = (goodsid: number) => {
            const { href } = router.resolve({
                name: "productDetail",
                params: {
                    id: goodsid,
                },
            });
            window.open(href, "_blank");
            return false;
        };
        let getImgUrl = inject(
            "getImgUrl",
            (index: string) => {
                return "";
            },
            false
        );
        let router = useRouter();

        onMounted(() => {
            getGoodsList()
        })
        return {
            attrArr,
            isActive,
            active,
            goodslit,
            getImgUrl,
            gotoGoods
        };
    },
})

