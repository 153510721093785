
import { defineComponent,ref } from "vue"
import { userStore } from "@/stores/userStore"
import { useRouter } from "vue-router"
import { fangankefu, shouhoukefu, shouqiankefu } from "@/utils/alert"

export default defineComponent({
	name: "FixedLeft",
	components: {},
	setup() {
		let router = useRouter()
		let id = userStore().id
		let goToUserCenter = () => {
			router.push({ name: "suggest" })
		}
		let goToTop = () => {
			window.scrollTo(0, 0)
		}
		const dialogVisible = ref(false)
		let url = ref('/static/info/sjfa.jpg')
		const fangankefu = ()=>{			
			url.value = "/static/info/sjfa.jpg"
			dialogVisible.value = true
		}
		const shouhoukefu = ()=>{
			url.value = "/static/info/bjbt.jpg"
			dialogVisible.value = true
		}

		return {
			goToTop,
			goToUserCenter,
			id,
			fangankefu,
			shouhoukefu,
			url,
			dialogVisible
			// shouqiankefu,
		}
	},
})
