<template>
	<div class="targetswiper" ref="target">
		<div class="layer" ref="target" v-show="!isOutside && showBigBox"
			:style="{ left: position.x + 'px', top: position.y + 'px' }"></div>
		<swiper :navigation="false" :spaceBetween="10" :thumbs="{ swiper: thumbsSwipers }" @slideChange="slideChange"
			:autoplay="{
				delay: 2500,
				disableOnInteraction: true,
			}" :modules="modules" @swiper="setSwiperRef" class="mySwiper2">
			<swiper-slide v-for="(pic, index) in piclist">
				<div class="box2">
					<div class="swiper-box">
						<!-- <vue3VideoPlay
              v-if="isMp4(pic) && isShowVideo"
              @play="onPlay"
              @pause="onPause"
              v-bind="getOption(pic)"
            /> -->
						<video-player v-if="isMp4(pic) && isShowVideo" :src="pic" controls @play="onPlay" @pause="onPause"
							width="620px" height="349px" />
						<img v-else-if="isMp4(pic) && !isShowVideo" class="mySwiper-img"
							src="@/assets/common/images/default.jpg" />
						<img v-else :class="{ 'top-img': type == 1 }" :src="pic" />
					</div>
				</div>
			</swiper-slide>
		</swiper>
	</div>
	<swiper @swiper="setThumbsSwipers" :spaceBetween="35" :slidesPerView="4.3" :freeMode="true" :watchSlidesProgress="true"
		:modules="modules" @slideChange="onSlideChange" class="mySwiper" :class="{ firstswiper: activeIndex == 0 }">
		<swiper-slide v-for="(pic, index) in pics" :class="{ isleft: index == 0 }">
			<img v-if="!isMp4(pic)" class="mySwiper-img" :src="pic" />
			<img v-else class="mySwiper-img" src="@/assets/common/images/default.jpg" />
		</swiper-slide>
	</swiper>
</template>
<script>
// Import Swiper Vue.js components
import {
	defineComponent,
	onMounted,
	ref,
	computed,
	watch,
	watchEffect,
	nextTick,
} from "vue"
import { useMouseInElement } from "@vueuse/core"

import { Swiper, SwiperSlide } from "swiper/vue"

import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"
import "vue3-video-play/dist/style.css"
import vue3VideoPlay from "vue3-video-play"

import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper"
import { getSuffix } from "@/utils/public"

export default defineComponent({
	emits: ["setPosition"],
	components: {
		Swiper,
		SwiperSlide,
		vue3VideoPlay,
	},
	props: {
		type: {
			type: Number,
			default: 0,
		},
		pics: {
			type: Array,
			default: () => [],
		},
		piclist: {
			type: Array,
			default: () => [],
		},
	},
	setup(props, { emit }) {
		const isShowVideo = ref(true)
		nextTick(() => {
			setTimeout(() => {
				isShowVideo.value = true
			}, 1000)
		})
		const target = ref(null)
		const { isOutside, elementX, elementY } = useMouseInElement(target)
		isOutside.value = false
		const position = computed(() => {
			let x = elementX.value - 100
			let y = elementY.value - 100
			x = x < 0 ? 0 : x
			y = y < 0 ? 0 : y
			x = x > 450 ? 450 : x
			y = y > 165 ? 165 : y

			if (showBigBox.value) {
				emit("setPosition", x, y, isOutside.value, activeIndex.value)
			}
			return {
				x,
				y,
			}
		})

		let thumbsSwipers = ref(null)
		const setThumbsSwipers = (swiper) => {
			thumbsSwipers.value = swiper
		}
		let onSlideChange = (index) => {
			//console.log(index,onSlideChange)
		}

		let activeIndex = ref(0)

		let showBigBox = ref(true)
		let isMp4 = (url) => {
			//console.log(getSuffix(url), url)
			if (getSuffix(url) == ".mp4") {
				return true
			} else {
				return false
			}
		}
		watchEffect(() => {
			// console.log("这里是activeindex",isMp4(props.pics[activeIndex.value]),props.pics[activeIndex.value], activeIndex.value)
			if (isMp4(props.piclist[activeIndex.value])) {
				showBigBox.value = false
			} else {
				showBigBox.value = true
			}
		})

		let slideChange = (res) => {
			console.log(res.activeIndex)
			activeIndex.value = res.activeIndex
		}
		let swiperRef = ref(null)
		const setSwiperRef = (swiper) => {
			swiperRef.value = swiper
		}
		let isPlayIng = ref(false)
		watch(isOutside, (newvalue, oldvalue) => {
			if (!isPlayIng.value) {
				if (newvalue) {
					swiperRef.value.autoplay.start()
				} else {
					swiperRef.value.autoplay.stop()
				}
			}
		})
		//console.log(props.piclist[0],"视频地址");
		//const.log(props.piclist[0],)

		let getOption = (url) => {
			return {
				width: "620px", //播放器高度
				height: "349px", //播放器高度
				color: "#409eff", //主题色
				title: "", //视频名称
				src: url, //视频源
				muted: false, //静音
				webFullScreen: false,
				speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
				autoPlay: false, //自动播放
				loop: false, //循环播放
				mirror: false, //镜像画面
				ligthOff: true, //关灯模式
				volume: 0.3, //默认音量大小
				control: false, //是否显示控制
				controlBtns: [
					"audioTrack",
					"quality",
					"speedRate",
					"setting",
					"pip",
					"pageFullScreen",
					"fullScreen",
				], //显示所有按钮,
			}
		}

		const onPlay = (ev) => {
			isPlayIng.value = true
			swiperRef.value.autoplay.stop()
		}
		const onPause = (ev) => {
			isPlayIng.value = false
			swiperRef.value.autoplay.start()
		}

		return {
			onPlay,
			onPause,
			modules: [FreeMode, Navigation, Thumbs, Autoplay],
			setThumbsSwipers,
			thumbsSwipers,
			target,
			isOutside,
			elementX,
			elementY,
			position,
			slideChange,
			activeIndex,
			setSwiperRef,
			onSlideChange,
			showBigBox,
			isMp4,
			getOption,
			isShowVideo,
		}
	},
	methods: {},
})
</script>
<style scoped lang="scss">
.isleft {
	margin-left: 2px;
}

.mySwiper-img {
	image-rendering: -moz-crisp-edges;
	image-rendering: -o-crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	image-rendering: crisp-edges;
	-ms-interpolation-mode: nearest-neighbor;
}

.top-img {
	width: 620px;
	height: 349px;
}

.box2 {
	width: 620px;
	height: 349px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.swiper-box {
	width: 620px;
	height: 349px;
}

.swiper-box img {
	width: 100%;
}

.swiper-box video {
	width: 100%;
}

.swiper-slide img {
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.mySwiper2 {
	margin-bottom: 20px;
}

.mySwiper {
	margin-left: 20px;

	:deep(.swiper-wrapper) {
		display: flex !important;
		justify-content: center !important;
	}

	:deep(.swiper-wrapper .swiper-slide-thumb-active) {
		border: 1px solid #2655ae;
	}

	:deep(.swiper-slide) {
		width: 100px;
		height: 60px; //80
		border: 1px solid #fff;
	}
}

.firstswiper {
	:deep(.swiper-wrapper .swiper-slide:first-child) {
		border: 1px solid #2655ae;
	}
}

.targetswiper {
	position: relative;

	.large {
		position: absolute;
		top: 0;
		left: 412px;
		width: 400px;
		height: 400px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		background-repeat: no-repeat;
		// 放大一倍
		background-size: 800px 800px;
		background-color: #ffffff;
	}

	.layer {
		width: 175px;
		height: 175px;
		background: rgba(0, 0, 0, 0.2);
		left: 0;
		top: 0;
		// 可以移动
		position: absolute;
		z-index: 60;
	}
}



::v-deep(.video-js .vjs-big-play-button) {
	border: none;
	line-height: 50px;
}
</style>
