
import { reactive, defineComponent, ref, onMounted, provide, watch } from "vue";
import type { TabsPaneContext } from "element-plus";
import { uploadHistory } from "@/service/api";



// import AuditAndReturn from "./AuditAndReturn.vue"
// import FailureAudit from "./FailureAudit.vue"
// import PassAudit from "./PassAudit.vue"
// import ReleaseSuccess from "./ReleaseSuccess.vue"

export default defineComponent({
  emits: ["showsss", "upWork", "changeState"],
  // components: {
  //   // 审核中
  //   AuditAndReturn,
  //   //未通过
  //   FailureAudit,
  //   //通过
  //   PassAudit,
  //   //发布成功
  //   ReleaseSuccess,

  // },
  props: {
    changeEd: {
      type: Number,
      default: 0
    }
  },

  setup(props, context) {
    interface result {
      data: any;
    }

    let getInformation: result = reactive({
      data: {},
    });
    const activeName = ref(0);
    const tableData = reactive({
      data: []
    })
    let handleClick = (tab: TabsPaneContext, event: Event) => {
      uploadHistory.uploadHistory(getInformation.data[parseInt(tab.index as string)].status).then((res: any) => {
        tableData.data = res.datas.data;
        //console.log(getInformation.data[parseInt(tab.index as string)],"111");
      });
    };



    let getHistory = () => {
      if (activeName.value == 0) {
        uploadHistory.uploadHistory(0).then((res: any) => {
          tableData.data = res.datas.data;
        });
      }

    }

    let changeShow = () => {
      context.emit("showsss");
    };

    let worksHistory = () => {
      uploadHistory.uploadHistory().then((res: any) => {
        //console.log("历史");
        getInformation.data = res.datas.statusArr;
      });
    };
    watch(props, () => {
      // console.log("gengxin le ")
      worksHistory()
      getHistory()
    })

    //  点击改变页面
    let changeState = (val: string, index: any) => {
      // console.log(tableData.data[index]);
      let workData = tableData.data[index]
      context.emit("changeState", workData);
    }


    let goone = () => {
      //console.log("Aaa");
      context.emit("upWork");
    }

    onMounted(() => {
      worksHistory();
      getHistory();
    });


    return {
      activeName,
      handleClick,
      tableData,
      changeShow,
      worksHistory,
      getInformation,
      getHistory,
      goone,
      changeState

    };
  },
});
