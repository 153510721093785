import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue3VideoPlay = _resolveComponent("vue3VideoPlay")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_vue3VideoPlay, _mergeProps({
      onPlay: _ctx.onPlay,
      onPause: _ctx.onPause
    }, _ctx.options), null, 16, ["onPlay", "onPause"])
  ]))
}