
import { defineComponent, ref, computed, reactive, onMounted } from "vue"
import HtmlFooter from "@/components/HtmlFooter.vue"
import HomeHead from "@/components/HomeHead.vue"
import CurrentAddress from "@/components/common/CurrentAddress.vue"
import { ElTable } from "element-plus"
import GoodsItem from "@/components/order/GoodsItem.vue"
import GoodsPrice from "@/components/order/GoodsOrderPrice.vue"
import ConsultingSelect from "@/components/order/ConsultingSelect.vue"
import { orderApi } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
import { useRoute, useRouter } from "vue-router"
import { userStore } from "@/stores/userStore"

export default defineComponent({
  components: {
    HomeHead,
    HtmlFooter,
    CurrentAddress,
    GoodsItem,
    GoodsPrice,
    ConsultingSelect,
  },
  setup() {
    const counterStore = userStore()
    let pwidth = (val: boolean = false) => {
      if (val) {
        //可以咨询服务
        return [570, 220, 200, 243, 207]
      } else {
        //不可以咨询服务
        return [683, 350, 407]
      }
    }
    const params = useRoute()
    let orderId = parseInt(params.params.id as string)
    let router = useRouter()
    let loading = ref(true)
    interface orderDataType {
      errno: number
      datas: any
      errmsg: string
    }
    const orderData: orderDataType = reactive({
      errno: 0,
      errmsg: "",
      datas: {
        list: [],
      },
    })
    const formInline = reactive({
      type: [],
    })

    const multipleTableRef = ref<InstanceType<typeof ElTable>>()

    let toggleAllSelection = () => {
      multipleTableRef.value!.toggleAllSelection()
    }

    interface User {
      date: string
      name: string
      address: string
    }
    const multipleSelection = ref<User[]>([])
    const toggleSelection = (rows?: User[]) => {
      if (rows) {
        rows.forEach((row) => {
          // TODO: improvement typing when refactor table
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          multipleTableRef.value!.toggleRowSelection(row, undefined)
        })
      } else {
        multipleTableRef.value!.clearSelection()
      }
    }

    const handleSelectionChange = (val: User[]) => {
      multipleSelection.value = val
      console.log(multipleSelection.value.length)
    }

    let handleClick = (index: number) => {}

    /**
     * 获取订单信息
     */
    let getOrderInfo = () => {
      loading.value = true
      orderApi.getOrderInfo(orderId).then((res: any) => {
        loading.value = false
        if (res.errno == 0) {
          if (res.datas.list && res.datas.id < 1) {
            //该订单不存在 todo
          } else if (res.datas.status != 2||res.datas.order_type==1) {
            //该订单已经完成，显示订单详细页
            router.replace({ name: "tobepaid", params: { id: orderId } })
          } else {
            orderData.datas = res.datas
          }
        } else {
          sjyAlert(res.errmsg, "error")
        }
      })
    }
    let gotoDown = () => {
      router.push({ name: "orderdown", params: { id: orderId } })
    }
    let stringToInt = (val: string) => {
      if (typeof val === "string") {
        // "string"
        return parseInt(val)
      }
      return val
    }

    let showMobile = (mmobile: string) => {
      var reg = /^(\d{3})\d{4}(\d{4})$/
      return mmobile.replace(reg, "$1****$2")
    }

    let getConsultStatus = (
      consult_status: number,
      is_consult: number,
      type: string = ""
    ) => {
      if (type == "text") {
        if (is_consult == 0) {
          return "未购买"
        } else {
          if (consult_status == 0) {
            return "可填写1-3个问题"
          } else if (consult_status == 1) {
            return "等待我们电话联系您"
          } else if (consult_status == 2) {
            return "已咨询"
          }
        }
      } else {
        if (is_consult == 0) {
          return "付费咨询"
        } else {
          if (consult_status == 0) {
            return "填写问题"
          } else if (consult_status == 1) {
            return "查看问题"
          } else if (consult_status == 2) {
            return "再次咨询"
          }
        }
      }
    }
    let gotoConsultInfo = (id: number) => {
      let routeData = router.resolve({ path: "/service/" + id + "/" + orderId })
      window.open(routeData.href, "_blank")
    }

    onMounted(() => {
      getOrderInfo()
    })
    return {
      orderData,
      handleClick,
      formInline,
      toggleAllSelection,
      multipleTableRef,
      handleSelectionChange,
      multipleSelection,
      stringToInt,
      showMobile,
      getConsultStatus,
      gotoConsultInfo,
      gotoDown,
      loading,
      pwidth,
      counterStore,
    }
  },
})
