
import { getVipInfo } from "@/utils/public"
import { defineComponent, onMounted, ref } from "vue"

export default defineComponent({
  components: {},
  props: {
    price: {
      type: Number,
      default: 25,
    },
  },
  setup(props: any) {
    let vipinfo = getVipInfo()
    

    return { vipinfo }
  },
})
