
import { goodsApi } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
import { useRouter } from "vue-router"
import { defineComponent, onMounted, reactive, ref, watch } from "vue"
import PageShow from "../common/PageShow.vue"
import GoodsItem from "./GoodsItem.vue"
export default defineComponent({
	props: {
		cid: {
			type: Number,
			default: 0,
		},
		aids: {
			type: Array,
			default: () => [],
		},
	},
	setup(props: any) {
		const prev = ref("<")
		const next = ref(">")
		const pageShow = ref<any>()
		let pageIndex = ref(1)

		interface goodsListType {
			data: any
			errno: number
			errmsg: string
			total: number
			pageSize: number
		}

		let goodsList: goodsListType = reactive({
			data: [],
			errno: 0,
			errmsg: "",
			total: 0,
			pageSize: 20,
		})

		let handleCurrentChange = (val: number) => {
			pageIndex.value = val

			getFreeGoodsList()
		}
		let pageSize = 20
		let getFreeGoodsList = () => {
			goodsApi
				.getFreeGoodsList(props.cid, props.aids, pageIndex.value, pageSize)
				.then((res: any) => {
					if (res.errno == 0) {
						goodsList.data = res.datas.data
						goodsList.errno = res.errno
						goodsList.errmsg = res.errmsg
						goodsList.pageSize = res.datas.pageSize
						goodsList.total = res.datas.total
					} else {
						sjyAlert(res.errmsg, "error")
					}
				})
		}

		watch(props, (newValue, oldValue) => {
			pageIndex.value = 1
			console.log("我执行了")
			if (pageShow != undefined && pageShow != null && pageShow.value != null) {
				pageShow.value.changePageIndex(pageIndex.value)
			}
			getFreeGoodsList()
		})
		let router = useRouter()
		let gotoHome = () => {
			router.push({
				name: "home",
			})
		}

		onMounted(() => {
			if (props.cid > 0) {
				getFreeGoodsList()
			}
		})

		return {
			prev,
			next,
			handleCurrentChange,
			goodsList,
			pageIndex,
			pageShow,
			gotoHome,
		}
	},
	components: { GoodsItem, PageShow },
})
