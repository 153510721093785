
import { reactive, ref, defineComponent, onMounted } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import { ElMessageBox } from "element-plus";

import { publicApi, userApi } from "@/service/api";
import { sjyAlert } from "@/utils/alert";
import { userStore } from "@/stores/userStore";
export default defineComponent({
  components: {},
  setup() {
    interface result {
      data: any;
    }

      // 是否是vip
      let userInfo = userStore();
    let isVip = userInfo.isVip;
    //删除弹框
    let splicVisible = ref(false);

    let radio = ref(3);

       // 创建子账号弹框
       const dialogVisible = ref(false);

    let addSonNumber = () =>{
      sonidForm.name = ""
      sonidForm.mobile = ""
      sonidForm.code = ""
      sonidForm.password = ""
      dialogVisible.value = true
    }

 

    const handleClose = (done: () => void) => {
      ElMessageBox.confirm("您的子账号还没有创建,您确定要关闭对话框吗?", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          done();
        })
        .catch(() => {});
    };

    // 创建子账号信息框
    let sonidForm = reactive({
      name: "",
      mobile: "",
      code: "",
      password: "",
    });
    const ruleFormRef = ref<FormInstance>();

    //信息输入框验证
    const rules = reactive<FormRules>({
      name: [{ required: true, message: "请输入用户名", trigger: "blur" }],

      mobile: [
        { required: true, message: "请输入手机号", trigger: "blur" },
        {
          pattern: "^1[35678]\\d{9}$",
          message: "请输入正确的手机号",
          trigger: "blur",
        },
      ],

      password: [
        { required: true, message: "请输入8-16位大小写字母、数字组合的登录密码", trigger: "blur" },
        {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{8,16}$/,
          message: "密码需为8-16位大小写字母、数字组合",
          trigger: "blur",
        },
      ],
    });

    //  子账号id
    let deldata = reactive({
      id: "",
    });

    // 删除子账号
    let splicClick = (index: number) => {
      ElMessageBox.confirm("确定要删除子账号吗?", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deldata.id = tableData.data[index].id;
         // console.log(deldata.id);
          userApi.splicSon(deldata).then((res: any) => {
            // console.log(res);
            if (res.errno == 0) {
              sjyAlert("子账号删除成功", "success");
              location.reload();
            } else {
              sjyAlert(res.errmsg, "error");
            }
          });
        })
        .catch(() => {});
    };

    // 子账号验证码
    let count = ref(60);
    let isShow = ref(true);
    //let 秒后再获取
    let countShowText = ref("获取验证码");
    var times: any;

    const countdown = async (formEl: FormInstance | undefined) => {

      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          count.value = 60; //赋值60秒
      isShow.value = false;

      getPhongCode();
      times = setInterval(() => {
        count.value = count.value - 1; //递减
        if (count.value <= 0) {
          // <=0 变成获取按钮
          isShow.value = true;
          clearInterval(times);
        }
        countShowText.value = count.value + "秒后再获取";
      }, 1000); //1000毫秒后执行
        } else {
        }
      })
     
    };

    let getPhongCode = () => {
      //这里应该验证一下手机号的正确性  TODO
      publicApi.getPhoneCode(sonidForm.mobile).then((res: any) => {
        if (res.errno == 0) {
          sjyAlert("发送成功", "success");
        } else {
          isShow.value = true;
          clearInterval(times);
          sjyAlert(res.errmsg, "error");
        }
      });
    };

    // 创建子账号
    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      await formEl.validate((valid, fields) => {
        if (valid) {
          console.log("submit!");
            dialogVisible.value = false;


          userApi.addSon(sonidForm).then((res: any) => {
            console.log(res);
            if (res.errno == 0) {
              sjyAlert("子账号创建成功", "success");
              getSonList()
            } else {
              sjyAlert(res.errmsg, "error");
            }
          });
          
        
        } else {
          //console.log("error submit!", fields);
        }
      });
    };

    const resetForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.resetFields();
    };

    const tableData: result = reactive({
      data: [],
    });
    // 获取子账号列表
    let getSonList = () => {
      userApi.sonlist().then((res: any) => {
        for (let i = 0; i < res.datas.length; i++) {
          res.datas[i].status = parseInt(res.datas[i].status);
        }
        tableData.data = res.datas;
       // console.log(res);
      });
    };

    //修改子账号状态

    let sonInformation = reactive({
      id: "",
      status: "",
    });

    let dofollow = (index: number) => {
      sonInformation.id = tableData.data[index].id;
      sonInformation.status = tableData.data[index].status;

      //console.log(sonInformation.status);

      userApi.changeSon(sonInformation).then((res: any) => {
       // console.log(res);
      });
    };

  let userSon = ref(0)
    let sonNumber = () =>{
      if(userInfo.vipInfo.vipid == 16){
        userSon.value =  3
      }  if(userInfo.vipInfo.vipid == 17){
        userSon.value =  5
      }  if(userInfo.vipInfo.vipid == 18){
        userSon.value =  10
      }
    }

    onMounted(() => {
      getSonList();
      sonNumber();
    });
    return {
      tableData,
      dialogVisible,
      handleClose,
      sonidForm,
      splicClick,
      radio,
      splicVisible,
      countdown,
      isShow,
      count,
      countShowText,
      ruleFormRef,
      rules,
      submitForm,
      resetForm,
      getSonList,
      deldata,
      addSonNumber,
      dofollow,
      sonInformation,
      isVip,
      sonNumber,
      userSon,
    };
  },
});
