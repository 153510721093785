
import {
	defineComponent,
	onMounted,
	reactive,
	ref,
	inject,
	watch,
	computed,
	onUnmounted,
} from "vue"
import { videoApi } from "@/service/api"
import formdata from "@/utils/dateFormat"
import { useRouter } from "vue-router"
import video from "@/service/module/videoApi"
import "vue3-video-play/dist/style.css"
//@ts-expect-error
import vue3VideoPlay from "vue3-video-play"
export default defineComponent({
	components: {
		vue3VideoPlay,
	},
	setup() {
		interface goodsListType {
			data: any
		}
		let videos: goodsListType = reactive({
			data: [],
		})
		const title = [
			{ name: "推荐", cid: 0 },
			{ name: "末端执行器", cid: 3 },
			{ name: "输送机构", cid: 2 },
			{ name: "自动化单元", cid: 1 },
			{ name: "工业机器人", cid: 7 },
			{ name: "视觉方案", cid: 5 },
			{ name: "控制柜", cid: 6 },
		]
		const isActive = ref(0)
		let active = (val: number) => {
			isActive.value = val
			if (val == 0) {
				where.cid = 0
				where.sort = 2
				where.pageSize = 8
			} else {
				where.pageSize = 5
				where.cid = title[val].cid
				where.sort = 0
			}
			getVideoList()
		}
		let where = {
			sort: 2,
			pageIndex: 1,
			pageSize: 8,
			cid: 0,
			aid: 0,
		}
		let videoList = reactive({
			datas: {
				data: [] as any[],
				pageSize: 0,
				total: 0,
			},
		})

		let titlevideoList = reactive({
			datas: {
				data: [] as any[],
				pageSize: 0,
				total: 0,
			},
		})
		const loading = ref(true)
		let getVideoList = () => {
			loading.value = false
			videoApi.videoList(where).then((res: any) => {
				if (isActive.value != 0) {
					videoList.datas = res.datas
					loading.value = true
				} else {
					loading.value = true
					videoList.datas.data = res.datas.data.slice(3, 8)
					videos.data = res.datas.data.slice(0, 3)
				}
			})
		}
		let videoFormat = (time: number) => {
			return formdata.durationFormat(time)
		}
		const staticUrl = inject("staticUrl")
		const imgUrl = computed(() => (goodsnames: string, cover: string) => {
			if (cover) {
				return cover
			} else {
				let modleName = goodsnames.split("：")[1]
				return staticUrl + "/filespath/files/video/img/" + modleName + ".jpg"
			}
		})

		let router = useRouter()
		let goto = (cid: number, id: number) => {
			const { href } = router.resolve({
				name: "video-info",
				params: { id: id, attach: cid + "-" + "0" + "-" + "0" },
			})
			window.open(href, "_blank")
		}

		const autoplay = ref(true) //是否自动切换
		const videoPlayer0: any = ref(null)
		const videoPlayer1: any = ref(null)
		const videoPlayer2: any = ref(null)
		let activeIndex = ref(0)
		let leftvideo = (index: number) => {
			return (
				(activeIndex.value == 0 && index == 2) ||
				(activeIndex.value == 1 && index == 0) ||
				(activeIndex.value == 2 && index == 1)
			)
		}
		let rightvideo = (index: number) => {
			return (
				(activeIndex.value == 0 && index == 1) ||
				(activeIndex.value == 1 && index == 2) ||
				(activeIndex.value == 2 && index == 0)
			)
		}
		const getIndex = (nowIndexs: any, oldIndex: any) => {
			activeIndex.value = nowIndexs
			// 用户可能在上个视频未播放完毕就切换
			// 此时需要暂停上个视频并把时间归零，否则影响对当前视频的监听逻辑
			if (oldIndex == 0) {
				const videoPlayer = videoPlayer0.value
				videoPlayer[0].pause()
			} else if (oldIndex == 1) {
				const videoPlayer = videoPlayer1.value
				videoPlayer[0].pause()
			} else {
				const videoPlayer = videoPlayer2.value
				videoPlayer[0].pause()
			}
		}
		// 监听视频的播放、暂停、播放完毕等事件
		let star = () => {
			autoplay.value = false
			videoFootShow.value = true
		}
		let stop = () => {
			autoplay.value = true
		}
		let goClass = () => {
			const { href } = router.resolve({
				name: "video",
				params: {
					id: isActive.value,
				},
			})
			window.open(href, "_blank")
			return false
		}
		let videoFootShow = ref(false)
		let options: any = reactive({})
		options = {
			width: "650px", //播放器高度
			height: "364px", //播放器高度
			color: "#409eff", //主题色
			title: "", //视频名称
			//src: "https://www.sjyuan.net.cn/filespath/files/video/ZS0600222.mp4", //视频源
			muted: false, //静音
			webFullScreen: false,
			speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
			autoPlay: false, //自动播放
			loop: false, //循环播放
			mirror: false, //镜像画面
			ligthOff: true, //关灯模式
			volume: 0.3, //默认音量大小
			// control: videoFootShow.value, //是否显示控制
			controlBtns: [
				"audioTrack",
				// "quality",
				// "speedRate",
				// "setting",
				// "pip",
				// "pageFullScreen",
				"fullScreen",
			], //显示所有按钮,
		}

		onMounted(() => {
			getVideoList()
		})
		onUnmounted(() => {})
		return {
			videos,
			isActive,
			title,
			active,
			videoList,
			videoFormat,
			imgUrl,
			loading,
			goto,
			titlevideoList,
			stop,
			star,
			getIndex,
			videoPlayer0,
			videoPlayer1,
			videoPlayer2,
			autoplay,
			goClass,
			options,
			videoFootShow,
			leftvideo,
			rightvideo,
		}
	},
})
