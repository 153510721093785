
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    headerHeight: {
      type: Number,
      default: 50,
    },
    headerBC: {
      type: String,
      default: "",
    },
  },
  setup() {
    let mclose=()=>{
        console.log("titlede关闭")
    }
    return {mclose};
  },
});
