
import { defineComponent, onMounted, reactive, watch, inject, ref } from "vue"
import { userApi } from "@/service/api"
import { sjyAlert } from "@/utils/alert"
export default defineComponent({
  emits: ["mclose", "changeGoShow"],
  name: "invoicesubmit",
  props: {
    selfForm: {
      type: Object,
      default: () => ({}),
    },
    firmForm: {
      type: Object,
      default: () => ({}),
    },
    exclusiveForm: {
      type: Object,
      default: () => ({}),
    },
    lastInfor: {
      type: Object,
      default: () => ({}),
    },
    goShow: {
      type: Boolean,
      default: true,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  setup(props: any, context) {
    let goBack = () => {
      //userinfor.data = {};
      //goShow.value = true;
      context.emit("changeGoShow", true)
    }
    let getInvoice = (data: Object) => {
      userApi.getInvoice(data).then((res: any) => {
        if (res.errno == 0) {
          sjyAlert("提交成功", "success")
          context.emit("mclose")
        } else {
          sjyAlert(res.errmsg, "error")
        }
      })
    }

    interface result {
      data: any
    }

    let radio1 = ref(0)
    let radio2 = ref(0)

    let userinfor: result = reactive({
      data: {},
    })
    // 点击提交
    let submit = () => {
      getInvoice(userinfor.data)
    }
    // let exclusiveForm = props.exclusiveForm;
    // console.log(exclusiveForm,"123");

    onMounted(() => {
      if (props.selfForm.name != "") {
        //给userinfor .data 赋值,
        radio1.value = 1
        radio2.value = 1
        userinfor.data = props.selfForm
      } else if (props.firmForm.name) {
        radio1.value = 1
        radio2.value = 2
        userinfor.data = props.firmForm
      } else if (props.exclusiveForm.com_address) {
        radio1.value = 2
        userinfor.data = props.exclusiveForm
      } else {
        //最后一次
        if (
          props.lastInfor.data.type == 1 &&
          props.lastInfor.data.iv_type == 1
        ) {
          //给userinfor .data 赋值,
          radio1.value = 1
          radio2.value = 1
        } else if (
          props.lastInfor.data.type == 1 &&
          props.lastInfor.data.iv_type == 2
        ) {
          radio1.value = 1
          radio2.value = 2
        } else if (props.lastInfor.data.type == 2) {
          radio1.value = 2
        }
        userinfor.data = props.lastInfor.data
      }
    })

    return {
      goBack,
      getInvoice,
      submit,
      userinfor,
      radio1,
      radio2,
      // exclusiveForm,
    }
  },
})
