
import { reactive, ref, defineComponent, onMounted, nextTick } from "vue"
import type { TabsPaneContext } from "element-plus"
import recharge from "./recharge.vue"
import record from "./record.vue"
import withdrawMoney from "./withdrawMoney.vue"
import { useRoute } from "vue-router"
import { userApi } from "@/service/api"

// import { nextTick } from "process";

export default defineComponent({
  components: { recharge, record, withdrawMoney },
  setup(props, context) {
    const activeName = ref("")

    let tabs = reactive({
      show: "",
    })
    const handleClick = (tab: TabsPaneContext, event: Event) => {
      //console.log(tab.index, event);
      if (tab.index == "2") {
        tabs.show = "record"
      }
      if (tab.index == "0") {
        tabs.show = "recharge"
      }
      if (tab.index == "1") {
        tabs.show = "withdrawMoney"
      }
    }
    let checked = () => {
      activeName.value = "third"
      tabs.show = "record"
    }

    let gorecord = (aaa: number) => {
      // console.log("a");
      // console.log(aaa);
    }
    let viptype = useRoute().params.type
    //console.log("viptype",viptype);

    const Son: any = ref(null)
    // 个人信息接口  判断是否是子账号
    interface result {
      data: any
    }
    let getInformation: result = reactive({
      data: {},
    })
    let getuserInformation = () => {
      userApi.getInformation().then((res: any) => {
        getInformation.data = res.datas
        if (getInformation.data.pid != 0) {
          activeName.value = "third"
          tabs.show = "record"
        } else {
          if (viptype && viptype == "third") {
            tabs.show = "record"
            activeName.value = "third"
          } else {
            activeName.value = "first"
            tabs.show = "recharge"
          }
        }
      })
    }
    let sonUser = () => {}
    sonUser()
    onMounted(() => {
      getuserInformation()
    })
    return {
      activeName,
      handleClick,
      tabs,
      checked,
      gorecord,
      Son,
      getuserInformation,
      getInformation,
      sonUser,
    }
  },
})
