
// 引入element ui 样式
import { reactive, ref, defineComponent, onMounted } from "vue";
import type { FormInstance, FormRules } from "element-plus";

import HtmlFooter from "../../components/HtmlFooter.vue";
import HomeHead from "../../components/HomeHead.vue";
import { userApi, publicApi } from "@/service/api";
import { sjyAlert } from "@/utils/alert";

import { userStore } from "@/stores/userStore";
import router from "@/router";

export default defineComponent({
  components: { HtmlFooter, HomeHead },
  setup() {
    const ruleFormRef = ref<FormInstance>();
    const loginForm = reactive({
      password: "",
    });

    const rules = reactive<FormRules>({
      password: [
      { required: true, message: "请输入8-16位大小写字母、数字组合的登录密码", trigger: "blur" },
        {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{8,16}$/,
          message: "密码需为8-16位大小写字母、数字组合",
          trigger: "blur",
        },
      ],
    });

    
    const informationForm = reactive({
      //修改支付密码 短信验证码框
      password: "",
    });

    let change = () => {
      informationForm.password = informationForm.password.toString();
      //console.log(informationForm.password);
      userApi.changePwd(loginForm).then((res: any) => {
        console.log(res);
        // 修改完密码退出登录重新登录
        if (res.errno == 0) {
          userStore().deleteUserInfo();
          sjyAlert("您的密码已修改,请重新登录", "success", () => {
            router.push({ name: "login" }); // userRouter
          });
        }
        if (res.errno == 10003) {
          sjyAlert("请输入密码", "error");
        }
      });
    };

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          change();
        } else {
          //console.log("error submit!", fields);
        }
      });
    };

    let phone = ref(true);

    return {
      loginForm,
      rules,
      ruleFormRef,
      submitForm,
      phone,
    };
  },
});
