import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d81b2b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "form-box"
}
const _hoisted_2 = { class: "info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_elui_china_area_dht = _resolveComponent("elui-china-area-dht")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_ctx.isShow)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_form, {
          model: _ctx.address.data,
          rules: _ctx.rules,
          ref: "ruleFormRef",
          "label-width": "120px",
          "label-position": "left"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "收货人",
              prop: "contacts"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.address.data.contacts,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.address.data.contacts) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "手机号码",
              prop: "phonenumber"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.address.data.phonenumber,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.address.data.phonenumber) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "所在地区",
              prop: "area"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_elui_china_area_dht, {
                  onChange: _ctx.onChange,
                  placeholder: "请选择地区",
                  modelValue: _ctx.userForm.addr,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userForm.addr) = $event))
                }, null, 8, ["onChange", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "详细地址",
              prop: "address"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.address.data.address,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.address.data.address) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onSubmit(_ctx.ruleFormRef))),
                    round: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("保存收货人信息")
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]))
    : _createCommentVNode("", true)
}