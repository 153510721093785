
import { useRoute } from "vue-router"
import { defineComponent, inject, ref, watchEffect } from "vue"
export default defineComponent({
	name: "collection",

	setup(props: any) {
		let baseurl = inject("BASEURL", "")
		let staticUrl = inject("staticUrl", "")
		let routerPath = ref("")
		routerPath.value = baseurl + useRoute().path
		let names = "设纪元网站"
		let desc =
			"设纪元模型站是一个专注于设计资料分享、设计文档交流互动的平台。为您提供工业机器人、自动化领域相关的详尽设计资料"
		let images = staticUrl + "/filespath/images/20210727131755.png"
		return {
			routerPath,
			names,
			desc,
			images,
		}
	},
})
