
import { ref, defineComponent, reactive } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import HtmlFooter from "@/components/HtmlFooter.vue";
import HomeHead from "@/components/HomeHead.vue";
import { userApi } from "@/service/api";
import type { UploadProps, UploadUserFile } from "element-plus";
import { sjyAlert } from "@/utils/alert";
import InvoiceDialog from "@/components/common/Dialog/InvoiceDialog.vue";
import { emailRegExp, phoneRegExp } from "@/utils/public";

export default defineComponent({
  components: {
    HtmlFooter,
    HomeHead,
    InvoiceDialog,
  },
  setup() {
    interface CardType {
      name: string;
      url: string;
    }
    interface userinfoType {
      type: string;
      content: string;
      connect: string;
      photo: Array<CardType>;
    }
    const userSuggestForm: userinfoType = reactive({
      type: "",
      content: "",
      connect: "",
      photo: [],
    });

    let beforeUpload: UploadProps["beforeUpload"] = (rawFile: any) => {
      if (userSuggestForm.photo.length > 5) {
        sjyAlert("最多上传5张图片", "error");
      }
      if (rawFile.type !== "image/jpeg" && rawFile.type !== "image/png") {
        sjyAlert("请上传正确的图片格式", "error");
        return false;
      } else if (rawFile.size / 1024 / 1024 > 5) {
        sjyAlert("图片单张大小不能超过5M", "error");
        return false;
      }
      //获取上传的签名
      // console.log("开始了", rawFile, "结束了");
      userApi.getOSSToken().then((res: any) => {
        let url = res.data.host;
        let new_multipart_params = {
          // key表示上传到Bucket内的Object的完整路径，例如exampledir/exampleobject.txtObject，完整路径中不能包含Bucket名称。
          // filename表示待上传的本地文件名称。
          key: res.data.dir + rawFile.uid + rawFile.name,
          policy: res.data.policy,
          OSSAccessKeyId: res.data.accessid,
          // 设置服务端返回状态码为200，不设置则默认返回状态码204。
          success_action_status: "200",
          signature: res.data.signature,
          file: rawFile,
        };

        userApi.updateOss(url, new_multipart_params).then((res: any) => {
          //console.log("返回信息",res,"返回信息结束")
          if (userSuggestForm.photo.length > 5) {
            sjyAlert("最多上传5张图片", "error");
          } else {
            userSuggestForm.photo.push({
              name: rawFile.uid + rawFile.name,
              url:
                "https://image.sjyuan.net.cn/img/sjy/" +
                rawFile.uid +
                rawFile.name,
            });
          }
        });
      });
      return false;
    };

    const validatePass = (rule: any, value: any, callback: any) => {
      //console.log("开始了", value, emailRegExp(value), phoneRegExp(value))
      if (value === "") {
        callback(new Error("请输入手机号或邮箱号"));
      } else if (!emailRegExp(value) && !phoneRegExp(value)) {
        callback(new Error("请输入正确的手机号或邮箱号"));
      } else {
        callback();
      }
    };

    const dialogImageUrl = ref("");
    const imgdialogVisible = ref(false);

    const handlePictureCardPreview: UploadProps["onPreview"] = (uploadFile) => {
      dialogImageUrl.value = uploadFile.url!;
      imgdialogVisible.value = true;
    };

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          let toSubmitFrom = {
            type: userSuggestForm.type,
            content: userSuggestForm.content,
            photo: userSuggestForm.photo,
            connect: userSuggestForm.connect,
          };
          userApi.userSuggest(toSubmitFrom).then((res: any) => {
            console.log(res);

            if (res.errno == 0) {
              setDialogVisible();
            }
          });
        } else {
        }
      });
    };

    let dialogVisible = ref(false);

    let setDialogVisible = () => {
      sonRef.value?.setVisible(true);
      //console.log();
    };
    const sonRef = ref<InstanceType<typeof InvoiceDialog>>();
    // 关闭弹框
    let mclose = () => {
      dialogVisible.value = false;
      sonRef.value?.setVisible(dialogVisible.value);
    };

    // 表单验证
    const ruleFormRef = ref<FormInstance>();
    const rules = reactive<FormRules>({
      type: [
        {
          required: true,
          message: "请选择建议类型",
          trigger: "change",
        },
      ],

      connect: [
        { validator: validatePass, trigger: "blur" },
        // {
        //   pattern: "/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/",
        //   message: "请输入正确的手机号或邮箱号",
        //   trigger: "blur",
        // },
      ],
      content: [
        { required: true, message: "请输入您的建议", trigger: "blur" },
        { min: 15, message: "建议最少15个字哦", trigger: "blur" },
      ],
    });
    return {
      userSuggestForm,
      beforeUpload,
      submitForm,
      dialogVisible,
      mclose,
      sonRef,
      setDialogVisible,
      ruleFormRef,
      rules,
      dialogImageUrl,
      imgdialogVisible,
      handlePictureCardPreview,
    };
  },
});
