import axios from "axios";
import qs from "qs";
import { sjyAlert } from "@/utils/alert";
import router from "../router";
import { userStore } from "@/stores/userStore";
import { useCookies } from "vue3-cookies";

// axios.defaults.baseURL = ''  //正式
//axios.defaults.baseURL = "/api"; //测试
axios.defaults.baseURL = "https://api.sjyuan.net.cn/api";

//post请求头
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

// 表示跨域请求时是否需要使用凭证 允许跨域携带cookie信息
//axios.defaults.withCredentials = false;
//axios.defaults.withCredentials = false;
// 允许跨域
//axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

//设置超时
axios.defaults.timeout = 15000;

axios.interceptors.request.use(
  (config) => {
    //判断token是否存在
    let userinfo = userStore();
    //let useCookies()
    const MCookies = useCookies();
    if (userinfo.token && MCookies.cookies.isKey("userLogin")) {
      // 在请求头中添加token
      if (config && config.headers) {
        config.headers.PCTOKEN = userinfo.token;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status == 200) {
      //console.log("我的天",response)
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    console.log(JSON.stringify(error));
    return false;
  }
);

export default {
  /**
   * @param {String} url
   * @param {Object} data
   * @returns Promise
   */

  //  get(url:string,data:Object){
  //     return axios.get(url,{params:data})
  //  },
  //  post(url:string,data:Object){
  //     return  axios.post(url,qs.stringify(data))
  //  },

  post(url: string, data: Object, isCookie: boolean = false, key: string = "", showError: boolean = true) {
    if (isCookie && localStorage.getItem(url + key)) {
      let thenable = {
        then: function (resolve: any, reject: any) {
          resolve(JSON.parse(localStorage.getItem(url + key) as string));
        },
      };
      return Promise.resolve(thenable);
    } else {
      return new Promise((resolve, reject) => {
        axios({
          method: "post",
          url,
          data: qs.stringify(data),
          withCredentials: true,
        })
          .then((res) => {
            // console.log(res.data.errno == 0);
            if (res.data.errno == 70010 && showError) {
              sjyAlert("请先登录", "error", () => {
                const userCommonStore = userStore();
                userCommonStore.deleteUserInfo();
                router.push({ name: "login" });
              });
            }
            if (isCookie) {
              localStorage.setItem(url + key, JSON.stringify(res.data));
            }
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  get(url: string, data: Object) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url,
        params: data,
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.errno == 70010) {
            //todo
            sjyAlert("请先登录", "error", () => {
              const userCommonStore = userStore();
              userCommonStore.deleteUserInfo();
              router.push({ name: "login" });
            });
          }
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getImg(url: string, data: Object) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url,
        params: data,
        responseType: "arraybuffer",
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getZip(url: string, data: Object) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url,
        params: data,
        responseType: "blob",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateOss(url: string, data: object) {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin-Type": "*",
          "Content-Type": "multipart/form-data",
        },
        method: "post",
        url,
        data: data,
        withCredentials: false,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
