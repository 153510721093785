
import { defineComponent } from "vue"
// import {getGoodsPrice} from "@/utils/public"
import { userStore } from "@/stores/userStore"
import { showPrice } from "@/utils/public"

export default defineComponent({
  components: {},
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any) {
    let userInfo = userStore()
    let isVip = userInfo.isVip
    let getGoodsPrice = (val: number) => {
      if (props.data.activity_price && props.data.activity_price > 0) {
        if (props.data.type == 1) {
          return Math.round(props.data.activity_price * 100) / 100
        } else {
          return Math.round(props.data.activity_price)
        }
      } else {
        if (props.data.type == 1) {
          return Math.round(props.data.price * 100) / 100
        } else {
          return Math.round(props.data.price * userInfo.discount)
        }
      }
    }
    return { getGoodsPrice, isVip ,showPrice}
  },
})
