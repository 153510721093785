import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-789c5bce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "attr-contents" }
const _hoisted_2 = { class: "title title1" }
const _hoisted_3 = { class: "title title3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info, (item, index) => {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(item.title), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.content, (sitem, sindex) => {
        return (_openBlock(), _createElementBlock("p", _hoisted_3, "· " + _toDisplayString(sitem.trim()), 1))
      }), 256))
    ]))
  }), 256))
}