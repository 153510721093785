
// 引入element ui 样式
import { reactive, ref, defineComponent, onMounted } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import HtmlFooter from "../../components/HtmlFooter.vue";
import HomeHead from "../../components/HomeHead.vue";
import { userApi, publicApi } from "@/service/api";
import { sjyAlert } from "@/utils/alert";
import { useRouter } from "vue-router";
import { userStore } from "@/stores/userStore";

export default defineComponent({
  components: { HtmlFooter, HomeHead },
  setup() {
    const ruleFormRef = ref<FormInstance>();
    const loginForm = reactive({
      mobile: "",
      code: "",
    });

    let router = useRouter();
    const userCommonStore = userStore();

    const rules = reactive<FormRules>({
      mobile: [
        { required: true, message: "请输入手机号", trigger: "blur" },
        {
          pattern: "^1[35678]\\d{9}$",
          message: "请输入正确的手机号",
          trigger: "blur",
        },
      ],
    });

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          userApi.userLogin(loginForm).then((res: any) => {
            console.log(res);
            if (res.errno == 0) {
              userCommonStore.setUserInfo(res.datas);
              router.push({ name: "surepwd" }); // userRouter
              //跳转
            } else {
              sjyAlert(res.errmsg, "error");
              //更改验证码
              //changeImgCode();
            }
          });
        } else {
          //console.log("error submit!", fields);
        }
      });
    };

    let phone = ref(true);

    let count = ref(60);
    let isShow = ref(true);
    //let 秒后再获取
    let countShowText = ref("获取验证码");
    var times: any;

    let countdown = () => {
      count.value = 60; //赋值60秒
      isShow.value = false;

      getPhongCode();
      times = setInterval(() => {
        count.value = count.value - 1; //递减
        if (count.value <= 0) {
          // <=0 变成获取按钮
          isShow.value = true;
          clearInterval(times);
        }
        countShowText.value = count.value + "秒后再获取";
      }, 1000); //1000毫秒后执行
    };

    let getPhongCode = () => {
      //这里应该验证一下手机号的正确性  TODO
      publicApi.getPhoneCode(loginForm.mobile).then((res: any) => {
        if (res.errno == 0) {
          sjyAlert("发送成功", "success");
        } else {
          isShow.value = true;
          clearInterval(times);
          sjyAlert(res.errmsg, "error");
        }
      });
    };

    return {
      loginForm,
      rules,
      ruleFormRef,
      submitForm,
      phone,
      isShow,
      countShowText,
      countdown,
    };
  },
});
