
import { reactive, defineComponent, ref } from "vue";
import HtmlFooter from "../../components/HtmlFooter.vue";
import HomeHead from "../../components/HomeHead.vue";
import { useHead } from "@vueuse/head";
import CurrentAddress from "@/components/common/CurrentAddress.vue";
export default defineComponent({
  components: { HtmlFooter, HomeHead, CurrentAddress },
  setup() {
    useHead({
      // Can be static or computed
      title: () => "设纪元生态合作伙伴",
      meta: [
        {
          name: `description`,
          content: () =>
            "设纪元秉承共享共赢共创的合作理念，携手各行业、领域的合作伙伴共建工业自动化新生态，为行业提供自动化设计解决方案，促进方案应用落地。实现用户体验迭代、生态各方增值，共享创造价值，推动行业发展。",
        },
        {
          name: `keywords`,
          content: () => "设纪元生态合作伙伴_共享、共赢、共创",
        },
      ],
    });

    return {};
  },
});
