
import {
	reactive,
	defineComponent,
	ref,
	onMounted,
	provide,
	watchEffect,
	watch,
} from "vue"
import type { TabsPaneContext } from "element-plus"
import design from "./design.vue"
import video from "./video.vue"
import free from "./free.vue"
import Lack from "./footLack.vue"
import spare from "./spare.vue"
import dateFormat from "@/utils/dateFormat"
import { userApi, goodsApi } from "@/service/api"
import { ElMessageBox } from "element-plus"
import { sjyAlert } from "@/utils/alert"
import { useRouter } from "vue-router"

export default defineComponent({
	components: { design, video, free, Lack, spare },
	setup() {
		interface result {
			data: any
		}
		const activeName = ref("first")
		let isloading = ref(false)
		const loading = ref(true)

		const handleClick = (tab: TabsPaneContext, event: Event) => {
			isloading.value = true

			if (tab.index == "2") {
				tabs.show = "free"
				time.type = 2
				time.ctime = ""
				getGoodsList()
				getday()
				isloading.value = false
			}
			if (tab.index == "0") {
				tabs.show = "design"
				time.type = 1
				time.ctime = ""
				// show.value = false;
				getGoodsList()
				getday()
				isloading.value = false
			}
			if (tab.index == "1") {
				tabs.show = "video"
				time.ctime = ""
				// show.value = false;
				time.type = 3
				getGoodsList()
				getday()
				isloading.value = false
			}
			if (tab.index == "3") {
				tabs.show = "spare"
				time.ctime = ""
				// show.value = false;
				time.type = 4
				getGoodsList()
				getday()
				isloading.value = false
			}
		}

		let time = reactive({
			ctime: "",
			type: 1,
		})

		let tabs = reactive({
			show: "design",
		})

		//0，不显示遮挡层   1.显示单个删除遮挡层  2.多选遮挡层
		let step = ref(0)

		let all = ref(false)
		//更改step
		const toedit = (val: number) => {
			step.value = val
			isall.value = []
			//console.log("删除");
		}
		let moreselect = ref(false)

		// 足迹列表
		let goodslist: any = reactive({
			datas: [],
		})

		// 获取足迹列表
		let getGoodsList = () => {
			loading.value = true
			userApi.getFoot(time).then((res: any) => {
				show.value = false
				if (res.errno == 0) {
					for (let k in res.datas) {
						show.value = true
						for (let i = 0; i < res.datas[k].length; i++) {
							res.datas[k][i].isselect = false
						}
					}
					goodslist.datas = res.datas
					loading.value = false
					// console.log(res, "获取列表");
				}
			})
		}

		//   子组件传id 给父组件 父组件删除
		let delfoot = (ids: any) => {
			goodsApi.addGoodsTrack(ids, time.type, 0).then((res: any) => {
				if (res.errno == 0) {
					getGoodsList()
				}
			})
		}

		//   子组件传id 给父组件 父组件收藏
		let addstore = (
			product_id: any,
			product_name: string,
			cid: number,
			val: number
		) => {
			let status = val

			let mdata = [
				{
					product_id: product_id,
					type: time.type,
					status: status,
					product_name: product_name,
					cid: cid,
				},
			]

			goodsApi.addStore(mdata).then((res: any) => {
				if (res.errno == 0) {
					sjyAlert("操作成功", "success")
					getGoodsList()
				} else {
					sjyAlert(res.errmsg, "error")
				}
			})
		}

		// 获取天数
		let getday = () => {
			//isloading.value = true
			userApi.dayFoot(time).then((res: any) => {
				holidays.data = res.datas
				// isloading.value = false
			})
		}

		// 日期选择器红点
		const holidays: any = reactive({
			data: [],
		})

		console.log(holidays.data, "红点选择")

		const isHoliday = (cell: any) => {
			return holidays.data.includes(cell.dayjs.format("YYYY-MM-DD"))
		}

		// 选择查询日期
		let times = reactive({
			ctime: "",
			type: 1,
		})

		let show = ref(true)
		let findfoot = () => {
			let a = Number(time.ctime)
			if (a != 0) {
				times.ctime = dateFormat.dateFormat(a, "YYYY-MM-dd")
				times.type = time.type
				userApi.getFoot(times).then((res: any) => {
					goodslist.datas = res.datas
					// show.value = false
					for (let k in res.datas) {
						if (res.datas[k].length > 0) {
							show.value = true
						} else {
							show.value = false
						}
					}
				})
			}
			if (a == 0) {
				getGoodsList()
			}
		}

		//全选
		const isall = ref([])
		let selectAllGoods = (val: boolean, goodslist: any) => {
			for (let k in goodslist.datas) {
				// console.log(goodslist.datas[k],"11");
				for (let i = 0; i < goodslist.datas[k].length; i++) {
					// console.log(goodslist.datas[k][i],"22");
					goodslist.datas[k][i].isselect = val
				}
			}
			// for (let i = 0; i < goodslist.datas.data.length; i++) {
			//   console.log(goodslist.datas.data[i]);
			//   goodslist.datas.data[i].isselect = val;
			// }
		}

		//批量删除
		let deleteall = () => {
			ElMessageBox.confirm("确定要删除吗?", "", {
				type: "warning",
				confirmButtonText: "确定",
				cancelButtonText: "取消",
			})
				.then(() => {
					getSelectGoods(goodslist)
					deleteGoodsListByids(goodslist)
					// //获取选中的商品
					deleteStore(selectGoodsList.value)
					//getGoodsList();
				})
				.catch(() => {
					// catch error
				})
		}

		let selectGoodsList = ref([1])
		//获取选择商品的id
		let getSelectGoods = (goodslist: any) => {
			selectGoodsList.value = []
			for (let k in goodslist.datas) {
				for (let i = 0; i < goodslist.datas[k].length; i++) {
					if (goodslist.datas[k][i].isselect) {
						selectGoodsList.value.push(goodslist.datas[k][i].id)
						// console.log(selectGoodsList.value);
					}
				}
			}
		}

		let deleteGoodsListByids = (goodslist: any) => {
			for (let i = 0; i < selectGoodsList.value.length; i++) {
				// console.log(selectGoodsList.value[i]);
				for (let k in goodslist.datas) {
					// console.log(goodslist.datas[k].length, "111");
					for (let j = 0; j < goodslist.datas[k].length; j++) {
						// console.log(goodslist.datas[k][j].id, "111");
						if (selectGoodsList.value[i] == goodslist.datas[k][j].id) {
							//console.log(goodslist.datas[k][j].id, "111");
							goodslist.datas[k].splice(j, 1)
							break
						}
					}
				}
			}
		}

		let deleteStore = (product_ids: any) => {
			goodsApi.addGoodsTrack(product_ids, time.type, 0).then((res: any) => {
				//console.log(res);
			})
		}

		//批量收藏
		let allAddStore = () => {
			console.log(selectGoodsList.value)

			if (selectGoodsList.value.length > 0) {
				ElMessageBox.confirm("确定要全部加入收藏吗?", "", {
					type: "warning",
					confirmButtonText: "确定",
					cancelButtonText: "取消",
				})
					.then(() => {
						getSelectGoods(goodslist)
						// //获取选中的商品
						addStores(selectGoodsList.value)
					})
					.catch(() => {
						// catch error
					})
			} else {
				sjyAlert("没有选中的商品", "error")
			}
		}
		// product_id: any,
		//   product_name: string,
		//   cid: number,
		//   val: number
		let addStores = (product_ids: any) => {
			let mdata = []
			for (let i = 0; i < product_ids.length; i++) {
				for (let j in goodslist.datas) {
					for (let k = 0; k < goodslist.datas[j].length; k++) {
						if (goodslist.datas[j][k].id == product_ids[i]) {
							mdata.push({
								product_id: product_ids[i],
								type: time.type,
								status: 1,
								product_name: goodslist.datas[j][k].names,
								cid: goodslist.datas[j][k].cid,
							})
							continue
						}
					}
				}
			}
			if (mdata.length > 0) {
				goodsApi.addStore(mdata).then((res: any) => {
					console.log(res)
					if (res.errno == 0) {
						sjyAlert("操作成功", "success")
						getGoodsList()
					} else {
						sjyAlert(res.errmsg, "error")
					}
				})
			} else {
				sjyAlert("没有选择商品", "error")
			}
		}

		watchEffect(() => {
			if (isall.value.length > 0) {
				selectAllGoods(true, goodslist)
			} else {
				selectAllGoods(false, goodslist)
			}
		})
		let router = useRouter()
		let tolook = () => {
			if (tabs.show == "free") {
				router.push({ name: "free" })
			}
			if (tabs.show == "design") {
				router.push({ name: "goods-class" })
			}
			if (tabs.show == "video") {
				router.push({
					name: "video",
				})
			}
		}

		provide("step", step)
		provide("toedit", toedit)

		onMounted(() => {
			getday()
			getGoodsList()
			findfoot()
		})

		return {
			activeName,
			time,
			times,
			handleClick,
			tabs,
			toedit,
			step,
			moreselect,
			goodslist,
			isall,
			isHoliday,
			holidays,
			findfoot,
			delfoot,
			isloading,
			loading,
			all,
			deleteall,
			addstore,
			allAddStore,
			addStores,
			show,
			tolook,
		}
	},
})
