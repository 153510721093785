import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12743806"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      "show-close": false,
      width: _ctx.dialogwidth
    }, {
      header: _withCtx(() => [
        _renderSlot(_ctx.$slots, "header", {}, undefined, true)
      ]),
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", { addressItems: _ctx.addressItems }, undefined, true)
      ]),
      footer: _withCtx(() => [
        _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["modelValue", "width"])
  ]))
}