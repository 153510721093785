import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c210c094"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "contents-all-class"
}
const _hoisted_3 = {
  key: 1,
  class: "contents-item-class"
}
const _hoisted_4 = { class: "item-title" }
const _hoisted_5 = { class: "item-line" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 1,
  class: "item-more"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "contents",
    id: _ctx.getIdName()
  }, [
    (_ctx.headerType == 1)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          _createElementVNode("li", {
            class: _normalizeClass({ active: _ctx.sort == 0 }),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeSort(0)))
          }, "最新更新", 2),
          _createElementVNode("li", {
            class: _normalizeClass({ active: _ctx.sort == 1 }),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeSort(1)))
          }, "人气热榜", 2)
        ]))
      : _createCommentVNode("", true),
    (_ctx.headerType == 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.classinfo.name), 1),
          _createElementVNode("span", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.classinfo.son, (sclass, sindex) => {
              return (_openBlock(), _createElementBlock("span", {
                class: _normalizeClass(["item-info", { 'item-info-active': _ctx.checkedClassItem == sindex }]),
                key: sindex,
                onClick: ($event: any) => (_ctx.changeClass(sindex))
              }, _toDisplayString(sclass.name), 11, _hoisted_6))
            }), 128))
          ]),
          (_ctx.isShowMore)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "item-more",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goto(_ctx.classinfo.cid)))
              }, "更多>"))
            : (_openBlock(), _createElementBlock("span", _hoisted_7, [
                _createElementVNode("span", {
                  class: _normalizeClass({ active: _ctx.sort == 0 }),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeSort(0)))
                }, "最新", 2),
                _createElementVNode("span", {
                  class: _normalizeClass({ active: _ctx.sort == 1 }),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeSort(1)))
                }, "人气", 2)
              ]))
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}