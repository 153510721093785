
import { defineComponent } from "vue"

export default defineComponent({
  components: {},
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {}
  },
})
