
import { defineComponent } from "vue"
import GoodsItem from "./GoodsItem.vue"

export default defineComponent({
	components: { GoodsItem },
	props: {
		goodslit: {
			type: Object,
			default: () => ({}),
		},
	},
	setup(props: any) {
		// watch(props.goodsWhere, (newvalue, oldvalue) => {
		//   //console.log(newvalue, oldvalue);
		//   getGoodsList()
		// });

		//let getGoodsList = () => {
		// goodsApi.getGoodsList(props.goodsWhere).then((res:any) => {
		//   if(res.errno==0){
		//     goodslit.datas = res.datas
		//   }

		// });
		//};

		// onMounted(()=>{
		//   getGoodsList()
		// })

		return {}
	},
})
