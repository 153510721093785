interface picType {
  name: string
  url: string
}

interface BasePostSaleType {
  id: number
  pid: string // 商品id   多个逗号分割  1,2,3
  sales_type: number //1 未发货售后  2 已发货售后
  reason_id: number | string //原因id
  reason: string //原因
  money: number | string //退款金额
  explain: string //说明
  goods_status?: number //货物状态 1 未收到货  2 已收到货
  service_type?: number //服务类型 1 仅退款（无需退货）  2 退货退款
  pics: Array<picType>
  asid?: number
}

interface PostSaleType extends BasePostSaleType {
  // service_type: number //地址id
  // goods_status: number
}

interface OrderGoodsType {
  brand: string
  id: string
  model: string
  names: string
  numbers: number
  photo: string
  price: number
  isSelect?: boolean
}

interface SaleGoodsType {}

class PostSale {
  list: Array<OrderGoodsType> = []
  selectlist: Array<OrderGoodsType> = []
  price: number = 0
  send_status: number = 1
}
export { BasePostSaleType, PostSaleType, OrderGoodsType, PostSale,picType }
