import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d46fe6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pay-dialog" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PayCommon = _resolveComponent("PayCommon")!
  const _component_PaySetPwd = _resolveComponent("PaySetPwd")!
  const _component_PayInputPwd = _resolveComponent("PayInputPwd")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PayCommon, { creatOrderInfo: _ctx.creatOrderInfo }, null, 8, ["creatOrderInfo"]),
    (_ctx.setPwd)
      ? (_openBlock(), _createBlock(_component_PaySetPwd, {
          key: 0,
          creatOrderInfo: _ctx.creatOrderInfo
        }, null, 8, ["creatOrderInfo"]))
      : _createCommentVNode("", true),
    (!_ctx.setPwd)
      ? (_openBlock(), _createBlock(_component_PayInputPwd, {
          key: 1,
          creatOrderInfo: _ctx.creatOrderInfo,
          wrongtimes: _ctx.wrongtimes
        }, null, 8, ["creatOrderInfo", "wrongtimes"]))
      : _createCommentVNode("", true)
  ]))
}