import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3746c97d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["contents-bottom-item-header", { 'is-fixed': _ctx.isFixed }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pic_cs, (item, index) => {
      return (_openBlock(), _createElementBlock("li", {
        class: _normalizeClass({ active: _ctx.nowIndex == index+1 }),
        onClick: ($event: any) => (_ctx.selectLable(index+1))
      }, _toDisplayString(item.title), 11, _hoisted_1))
    }), 256))
  ], 2))
}