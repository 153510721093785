
import { reactive, defineComponent, ref } from "vue";
import HtmlFooter from "../../components/HtmlFooter.vue";
import HomeHead from "../../components/HomeHead.vue";
import writeInformation from "./component/writeInformation.vue";
import succeed from "./component/succeed.vue";
import { useHead } from "@vueuse/head";

export default defineComponent({
  components: { HomeHead, HtmlFooter, writeInformation, succeed },
  setup() {
    const firmverifyForm = reactive({
      name: "",
      id: "",
      currentView: "WrittenInformation",
      status: "",
    });

    let verify = reactive({
      show: "writeInformation",
    });

    let gonext = () => {
      verify.show = "succeed";
    };

    let goback = () => {
      verify.show = "WriteInformation";
    };
    useHead({
      // Can be static or computed
      title: () => "设纪元供应链合作",
      meta: [
        {
          name: `description`,
          content: () =>
            "设纪元自动化供应链，立足工业自动化行业领域，汇聚中国工业机器人上下游名、特、优生产制造企业，为合作伙伴提供技术、营销、资源等多方面的支持，与合作伙伴共建工业自动化新生态。",
        },
        {
          name: `keywords`,
          content: () => "设纪元供应链合作_工业自动化商品采购_制造商_贸易商",
        },
      ],
    });
    return {
      firmverifyForm,
      verify,
      goback,
      gonext,
    };
  },
});
