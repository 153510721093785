
import { reactive, defineComponent, ref } from "vue";
import type { TabsPaneContext } from "element-plus";
import type { FormInstance, FormRules } from "element-plus";
import { userApi } from "@/service/api";
import { userStore } from "@/stores/userStore";
import { sjyAlert } from "@/utils/alert";
import { useRouter } from "vue-router";
export default defineComponent({
  setup() {
    const informationForm = reactive({
      //修改登录密码 短信验证码框
      password: "",
    });
    let router = useRouter();

    const ruleFormRef = ref<FormInstance>();
    const rules = reactive<FormRules>({
      password: [
        {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{8,16}$/,
          message: "密码需为8-16位大小写字母、数字组合",
          trigger: "blur",
        },
      ],
    });
    let change = () => {
      informationForm.password = informationForm.password.toString();
      //console.log(informationForm.password);
      userApi.changePwd(informationForm).then((res: any) => {
        //console.log(res);
        // 修改完密码退出登录重新登录
        if (res.errno == 0) {
          userStore().deleteUserInfo();
          sjyAlert("您的密码已修改,请重新登录", "success", () => {
            router.push({ name: "login" }); // userRouter
          });
        }
        if (res.errno == 10003) {
          sjyAlert("请输入8-16位大小写字母、数字组合的登录密码", "error");
        }
      });
    };

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          change();
        } else {
          // console.log("error submit!", fields);
        }
      });
    };

    return {
      informationForm,
      change,
      submitForm,
      ruleFormRef,
      rules,
    };
  },
});
