
import { defineComponent, onMounted, reactive, ref, inject } from "vue"
import { goodsApi } from "@/service/api"
import { useRouter } from "vue-router"
import { showPrice } from "@/utils/public"
export default defineComponent({
	components: {},
	setup() {
		const isActive = ref(0)
		const title = [
			{ name: "推荐", cid: 0 },
			{ name: "末端执行器", cid: 3 },
			{ name: "输送机构", cid: 2 },
			{ name: "自动化单元", cid: 1 },
			{ name: "工业机器人", cid: 7 },
			{ name: "控制柜", cid: 6 },
			{ name: "视觉方案", cid: 5 },
		]
		let goodsWhere = reactive({
			params: {
				cid: 3,
				pageSize: 5,
				pageIndex: 1,
				sort: 0,
			},
		})
		let goodslit = reactive({
			datas: {
				data: [] as any[],
				pageSize: 0,
				total: 0,
			},
		})
		//获取商品列表
		let getGoodsList = () => {
			loading.value = true
			goodsApi.getGoodsList(goodsWhere).then((res: any) => {
				if (res.errno == 0) {
					goodslit.datas = res.datas
					loading.value = false
				}
			})
		}
		let router = useRouter()
		let goGoods = (goodsid: number) => {
			const { href } = router.resolve({
				name: "productDetail",
				params: {
					id: goodsid,
				},
			})
			window.open(href, "_blank")
			return false
		}
		let goClass = () => {
			const { href } = router.resolve({
				name: "goods-class",
				params: {
					classid: title[isActive.value].cid,
				},
			})
			window.open(href, "_blank")
			return false
		}
		const indicator = [
			{
				name: "末端执行器",
				top: "220px",
				left: "585px",
				indexs: 1,
			},
			{
				name: "输送机构",
				top: "252px",
				left: "507px",
			},
			{
				name: "自动化单元",
				top: "80px",
				left: "675px",
			},
			{
				name: "工业机器人",
				top: "252px",
				left: "372px",
			},
			{
				name: "控制柜",
				left: "828px",
				top: "316px",
			},
			{
				name: "视觉方案",
				top: "82px",
				left: "510px",
			},
		]
		const loading = ref(true)
		let active = (val: number) => {
			isActive.value = val
			// goodsWhere.params.cid = title[val].cid
			// console.log(goodsWhere.params.cid)
			if (val == 0) {
				goodsWhere.params.cid = 0
				goodsWhere.params.sort = 2
			} else {
				goodsWhere.params.cid = title[val].cid
				goodsWhere.params.sort = 0
			}
			getGoodsList()
		}
		const staticUrl = inject("staticUrl")
		let changeUrl = (val: any) => {
			if (val == undefined) {
			} else {
				if (val.startsWith("https")) {
					return val
				} else {
					return staticUrl + val
				}
			}
		}
		onMounted(() => {
			getGoodsList()
		})
		return {
			isActive,
			title,
			active,
			indicator,
			goodslit,
			changeUrl,
			loading,
			goGoods,
			goClass,
			showPrice,
		}
	},
})
