
import { defineComponent, reactive, toRefs } from "vue"

export default defineComponent({
  components: {},
  name: "ConfirmModal",
  props: {
    tips: {
      type: String,
      default: "是否删除?",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    confirmText:{
      type: String,
      default: '删除'
    },
    cancelText:{
      type: String,
      default: '取消'
    }
  },
  emits: {
    onConfirm: null,
    onCancel: null,
  },
  setup(props: any, { emit }) {
    let tempData = Object.assign({}, props)
    const propsData = reactive(tempData)

    let handleConfirm = () => {
      propsData.visible = false;
      emit("onConfirm")
    }
    let handleCancel = () => {
      propsData.visible = false;
      emit("onCancel")
    }
    return { handleConfirm, handleCancel, ...toRefs(propsData) }
  },
})
