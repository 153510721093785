
import { reactive, defineComponent, ref, onMounted } from "vue";
import { userStore } from "@/stores/userStore";
import { userApi, publicApi } from "@/service/api";
import { sjyAlert } from "@/utils/alert";
import vueQr from "vue-qr/src/packages/vue-qr.vue";
import InvoiceDialog from "@/components/common/Dialog/InvoiceDialog.vue";

export default defineComponent({
  components: { vueQr, InvoiceDialog },
  setup() {
    interface result {
      data: any;
    }
    // 控制线下汇款提示出现与隐藏
    let show = ref(false);
    let hintShow = () => {
      show.value = !show.value;
    };

    //点击会员类型赋予边框

    let vip = ref("0");
    let vipShow = () => {
      vip.value = "0";
      vipData.vipId = 16;
      vipData.total = 49;
    };

    let vipShow1 = () => {
      vip.value = "1";
      vipData.vipId = 17;
      vipData.total = 120;
    };

    let vipShow2 = () => {
      vip.value = "2";
      vipData.vipId = 18;
      vipData.total = 450;
    };

    let changeMoney = (vips: any) => {
      if (vips == "0") {
        return 49;
      }
      if (vips == "1") {
        return 120;
      }
      if (vips == "2") {
        return 450;
      }
    };

    let userinfot = userStore();
    //  发送到后端的购买数据
    let vipData = reactive({
      type: 0,
      orderType: 2,
      vipId: 0,
      total: 0,
      userid: userinfot.id,
      channel: 2,
    });
    // 对公支付手机号正则中间不展示

    let newTel =
      userinfot.mobile.substr(0, 3) + "****" + userinfot.mobile.substr(7);

    // 打开弹框
    let dialogVisible = ref(false);
    interface addressType {
      data: any;
    }
    let setDialogVisible = () => {
      sonRef.value?.setVisible(true);
      //console.log();
    };
    const sonRef = ref<InstanceType<typeof InvoiceDialog>>();
    // 关闭弹框
    let mclose = () => {
      dialogVisible.value = false;
      sonRef.value?.setVisible(dialogVisible.value);
    };
    // 点击支付并处理二维码
    const qrCodeValue = ref(""); //二维码内容
    let hintHide = (val: number) => {
      show.value = false;

      vipData.type = val;
      //console.log(vipData);
      userApi.userPay(vipData).then((res: any) => {
        console.log(res);
        if (res.errno == 0) {
          if (vipData.type == 1) {
            setDialogVisible();
            qrCodeValue.value = res.data.code_url;
            console.log(qrCodeValue);
          } else if (vipData.type == 2) {
            window.open(res.data);
          }
        } else {
          sjyAlert(res.errmsg, "error");
        }
      });
    };

    let count = ref(60);
    let isShow = ref(true);
    //let 秒后再获取
    let countShowText = ref("获取验证码");
    var times: any;

    let countdown = () => {
      count.value = 60; //赋值60秒
      isShow.value = false;

      getPhongCode();
      times = setInterval(() => {
        count.value = count.value - 1; //递减
        if (count.value <= 0) {
          // <=0 变成获取按钮
          isShow.value = true;
          clearInterval(times);
        }
        countShowText.value = count.value + "秒后再获取";
      }, 1000); //1000毫秒后执行
    };

    let getPhongCode = () => {
      //这里应该验证一下手机号的正确性  TODO
      publicApi.bankCode().then((res: any) => {
        if (res.errno == 0) {
          sjyAlert("发送成功", "success");
        } else {
          isShow.value = true;
          clearInterval(times);
          sjyAlert(res.errmsg, "error");
        }
      });
    };

    // 获取用户信息 判断是否是子账号

    let getInformation: result = reactive({
      data: {},
    });

    let getuserInformation = () => {
      userApi.getInformation().then((res: any) => {
        getInformation.data = res.datas;
        console.log(res);
      });
    };

    onMounted(() => {
      vipShow();
      getuserInformation();
    });
    return {
      show,
      hintShow,
      hintHide,
      vip,
      vipShow,
      vipShow1,
      vipShow2,
      changeMoney,
      vipData,
      userinfot,

      newTel,
      qrCodeValue,
      dialogVisible,
      sonRef,
      mclose,
      setDialogVisible,
      countdown,
      isShow,
      getPhongCode,
      countShowText,
      getInformation,
    };
  },
});
