
import { reactive, ref, defineComponent, onMounted } from "vue"
import { userApi, publicApi } from "@/service/api"
import { userStore } from "@/stores/userStore"
import { sjyAlert } from "@/utils/alert"
import vueQr from "vue-qr/src/packages/vue-qr.vue"
import InvoiceDialog from "@/components/common/Dialog/InvoiceDialog.vue"
import * as _ from "lodash"
import { value } from "dom7"
export default defineComponent({
  components: { vueQr, InvoiceDialog },
  setup() {
    let shows = ref(false)
    const hideNumber = () => {
      shows.value = !shows.value
    }

    // 控制线下汇款提示出现与隐藏
    let show = ref(false)
    let hintShow = () => {
      show.value = !show.value
    }

    let vip = ref("1")

    let money = ref(1)

    let self = ref(true)
    let tabs = () => {
      vip.value = "8"
      self.value = false
    }

    let changeMoney = (vips: any) => {
      if (vips == "1") {
        vipData.total = 50
        return 50
      }
      if (vips == "2") {
        vipData.total = 100
        return 100
      }
      if (vips == "3") {
        vipData.total = 300
        return 300
      }
      if (vips == "4") {
        vipData.total = 500
        return 500
      }
      if (vips == "5") {
        vipData.total = 1000
        return 1000
      }
      if (vips == "6") {
        vipData.total = 3000
        return 3000
      }
      if (vips == "7") {
        vipData.total = 5000
        return 5000
      }
      if (vips == "8") {
        vipData.total = money.value
        return money.value
      }
    }

    // 个人信息拿余额
    interface result {
      data: any
    }
    let getInformation: result = reactive({
      data: {},
    })
    let balance = ref(0)
    let getuserInformation = () => {
      userApi.getInformation().then((res: any) => {
        getInformation.data = res.datas
        console.log(res)
      })
    }
    // 对公支付手机号正则中间不展示
    let userInfo = userStore()
    let newTel =
      userInfo.mobile.substr(0, 3) + "****" + userInfo.mobile.substr(7)

    // 计算余额 两种齿轮币余额相加
    let a = ref(0)
    let moneys = () => {
      if (getInformation.data.sgear == null) {
        a.value = 0
      } else {
        a = getInformation.data.sgear
      }
    }
    //  发送到后端的用户充值数据
    let vipData = reactive({
      type: 0,
      orderType: 1,
      total: 0,
      userid: userInfo.id,
      channel: 2,
    })
    // 打开弹框
    let dialogVisible = ref(false)
    interface addressType {
      data: any
    }
    let setDialogVisible = () => {
      sonRef.value?.setVisible(true)
      //console.log();
    }
    const sonRef = ref<InstanceType<typeof InvoiceDialog>>()
    // 关闭弹框
    let mclose = () => {
      dialogVisible.value = false
      sonRef.value?.setVisible(dialogVisible.value)
    }

    // 处理二维码
    const qrCodeValue = ref("") //二维码内容
    //  点击付款方式进行付款
    let hintHide = (val: number) => {
      show.value = false
      vipData.type = val
      let value = vipData.total

      const boolean = new RegExp("^[1-9][0-9]*$").test(value + "")
      setTimeout(() => {
        if (!boolean) {
          sjyAlert("充值金额必须为大于0的整数", "error")
          value = 0
        } else {
          if (value < 0 || value > 10000) {
            sjyAlert("充值金额必须大于0小于10000", "error")
          } else {
            userApi.userPay(vipData).then((res: any) => {
              console.log(res)
              if (res.errno == 0) {
                if (vipData.type == 1) {
                  setDialogVisible()
                  qrCodeValue.value = res.data.code_url
                  console.log(qrCodeValue)
                } else if (vipData.type == 2) {
                  window.open(res.data)
                }
              } else {
                // sjyAlert(res.errmsg, "error");
              }
            })
          }
        }
      }, 200)

      //console.log(vipData);
    }

    //输入充值额度正则
    let checkAge = (value: any) => {
      const boolean = new RegExp("^[1-9][0-9]*$").test(value)
      if (!value) {
        sjyAlert("充值金额不能为空", "error")
        //money.value = 1
      } else if (!boolean) {
        sjyAlert("充值金额必须为整数", "error")
        money.value = 1
      } else {
        if (value < 0) {
          sjyAlert("充值金额必须大于0", "error")
          money.value = 1
        } else if (value > 10000) {
          sjyAlert("充值金额最大10000", "error")
          money.value = 10000
        }
      }

      //todo
      // setTimeout(() => {
      //   if (!boolean) {
      //     sjyAlert("充值金额必须为整数", "error");
      //   } else {
      //     if (value < 0) {
      //       sjyAlert("充值金额必须大于0", "error");
      //     } else {
      //     }
      //   }
      // }, 200);
    }

    let count = ref(60)
    let isShow = ref(true)
    //let 秒后再获取
    let countShowText = ref("获取验证码")
    var times: any

    let countdown = () => {
      count.value = 60 //赋值60秒
      isShow.value = false

      getPhongCode()
      times = setInterval(() => {
        count.value = count.value - 1 //递减
        if (count.value <= 0) {
          // <=0 变成获取按钮
          isShow.value = true
          clearInterval(times)
        }
        countShowText.value = count.value + "秒后再获取"
      }, 1000) //1000毫秒后执行
    }

    let getPhongCode = () => {
      //这里应该验证一下手机号的正确性  TODO
      publicApi.bankCode().then((res: any) => {
        if (res.errno == 0) {
          sjyAlert("发送成功", "success")
        } else {
          isShow.value = true
          clearInterval(times)
          sjyAlert(res.errmsg, "error")
        }
      })
    }

    onMounted(() => {
      getuserInformation()
    })

    return {
      show,
      hideNumber,
      balance,
      shows,
      hintShow,
      hintHide,
      money,
      vip,
      self,
      tabs,
      changeMoney,
      getuserInformation,
      moneys,
      getInformation,
      a,
      newTel,
      userInfo,
      vipData,
      sonRef,
      mclose,
      dialogVisible,
      setDialogVisible,
      qrCodeValue,
      checkAge,
      count,
      isShow,
      countShowText,
      countdown,
      getPhongCode,
    }
  },
})
