
import { defineComponent, ref, inject } from "vue"
import { useRoute, useRouter } from "vue-router"

export default defineComponent({
	components: {},
	name: "goodsrealitem",
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
		itemWidth: {
			type: Number,
			default: 294,
		},
	},
	setup(props: any) {
		const staticUrl = inject("staticUrl")

		let getImgUrl = inject(
			"getImgUrl",
			(index: string) => {
				return ""
			},
			false
		)
		let router = useRouter()
		console.log(props.data, "xixixixix")

		let goto = (goodsid: any, id: any) => {
			let gid = 0
			if (goodsid && goodsid > 0) {
				gid = goodsid
			} else {
				gid = id
			}
			router.push({
				name: "productDetail",
				params: {
					id: gid,
				},
			})
		}

		return { goto, staticUrl, getImgUrl }
	},
})
