import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f2cb2260"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "collection",
  style: { 'margin-top': '20px' }
}
const _hoisted_2 = { class: "collection-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_share = _resolveComponent("share", true)!
  const _component_templete = _resolveComponent("templete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_templete, null, {
        default: _withCtx(() => [
          _createVNode(_component_share, {
            douban: false,
            QZone: false,
            url: _ctx.routerPath,
            title: _ctx.names,
            description: _ctx.desc,
            image: _ctx.images
          }, null, 8, ["url", "title", "description", "image"])
        ]),
        _: 1
      })
    ])
  ]))
}