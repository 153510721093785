import axios from "../service";

//作品上传历史

let { get, post } = axios;

const uploadHistory = {
    uploadHistory(status:number=0) {
        return post("pc/user/works", {status});
      },
};
export default uploadHistory;
