
import { defineComponent,ref,reactive, onMounted } from "vue";
import HtmlFooter from "@/components/HtmlFooter.vue";
import HomeHead from "@/components/HomeHead.vue";
import CurrentAddress from "@/components/common/CurrentAddress.vue";
import GoodsList from "@/components/goods/project/GoodsList.vue";
import { goodsApi, orderApi } from "@/service/api";
import { useRoute, useRouter } from "vue-router";
import { sjyAlert } from "@/utils/alert";
import { updateCartTotal } from "@/utils/public"

export default defineComponent({
  components: {GoodsList, HtmlFooter, HomeHead, CurrentAddress },
  setup() {
    let loading = ref(false)

    let goodsWhere = reactive({
     
    });

    let goodslit = reactive({
      datas:{
        data:[],
        pageSize:0,
        total:0
      }
    })

     //获取商品列表
     let getGoodsList = () => {
      loading.value= true;
      goodsApi.getHotGoodsList(goodsWhere).then((res:any) => {
        if(res.errno==0){
          goodslit.datas = res.datas
          loading.value= false;
        }        
      });
    };
    interface orderDataType {
      errno: number;
      datas: any;
      errmsg: string;
    }
    const orderData: orderDataType = reactive({
      errno: 0,
      errmsg: "",
      datas: [],
    });

    const params = useRoute()
    const router = useRouter()
    let orderId = parseInt(params.params.id as string)
    /**
     * 获取订单信息
     */
     let getOrderInfo = () => {
      loading.value = true
      orderApi.getOrderInfo(orderId).then((res: any) => {
        loading.value = false
        if (res.errno == 0) {
          if (res.datas.list && res.datas.id < 1) {
            return false
          } else if (res.datas.status != 2) {
            //该订单已经完成，显示订单详细页
            router.push({ name: "tobepaid", params: { id: orderId } })
          } else {
            orderData.datas = res.datas
          }
          updateCartTotal()
        } else {
          sjyAlert(res.errmsg, "error")
        }
      })
    }

    //跳转到下载页面
    let gotoDown = ()=>{
      router.push({ name: "orderdown", params: { id: orderId } })
    }

    //跳转到首页
    let gotoHome = ()=>{
      router.push({ name: "home"})
    }

    //跳转到订单详细页
    let gotoDetai = ()=>{
      router.push({ name: "orderover", params: { id: orderId }})
    }
    onMounted(()=>{
      getGoodsList()
      getOrderInfo()
    })
    return {loading,goodslit,orderData,gotoDetai,gotoHome,gotoDown};
  },
});
