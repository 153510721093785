
import {
	defineComponent,
	onMounted,
	reactive,
	ref,
	watch,
	computed,
	nextTick,
	toRef,
} from "vue"
import { videoApi } from "@/service/api"
import { stringify } from "qs"
import "vue3-video-play/dist/style.css"
//@ts-expect-error
import vue3VideoPlay from "vue3-video-play"
export default defineComponent({
	components: {
		vue3VideoPlay,
	},
	setup() {
		interface videoListType {
			datas: any
		}

		const videos: videoListType = reactive({
			datas: [],
		})
		const videoListRef = ref<HTMLDivElement | null>(null)
		// const gap = 20; // 左右两边的间隔
		const currentIndex = ref(0)
		const canScrollLeft = computed(() => currentIndex.value > 0)
		const canScrollRight = computed(
			() => currentIndex.value < videos.datas.length - 3
		)
		let showleft = ref(true)

		const scrollLeft = () => {
			if (canScrollLeft.value) {
				currentIndex.value--
			}

			if (currentIndex.value == 0) {
				showleft.value = true
			} else {
				showleft.value = false
			}
		}
		const scrollRight = () => {
			if (canScrollRight.value) {
				currentIndex.value++
			} else {
				currentIndex.value = 0 // 将 currentIndex 设置为 0，以滚动到第一个视频
			}

			if (currentIndex.value == 0) {
				showleft.value = true
			} else {
				showleft.value = false
			}
		}

		const updateVideoListPosition = (type: boolean = true) => {
			const videoList = videoListRef.value
			if (videoList) {
				const videoItemWidth = videoList.children[0].clientWidth
				const maxOffset = videos.datas.length * videoItemWidth - 1168 - 18
				let newPosition
				if (type) {
					newPosition = -(currentIndex.value - 1) * videoItemWidth - 48
				} else {
					newPosition = -currentIndex.value * videoItemWidth
				}
				newPosition = Math.max(newPosition, -maxOffset)
				newPosition = Math.min(newPosition, 0)
				videoList.style.transform = `translateX(${newPosition}px)`
			}
		}

		let getVideoList = () => {
			videoApi.homeVideoList().then((res: any) => {
				for (let i = 0; i < res.datas.length; i++) {
					res.datas[i].isMouseOver = false
					res.datas[i].position = {
						top: 0,
						left: 0,
					}
				}
				videos.datas = res.datas
			})
		}

		// 弹框
		const selectedVideoIndex = ref(-1) // 当前被点击的视频索引
		const isModalVisible = ref(false) // 弹窗是否可见

		// 视频点击事件处理函数
		const handleVideoClick = (index: any) => {
			selectedVideoIndex.value = index
			isModalVisible.value = true
		}

		// 弹窗视频点击事件处理函数
		const handleModalVideoClick = () => {
			isModalVisible.value = false
		}
		let showPlayOverlay = ref(false)
		let showOver = (val: number) => {
			videos.datas[val].isMouseOver = true
		}
		let showOut = (val: number) => {
			videos.datas[val].isMouseOver = false
		}

		let sonRef = ref()

		let handleOutsideClick = (event: any) => {
			const modalElement = sonRef.value.querySelector(".d-player-wrap")
			if (modalElement) {
				if (!modalElement.contains(event.target)) {
					isModalVisible.value = false
				}
			}
		}
		let options: any = reactive({})
		options = {
			width: "80%", //播放器高度
			height: "80%", //播放器高度
			color: "#fff", //主题色
			title: "", //视频名称
			//src: "https://www.sjyuan.net.cn/filespath/files/video/ZS0600222.mp4", //视频源
			muted: false, //静音
			webFullScreen: false,
			speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
			autoPlay: false, //自动播放
			loop: false, //循环播放
			mirror: false, //镜像画面
			ligthOff: true, //关灯模式
			volume: 0.3, //默认音量大小
			// control: videoFootShow.value, //是否显示控制
			controlBtns: [
				"audioTrack",
				// "quality",
				// "speedRate",
				// "setting",
				// "pip",
				// "pageFullScreen",
				"fullScreen",
			], //显示所有按钮,
		}

		watch(currentIndex, (value, oldValue) => {
			// console.log(currentIndex.value, "currentIndex")
			nextTick(() => {
				updateVideoListPosition(oldValue < value)
			})
		})
		onMounted(() => {
			getVideoList()
		})
		return {
			scrollLeft,
			scrollRight,
			videos,
			canScrollLeft,
			canScrollRight,
			videoListRef,
			selectedVideoIndex,
			isModalVisible,
			handleVideoClick,
			handleModalVideoClick,
			showPlayOverlay,
			showOver,
			showOut,
			handleOutsideClick,
			sonRef,
			showleft,
			options,
		}
	},
})
