
import { defineComponent, reactive } from "vue"
import CountDown from "@/components/common/CountDown.vue"

export default defineComponent({
  components: { CountDown },
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
    },
    counterStore: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["goBuy", "updateOrderStatus", "updateOrderSendStatus"],
  setup(props: any, { emit }) {
    let showMobile = (mmobile: string) => {
      var reg = /^(\d{3})\d{4}(\d{4})$/
      return mmobile.replace(reg, "$1****$2")
    }

    interface orderDataType {
      errno: number
      datas: any
      errmsg: string
    }
    const orderData: orderDataType = reactive({
      errno: 0,
      errmsg: "",
      datas: [],
    })

    orderData.datas = props.orderData.datas

    let goBuy = () => {
      emit("goBuy")
    }

    let counterStore: any = props.counterStore

    let updateOrderStatus = (status: number) => {
      if (status == 4) {
        emit(
          "updateOrderStatus",
          status,
          "确认删除该订单？",
          "确定",
          "取消",
          "已删除"
        )
      } else {
        emit(
          "updateOrderStatus",
          status,
          "确认取消该订单？",
          "确定",
          "取消",
          "已取消"
        )
      }
    }

    let updateOrderSendStatus = (status: number) => {
      emit("updateOrderSendStatus", status)
    }

    let getTime = (val: number = 0) => {
      let time = new Date().getTime() / 1000
      return val - time
    }

    return {
      showMobile,
      goBuy,
      orderData,
      counterStore,
      updateOrderStatus,
      updateOrderSendStatus,
      getTime,
    }
  },
})
